import { useMountEffect, useUpdateEffect } from "@react-hookz/web"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { groupsAPI } from "../../../../API/groupsAPI"
import { userAPI } from "../../../../API/userAPI"
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { MyTable } from "../../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../../components/table/MyTable/MyTableHead"
import { useMyTable } from "../../../../components/table/MyTable/useMyTable"
import { Card } from "../../../../components/UI/Card"
import { Head } from "../../../../components/UI/Head"
import { useComboSearch } from "../../../../hooks/url/useComboSearch"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import { getBoolFromString, isEmpty, loader } from "../../../../js-helpers/helpers"
import { useExportData } from "../../../../services/export/useExportData"
import { FiltersPageUsers } from "../../../members/pageUsers/FilterPageUsers/FiltersPageUsers"
import { prepareItem } from "../../../members/pageUsers/PageUsers"
import { pageUsersConfig } from "../../../members/pageUsers/pageUsersTables"

const {searchParams, getTable} = pageUsersConfig

export const PageGroupsCreate = () => {
  const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		,
		[urlDivision, setUrlDivision],
		[urlSubDivision, setUrlSubDivision], 
    ,
    [urlBlocked, setUrlBlocked],	
    [urlHaveDevice, setUrlHaveDevice], 
    ,
    [urlUser, setUrlUser], [urlDevice, setUrlDevice]
  ] = useComboSearch(searchParams)

  const [groupTitle, setGroupTitle] = useState('')

  useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1)
	}, [
		urlSearchQuery, urlLimit, urlDivision, urlHaveDevice,
		urlSubDivision, urlUser, urlDevice, urlBlocked
	])

  const clearFilters = () => {
    setUrlDivision('')
    setUrlSubDivision('')
    setUrlUser('')
    setUrlHaveDevice('true')
    setUrlDevice('')
    setUrlSearchQuery('')
    setUrlBlocked('false')
  }

  const urlParamsRequestQuery = {
		search: urlSearchQuery,
    ever_have_device: getBoolFromString(urlHaveDevice),
    account__is_active: getBoolFromString(urlBlocked, {invert: true}),
		account__division__in: urlDivision,
		account__subdivision__in: urlSubDivision,
    account__keycloak_id__in: urlUser,
    device2__serial_number__in: urlDevice
	}
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		...urlParamsRequestQuery
	}

  const {data: users, isReady, getData} = useFetchStates(
		(_page = urlPage) => userAPI.getAllUsersWithCustomParams({...requestQuery, page: _page,}),
		{noInitFetch: true, initData: {results: []}})

  const prepareUsers = useMemo(() => users.results.map(prepareItem), [users])


  const onCreateGroup = async (usersData) => {  
    const usersList = usersData.map(item => item.keycloak_id)

    const submitData = {
      title: groupTitle,
      users_ids: usersList
    }

    try {
      await groupsAPI.createGroup(submitData)

      setGroupTitle('')
      clearFilters()
      exportStates.clearIncludedIDS()
      toast.success('Группа создана!', {autoClose: 5000})

    } catch (e) {
      if (e?.response?.data?.errors?.[0]?.attr === 'title') {
        if (e?.response?.data?.errors?.[0]?.code === 'unique') {
          toast.error(
          <>Группа с названием <b>{submitData.title}</b> уже существует!</>, {autoClose: 5000})
        } else if (e?.response?.data?.errors?.[0]?.code === 'blank') {
          toast.error(
            'Введите название группы!', {autoClose: 5000})
        }
      }
      else {
        toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
      }
    }
  }

  const {
      ButtonToggleExport,
      CounterToExport,
      ButtonSaveExport,
      checkBoxesColl,
      ...exportStates
    } = useExportData({
      requestMethod: userAPI.getAllUsersWithCustomParams,
      requestParams: urlParamsRequestQuery,
      prepareItemFunc: prepareItem,
      pageData: prepareUsers,
      onSaveExport: onCreateGroup
  })

  useMountEffect(() => {
    exportStates.setMode('create')
  })
  
  
  useEffect(() => {
    exportStates.setOnSaveExport(onCreateGroup)
  }, [groupTitle])

  const isEmptySearchQuery = isEmpty(urlSearchQuery)

  const tableOptions = useMemo(() =>
    getTable(isEmptySearchQuery, true, checkBoxesColl, true),
  [isEmptySearchQuery])

  const {tableHeads, tableColgroup} = useMyTable(tableOptions)

  const isInValid = useMemo(() => 
    groupTitle.length > 150 || (groupTitle.length > 0 && groupTitle.trim()?.length === 0)
  , [groupTitle])

  return (
    <>
      <ButtonGoBack className={'float-right'}/>
      <Head children={'Создание группы'}/>
     
      <Card secondary className={'mb-50'}>
        <h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase p-0 pt-50'}>
          Название группы
        </h3>

        <input  value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
                className={`form-control col-6 ${isInValid ? 'is-invalid' : ''}`}/>

        {(isInValid) && <div className='mt-50 text-danger font-small-1'>{groupTitle.length === 0  || groupTitle.length !== 0 && groupTitle?.trim()?.length === 0 ? 'Введите название группы' : 'Максимальная длина - 150 символов'}</div>}

      </Card>

      <FiltersPageUsers page={'groups'}/>

      <CounterToExport isCreateMode={true}/>

      <MyTable  colgroup={tableColgroup}
			          renderCardContainer={true}
			          withScroll
			          appendElement={<ButtonSaveExport mode={'create'} isDisabled={groupTitle.length == 0 || isInValid}/>}
			          showEmptyFoundDataMessage={users?.count === 0 && isReady}
			          isDataReady={isReady}
			          paginationAllCount={users?.count}>
				
		    <MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(isReady)}
				             data={prepareUsers}
				             tableOptions={tableOptions}
				             highestRowToFill={45}
				/>
			</MyTable>
    </>
  )
}