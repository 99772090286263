import React, {useEffect} from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import {ListServiceButtons} from '../../../../components/UI/ListServiceButtons';
import {loader} from '../../../../js-helpers/helpers';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {contactsAPI} from '../../../../API/contactsAPI';
import {baseTableOptions, externalContactsColls, internalContactsColls} from './userContactsTableOptions';
import {MyTable} from '../../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../../components/table/MyTable/MyTableBody';
import {useMyTable} from '../../../../components/table/MyTable/useMyTable';
import {useUpdateEffect} from '@react-hookz/web';
import {useComboSearch} from '../../../../hooks/url/useComboSearch';
import {urlParams} from '../../../../constants/urlParams';
import {SearchInput} from '../../../../components/form/SearchInput';

export const contactTypes = [
	{text: 'Внутренние', service: 'shared', type: 3, extraColls: internalContactsColls},
	{text: 'Внешние', service: 'outer', type: 1, extraColls: externalContactsColls},
	{text: 'Личные', service: 'personal', type: 2},
]

const getAdditionalColls = typeSearch =>
	contactTypes.find(({service}) => service === typeSearch)?.extraColls || [];

const {PAGE, LIMIT, TYPE, SEARCH, SORT} = urlParams
const pageSearchParams = [
	{param: TYPE, init: contactTypes[0].service},
	{param: PAGE, init: 1},
	{param: SEARCH, init: '', debounce: 333},
	{param: LIMIT},
	{param: SORT, init: 'contact__last_name'},
]

export const PageUserContacts = () => {
	const {keycloakID} = useParams()
	const {setPageHead} = useOutletContext()

	const [
		[urlType],
		[urlPage, setUrlPage],
		[urlSearchQuery, setUrlSearchQuery],
		[urlLimit],
		[urlSortField, setUrlSortField],
	] = useComboSearch(pageSearchParams)
	
	const {data: contactsState, getData: getContacts, isReady} = useFetchStates(
		() => contactsAPI.getUserContacts({
        keycloakID,
				page: urlPage,
				contact__type: typeContactID,
				contact__folders2__name: !typeContactID ? urlType : null,
				page_size: urlLimit,
				search: urlSearchQuery,
				ordering: urlSortField
			}
		), {noInitFetch: true})
	
	const typeContactID = contactTypes.find(({service}) => service === urlType)?.type
	
	useUpdateEffect(() => {
		setUrlPage(1)
		setUrlSortField('contact__last_name')
	}, [urlType])
	
	useEffect(() => {
		const typeContactName = contactTypes
			.find(({service}) => service === urlType)?.text || 'Все'
		
		const head = typeContactName
			? `${typeContactName} контакты`
			: `Контакты папки "${urlType}"`
		
		setPageHead({head})
	}, [urlType])
	
	useEffect(() => {
		getContacts()
	}, [urlPage, urlType, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		getContacts()
	}, [urlSearchQuery, urlLimit])
	
	const {tableHeads, tableColgroup, tableOptions} = useMyTable(
		[...baseTableOptions, ...getAdditionalColls(urlType)],
		{memoDependencies: [contactsState]})
	
	return (
			<main className={'row no-gutters'}>
				
				<ul className={'width-15-per list-gap-025r list-unstyled mr-1'}>
					
					<ListServiceButtons
						searchName={'type'}
						init={contactTypes[0].service}
						arrServices={contactTypes}
					/>
				</ul>
				
				<div className={'col'}>
					<SearchInput
						withBorder
						className={'mb-1'}
						onChange={e => setUrlSearchQuery(e.target.value)}
						placeholder={'Поиск по всем полям контактов...'}
						defaultValue={urlSearchQuery}
					/>
					
					<MyTable
						isDataReady={isReady}
						showEmptyFoundDataMessage={urlSearchQuery}
						colgroup={tableColgroup}
						paginationAllCount={contactsState?.count}>
						
						<MyTableHead heads={tableHeads}/>
						
						<MyTableBody
							className={`${loader(isReady)}`}
							data={contactsState?.results}
							tableOptions={tableOptions}
							highestRowToFill={45}
						/>
					
					</MyTable>
				</div>
			</main>
	)
};