import {isEqualArrays, loader} from '../../../../js-helpers/helpers';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {useEffect, useMemo, useRef} from 'react';
import {Card} from '../../../../components/UI/Card';
import {divisionsAPI} from '../../../../API/divisionsAPI';
import {Button} from '../../../../components/button/Button';
import {Head} from '../../../../components/UI/Head';
import {ExceptionsLists} from '../../../../components/listsAccess/ExceptionsLists';
import {toast} from 'react-toastify';
import {ButtonGoBack} from '../../../../components/button/ButtonGoBack';

const getPageOptions = ({sites, apps}) => {
	const {getDefaultApps, getDefaultSites, postDefaultSites, postDefaultApps} = divisionsAPI
	
	return sites ? {
		head: 'Настройка сайтов по умолчанию',
		getDefaultsMethod: getDefaultSites,
		postDefaultsMethod: postDefaultSites,
		postField: 'site_ids',
		headExceptions: 'Запрещенные сайты: '
	} : {
		head: 'Настройка приложений по умолчанию',
		getDefaultsMethod: getDefaultApps,
		postDefaultsMethod: postDefaultApps,
		postField: 'app_ids',
		headExceptions: 'Доступные приложения: '
	}
}

export function PageDivisionsDefaults({sites, apps}) {
	const type = {sites, apps}
	const initExceptionsRef = useRef([])

	const {head, getDefaultsMethod, postDefaultsMethod, postField, headExceptions} = useMemo(
		() => getPageOptions(type)
		, [sites, apps])
	
	const customSetter = ({data}) => {
    if(apps) {
      initExceptionsRef.current =  data.results
      setExceptions(data.results)
    } else {
      initExceptionsRef.current = data
      setExceptions(data)
    }
		setReady(true)
	}

	const {data: exceptions, isReady, setReady, getData, setData: setExceptions}
		= useFetchStates(() => {
      if(apps) {
        return getDefaultsMethod(1, 999)
      } else {
        return getDefaultsMethod
      }
    },
		{initData: [], noInitFetch: true, customSetter})

	useEffect(() => {
		getData()
	}, [sites, apps])

	const onSubmit = async () => {
		setReady(false)
		const exceptionsIDS = exceptions.map(({id}) => id)
		
		try {
			await postDefaultsMethod({[postField]: exceptionsIDS})
			toast.success('Настройки по умолчанию сохранены!')
			getData()
			
		} catch (e) {
			toast.error(e?.response?.data?.errors?.[0]?.detail)
			setReady(true)
		}
	}

	const exceptionsWithInitField = useMemo(() => 
    exceptions.map(exceptionsItem => {
      const haveIDinInit = initExceptionsRef.current
        .some(({id}) => id === exceptionsItem.id)

      return haveIDinInit
        ? {...exceptionsItem, isInit: true}
        : exceptionsItem
    }), [sites, initExceptionsRef.current, exceptions])

	const isSaveBtnDisabled = useMemo(() =>
		isEqualArrays(initExceptionsRef.current, exceptions)
	, [exceptions])
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			
			<Head>{head}</Head>
			<Card className={loader(isReady)}>
				<Head secondary uppercase children={'настройки доступа'}/>
				
				<ExceptionsLists
					exceptions={exceptionsWithInitField}
					setExceptions={setExceptions}
					type={sites ? 'sites' : 'apps'}
					headExceptions={headExceptions + exceptions.length}
				/>
				
				<Button
					type={'button'}
					save
					disabled={isSaveBtnDisabled}
					onClick={onSubmit}
					className={'d-block ml-auto mt-1'}
				/>
			</Card>
		</>
	)
}