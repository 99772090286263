/* eslint-disable max-lines */
import React, {useEffect, useMemo, useRef} from 'react';
import {MySelectContainer} from './components/MySelectContainer';
import {MySelectToggleButton} from './components/MySelectToggleButton';
import {MySelectList} from './components/MySelectList';
import {SearchInput} from '../SearchInput';
import {MySelectItem} from './components/MySelectItem';
import {InView} from 'react-intersection-observer';
import {isEmpty, isString, loader, toggle } from '../../../js-helpers/helpers';
import {useNewFind} from '../../../hooks/useNewFind';
import {oneOf} from 'prop-types';
import {useFirstMountState, useUpdateEffect} from '@react-hookz/web';
import { selectOptionsOnType } from './MySelectFunctions';
import { MultiDownshift } from './MultiDownshift';

//TODO: добавить FilterSelect с useDownshift вместо Downshift
// внутри должна быть связаная логика с квери урла, нужен ресет, сет другого айтема извне

export const MySearchMultiSelect = ({	
  type, 
  onSelect, 
  onChange,
  disabled, 
  initText, 
  resetDeps = [],
  resetWhen, 
  init = true, 
  noSearchInput,
  className = '', 
  // inputProps = {},
  titleClassName = '', 
  initialSelectedItems,
  defaultIsOpen, 
  activeItemClassName = 'active_gray',
  clearUrl,
  model = null, 
  brand = null,
  division = null}) => {

  const {apiMethod, prepareItems, findHolder} = selectOptionsOnType[type];

  const isFirstMount = useFirstMountState();

  const downshiftActionsRef = useRef(null);

  const prepareInitArray = () => {
    const textArray = initialSelectedItems?.text?.split(',');

    if (type === 'usersWithKeycloakID' || type === 'allSetupAdmins' || type === 'allSmassAdmins' || type === 'divisionsWithUsers') {
      const valuesArray = initialSelectedItems?.value?.split(',');

      const initArray =  textArray?.map((text, index) => ({text, value: valuesArray[index]}))

      return initArray
    } else {
      const initArray =  textArray?.map((text) => ({text, value: text}))

      return initArray
    }
  }

  useUpdateEffect(() => {
		resetWhen && downshiftActionsRef.current?.clearSelection();
	}, [resetWhen]);

  const {
		lastSearchPage,
		searchQuery,
		setSearchQuery,
		searchPage,
		setSearchPage,
		searchItemsStates,
	} = useNewFind(apiMethod);

  const filteredDivision = useMemo(() => {
  if (division) {
    if (division.includes('Без дивизиона')) {
      return division?.split(',')?.filter(div => div !== 'Без дивизиона')?.join(',') 
    } else {
      return division
    }
  } else {
    return undefined
  }
  }, [division])

  useEffect(() => {
		setSearchPage(1);
		searchItemsStates.getData(
			{
				page: 1,
				search: searchQuery,
				page_size: 25,
				model__in: model,
				brand__in: brand,
        division__in: filteredDivision
			}
		);
	}, [searchQuery, model, brand, filteredDivision]);

  const onLastItemVisible = (inView) => {
		inView && searchItemsStates.getData({
			page: searchPage,
			search: searchQuery,
			page_size: 25,
			model__in: model,
			brand__in: brand,
      division__in: filteredDivision
		});
	};

  const preparedItems = useMemo(
		() => prepareItems(searchItemsStates.data),
		[searchItemsStates.data]
	);

  if (!init)
  return (
    <input
      className={`form-control px-75 ${className}`}
      disabled={true}
      defaultValue={initText}
    />
  );

  const showNoResults = isEmpty(preparedItems) && searchItemsStates.isReady;
  const preventLoadNextPage =
  !searchItemsStates.isReady || lastSearchPage || searchItemsStates.error;

  const getSelectedItems = (items, getItemProps) => 
    items?.map((item, index) => {
      const {text, extraText, value} = item;

      const activeItem = items.find(item => 
        item?.value === value);
      const key = isString(value) ? value : index;
      
      return (
        <MySelectItem
          key={key}
          itemProps={{
            activeItem,
            activeItemClassName,
            getItemProps: getItemProps({item, index}),
          }}
        >
          {text}
          
          {extraText && (
            <span className={'ml-auto'}>{extraText}</span>
          )}
        </MySelectItem>
      );
  })
  
    return (
        <MultiDownshift
          onChange={onSelect}
          itemToString={(item) => (item?.text)}
          initialSelectedItems={prepareInitArray()}
          clearUrl={clearUrl}
        >
          {({
            getInputProps,
            getToggleButtonProps,
            getMenuProps,
            getRemoveButtonProps,
            removeItem,
            isOpen,
            inputValue,
            selectedItems,
            getItemProps,
            highlightedIndex,
            toggleMenu,
            getRootProps,
            closeButtonHandled,
            ...actions
          }) => { 

            if (isFirstMount) downshiftActionsRef.current = actions;

            // const order = selectedItems.map(item => item.value);
            // preparedItems.sort((a, b) =>  order.indexOf(b.value) - order.indexOf(a.value))

            const selectedItemsValues = selectedItems?.map(item => item.value);
    
            const selectedItemsSet = new Set(selectedItemsValues);
            // const preparedItemsSet = new Set(searchItemsStates.data);

            const filteredPreparedItems = preparedItems?.filter(
              (item) => !selectedItemsSet.has(item.value)
            );
            // const filteredSelectedItems = selectedItems.filter(
            //   (item) => preparedItemsSet.has(item.value)
            // )

            const filteredSelectedItems = preparedItems?.filter(
              (item) => selectedItemsSet.has(item.value)
            );

            return (
            <MySelectContainer
						containerProps={{
							getRootProps: getRootProps({}, {suppressRefError: true}),
							className,
							isOpen,
						}}
					>
						<MySelectToggleButton
							buttonProps={{
								disabled,
								titleClassName,
								getToggleButtonProps,
								isOpen,
                closeButton: selectedItems.length > 0,
                closeButtonHandled,
							}}
						>

              {initText} {' '}
              {selectedItems?.length === 0 ? '' : `(${selectedItems?.length})`}

						</MySelectToggleButton>
						
						<MySelectList getMenuProps={getMenuProps}>
							{!noSearchInput && (
								<SearchInput
									placeholder={findHolder}
									classNameInput={'text-c-crop'}
									onChange={(e) => setSearchQuery(e.target.value)}
									defaultValue={searchQuery}
								/>
							)}
							
							<ul
								className={`list-unstyled scroll-c overflow-y-auto height-350 scrollbar-width ${toggle(
									isOpen,
									loader(searchItemsStates.isReady)
								)}`}
							>
								{showNoResults && (
									<li
										className={
											'dropdown-item disabled px-1 text-c-transform-none'
										}
									>
										{searchItemsStates.error
											? 'Ошибка загрузки!'
											: 'Не найдено...'}
									</li>
								)}

                {searchQuery ? getSelectedItems(filteredSelectedItems, getItemProps) : getSelectedItems(selectedItems, getItemProps)} 

								{filteredPreparedItems.map((item, index) => {
									const {text, extraText, value} = item;

									const activeItem = selectedItems.find(item => 
                    item?.value === value);

                  const newIndex = selectedItems?.length + index
									const key = isString(value) ? value : newIndex;
									
									return (
										<MySelectItem
											key={key}
											itemProps={{
												activeItem,
												activeItemClassName,
												getItemProps: getItemProps({item, index: newIndex}),
											}}
										>
											{text}
											
											{extraText && (
												<span className={'ml-auto'}>{extraText}</span>
											)}
										</MySelectItem>
									);
								})}
								
								<InView
									as={'li'}
									delay={333}
									className={`py-25 ${loader(searchItemsStates.isReady, {
										size: 'small',
									})}`}
									skip={preventLoadNextPage}
									onChange={onLastItemVisible}
								/>
							</ul>
						</MySelectList>
					</MySelectContainer>
          )}}
        </MultiDownshift>
    )
}

MySearchMultiSelect.propTypes = {
	type: oneOf([
		// 'devises',
		'allDevices',
		'users',
		'usersWithKeycloakID',
		'rules',
		'rulesWithFullData',
		'usersWithoutDevice',
		'usersWithCalls',
		'subdivisions',
		'divisions',
    'divisionsWithUsers',
		'launcherVersions',
		'allSetupAdmins',
    'allSmassAdmins',
		'allBrands',
		'allModels',
    'channels'
	]).isRequired,
};
