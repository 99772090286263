import React, {useRef} from 'react';
import {useClickOutside, useSafeState} from '@react-hookz/web';
import {useTimeout} from '../../hooks/useTimeoutFn';
import {isEmpty} from '../../js-helpers/helpers';
import {useUpdateEffect} from '@react-hookz/web/esnext';
import PropTypes from 'prop-types';
import {flip, offset, shift, useFloating,} from '@floating-ui/react-dom';
import {CSSTransition} from 'react-transition-group';

export const WithTooltip = (
	{
		elem,
		tooltips = [],
		showAbove = 0,
		show = true,
		placement = 'top-start',
		className = '',
    containerClassName = '',
		children,
		delay = 0,
		hideDelay = 250,
	}) => {
	
	const arrowRef = useRef(null)
	
	
	const {
		x, y, reference, floating, strategy, placement: currentPlacement,
	} = useFloating({
		middleware: [
			flip(),
			shift({padding: 15}),
			offset(8),
		],
		placement,
	})
	
	const [showState, setShowState] = useSafeState(false)
	const transitionRef = useRef(null)
	
	useClickOutside(transitionRef, () => setShowState(false))
	
	const [showWithDelay, showCancel] =
		useTimeout(() => setShowState(true), delay)
	
	const [hideWithDelay, hideCancel] =
		useTimeout(() => setShowState(false), hideDelay)
	
	useUpdateEffect(() => {
		setShowState(show)
	}, [show])
	
	const isEmptyTooltips = tooltips.every(tooltip => isEmpty(tooltip))
	
	if (!show || tooltips.length <= showAbove || isEmptyTooltips)
		return elem || children
	
	const onMouseLeave = () => {
		showCancel()
		hideWithDelay()
	}
	
	const onMouseEnter = () => {
		hideCancel()
		showWithDelay()
	}
		
	return (
		<span className={`position-relative ${containerClassName}`}
		      ref={reference}
		      onMouseEnter={onMouseEnter}
		      onMouseLeave={onMouseLeave}>
			
			{elem || children}
			
			<CSSTransition
				in={showState}
				timeout={500}
				classNames={'transition-fade'}
				nodeRef={transitionRef}
				unmountOnExit
			>
				<>
					<ul ref={floating}
					    className={`c-tooltip scroll-c ${className}`}
					    style={{
						    position: strategy,
						    top: y,
						    left: x,
					    }}
					>
							{tooltips.map((tl, ind) =>
								<li className={'tooltip-item text-break'} key={tl + ind}>
									{tl}
								</li>
							)}
						</ul>
				</>
			</CSSTransition>
		</span>
	)
}

WithTooltip.propTypes = {
	elem: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	tooltips: PropTypes.array,
	showAbove: PropTypes.number,
	show: PropTypes.bool,
	position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
}

export const getName = (arr, objField) =>
	arr.length > 1
		? (arr[0] && arr[0][objField]) + ' ...'
		: (arr[0] && arr[0][objField]) || '-'

export const getElemText = (text, length) =>
	text?.length > length
		? text.slice(0, length) + ' ...'
		: text || '-'

