import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';


export const adminSmassAPI = {
	getInfo: (keycloakID) => axios(`/admins/smass/${keycloakID}/`),
	
	// getAll: (page = 1) => axios('/admins/smass/', {...prepareAxiosParams({page})}),
	
	searchAdmins({page, search, ordering, page_size}, config = {}) {
		return axios('/admins/smass/',
      {
        ...setPreparedAxiosParams(arguments[0]),
        ...config
      })
	},
  
  getAllNew() {
    return axios('/admins/smass/new/',
      setPreparedAxiosParams({
        ...arguments[0],
      }),
    )
  },

  markAsViewed: (data) => axios.post('/admins/smass/new/mark-as-viewed/', data),
	
	getLoggedNow: () => axios('/admins/smass/current/'),
	patchAdmin: (keycloakID, data) => axios.patch(`/admins/smass/${keycloakID}/`, data),
}

