import {getLocaleFullDate, getShortFullNameFromObj, isEqualSome, isNotEmpty} from '../../../../js-helpers/helpers';
import {translatedFields} from './useGlobalSearchItem/translatedFields';
import {contactTypes} from '../../../members/pageUsers/PageUserContacts/PageUserContacts'; 
import {modelsOptions} from './useGlobalSearchItem/modelsOptions';
import {LOG_IN_OUT_REASONS, LOG_IN_OUT_STATUSES} from '../../../../constants/constants';

// 'sad <strong>asd</strong> sad' => 'sad ' + <strong>asd</strong> + ' sad'
const getMatchedString = value => {

	// const splitValues = value.map(substring => {
	// 	return String(substring).match(
	// 		/(^([^<strong>](.|\n)*?)(?=<strong>))|(<strong>(?<=<strong>).*?(?=<\/strong>)<\/strong>)|((?<=<\/strong>).*?(?=<strong>))|((?<=<\/strong>).+)/g
	// 	)
	// })
// console.log( Array.isArray(value))
// console.log(value)

  const splitValues = value.map(substring => {
      const result = [];
    	const arr =  String(substring).split('<strong>')

      arr.forEach((item, index, array) => {
        const subArr = item.split('</strong>')
        if (subArr.length > 1 && subArr[1].trim().length > 0) {
          subArr[0] = subArr[0] + '</strong>'
          array.splice(index, 1, ...subArr)
        }
      })

      const arr2 = arr.map(item => item.includes('</strong>') ? '<strong>' + item : item)

      arr2.forEach(item => {
        if (item.includes('</strong>') && item.includes(' ')) {
          result.push(item.trim())
          result.push(' ')
        } else {
          result.push(item)
        }
      })

      return result
    })

	return splitValues.map((_value) =>
		_value && <span key={_value}>
			
			{Array.from(_value).map((val, ind) => {
				return val.startsWith('<strong>')
					? (
						<strong key={val + ind} className={'bg-rgba-success'}>
							{val.substring(8, val.length - 9)}
						</strong>
					) : val
			})}
			
			<br/>
		</span>
	)
}

// contactphonenumberv2_set.phone_number.phone_number =>> contactphonenumberv2_set
const getFieldNamesWithoutDots = match_fields =>
	Object.fromEntries(
		Object.entries(match_fields)
			.map(([key, value]) => {
					if (key.includes('.')) key = key.split('.').at(0)
					return [key, value]
				}
			)
	)

const getTypeName = (value, model) => {
	if (model.includes('contact'))
		return contactTypes.find(type => type.type === value).text
	if (model === ('sms'))
		return value === 2 ? 'Входящие' : 'Исходящие'
}

const prepareItem = (field, value, model, matchFields) => {
  
	if (field === 'contactphonenumberv2_set')
		value = value.map(item => item.phone_number)
	
	if (field === 'contactemailv2_set')
		value = value.map(item => item.email)
	
	if (field === 'type' && model !== 'loginlogoutlog') {
		value = getTypeName(value, model)
  } else if (field === 'type' && model === 'loginlogoutlog') {
    value = LOG_IN_OUT_STATUSES[value]
  }

	if (field === 'date')
		value = getLocaleFullDate(value)
	
	//todo Спросить у Вити изменения в модели для полей приложения
	if (field === 'app')
		value = value.name

  if (model !== 'devicev2' && field === 'user') {
    value = getShortFullNameFromObj(value)
  }

  if (model === 'devicev2' && field === 'user' && matchFields[0] !== 'user') {
    value = getShortFullNameFromObj(value)
  }
  
  if (field === 'is_success')
    value = value ? 'Успешно' : 'Ошибка'

  if (field === 'reason') 
    value = LOG_IN_OUT_REASONS[value]
    
	
	const isMatchedField = matchFields.some(matchField => matchField === field)

	if (isMatchedField)
		value = getMatchedString(value)

	return [field, value]
}

const addDotsToString = (clearValue, value, originalValue) => {
	// console.log({clearValue, value, originalValue})
	if (String(originalValue).startsWith(clearValue))
		return `${value}...`
	
	if (String(originalValue).endsWith(clearValue))
		return `...${value}`
	
	return `...${value}...`
}

const clearStrong = value => value.replaceAll('<strong>', '').replaceAll('</strong>', '');

const getMergedFields = (fields, matchFieldsWithoutDots, matchKeys) => {
	
	matchKeys.forEach(_matchKey => {
		const matchValues = matchFieldsWithoutDots[_matchKey]
		const originalValue = fields[_matchKey]
		
		if (_matchKey.endsWith('v2_set')) {
			const replacedValues = originalValue.map(_origObj => {
				
				const origObjWithMatchedValues = Object.fromEntries(Object.entries(_origObj)
					.map(([origKey, origValue]) => {
						matchValues.forEach(matchValue => {
							if (clearStrong(matchValue) === origValue)
								origValue = matchValue
						})
						return [origKey, origValue]
					}))
				
				return origObjWithMatchedValues
			})
			
			matchFieldsWithoutDots[_matchKey] = replacedValues
		} else {
			
			const withDotsMatchValues = matchValues.map(value => {
				const clearValue = clearStrong(value)
				if (clearValue?.length !== originalValue?.length)
					return addDotsToString(clearValue, value, originalValue)
				
				return value
			})
			matchFieldsWithoutDots[_matchKey] = withDotsMatchValues
		}
	})
	return {...fields, ...matchFieldsWithoutDots}
}

function prepareValue(_value) {
	return Array.isArray(_value) && _value.length > 1
		? _value.map((value, ind) => <p className={'mb-25'} key={(value?.key || value) + ind}>{value}</p>)
		: _value
}

const ResultField = ({field, value}) => {
	// console.log({field})
	// console.log({value})
	return (
		
		<li>
			<h4 className={'font-small-2 mb-25 text-muted'}>
				{translatedFields[field] || field}
			</h4>
			
			{value}
		</li>
	);
}

function ExtraFields({fields, model}) {
	if (!isEqualSome(model, ['account', 'admin']))
		return null
	const {field, value} = modelsOptions[model].getExtraFields(fields)
	
	return (
		<ResultField field={field} value={value}/>
	)
}

export const ResultsFields = ({item: {fields, match_fields, model}}) => {
	const matchFieldsWithoutDots = getFieldNamesWithoutDots(match_fields)
	const matchKeys = Object.keys(matchFieldsWithoutDots)
	const mergedFields = getMergedFields(fields, matchFieldsWithoutDots, matchKeys)
	
	// console.log({mergedFields})
	
	const translatedFieldsWithValues =
		Object.entries(mergedFields)
			.filter(([, value]) => isNotEmpty(value))
			.map(([field, value]) => {
				const [_field, _value] = prepareItem(field, value, model, matchKeys)
				return !translatedFields?.[_field] ? [_field, null] : [_field, _value];
			})
			.filter(([, value]) => isNotEmpty(value))
			.map(([field, value]) => [field, prepareValue(value)])
	
	return (
		<ul className={'d-flex gap-2r overflow-auto scroll-c list-unstyled'}>
			
			{translatedFieldsWithValues.map(([field, value]) => (
				<ResultField key={field} field={field} value={value}/>
			))
			}
			
			<ExtraFields fields={fields} model={model}/>
		
		</ul>
	)
};