import {loader, toggle} from '../../../../js-helpers/helpers';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import {Card} from '../../../../components/UI/Card';
import {divisionsAPI} from '../../../../API/divisionsAPI';
import {Button} from '../../../../components/button/Button';
import {Head} from '../../../../components/UI/Head';
import {StatusesList} from './StatusesList';
import {ExceptionsLists} from '../../../../components/listsAccess/ExceptionsLists';
import {toast} from 'react-toastify';

const getPageOptions = (sites, apps, status) => {
	const {getSites, getApps, putSites, putApps, getDefaultApps, getDefaultSites} = divisionsAPI
	
	const fetchDataWithType = sites ? getSites : getApps
	const putItems = sites ? putSites : putApps
	
	const itemsName = sites ? 'сайты' : 'приложения'
	const availabilityText = status === 2 ? 'Запрещённые ' : 'Доступные '
	const headExceptions = `${availabilityText + itemsName}: `
	
	const defaultExceptionsRequest = sites ? getDefaultSites : () => getDefaultApps(1, 999)
	
	return {fetchDataWithType, putItems, headExceptions, defaultExceptionsRequest}
}

export function PageDivisionEdit({sites, apps}) {
	const {divID} = useParams()
	const type = sites ? 'sites' : 'apps'
	const [status, setStatus] = useState(null)
	const [initData, setInitData] = useState({exceptions: []})
	const [exceptions, setExceptions] = useState([])
	
	const defaultStatus = status === 1
	
	const {fetchDataWithType, putItems, headExceptions, defaultExceptionsRequest} =
		useMemo(() => getPageOptions(sites, apps, status)
		, [sites, apps, status])
	
	const {isReady, setReady, getData, setData} =
		useFetchStates(fetchDataWithType
		, {initData: {name: ''}, noInitFetch: true, customSetter})
	
	useEffect(() => {
		getData(divID)
	}, [sites, apps])
	
	const defaultExceptions = useFetchStates(defaultExceptionsRequest, {initData: []})

	useEffect(() => {
		if (defaultStatus) {
			defaultExceptions.getData()
		}
	}, [status])
	
	const setPageName = useOutletContext()
	
	function customSetter({data}) {
		setPageName(data.name)
		setData(data)
		setStatus(data.status)
		setExceptions(data.exceptions)
		setInitData(data)
		setReady(true)
	}
	
	const onSubmit = async () => {
		setReady(false)
		const exceptionsIDS = defaultStatus ? [] : exceptions.map(item => item.id)
		const data = {status, exceptions: exceptionsIDS}
		
		try {
			await putItems(divID, data)
			toast.success('Настройки доступа сохранены')
			getData(divID)
		} catch (e) {
			toast.error('Ошибка сохранения настроек доступа')
		} finally {
			setReady(true)
		}
	}

  // const getExceptionsOnStatus = () => defaultStatus ? defaultExceptions.data : exceptions
	
	const getExceptionsOnStatus = () => defaultStatus ? apps ? defaultExceptions?.data?.results || defaultExceptions?.data : defaultExceptions?.data : exceptions

	const likeInitExceptions = () => {
		if (!exceptions || !initData?.exceptions) return
		
		const likeInitExceptionsIDS =
			JSON.stringify(exceptions.map(item => item.id).sort()) ===
			JSON.stringify(initData.exceptions.map(item => item.id).sort())
		
		const likeInitStatus = status === initData.status
		
		return likeInitStatus && likeInitExceptionsIDS
	}
	
	const exceptionsWithInitField = useMemo(() =>
			getExceptionsOnStatus().map(exceptionsItem => {
				const haveIDinInit = initData.exceptions
					.some(initException => initException.id === exceptionsItem.id)
				
				return (haveIDinInit && status === initData.status)
					? {...exceptionsItem, isInit: true}
					: exceptionsItem
			})
		, [sites, status, exceptions, initData, defaultExceptions.data])
	
	
	return (
		<main className={'row no-gutters'}>
			<StatusesList
				sites={sites}
				apps={apps}
				status={status}
				setStatus={setStatus}
				initStatus={initData?.status}
				className={`mr-2 d-flex flex-column align-self-start ${loader(isReady)}`}
			/>
			
			<Card className={'col'}>
				<Head secondary
				      uppercase
				      title={toggle(defaultStatus, 'доступ по умолчанию', 'настройки доступа')}
				/>
				
				<ExceptionsLists
					disabled={defaultStatus}
					type={type}
					exceptions={exceptionsWithInitField}
					className={`${loader(defaultExceptions.isReady || isReady)}`}
					setExceptions={setExceptions}
					headExceptions={headExceptions + exceptionsWithInitField?.length}
				/>
				
				<Button
					save
					disabled={likeInitExceptions()}
					type={'button'}
					onClick={onSubmit}
					className={'d-block ml-auto mt-1'}
					children={defaultStatus && 'Применить'}
				/>
			
			</Card>
		</main>
	)
}