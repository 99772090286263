import {useCallback, useState} from "react";

export function useToggleState(init) {
	const [boolState, setBoolState] = useState(init)
	
	const toggleState = useCallback(() => {
		setBoolState(boolState => !boolState)
	}, [])
	
	return [boolState, toggleState, setBoolState]
}