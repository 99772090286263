export const LOGS_SERVICES = {
  appInstalls: 'app-installs',
  appUpdates: 'app-updates',
  appLaunch: 'app-launch',
  logInLogOut: 'login-logout',
  launcherUpdates: 'launcher-updates',
  deviceShutdowns: 'device-shutdowns',
  crashes: 'crashes'
}

export const appsOptions = [
  {
    text: 'Установка',
    service: LOGS_SERVICES.appInstalls,
    pageTitle: 'Установка приложений',
    globalSearchModel: 'appinstalllog',
  },
  {
    text: 'Обновление',
    service: LOGS_SERVICES.appUpdates,
    pageTitle: 'Обновление приложений',
    globalSearchModel: 'appupdatelog',
  },
  {
    text: 'Запуск',
    service: LOGS_SERVICES.appLaunch,
    pageTitle: 'Запуск приложений',
    globalSearchModel: 'applaunchlog'
  },
];

export const logInOutOptions = [
  { text: 'Логи входа', service: 'logins', pageTitle: 'Логи входа в лаунчер' },
  { text: 'Логи выхода', service: 'logouts', pageTitle: 'Логи выхода из лаунчера' },
];

export const baseLogsOptions = [
  {
  text: 'Логи входа/выхода',
  service: LOGS_SERVICES.logInLogOut, 
  globalSearchModel: 'loginlogoutlog',
  icon: 'login-logout',
},
{
  text: 'Обновление лаунчера',
  service: LOGS_SERVICES.launcherUpdates,
  globalSearchModel: 'launcherupdateslog',
  icon: 'refresh2',
},
// {
//   text: 'Уведомления',
//   service: 'app-notifications',
//   apiVersion: 2,
//   globalSearchModel: 'appnotificationlog',
//   icon: 'bell',
// },
// {
//   text: 'Клавиатура',
//   service: 'keyboards',
//   apiVersion: 2,
//   globalSearchModel: 'keyboardlog',
//   icon: 'keyboard',
// },
// {
//   text: 'Буфер обмена',
//   service: 'clipboards',
//   apiVersion: 1,
//   globalSearchModel: 'clipboardlog',
//   icon: 'clipboard',
// },
{
  text: 'Отключение телефона',
  service: LOGS_SERVICES.deviceShutdowns,
  // globalSearchModel: '',
  icon: 'power',
},
{
  text: 'Сбои приложений',
  service: LOGS_SERVICES.crashes,
  icon: 'crashes',
},
];

export const userLogsOptions = [
{
  head: 'Приложения',
  groupName: 'apps',
  options: appsOptions,
  icon: 'apps',
},
];

export const logsOptions = [
...appsOptions,
...baseLogsOptions,
];
