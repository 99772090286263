// export const getOrderingWithMinus =	(string) =>
// 	string.startsWith('-') ? `-${string.replace('-', '')}` : string

// '..last_name..' => 'last_name,first_name,middle_name'
export const getExtraFullNameOrdering = (ordering) => {
	if (!ordering?.includes('last_name') || !ordering)
		return ordering
	
	const fullNames = [
		ordering,
		ordering.replace('last_name', 'first_name'),
		ordering.replace('last_name', 'middle_name')
	]
	
	return fullNames.join(',')
}