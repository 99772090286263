import {Outlet} from 'react-router-dom';
import {useState} from 'react';
import {ButtonGoBack} from '../../../../components/button/ButtonGoBack';
import {Head} from '../../../../components/UI/Head';

export function DivisionsLayout() {
	const [divName, setDivName] = useState('-');
	
	return (
		<>
			<ButtonGoBack className={'float-right ml-1'}/>
			<Head>Дивизион: {divName}</Head>

			<Outlet context={setDivName}/>
		</>
	)
}