import React, {useEffect} from 'react';
import {Head} from '../../../components/UI/Head';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {servicesAPI} from '../../../API/servicesAPI';
import {MailingCard} from './MailingCard';
import {urlParams} from '../../../constants/urlParams';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {FullPagination} from '../../../components/paginations/FullPagination';
import {loader} from '../../../js-helpers/helpers';
import {useToggleState} from '../../../hooks/useToggleState';
import {useImmer} from 'use-immer';
import {ModalDialog} from '../../../components/modals/ModalDialog';
import {MailingModal} from './MailingModal';
import {toast} from 'react-toastify';
import {useUpdateEffect} from '@react-hookz/web/esnext';
import {SearchInput} from '../../../components/form/SearchInput';
import {EmptyFoundDataMessage} from '../../../components/EmptyFoundDataMessage/EmptyFoundDataMessage';
import { ButtonGoBack } from '../../../components/button/ButtonGoBack';

const {PAGE, LIMIT, SEARCH} = urlParams

const searchParams = [
	{param: PAGE, init: 1},
	{param: LIMIT},
	{param: SEARCH, init: '', debounce: 333},
]

function PageMailing() {
	const [
		[urlPage, setUrlPage],
		[urlLimit],
		[urlSearch, setUrlSearch],
	] = useComboSearch(searchParams)
	
	const {data, getData, isReady} = useFetchStates(
		() => servicesAPI.getMailingLists({page: urlPage, page_size: urlLimit, search: urlSearch}), {
			initData: {results: []},
		})
	
	useEffect(() => {
		getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		getData()
	}, [urlLimit, urlSearch])
	
	const [isShowModal, toggleShowModal] = useToggleState(false)
	const [modalDialogProps, setModalDialogProps] = useImmer({})
	
	function addNotification() {
		setModalDialogProps({
			head: 'Новая рассылка',
			body: <MailingModal
				onSuccessCreate={() => {
					toggleShowModal()
					getData()
				}}
				onCancelClick={() => toggleShowModal(false)}/>,
			showFooter: false,
			className: 'width-600',
		})
		
		toggleShowModal()
	}
	
	const deleteItem = (itemData, id,) => {
		
		const deleteItem = async (ev) => {
			ev.target.disabled = true
			
			try {
				await servicesAPI.deleteMailingItem(id)
				toggleShowModal()
				toast(`Рассылка для "${itemData.email}" удалена!`, {type: 'success'})
				getData()
				
			} catch (e) {
				toast(`Ошибка удаления рассылки для "${itemData.email}": ${e?.response?.data?.errors?.[0]?.detail}`, {type: 'error'})
				ev.target.disabled = false
			}
		}
		
		setModalDialogProps({
			head: 'Отмена рассылки',
			body: `Вы действительно хотите удалить "${itemData.email}" из рассылки?`,
			onSuccessClick: deleteItem,
			onCancelClick: toggleShowModal,
			className: 'width-600',
			successText: 'Удалить',
		})
		
		toggleShowModal()
	}
	
	const showEmptyFoundDataMessage = urlSearch && !data.results.length;
	
	return (
		<>
			
			<ModalDialog isShow={isShowModal}
				          {...modalDialogProps}/>
			
			<ButtonGoBack className={'float-right ml-1'}/>

			<button className={'btn btn-outline-primary pl-1 bg-white float-right '}
			        onClick={addNotification}
			>
				<span className={'font-medium-4 mr-75 align-top'}>+</span>
				Добавить рассылку
			</button>

			<Head>Рассылка</Head>
			
			<SearchInput withBorder
			             className={'mb-1'}
			             onChange={e => {
				             setUrlSearch(e.target.value)
			             }}
			             defaultValue={urlSearch}
			             placeholder={'Поиск по e-mail...'}/>
			
			{showEmptyFoundDataMessage &&
			<EmptyFoundDataMessage isDataReady={isReady}/>
			}
			
			<ul className={`list-unstyled list-gap-025r ${loader(isReady || showEmptyFoundDataMessage)}`}>
				
				{data.results.map((item) =>
					<MailingCard key={item.id} {...item} onDeleteClick={deleteItem}/>
				)}
			</ul>
			
			<FullPagination allCount={data.count}/>
		</>
	)
}

export default PageMailing;