import {makeAutoObservable} from 'mobx';
import {isObject} from '../js-helpers/helpers';

const getInitLimits = () => {
	const fromLS = JSON.parse(localStorage.getItem('limits'))
	return isObject(fromLS) ? fromLS : {}
}

export const limitsStore = makeAutoObservable({
	limits: getInitLimits(),
	
	setNewLimit: (key, value) => {
		limitsStore.limits[key] = value
	},
	
	saveLimits: () => {
		localStorage.setItem('limits', JSON.stringify(limitsStore.limits))
	}
});