import React, {useState} from 'react';
import {Card} from '../UI/Card';
import {getIsoUtcDate, isValidDate} from '../../js-helpers/helpers';
import dayjs from 'dayjs';
import {RangeDatePicker} from '../form/RangeDatePicker';
import {useKeyboardEvent} from '@react-hookz/web';

export const DatesRangeModal = (
	{
		onClickDownload,
		onClickAbort,
		className = '',
		extraBodyElem,
		initialDateFrom,
		initialDateTo,
	}) => {
	
	const [dateFrom, setDateFrom] = useState(initialDateFrom)
	const [dateTo, setDateTo] = useState(initialDateTo)
	
	useKeyboardEvent(true, e => {
		if (e.key === 'Escape') {
			onClickAbort()
		}
	})
	
	const fromPickerProps = {
		value: dateFrom,
		onChange: ([date]) => {
			setDateFrom(isValidDate(date) ? getIsoUtcDate(date) : '')
		},
		options: {maxDate: dateTo}
	}
	
	const toPickerProps = {
		value: dateTo,
		onChange: ([date]) => {
			setDateTo(isValidDate(date) ? getIsoUtcDate(dayjs(date).second(59).toDate()) : '')
		},
		options: {minDate: dateFrom}
	}
	
	return (
		<div
			className={`position-fixed position-top-0 position-left-0 zindex-2 full-width full-height bg-rgba-dark d-flex justify-content-center align-items-center ${className}`}>
			
			<Card size={0} className={'text-center px-4 py-3 bg-white'}>
				<h3 className={'font-weight-bold mb-2'}>
					Выберите период для выгрузки
				</h3>
				
				<RangeDatePicker className={'mb-1'}
				                 prependLabel={null}
				                 fromPickerProps={fromPickerProps}
				                 toPickerProps={toPickerProps}
				/>
				
				{extraBodyElem}
				
				<button className={'btn mr-3 btn-outline-light px-3 mt-1'}
				        onClick={onClickAbort}>
					Отмена
				</button>
				
				<button disabled={!(dateTo || dateFrom)}
				        className={'btn btn-primary px-3 mt-1'}
				        onClick={() => onClickDownload(dateFrom, dateTo)}>
					Скачать
				</button>
			
			</Card>
		</div>
	)
};