import {urlParams} from "../../../constants/urlParams";

const {
	SEARCH, REASON, DATE_FROM, DATE_TO, SORT,
	PAGE, LIMIT, STATUS, USER, DEVICE, APP,
	DATE_OFF_FROM, DATE_OFF_TO, DATE_ON_FROM,
	DATE_ON_TO, TYPE
} = urlParams;

export const pageSearchParams = [
	{param: STATUS},
	{param: TYPE},
	{param: REASON},
	{param: USER},
	{param: DEVICE},
	{param: APP},
	{param: PAGE, init: 'last'},
	{param: SORT, init: 'date'},
	{param: LIMIT},
	{param: SEARCH, init: '', debounce: 333},
	{param: DATE_FROM},
	{param: DATE_TO},
	{param: DATE_OFF_FROM},
	{param: DATE_OFF_TO},
	{param: DATE_ON_FROM},
	{param: DATE_ON_TO},
];