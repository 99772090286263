import dayjs from "dayjs"
import { Card } from "../../../../components/UI/Card"
import { WithTooltip } from "../../../../components/UI/WithTooltip"
import { getLocaleFullDate, isValidDate, toggle } from "../../../../js-helpers/helpers"

export const UserInfoSystemCard = ({date_joined, last_login, className=''}) => {
  return (
    <Card secondary className={className} title={'Пользователь в Системе'}>
    <ul className={'list-unstyled list-gap-1r'}>
      <Card as={'li'} size={1} className={''}>
        <h3 className={'font-size-base font-weight-bold'}>
          Последняя активность:
        </h3>
        
        <WithTooltip tooltips={[getLocaleFullDate(last_login)]}>
          {toggle(
            isValidDate(last_login),
            dayjs(dayjs(last_login).subtract(10, 'seconds')).fromNow(),
            '-'
          )}
        </WithTooltip>
      </Card>
      
      <Card as={'li'} size={1} className={'font-small-3'}>
        <h3 className={'font-size-base font-weight-bold'}>
          Дата регистрации пользователя в системе:
        </h3>
        
        <span className={'font-weight-light'}>
          {getLocaleFullDate(date_joined)}
        </span>
      </Card>
    </ul>
  </Card>
  )
}