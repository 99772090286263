import { toggle } from "../../js-helpers/helpers"

export const backgroundClassName = {
	white: 'bg-white',
	default: 'bg-c-rgba-light',
}

export const getItemClassName = (background = 'default', disabled) => {
	
	const options = {
		white: 'hover-c-gray-75',
		default: 'c-hover-opacity',
	}
	
	return `${options[background]} ${toggle(disabled, 'cursor-default')}`
}