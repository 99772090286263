import {useController} from 'react-hook-form'
import {useEffect, useState} from 'react';
import {MyDatePicker} from '../../../../../../../components/form/MyDatePicker/MyDatePicker';
import {getDateNow, getIsoUtcDate} from '../../../../../../../js-helpers/helpers';

export const ReleaseDate = ({data, isDisabled, control}) => {
	const {field} = useController({control, name: 'release_date'})
	const [value, setValue] = useState(data ? new Date(data) : getDateNow())
	
	useEffect(() => {
		field.onChange(value ? getIsoUtcDate(value) : '')
	}, [value])
	
	return (
		<label>
			Дата и время установки
			
			<MyDatePicker
				disabled={isDisabled}
				value={value}
				options={{
					minDate: !data ? getDateNow() : undefined
				}}
				onChange={selectedDates => {
					setValue(selectedDates[0])
				}}
			/>
		</label>
	)
}