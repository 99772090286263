import {useEffect, useRef} from 'react';
import Duration from './Duration';
import ReactPlayer from 'react-player';
import {loader} from '../../../js-helpers/helpers';
import {ReactComponent as PlaySVG} from './play.svg'
import {ReactComponent as PauseSVG} from './pause.svg'
import {useImmer} from 'use-immer';

const initState = {
	playing: false,
	played: 0,
	seeking: false,
	duration: 0,
	badURL: false,
	isReady: false,
  volume: 0
}

export const AudioPlayer = ({
	                            url,
	                            playingCallURL,
	                            setPlayingCallURL,
	                            setLoadedLinks,
	                            setBadLinks,
                              showRange = true,
                              className,
                              fullScreenPlaying,setFullScreenPlayerState,
                              setAudioName,
                              fileName
                            }
) => {
	const player = useRef()

	const [
		{
			playing: localPlaying,
			played: localPlayed,
			seeking:  localSeeking,
			duration:  localDuration,
			badURL:  localBadURL,
			isReady:  localIsReady,
      volume
		},
		setLocalPlayerState
	] = useImmer(initState)
	
	useEffect(() => {
		playingCallURL !== url && localPlaying && 
		setLocalPlayerState(draft => { draft.playing = false }) 
	}, [playingCallURL])

  useEffect(() => {
		playingCallURL === url && 
		setLocalPlayerState(draft => { draft.playing = fullScreenPlaying }) 
	}, [ playingCallURL ,fullScreenPlaying])

	const handlePlayPause = () => {
		setLocalPlayerState(draft => { draft.playing = !draft.playing })
    !localPlaying && setPlayingCallURL(url) 

    setFullScreenPlayerState(draft => { draft.playing = !draft.playing })
	
    setAudioName(fileName)
	}
	
	const inputProps = {
		onChange: e => {
			setLocalPlayerState(draft => {draft.played = parseFloat(e.target.value)})
		},
		onMouseUp: e => {
			setLocalPlayerState(draft => {
				draft.seeking = false
				draft.played = parseFloat(e.target.value)
			})
			player.current.seekTo(parseFloat(e.target.value))
		},
		onMouseDown: () => {
			setLocalPlayerState(draft => {draft.seeking = true})
		},
	}
	
	const playerProps = {
		onProgress: ({played, playedSeconds}) => {
			setLocalPlayerState(draft => {
				draft.duration = playedSeconds
				localSeeking
					? draft.seeking = played
					: draft.played = played
			})
		},
		onError: () => {
			setLocalPlayerState(draft => {
				draft.badURL = true
				draft.isReady = true
			})
			setBadLinks && setBadLinks(badURL => [...badURL, url])
		},
		onReady: () => {
			setLocalPlayerState(draft => {
				draft.isReady = true
			})
			setLoadedLinks && setLoadedLinks(loadedLinks => [...loadedLinks, url])
		},
		onEnded: () => {
			setLocalPlayerState(draft => {
				draft.playing = false
			})
		},
	}
	
	return (
		<div className={`d-flex position-relative align-items-center ${loader(localIsReady, {size: 'small'})} ${className ? className : ''}`}>
			
			<button className={'btn btn-primary btn-c-audio'}
			        disabled={localBadURL}
			        onClick={handlePlayPause}>
				
				{localPlaying ? <PauseSVG/> : <PlaySVG/>}
			
			</button>
			
			{showRange && <div className='w-100 d-flex position-relative'>
				
				<input min={0}
				       max={1}
				       step='any'
				       type='range'
				       value={localPlayed}
				       disabled={localBadURL}
				       className={'audio-c-range min-c-touch-zone'}
				       {...inputProps}
				/>
				
				<progress max='1'
				          value={localPlayed}
				          className={'w-100'}/>
			
			</div>}
			
			{!!localPlayed && showRange &&
			<Duration className={'audio-c-time'} seconds={localDuration}/>
			}
			
			<ReactPlayer className={'d-none'}
			             progressInterval={25}
			             url={url}
			             ref={player}
			             playing={localPlaying}
                   volume={volume}
			             {...playerProps}
			/>
		</div>
	)
}