import {getDateNow, getFileNameFormatDate} from '../../../js-helpers/helpers';
import exportFromJSON from 'export-from-json';
import {toast} from 'react-toastify';
import {getCell} from '../../../components/table/MyTable/MyTableBody';
import {callsHistoryTable} from './table';

export const exportExcel = (exportList) => {
	const dataToExcel = exportList.map(item => {
		const finalObject = {}

		callsHistoryTable.exportTable.forEach((col) => {
			finalObject[col.head] = getCell(col, item)
		})
		
		return finalObject
	})
	
	const fileName = `Звонки_${getFileNameFormatDate(getDateNow())}`
	
	exportFromJSON({
		data: dataToExcel,
		withBOM: true,
		fileName,
		exportType: exportFromJSON.types.xls,
	})
	
	toast.success(`Файл создан!
	Экспортировано звонков: ${dataToExcel.length}`)
}
