export const adminPermissions = {
	ADMINS: 'admins',
	
	CALL_HISTORY: 'calls',
	LOGS: 'logs',
	SCREENSHOTS: 'screenshots',
	
	DIVISIONS: 'divisions',
  GROUPS: 'groups',
	CONTACTS: 'contacts',
  LAUNCHERS: 'launchers',
	APPS: 'apps',
	FEEDBACKS: 'feedbacks',
	NOTIFICATIONS: 'notifications',
	// CRASHLYTICS: 'crashlytics',
	MAILINGS: 'mailings',
	// GLOBAL_SEARCH: 'global_search',
	
	USERS: 'users',
	USERS_GENERAL: 'users_general',
	USERS_CALLS: 'users_calls',
	USERS_LOGS: 'users_logs',
	// USERS_DETAILS: 'users_details',
	USERS_SCREENSHOTS: 'users_screenshots',
	// USERS_MESSAGES: 'users_messages',
	USERS_PHONEBOOK: 'users_phonebook',
	USERS_TRACKERS: 'users_trackers',
  USERS_NOTIFICATIONS: 'users_notifications',
	USERS_SETTINGS: 'users_settings',
	
	//USERS_P2P_NOTIFICATIONS: 'users_p2p_notifications',
	
	DEVICES: 'devices',
	DEVICES_GENERAL: 'devices_general',
	DEVICES_CALLS: 'devices_calls',
	DEVICES_LOGS: 'devices_logs',
	DEVICES_SCREENSHOTS: 'devices_screenshots',
	// DEVICES_DETAILS: 'devices_details',
	// DEVICES_MEDIA: 'devices_media',
	DEVICES_SETTINGS: 'devices_settings'
}

//USERS = "users"
// USERS_GENERAL = "users_general"
// USERS_CALLS = "users_calls"
// USERS_LOGS = "users_logs"
// USERS_SCREENSHOTS = "users_screenshots"
// USERS_PHONEBOOK = "users_phonebook"
// USERS_TRACKERS = "users_trackers"
// USERS_SETTINGS = "users_settings"
// 
// # Устройства
// DEVICES = "devices"
// DEVICES_GENERAL = "devices_general"
// DEVICES_CALLS = "devices_calls"
// DEVICES_LOGS = "devices_logs"
// DEVICES_SCREENSHOTS = "devices_screenshots"
// DEVICES_SETTINGS = "devices_settings"
// 
// # Администраторы
// ADMINS = "admins"
// 
// # Таблицы данных
// CALL_HISTORY = "calls"
// LOGS = "logs"
// SCREENSHOTS = "screenshots"
// 
// # Управление системой
// DIVISIONS = "divisions"
// CONTACTS = "contacts"
// APPS = "apps"
// FEEDBACKS = "feedbacks"
// NOTIFICATIONS = "notifications"
// MAILINGS = "mailings"
