import { useMemo } from 'react'
import { Controller } from 'react-hook-form';
import { Radio } from '../../../../components/form/Radio';
import { Card } from '../../../../components/UI/Card'
import { Head } from '../../../../components/UI/Head'
import { autoChangeHeight } from '../../../../js-helpers/helpers';
import { loggedAdmin } from '../../../../stores/loggedAdmin';
import { observer } from 'mobx-react-lite';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DescriptionBabble } from "../../../../components/DescriptionInfoBabble/DescriptionInfoBabble"

const MAX_BODY_LENGTH = 1024
const MAX_TITLE_LENGTH = 150

const FormNotificationsTitles = observer(({
  control, titleValue, 
  setTitleValue, bodyValue, 
  setBodyValue, showRecipientList, 
  setShowRecipientList, checkedAllRecipient, 
  setCheckedAllRecipient}) =>  {

  const {isSuperAdmin} = loggedAdmin.isSuperAdmin

  const toggleRecipient = () => {
    setCheckedAllRecipient(!checkedAllRecipient);
    setShowRecipientList(!showRecipientList)
  }

  const checkValidTitleInput = useMemo(() => {
    return  titleValue.length <= MAX_TITLE_LENGTH 
  }, [titleValue])

  const checkValidBodyInput = useMemo(() => {
    return  bodyValue.length <= MAX_BODY_LENGTH 
  }, [bodyValue])

  return (
    <>
      <Head secondary uppercase>Создать уведомление</Head>
      <Card secondary as={'ul'} className={'list-unstyled'}>
        <li className={'row py-1'}>
          <h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase pt-50'}>
            Заголовок*
          </h3>
          <div style={{position: 'relative', width: 'max-content'}} className={'col-6 p-0'}>
            <Controller  
              name={'title'}
              control={control}
              rules={{maxLength: MAX_TITLE_LENGTH}}
              render={({field}) => 
                <textarea 
                  className={`form-control overflow-hidden ${checkValidTitleInput ? '' : 'is-invalid'}`} 
                  style={{resize: 'none'}} 
                  rows='1' 
                  value={titleValue}
                  onChange={(e) => {
                    autoChangeHeight(e, '50px', 15);
                    setTitleValue(e.target.value.trim() !== '' ? e.target.value : '');
                    field.onChange(e.target.value.trim() !== '' ? e.target.value : '')
                  }}>
                </textarea>
              }/>
            <span style={{position: 'absolute',   bottom: '10px', right: '10px'}} className={`${checkValidTitleInput ? 'text-muted' : 'text-danger' } font-small-1`}>
              <span>{titleValue.length}</span>
              /
              <span>{MAX_TITLE_LENGTH}</span>
            </span>
          </div>
        </li>

        <li className={'row py-1 border-top'}>
          <h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase pt-50'}>
            Текст*
          </h3>
          <div style={{position: 'relative', width: 'max-content'}} className={'col-6 p-0'}>
          <Controller  
              name={'body'}
              control={control}
              rules={{maxLength: MAX_BODY_LENGTH}}
              render={({field}) => 
                <textarea 
                  className={`form-control overflow-hidden ${checkValidBodyInput ? '' : 'is-invalid'}`} 
                  style={{resize: 'none', height: '100%'}}  
                  rows='5' 
                  value={bodyValue} 
                  required={true}
                  onChange={(e) => {
                    autoChangeHeight(e, '100px', 15);
                    setBodyValue(e.target.value.trim() !== '' ? e.target.value : '');
                    field.onChange(e.target.value.trim() !== '' ? e.target.value : '')
                  }}>
                </textarea>
              }/>
            <span style={{position: 'absolute',   bottom: '10px', right: '10px'}} className={`${checkValidBodyInput ? 'text-muted' : 'text-danger' } font-small-1`}>
              <span style={{marginLeft: '0.3rem'}}>{bodyValue.length}</span>
              /
              <span>{MAX_BODY_LENGTH}</span>
             
            </span>
          </div>
        </li>

        <li className={'row align-items-center py-1 border-top'}>
          <h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase'}>
            Получатель*
          </h3>
          <div className={'d-flex'}>
            <label className={`cursor-pointer d-block text-c-transform-none font-size-base mr-3 mb-0 ${checkedAllRecipient && 'text-body'}`}>
						  <Radio
							  name='all'
                checked={checkedAllRecipient}
							  onChange={toggleRecipient}
						  />
              <DescriptionBabble 
                text={'Все'}  
                tooltip={loggedAdmin ? ['В рассылку входят все устройства системы'] : ['В рассылку входят все устройства с пользователями вашего Дивизиона']} 
                textClassName={'mr-50'} 
                rightPosition={'0px'}/>
					  </label>
            <label className={`cursor-pointer d-block text-c-transform-none font-size-base mb-0 ${!checkedAllRecipient && 'text-body'}`}>
						  <Radio
							  name='list'
                checked={!checkedAllRecipient}
                onChange={toggleRecipient}
						  />
						    Согласно списку
					  </label>
          </div>
        </li>
      </Card>
    </>
    )
  })

export default FormNotificationsTitles 