import {useParams} from "react-router-dom";
import {notificationsAPI} from "../../../../API/notificationsAPI";
import {ButtonGoBack} from "../../../../components/button/ButtonGoBack";
import {Card} from "../../../../components/UI/Card";
import {Head} from "../../../../components/UI/Head";
import { urlParams } from "../../../../constants/urlParams";
import { useComboSearch } from "../../../../hooks/url/useComboSearch";
import {useFetchStates} from "../../../../hooks/useFetchStates";
import {getLocaleDate, getLocaleTime, loader} from "../../../../js-helpers/helpers";
import {createNotification} from "./pageNotificationsItemFunctions";
import NotificationReceiversTable from "./NotificationReceiversTable";

const {SEARCH, PAGE, LIMIT, TYPE} = urlParams
const pageSearchParams = [
  {param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 1},
	{param: LIMIT},
  {param: TYPE}
]


function PageNotificationsItem() {
	const {notificationID} = useParams();
  const [	
    [urlSearchQuery, setUrlSearchQuery],
    [urlPage, setUrlPage],
    [urlLimit],
    [urlType]
  ] = useComboSearch(pageSearchParams)
	
	const {data: dataNotification, isReady: notificationIsReady} = useFetchStates(
		() => {
      if (urlType === 'admin') {
        return notificationsAPI.getOneItem(notificationID)
      } else if (urlType === 'user') {
        return notificationsAPI.getP2POneItem(notificationID)
      }
    },
		{initData: {}}
	);
	
	const fieldsNotification = createNotification(dataNotification);
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head title={`Уведомление: ${notificationID}`}/>
			
			<Card
				className={`${loader(notificationIsReady)}`}
			>
				
				<div
					className='font-weight-bold float-right col-2'>{
					getLocaleDate(dataNotification?.created_at)}—
					{getLocaleTime(dataNotification?.created_at)}
				</div>
				
        <div className="col-10">
				{fieldsNotification?.map((item, index) => (
					<div key={index} >
						<span className='font-weight-bold mr-50'>{item[0]}:</span>
						<span className='text-break children-p-mb-0'>{item[1]}</span>
					</div>
				))}
        </div>
				<NotificationReceiversTable/>
			</Card>
		</>
	)
}

export default PageNotificationsItem
