import { Link } from "react-router-dom"
import { Card } from "../../../../components/UI/Card"
import { loader } from "../../../../js-helpers/helpers"
import { ReactComponent as GroupIcon } from "./group_icon.svg"

export const UserGroupsCard = ({data = [], isReady = true, className=''}) => {
  return (
    <Card secondary
          title={'Группы пользователя'}
          className={`${loader(isReady)} ${className} height-300`}
    >
      {data?.length > 0 ?
        <ul className={'list-unstyled height-200 scroll-c overflow-y-auto scrollbar-width'}>
          <div className={'px-2 py-50 bg-white rounded-lg'}>
            {data?.map(({id, title}, index, array) => (
            <li className={`${index !== array.length - 1 ? 'border-bottom-extra-light' : ''} py-50`} key={id}>
              <Link to={`/admins/groups/${id}`}>{title}</Link>
            </li>
            ))}
          </div>
        </ul>
        : 
        <Card className={'text-center pt-3 pb-2'}>
          <GroupIcon/>
          <div className={'text-muted my-1'}>Не относится ни к одной группе</div>
          <Link to={'/admins/groups/'} className={'d-flex justify-content-center'}>
            <span className={'font-weight-bold'}>
              Настройка групп 
            </span> 
            <i className='bx bx-right-arrow-alt'></i>
          </Link>
        </Card>
      }
    </Card>
  )
}