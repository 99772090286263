import {useCustomSearch} from '../../../hooks/url/useCustomSearch';
import {toggle} from '../../../js-helpers/helpers';

const getCassName = (sortField, sortQuery) => {
	const sameSortField = sortField === sortQuery || `-${sortField}` === sortQuery
	const isSortedDesc = !sortQuery?.startsWith('-')
	
	return toggle(sortField, `c-sort-chevrons pr-2 ${toggle(sameSortField, toggle(isSortedDesc, 'down', 'up'))}`)
}

export const MyTableHead = ({heads}) => {
	const [sortQuery, setSortQuery] = useCustomSearch('sort')
	
	const toggleSortField = (field) => {
		const currentSortField = sortQuery === field ? `-${field}` : field
		setSortQuery(currentSortField)
	}
	
	const onSortClick = (sortField) => {
		toggleSortField(sortField)
	}
	
	return (
		<thead>
		<tr>
			{heads.map(({head, headClassName = '', sortField}) =>
				<th key={head}
				    className={`${headClassName} ${toggle(sortField, 'cursor-pointer')}`}
				    onClick={sortField && (() => onSortClick(sortField))}
				>
					{sortField
						? <span className={getCassName(sortField, sortQuery)}>{head}</span>
						: head
					}
				</th>
			)}
		</tr>
		</thead>
	)
};