import React, {useRef} from 'react';
import {array, bool, element, func, node, number, object, oneOf, oneOfType, shape, string} from 'prop-types';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import './MyDatePicker.scss';
import {Russian} from 'flatpickr/dist/l10n/ru.js'

import {toggle} from '../../../js-helpers/helpers';

export function MyDatePicker(
	{
		options = {},
		onChange,
		type,
		defaultValue,
		value,
		className = '',
		inputClassName = '',
		placeholder,
		onOpen,
		...props
	}) {
	
	const instanceRef = useRef(null)
	const fp = instanceRef.current?.flatpickr
	
	const _options = {
		allowInput: true,
		// autoFillDefaultTime: false,
		// allowInvalidPreload: true,
		defaultHour: 0,
		locale: Russian,
		dateFormat: 'd.m.Y H:i',
		time_24hr: true,
		enableTime: true,
		nextArrow: '&#8250;',
		prevArrow: '&#8249;',
		...options
	}
	
	const isHaveDate = fp?.selectedDates.length > 0

	const _onOpen = (...args) => {
		// исправляет ошибку установки минимального времени вместо дефолтного
		const _fp = args[2]
		const {defaultHour, defaultMinute} = options
		const updateTimeFromDefaults = (defaultHour || defaultMinute) && !isHaveDate && _fp
		
		if (updateTimeFromDefaults) {
			_fp.hourElement.value = defaultHour
			_fp.minuteElement.value = defaultMinute
		}
		
		onOpen?.(...args)
	}
	
	const showClearBtn = isHaveDate && !props.disabled
	
	return (
		<fieldset className={`position-relative has-icon-left flatpickr ${className}`}>
			<Flatpickr
				className={`form-control ${inputClassName}`}
				value={value}
				ref={instanceRef}
				onChange={onChange}
				placeholder={placeholder}
				defaultValue={defaultValue}
				options={_options}
				onOpen={_onOpen}
				{...props}
			>
			</Flatpickr>
			
			<div className='form-control-position'>
				<i className={`bx bx-calendar-check ${toggle(isHaveDate, 'text-primary')}`}/>
			</div>
			
			{showClearBtn &&
			<div className='input-group-append abs-c-right-center cursor-pointer'
			     onClick={fp?.clear}>
				<i className='flatpickr-c-close input-group-text bx bx-x border-0 bg-transparent px-50'/>
			</div>
			}
		
		</fieldset>
	)
}


MyDatePicker.propTypes = {
	defaultValue: string,
	type: oneOf(['range']),
	options: shape({
		/*
  Allows the user to enter a date directly input the input field. By default, direct entry is disabled.
  */
		allowInput: bool,
		
		/* Allow preloading of invalid date */
		allowInvalidPreload: bool,
		
		/* Exactly the same as date format, but for the altInput field */
		altFormat: string,
		
		/* Show the user a readable date (as per altFormat), but return something totally different to the server.*/
		altInput: bool,
		
		/* This class will be added to the input element created by the altInput option.  Note that altInput already inherits classes from the original input. */
		altInputClass: string,
		
		/* Whether to enable animations, such as month transitions */
		animate: bool,
		
		/* Instead of body, appends the calendar to the specified node instead. */
		appendTo: node,
		
		/* Defines how the date will be formatted in the aria-label for calendar days, using the same tokens as dateFormat. If you change this, you should choose a value that will make sense if a screen reader reads it out loud. */
		/* Defaults to "F j, Y" */
		ariaDateFormat: string,
		
		/* Whether the default time should be auto-filled when the input is empty and gains or loses focus. */
		/* Defaults to true */
		autoFillDefaultTime: bool,
		
		/*
		  Whether clicking on the input should open the picker.
		  Set it to false if you only want to open the calendar programmatically
		*/
		clickOpens: bool,
		
		/* Whether calendar should close after date selection */
		closeOnSelect: bool,
		
		/*
		  If "mode" is "multiple", this string will be used to join
		  selected dates together for the date input value.
		*/
		conjunction: string,
		
		/*
		  A string of characters which are used to define how the date will be displayed in the input box.
		  See https://chmln.github.io/flatpickr/formatting
		*/
		dateFormat: string,
		
		/* The initial selected date(s). */
		// defaultDate: oneOfType([string, array]),
		
		/* Initial value of the hour element, when no date is selected */
		defaultHour: number,
		
		/* Initial value of the minute element, when no date is selected */
		defaultMinute: number,
		
		/* Initial value of the seconds element, when no date is selected */
		defaultSeconds: number,
		
		/*
		  Disables certain dates, preventing them from being selected.
		  See https://chmln.github.io/flatpickr/examples/#disabling-specific-dates */
		//["2025-01-30", "2025-02-21", "2025-03-08", new Date(2025, 4, 9) ],function(date) {
		//             // return true to disable
		//             return (date.getDay() === 0 || date.getDay() === 6);
		//
		//         }
		disable: oneOfType([array, func]),
		
		/* Set this to true to always use the non-native picker on mobile devices.
	  By default, Flatpickr utilizes native datetime widgets unless certain options (e.g. disable) are used. */
		disableMobile: bool,
		
		/* Disables all dates except these specified. See https://chmln.github.io/flatpickr/examples/#disabling-all-dates-except-select-few */
		//["2025-01-30", "2025-02-21", "2025-03-08", new Date(2025, 4, 9) ],
		enable: array,
		
		/* Enables seconds selection in the time picker.
		 */
		enableSeconds: bool,
		
		/* Enables the time picker */
		enableTime: bool,
		
		// (e: Error) => void,
		errorHandler: func,
		
		/* Allows using a custom date formatting function instead of the built-in. Generally unnecessary.  */
		// (date: Date, format: string, locale: Locale) => string,
		formatDate: func,
		
		/* If "weekNumbers" are enabled, this is the function that outputs the week number for a given dates, optionally along with other text  */
		//(date: Date) => string | number,
		getWeek: func,
		
		/*   Adjusts the step for the hour input (incl. scrolling) */
		hourIncrement: number,
		
		/* By default, clicking anywhere outside of calendar/input will close the calendar.
		Clicking on elements specified in this option will not close the calendar */
		ignoredFocusElements: oneOfType([node, element]),
		
		/* Displays the calendar inline */
		inline: bool,
		
		/* The locale, either as a string (e.g. "ru", "en") or as an object.
		See https://chmln.github.io/flatpickr/localization/ */
		locale: oneOfType([string, object]),
		
		/* The maximum date that a user can pick to (inclusive). */
		maxDate: oneOfType([string, object]),
		
		/* The maximum time that a user can pick to (inclusive). */
		maxTime: string,
		
		/* The minimum date that a user can start picking from (inclusive). */
		minDate: oneOfType([string, object]),
		
		/* The minimum time that a user can start picking from (inclusive). */
		//"16:00"
		minTime: string,
		
		/* Adjusts the step for the minute input (incl. scrolling)
		Defaults to 5 */
		minuteIncrement: number,
		
		/* Date selection mode, defaults to "single" */
		mode: oneOf(['single', 'multiple', 'range', 'time']),
		
		/* How the month selector in the calendar should be shown */
		monthSelectorType: oneOf(['dropdown', 'static']),
		
		/* HTML for the right arrow icon, used to switch months. */
		nextArrow: node,
		
		/* Hides the day selection in calendar.
	  Use it along with "enableTime" to create a time picker. */
		noCalendar: bool,
		
		/* A custom datestring parser */
		//(date: string, format: string) => Date,
		parseDate: func,
		
		/* Plugins. See https://chmln.github.io/flatpickr/plugins/ */
		//[new confirmDatePlugin({})]
		plugins: array,
		
		/* How the calendar should be positioned with regards to the input. Defaults to "auto" */
		position: oneOf([
			'auto',
			'above',
			'below',
			'auto left',
			'auto center',
			'auto right',
			'above left',
			'above center',
			'above right',
			'below left',
			'below center',
			'below right']
		),
		
		/*
		  The element off of which the calendar will be positioned.
		  Defaults to the date input
		*/
		positionElement: node,
		
		/* HTML for the left arrow icon, used to switch months. */
		prevArrow: string,
		
		/* Whether to display the current month name in shorthand mode, e.g. "Sep" instead "September" */
		shorthandCurrentMonth: bool,
		
		/* Creates a wrapper to position the calendar. Use this if the input is inside a scrollable element */
		static: bool,
		
		/* Sets the number of months to show */
		showMonths: number,
		
		/* Displays time picker in 24 hour mode without AM/PM selection when enabled.*/
		time_24hr: bool,
		
		/* Display week numbers left of the calendar. */
		weekNumbers: bool,
		
		/* See https://chmln.github.io/flatpickr/examples/#flatpickr-external-elements */
		wrap: bool,
	}),
	onChange: oneOfType([func, array]),
	onOpen: oneOfType([func, array]),
	onClose: func,
	onMonthChange: func,
	onYearChange: func,
	onReady: func,
	onValueUpdate: func,
	onDayCreate: func,
	onCreate: func,
	onDestroy: func,
	value: oneOfType([string, array, object, number]),
	// children: node,
	className: string,
	render: func
}