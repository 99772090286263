import { Link } from 'react-router-dom'
import { servicesAPI } from '../../API/servicesAPI'
import { useFetchStates } from '../../hooks/useFetchStates'
import { getLocaleFullDate, isEmpty, loader, translateThisBullshit } from '../../js-helpers/helpers'
import { Card } from '../UI/Card'
import { StatusCall } from '../UI/StatusCall'


export function InfoCallsCard({pageType, keycloakID, deviceID, className = ''}) {
	
	const calls = useFetchStates(() => {
    if(pageType === 'users') {
      return  servicesAPI.getCalls({
        page: 1, page_size: 3, user__account__keycloak_id: keycloakID, ordering: '-date'
    })
    } else if (pageType === 'devices') {
      return  servicesAPI.getCalls({
        page: 1, page_size: 3, device__serial_number: deviceID, ordering: '-date', search: deviceID
    })
    }
  },
		{initialData: {results: []}, setNestedField: 'results'}
	)
	
	return (
		<Card secondary title={'Журнал звонков'} className={`${loader(calls.isReady)} ${className}`}>
			{isEmpty(calls.data)
				? <span>Нет звонков</span>
				:
				<>
					<ul className={'list-unstyled m-0'}>
						{calls.data?.map(
							({
								 type, contact_name,
								 contact_phone,
								 date, duration
							 }) => (
								<Card as={'li'}
								      size={1}
								      key={date}
								      className={'mb-1 text-muted font-small-3'}>
									
									<StatusCall type={type} className={'float-right bg-c-rgba-light'}/>
									<h3 className={'font-small-3 font-weight-bold text-body'}>
										{translateThisBullshit(contact_name)} ({contact_phone})
									</h3>
									
									{getLocaleFullDate(date)}
									<br/>
									{`Длительность: ${duration}`}
								
								</Card>
							))}
					</ul>
					
					<Link to={`/admins/${pageType}/${keycloakID || deviceID}/calls/`}
					      className={'text-underline float-right'}>
						Просмотреть все
					</Link>
					<br/>
				</>
			}
		</Card>
	)
}