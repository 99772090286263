import { useUpdateEffect } from "@react-hookz/web";
import { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { servicesAPI } from "../../../../API/servicesAPI";
import { AudioPlayerFullScreen } from "../../../../components/media/AudioPlayer/AudioPlayerFullScreen";
import { useAudioPlayer } from "../../../../components/media/AudioPlayer/useAudioPlayer";
import { MyTable } from "../../../../components/table/MyTable/MyTable";
import { MyTableBody } from "../../../../components/table/MyTable/MyTableBody";
import { MyTableHead } from "../../../../components/table/MyTable/MyTableHead";
import { useMyTable } from "../../../../components/table/MyTable/useMyTable";
import { callTypes, initStateFullScreenPlayer } from "../../../../constants/constants";
import { urlParams } from "../../../../constants/urlParams";
import { useComboSearch } from "../../../../hooks/url/useComboSearch";
import { useFetchStates } from "../../../../hooks/useFetchStates";
import { loader, toggle, translateThisBullshit } from "../../../../js-helpers/helpers";
import { callsHistoryTable } from "../../../dataTables/PageCallsHistory/table";


const { PAGE, LIMIT, SORT } = urlParams;
const pageSearchParams = [
  { param: PAGE, init: 'last' },
  { param: SORT, init: 'date' },
  { param: LIMIT },
];

export const PageDeviceCalls = () => {
  const { deviceID } = useParams();

  const [[urlPage, setUrlPage], [urlSortField], [urlLimit]] =
  useComboSearch(pageSearchParams);
  
  const setPageHead = useOutletContext();

  useEffect(() => {
    setPageHead({ head: `Звонки устройства (${deviceID})` });
  }, [deviceID]);

  const {
    data: calls,
    getData,
    isReady: callsIsReady,
  } = useFetchStates(() =>
    servicesAPI.getDeviceCalls(urlPage, deviceID, urlLimit, deviceID, urlSortField)
  ); 

  const audioArray = useMemo(() => {
    return calls?.results.map((call) =>  (call.normalized_audio || call.audio) && (
     { fileName: `${translateThisBullshit(call.contact_name)
         }_${callTypes[call.type][0]
         }_${call.contact_phone}_${
           new Date(call.date)
             .toLocaleString()
             .replaceAll('.', '-')
             .replaceAll(' ', '_')
             .replaceAll(',', '')
             .replaceAll(':', '-')
         }_${call.duration.replaceAll(':', '-')}`,
         audio: call.normalized_audio || call.audio
     })).filter((call) => call !== null)
   }, [calls])

   useUpdateEffect(async () => {
    await getData();
    playerStates.setBadLinks([]);
  }, [urlPage, urlSortField]);

  useUpdateEffect(async () => {
    if (urlPage !== 'last') {
      setUrlPage('last');
      return;
    }
    await getData();
    playerStates.setBadLinks([]);
  }, [urlLimit]);

  const { playerProps, playerStates } = useAudioPlayer();
  const { badLinks, loadedLinks, playingCallURL } = playerStates;

  const [audioName, setAudioName] = useState('')

  const [
    { playing: fullScreenPlaying, 
      played: fullScreenPlayed, 
      seeking: fullScreenSeeking, 
      duration: fullScreenDuration, 
      badURL: fullScreenBadURL, 
      isReady: fullScreenIsReady, 
      volume },
    setFullScreenPlayerState,
  ] = useImmer(initStateFullScreenPlayer);

  const { tableOptions, tableHeads, tableColgroup } = useMyTable(
    callsHistoryTable.getTableCallsHistory({
      playerStates,
      playerProps,
      userNotNeeded: false,
      deviceNotNeeded: true,
      setFullScreenPlayerState,
      fullScreenPlaying,
      setAudioName
    }),
    {
      memoDependencies: [
        playingCallURL,
        badLinks,
        loadedLinks,
        fullScreenPlaying
      ],
    }
  );

  useUpdateEffect(() => {
    playerProps.setPlayingCallURL(false)
  }, [urlPage]);

  return (
    <div style={{ marginBottom: '120px'}}>
       <MyTable colgroup={tableColgroup} paginationAllCount={calls?.count} addScroll={true}>

        <MyTableHead heads={tableHeads} />
        <MyTableBody
          getRowClassName={(row) =>
            toggle(row?.is_success === false, 'bg-rgba-danger-light')
          }
          data={calls?.results}
          tableOptions={tableOptions}
          className={loader(callsIsReady)}
          highestRowToFill={40.5}
          rowClassName={'showInHover'}
        />
       </MyTable>

       {playingCallURL &&
        <div  className='position-fixed container p-0' 
              style={{bottom: '0', zIndex: '4'}}>
          <AudioPlayerFullScreen 
              url={playingCallURL} playingCallURL={playingCallURL}
              setFullScreenPlayerState={setFullScreenPlayerState} 
              fullScreenPlaying={fullScreenPlaying} 
              fullScreenPlayed={fullScreenPlayed} 
              fullScreenSeeking={fullScreenSeeking} 
              fullScreenDuration={fullScreenDuration} 
              fullScreenBadURL={fullScreenBadURL} 
              fullScreenIsReady={fullScreenIsReady}
              volume={volume}  audioName={audioName}
              setAudioName={setAudioName}
              audioArray={audioArray}
              {...playerProps}/>
        </div>
      }
    </div>
  )
}