import {useImmer} from 'use-immer';
import {useMyTable} from '../../../../../../components/table/MyTable/useMyTable';
import {Card} from '../../../../../../components/UI/Card';
import {MyTable} from '../../../../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../../../../components/table/MyTable/MyTableBody';
import {Button} from '../../../../../../components/button/Button';
import {loader} from '../../../../../../js-helpers/helpers';
import {AppWimg} from '../../../../../../components/UI/AppWimg';
import {viber_2x, whatsapp_2x} from '../../../../../../assets/icons/apps/appsLogos';
import {CheckBox} from '../../../../../../components/form/CheckBox';
import {useEffect} from 'react';
import { DescriptionBabble } from '../../../../../../components/DescriptionInfoBabble/DescriptionInfoBabble';

const tableData = [
	{
		app: null,
		personal: ['messengers_whatsapp_personal', 'messengers_viber_personal'],
		other: ['messengers_whatsapp_other', 'messengers_viber_other'],
	}, {
		app: <AppWimg name={'WhatsApp'} icon_url={whatsapp_2x}/>,
		personal: 'messengers_whatsapp_personal',
		other: 'messengers_whatsapp_other'
	}, {
		app: <AppWimg name={'Viber'} icon_url={viber_2x}/>,
		personal: 'messengers_viber_personal',
		other: 'messengers_viber_other'
	}]


const getTrackersTableOptions = (trackers, setTrackers) => {
	
	const toggleTrack = (track, checked) => {
		setTrackers(draft => {
			Array.isArray(track)
				? track.forEach(track => {
					draft[track] = checked
				})
				: draft[track] = checked
		})
	}
	
	function getIsChecked(other) {
		return Boolean(Array.isArray(other)
			? other.every(track => trackers[track])
			: (trackers[other]))
	}
	
	return [
		{
			head: 'Название мессенджера',
			headClassName: 'text-left',
			cellClassName: 'py-2 text-left',
			cell: 'app',
		}, {
			head: <DescriptionBabble text={'Внутренние / Внешние / Остальные'} tooltip={'Контакты, которые не внесены в телефонную книгу пользователя'}/> ,
			nestedCellField: 'other',
			cell: (other) =>
				<CheckBox checked={getIsChecked(other)}
				          onChange={e => toggleTrack(other, e.target.checked)}/>,
		}, {
			head: 'Личные',
			nestedCellField: 'personal',
			cell: (personal) =>
				<CheckBox checked={getIsChecked(personal)}
				          onChange={e => toggleTrack(personal, e.target.checked)}/>,
		},
	]
}


export function WhatsAppViberCard({
	                                  onSave,
	                                  isReadyRequests,
	                                  initTrackers
                                  }) {
	
	const [trackers, setTrackers] = useImmer({})
	
	useEffect(() => {
		isSameInitTrackers() &&
		setTrackers(
			Object.fromEntries(
				Object.entries(initTrackers)
				.filter(([key]) => key.includes('whatsapp') || key.includes('viber')))
		)
	}, [initTrackers])
	
	const {tableColgroup, tableHeads, tableOptions} = useMyTable(
		getTrackersTableOptions(trackers, setTrackers) || [],
		{memoDependencies: [isReadyRequests, trackers]})
	
	function getIsDisabledSaveBtn() {
		return !isReadyRequests || isSameInitTrackers()
	}
	
	function isSameInitTrackers() {
		return Object.entries(trackers).every(([track, checked]) => initTrackers[track] === checked)
	}
	
	return (
		<Card className={'mb-1'}>
			
			<MyTable colgroup={tableColgroup}
			         tableClassName={'bg-c-rgba-light text-center'}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={tableData}
				             getRowClassName={(row) => !row.app ? 'text-c-transparent bg-c-rgba-light-darker hide-table-row-hover' : ''}
				             tableOptions={tableOptions}
				             className={loader(isReadyRequests)}
				/>
			</MyTable>
			
			<Button save
			        disabled={getIsDisabledSaveBtn()}
			        position={'right'}
			        onClick={() => onSave(trackers)}/>
		</Card>
	)
}