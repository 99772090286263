import { Link } from "react-router-dom"
import { Card } from "../../../../../components/UI/Card"
import { Head } from "../../../../../components/UI/Head"
import { getFullNameFromObj, loader } from "../../../../../js-helpers/helpers"

export const PageDeviceUserInfo = ({device, className = '', isReady}) => {
  return ( 
    <Card secondary className={`${className} ${loader(isReady)}`}>

      <Head secondary>Информация о пользователе</Head>

      <Card className={'d-flex flex-column'}>

        {device?.user ? (
          <>
            <Link to={`/admins/users/${device?.user?.keycloak_id}/info/`} className={'font-weight-bold font-small-3'}>
              {getFullNameFromObj(device?.user)}
            </Link>
          </>
        ) : (
          'Отсутствует'
        )}
      </Card>
    </Card>
  )
}