import {useImmer} from 'use-immer';
import {useToggleState} from '../../../hooks/useToggleState';
import {servicesAPI} from '../../../API/servicesAPI';
import {toast} from 'react-toastify';
import {isEmpty, loader} from '../../../js-helpers/helpers';
import {MyNewInput} from '../../../components/form/MyNewInput';
import {CheckBox} from '../../../components/form/CheckBox';
import React from 'react';
import {ModalDialogFooter} from '../../../components/modals/ModalDialogFooter';

const initData = {
	crashes: true,
	feedbacks: true,
	email: '',
}

export function MailingModal({onCancelClick, onSuccessCreate}) {
	const [currentData, setCurrentData] = useImmer(initData)
	const [isReady, toggleReady] = useToggleState(true)
	
	const toggleCrashes = e => {
		setCurrentData(draft => {
			draft.crashes = e.target.checked
		})
	}
	
	const toggleFeedbacks = e => {
		setCurrentData(draft => {
			draft.feedbacks = e.target.checked
		})
	}
	
	const setEmail = e => {
		setCurrentData(draft => {
			draft.email = e.target.value
		})
	}
	
	const onSuccessClick = async () => {
		toggleReady()
		
		try {
			await servicesAPI.postMailingItem(currentData)
			onSuccessCreate()
			toast('Новая рассылка добавлена!', {type: 'success'})
			
		} catch (e) {
			toast(`Ошибка добавления рассылки: ${e?.response?.data?.errors?.[0]?.detail}`, {type: 'error'})
			toggleReady()
		}
	}
	
	const {crashes, feedbacks, email} = currentData
	const isDisabledSaveBtn = (!crashes && !feedbacks) || !isReady || isEmpty(email)
	
	return (
		<div className={loader(isReady)}>
			<div className={'text-left my-2 mx-3'}>
				<MyNewInput className={'mb-2'}
				            label={'e-mail'}
				            value={email}
				            onChange={setEmail}
				            placeholder={'Введите e-mail'}/>
				
				<label className={'d-block'}>разделы для отправки</label>
				
				<CheckBox appendLabel={'Запросы от пользователей'}
				          checked={feedbacks}
				          onChange={toggleFeedbacks}
				          className={'mr-2'}/>
				
				<CheckBox appendLabel={'Crashlytics'}
				          checked={crashes}
				          onChange={toggleCrashes}/>
			
			</div>
			
			<ModalDialogFooter successDisabled={isDisabledSaveBtn}
			                   onSuccessClick={onSuccessClick}
			                   onCancelClick={onCancelClick}/>
		</div>
	)
}