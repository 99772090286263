import { useContext, useEffect } from 'react';
import { KeycloakContext } from '../../../context/KeycloakProvider';
import axios from 'axios';
import { getDateNow } from '../../../js-helpers/helpers';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import { useNetworkState } from '@react-hookz/web';
import { useNavigate } from 'react-router-dom';
import { useAdminAndAppUpdate } from './hooks/useAdminAndAppUpdate';
import { useAbortRequest } from './hooks/useAbortRequest';
import { useRetryRequest } from './hooks/useRetryRequest';
import { showRequestErrorMessage } from './showRequestErrorMessage';
import {API_VERSION} from '../../../API/constants/API';

export const AxiosInterceptorsHelper = () => {
  const { appLogoutWithMsg } = useContext(KeycloakContext);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const { online: isOnline } = useNetworkState();

  const checkNeedUpdateAdminAndAppVer = useAdminAndAppUpdate();
  const isRetryRequest = useRetryRequest();
  const checkSameRequest = useAbortRequest();

  useEffect(() => {
    axios.interceptors.request.use(
      async (config) => {
        
        config.headers.Authorization = `Bearer ${keycloak?.token}`

        // config.headers = {Authorization: `Token ede2e22fcc435719f6f7a692b463e6cffc99209b`}

        const {
          baseURL,
          allowMultipleRequests,
          method,
          apiVersion = API_VERSION,
        } = config;
        // console.log({allowMultipleRequests})
        if (baseURL.endsWith('/api/')) config.baseURL += `v${apiVersion}`;

        if (!allowMultipleRequests) config = checkSameRequest(config);

        if (method === 'get') config.timeout = 10000;

        return config;
      },
      (error) => Promise.reject(error)
    );
  }, [keycloak?.token]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        const { status } = response || {};

        if (status === 200) checkNeedUpdateAdminAndAppVer(getDateNow());

        if (status > 500)
          toast(`Ошибка сервера: ${status}`, {
            type: 'error',
            position: 'top-center',
          });

        return response;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const { response, config, code, message } = error || {};
        const skipError = config?.skipErrorHandling;

        if (isRetryRequest(code)) return axios.request(config);

        !skipError && showRequestErrorMessage(response, message, isOnline);

        const { status, data: { detail } = {} } = response || {};

        if (status === 404 && !skipError) {
          navigate('/404/', { replace: true });
        }

        if (
          status === 401 ||
          (status === 403 && detail === 'Пользователь неактивен или удален.')
        ) {
          toast.error(detail);
          appLogoutWithMsg(detail);
          return;
        }

        if (status === 403) {
          navigate('/403/', { state: detail, replace: true });
          return;
        }

        return Promise.reject(error);
      }
    );
  }, [isOnline]);

  return null;
};
