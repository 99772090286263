import React, {useEffect} from 'react';
import {useCustomSearch} from '../../hooks/url/useCustomSearch';
import {usePreviousDistinct, useUpdateEffect} from '@react-hookz/web/esnext';
import {CheckBox} from '../../components/form/CheckBox';
import {urlParams} from '../../constants/urlParams';
import {WithTooltip} from '../../components/UI/WithTooltip';
import {observer} from 'mobx-react-lite';
import {exportDataStore} from './exportStore';
import {useMountEffect, useUnmountEffect} from '@react-hookz/web';
import {ButtonWithIcon} from '../../components/button/ButtonWithIcon';
import {ButtonGoBack} from '../../components/button/ButtonGoBack';

const ButtonAllCheck = observer(() => {
	const {isReady, toggleAllChecked} = exportDataStore;
	return <button disabled={!isReady}
	               className={'btn btn-sm btn-block btn-outline-primary'}
	               onClick={() => toggleAllChecked(true)}>
		Выбрать всё
	</button>
})

const ButtonAllUnCheck = observer(() => {
	const {isReady, toggleAllChecked} = exportDataStore;
	return <button disabled={!isReady}
	               className={'btn btn-sm btn-outline-primary'}
	               onClick={() => toggleAllChecked(false)}>
		Исключить всё
	</button>
})

const CheckboxToggleItem = observer(({id, isDisabled}) => {
	const {onCheckItem, isReady, getIsItemChecked} = exportDataStore;
	return <CheckBox onChange={(e) => {onCheckItem(id, e.target.checked)}}
	                 checked={getIsItemChecked(id)}
	                //  disabled={!isReady}
                  disabled={isDisabled ? isDisabled : !isReady}
	/>
})

const CheckboxToggleAllPageItems = observer(({isDisabled}) => {
	const {isReady, onCheckAllOnPage, getIsAllCheckedOnPage} = exportDataStore
	
	return <CheckBox disabled={isDisabled ? isDisabled : !isReady}
	                 checked={getIsAllCheckedOnPage()}
	                 onChange={onCheckAllOnPage}
	/>
})

const CounterToExport = observer(({isCreateMode = false, customCount = null}) => {
		const [urlExportMode] = useCustomSearch(urlParams.EXPORT_MODE)
		
		if (!urlExportMode && !isCreateMode)
			return null
		
		return (
			<label className={'font-weight-bold text-body'}>
				Выбрано: {customCount ? customCount : exportDataStore.includedIDSCount}
			</label>
		)
	}
)

const checkBoxesColl = (cellClassName = 'text-center', isDisabled) => ({
	head:
    isDisabled ? 
    <CheckboxToggleAllPageItems isDisabled={isDisabled}/> 
    : <WithTooltip delay={1000} position={'right'} className={'bg-white'}
		             tooltips={[<ButtonAllCheck/>, <ButtonAllUnCheck/>,]}>
			<CheckboxToggleAllPageItems isDisabled={isDisabled}/>
		</WithTooltip>,
	cell: item =>
		<label className={'cursor-pointer m-0'}>
			<CheckboxToggleItem id={item.keycloak_id ? item.keycloak_id : item.uuid ? item.uuid : item.serial_number ? item.serial_number : item.id} isDisabled={isDisabled}/>
		</label>,
	cellClassName,
	// nestedCellField: 'id',
})

const ButtonToggleExport = ({className = ''}) => {
	const [urlIsExportMode, setIsExportMode] = useCustomSearch(urlParams.EXPORT_MODE)
	
	if (urlIsExportMode)
		return <ButtonGoBack onClick={(() => setIsExportMode(null))}
		                     className={'float-right ml-1'}/>
	
	return <ButtonWithIcon type={'export'}
	                       onClick={() => setIsExportMode(true)}
	                       className={className}/>
}

const ButtonSaveExport = observer(({className = '', mode = '', isDisabled}) => {
    const buttonType = {
      create: 'Создать',
      update: 'Обновить'
    }

		const {includedIDSCount, onSaveBtnClick} = exportDataStore;
		const [urlIsExportMode] = useCustomSearch(urlParams.EXPORT_MODE)
		
		if (!urlIsExportMode && !mode) return null;

		return (
			<button className={`btn btn-primary d-block ml-auto ${className}`}
			        disabled={urlIsExportMode ? !includedIDSCount :  !includedIDSCount || isDisabled}
			        onClick={onSaveBtnClick}>
				{urlIsExportMode ? 'Скачать' : buttonType[mode]}
			</button>
		)
	}
)

export const useExportData = (
	{
		requestMethod,
		requestParams = {},
		pageData = [],
		onSaveExport,
		prepareItemFunc
	}) => {
	const {isReady, getIcludedIDSArray, clearIncludedIDS, resetStore, setRequestMethod, setRequestParams, setMode, setIncludedIDS, includedIDSCount} = exportDataStore

	// console.log({exportDataStore})
	useMountEffect(() => {
		exportDataStore.currRequestMethod = requestMethod
		exportDataStore.onSaveExport = onSaveExport
		exportDataStore.currPrepareItemFunc = prepareItemFunc
	})

  const setOnSaveExport = (cd) => {
    exportDataStore.onSaveExport = cd
  }

	const stringifyRequestParams = JSON.stringify(requestParams)

	useEffect(() => {
		exportDataStore.currRequestParams = requestParams
	}, [stringifyRequestParams])
	
	useEffect(() => {
		exportDataStore.currPageData = pageData
	}, [pageData])
	
	//ресет стора
	const [urlIsExportMode] = useCustomSearch(urlParams.EXPORT_MODE)
	const prevUrlIsExportMode = usePreviousDistinct(urlIsExportMode)
	
	useUpdateEffect(() => {
		if (prevUrlIsExportMode && !urlIsExportMode)
			clearIncludedIDS()
	}, [urlIsExportMode])
	
	useUnmountEffect(() => {
		// console.log('useUnmountEffect')
		resetStore()
	})
	
	return {
		isReady,
    getIcludedIDSArray,
		clearIncludedIDS,
		CounterToExport,
		ButtonToggleExport,
		ButtonSaveExport,
		checkBoxesColl,
    resetStore,
    setRequestMethod,
    setOnSaveExport,
    setRequestParams,
    setMode,
    setIncludedIDS, 
    includedIDSCount
	}
}
