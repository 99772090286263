import React from 'react';

export function MySelectToggleButton({children, buttonProps}) {
	const {
		disabled, titleClassName = '', getToggleButtonProps = () => {}, closeButton, closeButtonHandled = () => {}
	} = buttonProps
	
	return (
    <>
		<button disabled={disabled}
		        className={`form-control dropdown-toggle text-left text-c-crop ${titleClassName} ${closeButton ? 'd-flex justify-content-between align-items-center position-relative' : ''}`}
		        {...getToggleButtonProps()}
		>
			{children}
			{/*// && getShortString(children.toString(), 32)}*/}
     
		</button>
    {closeButton ? <i  className={'bx bx-x position-absolute cursor-pointer dropdown-close'}
                          style={{top: '25%', right: '8px', backgroundColor: '#fff', color: '#a4b0be'}}
                          onClick={closeButtonHandled}>
                      </i> : <></>}
    </>
	)
}