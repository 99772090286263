import exportFromJSON from 'export-from-json';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCell } from '../../../components/table/MyTable/MyTableBody';
import { getNotificationsTableOptions, columnsNotifications } from '../../../components/table/MyTable/options/columnsNotifications';
import { ADMIN_TYPE, USER_TYPE } from '../../../constants/constants';
import { urlParams } from '../../../constants/urlParams';
import { getDateNow, getFileNameFormatDate} from '../../../js-helpers/helpers';
import { notificationsOptions } from './notificationsOptions';

const {SEARCH, SORT, PAGE, LIMIT, TYPE, SENDER, RECEIVER, DATE_FROM, DATE_TO, EXPORT_MODE} = urlParams;

const {id, title, clearText, sender, receiver, clearDate, sendCount, receiveCount, admin} = columnsNotifications

export const topButtons = (ButtonToggleExport) => ({
  admin: (
    <>
      <Link to={'create/'} className={'btn btn-primary float-right mr-1'}>
        Добавить уведомления
      </Link>
      <ButtonToggleExport className={'float-right mr-1'} />
    </>
  ),

  user: (
    <ButtonToggleExport className={'float-right mr-1'} />
  ),
  rules: (
    <>
      <Link to={'rules/create/'} className={'btn btn-primary mr-1'}>
        Создать правило
      </Link>
      <Link
        to={'rules/default/'}
        className={'btn btn-outline-primary bg-white mr-1'}
      >
        Правило по умолчанию
      </Link>
    </>
  ),
});

export const pageNotificationsConfig = {
  pageSearchParams: [
    { param: SEARCH, init: '', debounce: 333 },
    { param: PAGE, init: 'last' },
    { param: SORT, init: 'created_at' },
    { param: LIMIT },
    { param: TYPE, init: notificationsOptions[0].type },
    { param: SENDER },
    { param: RECEIVER },
    { param: DATE_FROM },
    { param: DATE_TO },
    { param: EXPORT_MODE },
  ],
  getNotificationsTable(isEmptySearchQuery, urlType, isExportMode, checkBoxesColl,deleteRule) {
    const baseOptions = [
      ...getNotificationsTableOptions(isEmptySearchQuery, urlType, deleteRule)[urlType],
    ];


   const exportModeOptions =  [checkBoxesColl(), ...baseOptions];
    return isExportMode ? exportModeOptions : baseOptions;
  },

  exportColumns(type) {
    if (type === USER_TYPE) {
      return [id, title, clearText, sender, receiver, clearDate]
    } else if (type === ADMIN_TYPE) {
      return [id, title, clearText, sendCount, receiveCount, admin, clearDate]
    }
  },
};

export const exportExcel = (exportList, type) => {
  const dataToExcel = exportList.map((item) => {
    const finalObject = {};

    pageNotificationsConfig.exportColumns(type).forEach((col) => {
      finalObject[col.head] = getCell(col, item);
    });

    return finalObject;
  });

  const fileName = `Уведомления_${getFileNameFormatDate(getDateNow())}`;

  exportFromJSON({
    data: dataToExcel,
    withBOM: true,
    fileName,
    exportType: exportFromJSON.types.xls,
  });

  toast.success(`Файл создан!
	Уведомлений: ${dataToExcel.length}`);
};
