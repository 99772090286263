import {useRef, useState} from 'react'
import {useClickOutside} from '@react-hookz/web';
import {flip, offset, shift, useFloating} from '@floating-ui/react-dom';
import {Card} from './UI/Card';
import {getShortString, toggle} from '../js-helpers/helpers';

const minLettersForModal = 30

export const TextFeedbacks = ({head, className = '', modalClassName = ''}) => {
	const [isOpen, setIsOpen] = useState(false)
	
	const {
		x, y, reference, floating, strategy,
	} = useFloating({
		middleware: [
			flip(),
			shift({padding: 15}),
			offset(10),
		],
		placement: 'top-start',
	})
	
	const ref = useRef()
	useClickOutside(ref, () => setIsOpen(false))
	
	if (head.length < minLettersForModal)
		return head
	
	return (
		<div ref={ref} className={`position-relative dropdown ${toggle(isOpen, 'show')}`}>
			<button
				ref={reference}
				className={`form-control font-small-3 dropdown-toggle text-left text-c-crop ${className}`}
				onClick={() => setIsOpen(open => !open)}
			>
				{getShortString(head, minLettersForModal)}
			</button>
			
			{isOpen &&
			<Card ref={floating}
			      style={{
				      position: strategy,
				      top: y ?? 0,
				      left: x ?? 0,
			      }}
			      className={`scroll-c c-tooltip max-c-size-half-viewport ${modalClassName}`}>
				{head}
			</Card>}
			{/*<PortalModal isOpen={isOpen} className={'modal-c-bg'} onClose={setIsOpen}*/}
			{/*             childrenClassName={'js-open'}/>*/}
		</div>
	)
}