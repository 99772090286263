import { useUpdateEffect } from "@react-hookz/web"
import { useEffect, useMemo } from "react"
import { adminSmassAPI } from "../../../../API/adminSmassAPI"
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { SearchInput } from "../../../../components/form/SearchInput"
import { MyTable } from "../../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../../components/table/MyTable/MyTableHead"
import { getAdminsTableOptions } from "../../../../components/table/MyTable/options/columnsAdmins"
import { useMyTable } from "../../../../components/table/MyTable/useMyTable"
import { Head } from "../../../../components/UI/Head"
import { urlParams } from "../../../../constants/urlParams"
import { useComboSearch } from "../../../../hooks/url/useComboSearch"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import { isEmpty, loader, toggle } from "../../../../js-helpers/helpers"

const {SEARCH, SORT, PAGE, LIMIT} = urlParams

const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: 'account__last_name'},
	{param: PAGE, init: 1, paramHandler: Number},
	{param: LIMIT}
]

export const PageAdminsNew = () => {
  const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(pageSearchParams)
	
	const queryRequest = {
		search: urlSearchQuery,
		ordering: urlSortField,
		page_size: urlLimit
	}

  const {data, setData, isReady, getData, setReady} = useFetchStates(
		(_page = urlPage) => adminSmassAPI.getAllNew({...queryRequest, page: _page}),
		{
			noInitFetch: true,
			customSetter: ({data}) => {
				const finalData = {
					count: data.count,
					results: data?.results,
				}
				setData(finalData)
				setReady(true)
			}
		})
	
	useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1)
	}, [urlSearchQuery, urlLimit])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const tableOptions = useMemo(() => getAdminsTableOptions(isEmptySearchQuery),
		[isEmptySearchQuery])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)

  return (
    <>
    	<ButtonGoBack className={'float-right'}/>
			<Head>Новые администраторы</Head>
			
			<SearchInput
				withBorder
				className={'mb-1'}
				onChange={e => setUrlSearchQuery(e.target.value)}
				placeholder={'Поиск по имени, ID, подразделению...'}
				defaultValue={urlSearchQuery}
			/>

			<MyTable colgroup={tableColgroup}
			         showEmptyFoundDataMessage={!isEmptySearchQuery}
			         isDataReady={isReady}
			         paginationAllCount={data?.count}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(isReady)}
				             getRowClassName={(row) => toggle(row?.is_active === false, 'opacity-c-50')}
				             data={data?.results}
				             tableOptions={tableOptions}
				             highestRowToFill={45}/>
			
			</MyTable>
    </>
  )
}