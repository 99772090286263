import {useOutletContext, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {UserInfoAboutCard} from './UserInfoAboutCard';
import {UserInfoDeviceCard} from './UserInfoDeviceCard';
import {InfoLogInOutCard} from '../../../../components/infoCards/InfoLogInOutCard';
import {Card} from '../../../../components/UI/Card';
import {InfoCallsCard} from '../../../../components/infoCards/InfoCallsCard';
import { UserInfoSystemCard } from './UserInfoSystemCard';
import { UserGroupsCard } from './UserGroupsCard';

export function PageUserInfo() {
	const {keycloakID} = useParams()
	const {setPageHead, userStates} = useOutletContext()
	const {date_joined, last_login} = userStates.data?.account || {}

	useEffect(() => {
		setPageHead({head: 'Общая информация'});
	}, [])
	
	return (
		<Card className={'row no-gutters gap-1r'}>

      <div className='col'>
        <div className='row mb-1 no-gutters gap-1r '>
          <UserInfoAboutCard userStates={userStates}
			                       userID={keycloakID}
			                       className={'col-7'}/>

          <UserInfoSystemCard date_joined={date_joined} 
                              last_login={last_login}
                              className={'col'}/>
        </div>

        <div className='row no-gutters gap-1r'>
          <InfoCallsCard pageType='users' 
                         className={'col'} 
                         keycloakID={keycloakID}/>

			    <InfoLogInOutCard pageType='users' 
                            className={'col'} 
                            keycloakID={keycloakID}/>

        </div>
      </div>
			
			<div className='col-4'>
        <UserInfoDeviceCard deviceSN={userStates.data?.device}
                            className={'mb-1'}/>
        <UserGroupsCard data={userStates?.data?.user_groups}/> 
      </div>
		</Card>
	)
}