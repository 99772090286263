import { WithTooltip } from "../../../../components/UI/WithTooltip"
import {urlParams} from "../../../../constants/urlParams"
import {useSearchParamsUpdate} from "../../../../hooks/url/useSearchParamsUpdate"
import {isFunction} from "../../../../js-helpers/helpers"

const {SEARCH, DIVISION, SUB_DIVISION, LAUNCHER_VER, BLOCKED, HAVE_DEVICE, IS_ONLINE} = urlParams

export const blockedStatusOptions = [
	{text: 'Активный', value: 'false'},
	{text: 'Заблокирован', value: 'true'},
]

export const haveDeviceOptions = [
	{ text:<WithTooltip elem={'Имеет / Имел устрой...'} tooltips={['Имеет / Имел устройство']}/>, value: 'true'},
	{text: 'Не имел устройства', value: 'false'},
]

export const isOnlineOptions = [
	{text: 'В сети', value: 'online'},
	{text: 'Разлогинен', value: 'offline'},
  {text: 'Нет информации', value: 'unknown'}
]

export const inGroupOptions = [
  {text: 'В группе', value: 'true'},
	{text: 'Не в группе', value: 'false'},
]

export const initSelects = {
	[DIVISION]: null,
	[SUB_DIVISION]: null,
	[LAUNCHER_VER]: null,
	[BLOCKED]: null,
	[HAVE_DEVICE]: null,
  [IS_ONLINE]: null
}

export const initPillsOptions = [
	{urlKey: SEARCH, label: null},
	{urlKey: DIVISION, label: null},
	{urlKey: SUB_DIVISION, label: null},
	{urlKey: LAUNCHER_VER, label: null},
	{urlKey: BLOCKED, label: null},
	{urlKey: HAVE_DEVICE, label: null},
]

export function UserFilterPills({pillsOptions}) {
	const {setNewSearch, searchParams} = useSearchParamsUpdate()
	
	return (
		<div className={'mb-1'}>
			{pillsOptions.map(({urlKey, label}) => {
				const value = searchParams.get(urlKey)
				if (!value || value === 'null') return null
				const finalLabel = isFunction(label) ? label(value) : label || value
				
				return (
					<span key={urlKey} className={'chip mr-50 bg-white'}>
							<div className={'chip-body'}>
								
								<span className='chip-text'>
									{finalLabel}
								</span>
								
								<div className={'chip-closeable'}
								     onClick={() => {
									     setNewSearch(
										     urlKey,
										     urlKey === BLOCKED ? 'null' : undefined
									     );
								     }}>
									<i className='bx bx-x'/>
								</div>
							</div>
						</span>
				)
			})}
		</div>
	)
}
