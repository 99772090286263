import {useLocation} from 'react-router-dom';
import {MySelect} from '../form/MySelect/MySelect';
import {observer} from 'mobx-react-lite';
import {limitsStore} from '../../stores/limitsOptionsStore';
import {useCustomSearch} from '../../hooks/url/useCustomSearch';
import {urlParams} from '../../constants/urlParams';

const limitOptions = [
	{value: 10, text: '10'},
	{value: 25, text: '25'},
	{value: 50, text: '50'},
]

export const PerPageLimiter = observer(({className = '', show}) => {
	const {pathname} = useLocation()
	const [urlLimit, setUrlLimit] = useCustomSearch(urlParams.LIMIT)
	const clearedUrl = pathname.split('/').filter(section => !section.match(/^\d+$/)).join('/')
	
	if (!urlLimit || !show)
		return null
	
	const onLimitSelect = ({value}) => {
		limitsStore.setNewLimit(clearedUrl, value)
		setUrlLimit(value)
	}
	
	return (
		<fieldset className={`text-gray-500 text-c-nowrap font-small-3 ${className}`}>
			Показывать:
			<MySelect className={'d-inline-block dropup ml-50'}
			          optionsArr={limitOptions}
			          init={!!urlLimit}
			          initialSelectedItem={{value: Number(urlLimit), text: urlLimit}}
			          onSelect={onLimitSelect}
			          titleClassName={'text-gray-500 font-small-3'}
			/>
		</fieldset>
	)
})