import { useToggle } from "@react-hookz/web";
import Downshift from "downshift";
import { useEffect, useState } from "react";

export const MultiDownshift = ({onSelect, onChange, initialSelectedItems, defaultIsOpen = false, children, clearUrl, ...props}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isInitSetted, setInitSetted] = useToggle(false)

  useEffect(() => {
    if(!isInitSetted && initialSelectedItems?.length > 0) {
      setSelectedItems(initialSelectedItems)
      setInitSetted(true)
    }
  }, [initialSelectedItems])

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: '',
        }
      default:
        return changes
    }
  }

  const handleSelection = (selectedItem, downshift) => {
    const callOnChange = (items) => {
      if (onSelect) {
        onSelect(items, getStateAndHelpers(downshift))
      }
      if (onChange) {
        onChange(items, getStateAndHelpers(downshift))
      }
    }

    const isNowSelected = selectedItems.some((item) => item?.value === selectedItem?.value)

    const _finalItems = isNowSelected
    ? selectedItems.filter((i) => i.value !== selectedItem.value)
    : [...selectedItems, selectedItem]

    setSelectedItems(_finalItems)
    callOnChange(_finalItems)

    // if (selectedItems.find((item) => item?.value === selectedItem?.value)) {
    //   removeItem(selectedItem, callOnChange)
    // } else {
    //   addSelectedItem(selectedItem, callOnChange)
    // }
  }

  const removeItem = (item, cb) => {
    setSelectedItems(selectedItems.filter((i) => i.value !== item.value))
    cb()
  }

  const addSelectedItem = (item, cb) => {
    setSelectedItems(prev => [...prev, item])
    cb()
  }

  const getRemoveButtonProps = ({onClick, item, ...props} = {}) => {
    return {
      onClick: (e) => {
        onClick && onClick(e)
        e.stopPropagation()
        removeItem(item)
      },
      ...props,
    }
  }

  const closeButtonHandled = (e) => {
    e.stopPropagation();
    setSelectedItems([]);
    clearUrl();
  }

  const getStateAndHelpers = (downshift) => {
    return {getRemoveButtonProps, removeItem, selectedItems, closeButtonHandled, ...downshift}
  }


    return (
      <Downshift
        {...props}
        defaultIsOpen={defaultIsOpen}
        stateReducer={stateReducer}
        onChange={handleSelection}
        selectedItem={null}
      >
        {(downshift) => children(getStateAndHelpers(downshift))}
      </Downshift>
    )
};