import { toggle } from "../../js-helpers/helpers"

export const ButtonFilter = ({showFilters, toggleShowFilters}) => {
  return (
    <button className={`btn btn-icon btn-outline-primary ${toggle(
            showFilters, '', 'border-c-transparent')}`}
            onClick={() => toggleShowFilters()}
    >
      <i className={'bx bx-menu-alt-left mr-75'} />
      Фильтр
    </button>
  )
}