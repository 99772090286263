import React, { useEffect } from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {UserEditSystemInfoCard} from './UserEditSystemInfoCard';
import {useImmer} from 'use-immer';
import {UserEditAppsExceptionsCard} from './UserEditAppsExceptionsCard';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { userAPI } from '../../../../API/userAPI';
import { isEmpty, isEqualArrays } from '../../../../js-helpers/helpers';
import { Card } from '../../../../components/UI/Card';
import { Button } from '../../../../components/button/Button';

export const PageUserEdit = () => {
	const {keycloakID} = useParams()
	const {setPageHead, userStates} = useOutletContext()

	const [{is_develop_mode, launcher_channel, trackers}, setUserState] = useImmer({
		is_develop_mode: false, launcher_channel: null, trackers: [],
	})
	
	const customSetter = ({data}) => {
		if (!data.launcher_channel) data.launcher_channel = 1
		const {trackers, is_develop_mode, launcher_channel} = data
		
		setUserState({trackers, is_develop_mode, launcher_channel})
		setData(data)
		setReady(true)
	}
	
	const {data: initUserInfo, isReady, setReady, setData, getData} =
		useFetchStates(() => userAPI.getUserInfo(keycloakID), {customSetter, noInitFetch: true, initData: {}})

  useEffect(() => {
    userStates.data && getData()
    setPageHead({head: 'Настройки'})
  }, [userStates.data])

	const onSave = async () => {
		setReady(false)
		
		try {
			await userAPI.patchUser(keycloakID, {is_develop_mode, launcher_channel, trackers,})
			toast.success('Изменения сохранены')
			await getData()
		} catch (e) {
			setReady(false)
			toast.error(e?.response?.data?.errors?.[0]?.detail)
		}
	}
	
	const getIsDisabledBtn = () => {
		if (!isReady || isEmpty(initUserInfo)) return true
		const sameDevMode = is_develop_mode === initUserInfo?.is_develop_mode
		const sameUpdateChanel = initUserInfo?.launcher_channel === launcher_channel
		const sameTrackers = isEqualArrays(initUserInfo?.trackers, trackers)
		
		return sameDevMode && sameUpdateChanel && sameTrackers
	}
	
	return (
		<>
			<Card>
				
				<UserEditSystemInfoCard
					userInfoReady={isReady}
					keycloakID={keycloakID}
					isDevelopMode={is_develop_mode}
					updateChanel={launcher_channel}
					setUserState={setUserState}
				/>
				
				<Button save
				        onClick={onSave}
				        disabled={getIsDisabledBtn()}
				        className={'d-block ml-auto mt-1'}/>
			</Card>
			
			<UserEditAppsExceptionsCard keycloakID={keycloakID}/>
		</>
	)
}