import {createElement} from "react";


export function Head({
                       thirdly,
	                     secondary,
	                     uppercase,
	                     className = '',
	                     as,
	                     noMargins,
	                     children,
	                     title,
                     }) {
	
	if (secondary) {
		as = as || 'h2'
		className += ' font-weight-bold'
	}
	
  if (thirdly) {
    as = as || 'h3'
		className += ' font-weight-bold'
  }

	if (uppercase) className += ' text-uppercase'
	
	if (uppercase && secondary) {
		className += ' font-small-3'
	}
	
	if (!as) as = 'h1'
	
	if (!noMargins) {
		secondary ? className += ' mb-75' : className += ' mb-2'
	} else {
		className += ' m-0'
	}
	
	return createElement(as, {className}, children || title)
}