import { useUpdateEffect } from "@react-hookz/web"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo } from "react"
import { userAPI } from "../../../../API/userAPI"
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { EmptyDataWithoutSearch } from "../../../../components/EmptyDataWithoutSearch/EmptyDataWithoutSearch"
import { SearchInput } from "../../../../components/form/SearchInput"
import { MyTable } from "../../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../../components/table/MyTable/MyTableHead"
import { useMyTable } from "../../../../components/table/MyTable/useMyTable"
import { Head } from "../../../../components/UI/Head"
import { useComboSearch } from "../../../../hooks/url/useComboSearch"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import { getBoolFromString, isEmpty, loader, toggle } from "../../../../js-helpers/helpers"
import { useExportData } from "../../../../services/export/useExportData"
import { FiltersPageUsers } from "../FilterPageUsers/FiltersPageUsers"
import { exportExcel } from "../pageUserFunctions"
import { prepareItem } from "../PageUsers"
import { pageUsersConfig } from "../pageUsersTables"

const {newUsersSearchParams, getTable} = pageUsersConfig

export const PageUsersNew = observer(() => {
  const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlIsExportMode],
		[urlDivision],
		[urlSubDivision],
		[urlLauncherVer],
		[urlBlocked],
		[urlHaveDevice],
    [urlOnline]
	] = useComboSearch(newUsersSearchParams)
	
	const exportRequestQuery = {
		search: urlSearchQuery,
		account__division__in: urlDivision?.split(',')?.filter((div) => div !== 'Без дивизиона')?.join(','),
		account__subdivision__in: urlSubDivision,
		device2__launcher_version_name__in: urlLauncherVer,
		account__is_active: getBoolFromString(urlBlocked, {invert: true}),
		// have_device_v1_or_v2: getBoolFromString(urlHaveDevice),
		ever_have_device: getBoolFromString(urlHaveDevice), //|| true,
    login_status: urlOnline,
    account__division__isnull: urlDivision ? urlDivision?.includes('Без дивизиона') : undefined
	}
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		...exportRequestQuery
	}
	
	const {data: users, isReady, getData} = useFetchStates(
		(_page = urlPage) => userAPI.getAllNew({...requestQuery, page: _page,}),
		{noInitFetch: true, initData: {results: []}})
	
	const prepareUsers = useMemo(() => users.results.map(prepareItem),
		[users])
	
	const onSaveExport = (exportData) => {
		exportExcel(exportData)
		exportStates.clearIncludedIDS()
	}
	
	const {
		ButtonToggleExport,
		CounterToExport,
		ButtonSaveExport,
		checkBoxesColl,
		...exportStates
	} = useExportData({
		requestMethod: userAPI.getAllNew,
		requestParams: exportRequestQuery,
		prepareItemFunc: prepareItem,
		pageData: prepareUsers,
		onSaveExport
	})
	
	useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1)
	}, [
		urlSearchQuery, urlLimit, urlDivision,
		urlSubDivision, urlLauncherVer, urlHaveDevice,
		urlBlocked, urlOnline
	])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const tableOptions = useMemo(() =>
			getTable(isEmptySearchQuery, urlIsExportMode, checkBoxesColl),
		[isEmptySearchQuery, urlIsExportMode])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)
	const title = urlIsExportMode ? 'Экспорт Новых пользователей' : 'Новые пользователи'

  return (
    <>
      {!urlIsExportMode && <ButtonGoBack className={'float-right ml-1'}/>}
      <ButtonToggleExport className={'float-right'}/>

      <Head children={title}/>

      {/* <FiltersPageUsers/> */}
      <SearchInput
				withBorder
				className={'mb-1'}
				onChange={e => setUrlSearchQuery(e.target.value)}
				placeholder={'Поиск по имени, ID, подразделению...'}
				defaultValue={urlSearchQuery}
			/>

      <CounterToExport/>
      
      <MyTable colgroup={tableColgroup}
			         renderCardContainer={urlIsExportMode}
			         withScroll
			         appendElement={<ButtonSaveExport/>}
			         showEmptyFoundDataMessage={!isEmptySearchQuery}
			         isDataReady={isReady}
			         paginationAllCount={users?.count}>
				
			  <MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(isReady)}
				             data={prepareUsers}
				             getRowClassName={(row) => toggle(row?.is_active === false, 'opacity-c-50')}
				             tableOptions={tableOptions}
				             highestRowToFill={45}
				      />
			</MyTable>
    </>
  )
})