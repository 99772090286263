import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logsAPI } from '../../API/logsAPI'
import { LOG_IN_OUT_REASONS } from '../../constants/constants'
import { useFetchStates } from '../../hooks/useFetchStates'
import { getLocaleFullDate, getShortFullNameFromObj, isEmpty, loader } from '../../js-helpers/helpers'
import { Card } from '../UI/Card'
import { StatusLogInOut } from '../UI/StatusLogInOut'

const LOGIN_LOGOUT = 'login-logout';

export function InfoLogInOutCard({pageType, keycloakID, deviceID, className = ''}) {
  const logs = useFetchStates(() => {
    if (pageType === 'users') {
      return logsAPI.getUserLogs({type: LOGIN_LOGOUT, page: '1', page_size: 3, ordering: '-date', keycloakID})
    } else if (pageType === 'devices') {
      return logsAPI.getDeviceLogs({type: LOGIN_LOGOUT, page: '1', page_size: 3, ordering: '-date', deviceID})
    }
  })

  useEffect(() => {
    logs.getData()
  }, [keycloakID])

  return (
    <Card secondary title={'Логи авторизации'} className={`${loader(logs.isReady)} ${className}`}>
      {isEmpty(logs.data?.results)
				? <span>Нет логов</span>
				: 
        <>
          <ul className={'list-unstyled m-0'}>
            {logs.data?.results?.map(
              ({date, type, device, reason, user}) => (
                <Card as={'li'}
                      size={1}
                      key={date}
                      className={'mb-1 text-muted font-small-3'}>
                  <StatusLogInOut className={'float-right bg-c-rgba-light'}
                                  type={type} 
                  />

                  {pageType === 'devices' ? 
                    <Link to={`/admins/users/${user?.keycloak_id}/info/`} 
                          className={'d-block font-weight-bold mb-50'}>
                      {getShortFullNameFromObj(user)}
                    </Link> :  pageType === 'users' ? 
                    
                    <Link to={`/admins/devices/${device?.serial_number}/info/`} 
                        className={'d-block font-weight-bold mb-50'}>
                      {device?.serial_number} 
                    </Link> : ''
                    }
                  
                  
                  {getLocaleFullDate(date)}
                  <br/>

                  {LOG_IN_OUT_REASONS[reason]}
                </Card>
              )
            )
              
            }
          </ul>

          <Link to={`/admins/${pageType}/${keycloakID || deviceID}/logs/?service=${LOGIN_LOGOUT}`}
					      className={'text-underline float-right'}>
						Просмотреть все
					</Link>
        </>
      }
    </Card>
  )
}