import {isEmpty, toggle} from '../../../js-helpers/helpers';
import {FullPagination} from '../../paginations/FullPagination';
import {Card} from '../../UI/Card';
import {EmptyFoundDataMessage} from '../../EmptyFoundDataMessage/EmptyFoundDataMessage';
import './table.scss'


// colgroup = [50, 100, 500]
const Colgroup = ({colgroup = []}) => {
	return isEmpty(colgroup) ? null
		: (
			<colgroup>
				{colgroup.map((col, ind) => (
					<col key={ind} {...col}/>
				))}
			</colgroup>
		)
}

const ScrollContainer = ({showOnlyChildren, children}) =>
	showOnlyChildren ? children
		: (
			<div className={
				`scroll-c mb-1 rounded ${toggle(!showOnlyChildren, 'overflow-c-x-auto')
				}`}>
				{children}
			</div>
		)

export function MyTable(
	{
		className = '',
		tableClassName = '',
		colgroup,
		paginationAllCount,
		paginationIsVisible,
		paginationClassName,
		renderCardContainer,
		withScroll,
		appendElement,
		showEmptyFoundDataMessage,
		isDataReady,
		...props
	}) {
	
	if (showEmptyFoundDataMessage && paginationAllCount === 0)
		return <EmptyFoundDataMessage isDataReady={isDataReady}/>
	
	return (
		<section className={className}>
			
			<Card secondary showOnlyChildren={!renderCardContainer}>
				<ScrollContainer showOnlyChildren={!withScroll}>
					<table className={`table table-c-footer table-layout-auto rounded-lg bg-white ${tableClassName}`}>
						<Colgroup colgroup={colgroup}/>
						
						{props.children}
						
					</table>
				</ScrollContainer>
				
				{appendElement}
			
			</Card>
			
			<FullPagination className={'mt-75'} allCount={paginationAllCount}/>
		</section>
	)
}

