import { useMountEffect } from "@react-hookz/web";
import dayjs from "dayjs";
import { convertToRaw, EditorState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { launchersAPI } from "../../../../API/launchersAPI";
import { Button } from "../../../../components/button/Button";
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { Card } from "../../../../components/UI/Card"
import { Head } from "../../../../components/UI/Head"
import { getDateNow, getIsoUtcDate, isValidDate, loader } from "../../../../js-helpers/helpers";
import { ChangelogItem } from "../pageLauncherItemComponents/ChangelogItem"
import { ChannelItem } from "../pageLauncherItemComponents/ChannelItem";
import { DatesItem } from "../pageLauncherItemComponents/DatesItem";
import { VersionItem } from "../pageLauncherItemComponents/VersionItem"

export const PageLauncherCreate = () => {
  const navigate = useNavigate()
  const defaultDate = dayjs(getDateNow()).add(10, 'minutes').toDate()

  const [valueFile, setValueFile] = useState('')
	const [file, setFile] = useState(null)
  const [apk, setApk] = useState(null)
  const [bodyValue, setBodyValue] = useState(EditorState.createEmpty())
  const [channel, setChannel] = useState(0)
  const [isLoaded, setIsLoaded] = useState(true)
  const [releaseDate, setReleaseDate] = useState(null)
  const [installDate, setInstallDate] = useState(null)

  const changeLogLength = useMemo(() => bodyValue.getCurrentContent().getPlainText('\u0001').length, [bodyValue])

  useMountEffect(() => {
    setReleaseDate(dayjs(defaultDate).second(59).toDate())
    setInstallDate(dayjs(defaultDate).second(59).toDate())
  })

  const onSubmit = async () => {
    setIsLoaded(false)
    const releaseVersionFormData = new FormData()
    const changelogRaw = draftToHtml(convertToRaw(bodyValue.getCurrentContent()))
    
    const submitData = {
      apk: apk[0],
      changelog: changelogRaw,
      channel,
      release_date: getIsoUtcDate(dayjs(releaseDate).toDate()),
      install_date: getIsoUtcDate(dayjs(installDate).toDate())
    }

	  Object.entries(submitData)
			.forEach(([field, value]) => {
				releaseVersionFormData.append(field, value)
			})

    try {
      await launchersAPI.postVersion(releaseVersionFormData);

      toast.success('Версия загружена!', {autoClose: 5000})
      navigate('/admins/launcher-versions/')
    } catch (e) {
      toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
    } finally {
      setIsLoaded(true)
    }
  }

  const isDisabled = useMemo(() => 
    !file || changeLogLength === 0 || !channel || !isValidDate(releaseDate) || !isValidDate(installDate)
  , [file, changeLogLength, channel, releaseDate, installDate])

  return (
    <>
      <ButtonGoBack className={'float-right'}/>
      <Head children={'Добавление версии лаунчера'}/>

      <Card className={`${loader(isLoaded)}`}>
      
        <Card secondary className={'mb-50'}>

          <VersionItem setApk={setApk}
                       file={file} 
                       setFile={setFile} 
                       valueFile={valueFile} 
                       setValueFile={setValueFile}/>

          <ChangelogItem bodyValue={bodyValue}
                         setBodyValue={setBodyValue}
                         changeLogLength={changeLogLength}/>

          <ChannelItem setChannel={setChannel} channel={channel}/>

          <DatesItem setReleaseDate={setReleaseDate}
                     releaseDate={releaseDate}
                     setInstallDate={setInstallDate}
                     installDate={installDate}/>
        </Card>

        <Button onClick={onSubmit}
                save 
                className={'mt-1 d-block ml-auto'} 
                disabled={isDisabled}/>
      </Card>

    </>
  )
}