import React, {useContext, useEffect, useMemo} from 'react'
import {Route, Routes, useRoutes} from 'react-router-dom'
import {PageLogin} from '../pages/PageLogin/PageLogin'
import {getRoutes} from '../routes/routes'
import {KeycloakContext} from '../context/KeycloakProvider'
import {loggedAdmin} from '../stores/loggedAdmin';
import {observer} from 'mobx-react-lite';
import {FullScreenLoader} from '../pages/FullScreenLoader';
import {isFunction} from '../js-helpers/helpers';
import {NotResolvedPage} from '../pages/notResolvedPage/NotResolvedPage';
import {AdminWelcomePage} from '../pages/adminWelcomePage/AdminWelcomePage'
import { Header } from './UI/Header'

const {getIsResolved} = loggedAdmin

const permissionsFilterRoutes = (routes) => {
  if (loggedAdmin.isEmptyAdminMenu || loggedAdmin.isEmptyVisibilityGroups) {
    
  }
	const resolvedRoutes = (_routes) => _routes.map((_route) => {
		const {resolve, children} = _route

		const _getIsResolved = () =>
			isFunction(resolve) ? resolve() : getIsResolved(resolve)

		if ((loggedAdmin.isEmptyAdminMenu) || loggedAdmin.isEmptyVisibilityGroups ) {
       _route.element = (
       <>
        <Header/>
        <AdminWelcomePage/>
       </>
       )
       return _route
    }

		if (!resolve && !children)
			return _route
    
		if (!resolve && children)
			_route.children = resolvedRoutes(children)
		
		if (resolve && !_getIsResolved(resolve)) { 

			if (children)
				_route.children = resolvedRoutes(children)
        
			  _route.element = loggedAdmin.isEmptyAdminMenu || loggedAdmin.isEmptyVisibilityGroups 
        ? <AdminWelcomePage/> 
        : <NotResolvedPage/> 
		}
		return _route
	})

	return resolvedRoutes(routes)
}

const loadingAdminRoute = [{path: '*', element: <FullScreenLoader text={'Получение данных администратора...'}/>}]

export const AppRouter = observer(() => {
	const {adminLoggedInKC} = useContext(KeycloakContext)
	const {data, isReady, fetchAdmin, isSuperAdmin} = loggedAdmin

	useEffect(() => {
		adminLoggedInKC && fetchAdmin()
	}, [adminLoggedInKC])
	
	const filteredRoutes = useMemo(() => {
			if (!isReady)
				return loadingAdminRoute
			
			return isSuperAdmin
				? getRoutes()
				: permissionsFilterRoutes(getRoutes())
		}
		, [data, isSuperAdmin])
	
	const securePage = useRoutes(filteredRoutes)
	
	return (
		adminLoggedInKC
			? securePage
			:
			<Routes>
				<Route path='*' element={<PageLogin/>}/>
			</Routes>
	)
})

