import {SearchInput} from '../../../components/form/SearchInput';
import {useEffect} from 'react';
import {AppWimg} from '../../../components/UI/AppWimg';
import {Link} from 'react-router-dom';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {Head} from '../../../components/UI/Head';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {isEmpty, loader} from '../../../js-helpers/helpers';
import {askToastDelete} from '../../../services/toasts/toasts';
import {appsAPI} from '../../../API/appsAPI';
import {useUpdateEffect} from '@react-hookz/web';
import {FullPagination} from '../../../components/paginations/FullPagination';
import {urlParams} from '../../../constants/urlParams';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {EmptyFoundDataMessage} from '../../../components/EmptyFoundDataMessage/EmptyFoundDataMessage';
import {ReactComponent as EditSVG} from '../../../assets/icons/services/edit.svg'
import { ButtonWithIcon } from '../../../components/button/ButtonWithIcon';

const {SEARCH, PAGE, LIMIT} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 1, paramHandler: Number},
	{param: LIMIT}
]

export function PageApps() {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(pageSearchParams)
	
	const items = useFetchStates(
		() => appsAPI.getAll({query: {page: urlPage, search: urlSearchQuery, page_size: urlLimit}}),
		{initData: []})
	
	useEffect(() => {
		items.getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		items.getData()
	}, [urlSearchQuery, urlLimit])
	
	const deleteApp = async (id, app) => {
		try {
			items.setReady(false)
			
			await askToastDelete({
				askText: 'Приложение ' + app,
				errorText: 'Ошибка удаления приложения',
				successText: `Приложение "${app}" удалено`,
				callMethod: () => appsAPI.deleteApp(id),
				onClose: () => items.setReady(true)
			})
			
			await items.getData()
			setUrlPage(1)
			
		} finally {
			items.setReady(true)
		}
	}
	
	const showEmptyFoundDataMessage = urlSearchQuery && isEmpty(items.data?.results)
	const showExtraEmptyItems =
		(items.data?.results?.length < 10 || !items.data?.results) && !urlSearchQuery
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Link to={'/admins/divisions/defaults/apps/'}
			      className={'btn btn-outline-primary bg-white float-right mr-1'}
			>
				Список по умолчанию
			</Link>
			
			<Link to={'upload/'} className={'btn btn-primary float-right mr-1'}>
				Добавить приложение
			</Link>
			
			<Head>Доступные приложения</Head>
			
			<SearchInput withBorder
			             className={'mb-1'}
			             onChange={e => setUrlSearchQuery(e.target.value)}
			             placeholder={'Поиск по приложениям...'}
			             defaultValue={urlSearchQuery}
			/>
			
			{showEmptyFoundDataMessage &&
			<EmptyFoundDataMessage isDataReady={items.isReady}/>}
			
			<ul className={`list-group mb-1 ${loader(items.isReady || showEmptyFoundDataMessage)}`}>
				
				{items.data?.results?.map(({id, name, icon, type}) => (
					<li key={id}
					    className={'list-group-item hover-c-gray-50 py-1 d-flex align-items-center'}>
						
						<AppWimg icon_url={icon}
						         name={name}
						         className={'text-c-transform-none'}/>
						
						{type === 2 &&
						<span className={'text-primary ml-1'}>(Системное)</span>
						}
						
						<div className='d-flex align-items-center ml-auto'>
							
							<Link to={`${id}/`} className={'c-hover-opacity min-c-touch-zone'}>
								<EditSVG style={{marginTop: '3px'}}/>
							</Link>
							
              <ButtonWithIcon type={'delete'}
                              onClick={() => deleteApp(id, name)}
                              className={'ml-2'}/>
						</div>
					</li>
				))
				}
				
				
				{showExtraEmptyItems &&
				Array(Math.max(10 - (items.data?.results?.length || 0), 0)).fill(null)
				.map((i, ind) => (
					<li key={ind}
					    style={{height: 74}}
					    className={'list-group-item py-1 '}/>
				))
				}
			</ul>
			
			<FullPagination allCount={items.data?.count}/>
		</>
	)
}