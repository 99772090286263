import {adminPermissions} from '../constants/adminPermissions'
import {PageDeviceCalls} from '../pages/members/pageDevices/deviceCalls/PageDeviceCalls'
import {PageDeviceLogs} from '../pages/members/pageDevices/deviceLogs/PageDeviceLogs'
import {PageDeviceMedia} from '../pages/members/pageDevices/deviceMedia/PageDeviceMedia'
import {PageDeviceSetup} from '../pages/members/pageDevices/deviceSetup/PageDeviceSetup'
import {PageDeviceSetupInfo} from '../pages/members/pageAdmins/pageSetupHistory/PageDeviceSetupInfo'
import {PageDevices} from '../pages/members/pageDevices/PageDevices'
import {DeviceNavLayout} from '../pages/members/pageDevices/layout/DeviceNavLayout'
import {PageDeviceInfo} from '../pages/members/pageDevices/pageDeviceInfo/PageDeviceInfo'

const {
	DEVICES,
	DEVICES_CALLS,
	DEVICES_LOGS,
	DEVICES_SCREENSHOTS,
	DEVICES_SETTINGS,
	DEVICES_GENERAL
} = adminPermissions

export const devicesRoutes = {
	path: '/admins/', children: [
		{
			path: 'devices/', resolve: DEVICES, children: [
				{path: '', element: <PageDevices/>},
				{
					path: ':deviceID/', children: [
						{
							element: <DeviceNavLayout/>, children: [
								{path: 'info/', resolve: DEVICES_GENERAL, element: <PageDeviceInfo/>},
								{path: 'calls/', resolve: DEVICES_CALLS, element: <PageDeviceCalls/>},
								{path: 'logs/', resolve: DEVICES_LOGS, element: <PageDeviceLogs/>},
								{path: 'media/', resolve: DEVICES_SCREENSHOTS, element: <PageDeviceMedia/>},
								{
									path: 'setup-history/', resolve: DEVICES_SETTINGS, children: [
										{path: '', element: <PageDeviceSetup/>},
										{path: ':setupID/', element: <PageDeviceSetupInfo/>},
									]
								}
							]
						}]
				},
			]
		}
	]
}

// DEVICES_GENERAL: "devices_general",
//   DEVICES_CALLS: "devices_calls",
//   DEVICES_LOGS: "devices_logs",
//   DEVICES_SCREENSHOTS: "devices_screenshots",
//   DEVICES_SETTINGS: "devices_settings",