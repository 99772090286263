export const RangeCounters =
	({allItems, currPage, maxItems, className = ''}) => {
		
		if (!allItems || !currPage || !maxItems)
			return null
		
		const firstItemCount = (currPage - 1) * maxItems + 1
		const lastShowedItemCount = Math.min(currPage * maxItems, allItems)
		
		return (
			<span className={`text-gray-500 font-small-3 ${className}`}>
				{`${firstItemCount} - ${lastShowedItemCount} из ${allItems}`}
			</span>
		)
	};