import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { notificationsAPI } from '../../../../API/notificationsAPI';
import { Button } from '../../../../components/button/Button';
import { ButtonGoBack } from '../../../../components/button/ButtonGoBack';
import { Card } from '../../../../components/UI/Card';
import { Head } from '../../../../components/UI/Head';
import { loader } from '../../../../js-helpers/helpers';
import FormNotificationsTitles from '../FormNotificationsTitles/FormNotificationsTitles';
import FormsNotificationsRecipient from '../formsNotificationsRecipient/FormsNotificationsRecipient';

function PageNotificationsCreate() {
  const {control, handleSubmit, reset, formState: {isSubmitting}} = useForm()

  const [titleValue, setTitleValue] = useState('')
  const [bodyValue, setBodyValue] = useState('')

  const [showRecipientList, setShowRecipientList] = useState(false);
  const [recipientsIdArray, setRecipientsIdArray] = useState([]);

  const [checkedAllRecipient, setCheckedAllRecipient] = useState(true)

  const onSubmit = async (submitData) => {
    const {title, body} = submitData
    if (showRecipientList && recipientsIdArray.length === 0) {
      toast.error('Выберите устройства для отправки или измените поле Получатель на ВСЕ')
      return
    }

    if (!title || titleValue.trim() === '') {
      toast.error('Заголовок уведомления не может быть пустым!')
      return 
    }
    if (!body || bodyValue.trim() === '') {
      toast.error('Текст уведомления не может быть пустым!')
      return 
    }

    if (recipientsIdArray.length === 0) {
      submitData.type = 'all'
    } else {
      submitData.type = 'custom'
      submitData.notify_devices = recipientsIdArray
    }

    try{
      await notificationsAPI.createNewNotification(submitData)
      toast.success(`Уведомление "${title}" успешно отправлено!`)
      reset({title: '', body: ''})
      setBodyValue('')
      setTitleValue('')
      setRecipientsIdArray([])
      setShowRecipientList(false)
      setCheckedAllRecipient(true)
    } catch (e) {
      toast.error('Ошибка сервера')
    }
  }

  return (
  <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <ButtonGoBack className={'float-right'}/>
      <Head>Уведомления</Head>

      <Card className={loader(!isSubmitting)}>

        <FormNotificationsTitles 
          control={control} 
          setTitleValue={setTitleValue} 
          titleValue={titleValue} 
          bodyValue={bodyValue} 
          setBodyValue={setBodyValue}
          setShowRecipientList={setShowRecipientList} 
          showRecipientList={showRecipientList}
          setCheckedAllRecipient={setCheckedAllRecipient}
          checkedAllRecipient={checkedAllRecipient}
          />

        {showRecipientList && <FormsNotificationsRecipient setRecipientsIdArray={setRecipientsIdArray}/>}

        <Button className={'mt-1 d-block ml-auto'} save children={'Отправить'}/>
      </Card>
    </form>
  </>
  )
}

export default PageNotificationsCreate