import { ServiceMobileIcon } from "./ServiceMobileIcon";

const icons = {
  1: <> {/* login */}
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1994 4.29758C9.51508 4.85282 9.08909 5.75108 8.96325 7.21248C8.93248 7.56976 8.61791 7.83445 8.26063 7.80369C7.90335 7.77292 7.63866 7.45835 7.66942 7.10107C7.81196 5.44581 8.32097 4.14941 9.38114 3.28917C10.4234 2.44347 11.88 2.11816 13.6926 2.11816H13.8051C15.8043 2.11816 17.3728 2.51449 18.4252 3.56691C19.4776 4.61933 19.874 6.18786 19.874 8.18701V13.8316C19.874 15.8308 19.4776 17.3993 18.4252 18.4517C17.3728 19.5042 15.8043 19.9005 13.8051 19.9005H13.6926C11.8932 19.9005 10.4453 19.5795 9.4052 18.7474C8.34631 17.9003 7.83058 16.6228 7.67849 14.9914C7.64521 14.6343 7.90767 14.3179 8.26473 14.2846C8.62178 14.2513 8.93821 14.5138 8.9715 14.8709C9.10511 16.3041 9.53303 17.1867 10.2164 17.7334C10.9186 18.2951 12.0117 18.6019 13.6926 18.6019H13.8051C15.6758 18.6019 16.8171 18.2234 17.507 17.5335C18.1969 16.8436 18.5754 15.7024 18.5754 13.8316V8.18701C18.5754 6.31629 18.1969 5.17505 17.507 4.48517C16.8171 3.79529 15.6758 3.41678 13.8051 3.41678H13.6926C11.9989 3.41678 10.9016 3.72779 10.1994 4.29758Z" fill="#474747"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.69336 10.9999C1.69336 10.6413 1.98406 10.3506 2.34266 10.3506H13.4934C13.852 10.3506 14.1427 10.6413 14.1427 10.9999C14.1427 11.3585 13.852 11.6492 13.4934 11.6492H2.34266C1.98406 11.6492 1.69336 11.3585 1.69336 10.9999Z" fill="#474747"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1042 7.64037C11.3578 7.3868 11.7689 7.3868 12.0225 7.64037L14.9227 10.5406C15.1763 10.7942 15.1763 11.2053 14.9227 11.4589L12.0225 14.3591C11.7689 14.6127 11.3578 14.6127 11.1042 14.3591C10.8507 14.1055 10.8507 13.6944 11.1042 13.4408L13.5453 10.9997L11.1042 8.55863C10.8507 8.30506 10.8507 7.89394 11.1042 7.64037Z" fill="#474747"/>
    </>,
  2: <> {/* logout */}
      <path fillRule="evenodd" clipRule="evenodd" d="M11.8006 4.29758C12.4849 4.85282 12.9109 5.75108 13.0368 7.21248C13.0675 7.56976 13.3821 7.83445 13.7394 7.80369C14.0966 7.77292 14.3613 7.45835 14.3306 7.10107C14.188 5.44581 13.679 4.14941 12.6189 3.28917C11.5766 2.44347 10.12 2.11816 8.30741 2.11816H8.19487C6.19572 2.11816 4.6272 2.51449 3.57477 3.56691C2.52235 4.61933 2.12603 6.18786 2.12603 8.18701V13.8316C2.12603 15.8308 2.52235 17.3993 3.57477 18.4517C4.6272 19.5042 6.19572 19.9005 8.19487 19.9005H8.30741C10.1068 19.9005 11.5547 19.5795 12.5948 18.7474C13.6537 17.9003 14.1694 16.6228 14.3215 14.9914C14.3548 14.6343 14.0923 14.3179 13.7353 14.2846C13.3782 14.2513 13.0618 14.5138 13.0285 14.8709C12.8949 16.3041 12.467 17.1867 11.7836 17.7334C11.0814 18.2951 9.98828 18.6019 8.30741 18.6019H8.19487C6.32415 18.6019 5.18291 18.2234 4.49303 17.5335C3.80315 16.8436 3.42464 15.7024 3.42464 13.8316V8.18701C3.42464 6.31629 3.80315 5.17505 4.49303 4.48517C5.18291 3.79529 6.32415 3.41678 8.19487 3.41678H8.30741C10.0011 3.41678 11.0984 3.72779 11.8006 4.29758Z" fill="#474747"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.8877 11.0001C6.8877 11.3587 7.1784 11.6494 7.537 11.6494H18.6877C19.0463 11.6494 19.337 11.3587 19.337 11.0001C19.337 10.6415 19.0463 10.3508 18.6877 10.3508H7.537C7.1784 10.3508 6.8877 10.6415 6.8877 11.0001Z" fill="#474747"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.2986 14.3596C16.5521 14.6132 16.9633 14.6132 17.2168 14.3596L20.1171 11.4594C20.3706 11.2058 20.3706 10.7947 20.1171 10.5411L17.2168 7.64091C16.9633 7.38734 16.5521 7.38734 16.2986 7.64091C16.045 7.89448 16.045 8.30559 16.2986 8.55916L18.7397 11.0003L16.2986 13.4414C16.045 13.6949 16.045 14.1061 16.2986 14.3596Z" fill="#474747"/>
    </>
}
export function StatusLogInOut({size, type, className = ''}) {
  return (
    <ServiceMobileIcon size={size} className={className}>
      <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
				{icons[type]}
			</svg>
    </ServiceMobileIcon>
  )
}