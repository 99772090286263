import { useMemo } from 'react';
import { Radio } from '../../../../../components/form/Radio';
import { ChooseAllExceptionsLists } from '../../../../../components/listsAccess/ChooseAllExceptionsLists';
import { Card } from '../../../../../components/UI/Card';
import { Head } from '../../../../../components/UI/Head';

export const RuleUsersSelected = ({
  checkedAllUsers, toggleUsers, exceptionsUsers,
  onExceptionItemClick, setExceptionsUsers,
}) => {

  const listHead = useMemo(() => 
    `Выбрано: ${exceptionsUsers?.length || 0}`
  , [exceptionsUsers])

  return (
    <Card secondary>
      <div className={'d-flex align-items-end'}>
        <Head secondary uppercase noMargins>
          применить к пользователям:
        </Head>
        {/* <div className={'d-flex ml-2'}>
          <label
            className={`cursor-pointer d-block text-c-transform-none font-size-base mr-3 mb-0 ${
              checkedAllUsers && 'text-body'
            }`}
          >
            <Radio
              name="users_all"
              checked={checkedAllUsers}
              onChange={toggleUsers}
            />
            Все
          </label>
          <label
            className={`cursor-pointer d-block text-c-transform-none font-size-base mb-0 ${
              !checkedAllUsers && 'text-body'
            }`}
          >
            <Radio
              name="users_list"
              checked={!checkedAllUsers}
              onChange={toggleUsers}
            />
            Согласно списку
          </label>
        </div> */}
      </div>
      {/* {!checkedAllUsers ? ( */}
        <ChooseAllExceptionsLists
          type={'users'}
          // background={'white'}
          headExceptions={listHead}
          exceptions={exceptionsUsers}
          onItemClick={onExceptionItemClick}
          setExceptions={setExceptionsUsers}
          className={'mt-2'}
        />
      {/* ) : (
        <></>
      )} */}
    </Card>
  );
};
