import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { toggle } from "../../../js-helpers/helpers";
import { NotificationsIcon } from "./NotificationsIcon";
import { baseNotificationsOptions, historyNotificationsGroup, historyNotificationsOptions } from "./notificationsOptions";

export const PageNotificationsNav = ({urlType, initShowNavs, onClickNavButton}) => {
  const [showedGroupServices, setShowedGroupServices] = useImmer(initShowNavs);
	
	const [showedUserLogsOptions, setShowedUserLogsOptions] = useState(
		historyNotificationsOptions.find((item) => item.type === urlType)
	);
	
	useEffect(() => {
		setShowedUserLogsOptions(
			historyNotificationsOptions.find((item) => item.type === urlType)
		);
	}, [urlType]);
	
	function toggleShowNav(groupName) {
		setShowedGroupServices((draft) => {
			draft[groupName] = !draft[groupName];
		});
	}

  return (
    <ul className={'list-unstyled list-gap-025r'}>
    {historyNotificationsGroup.map(({head, groupName, icon, options}) => {
      return (
        <li
          key={head}
          className={`dropdown rounded width-250 ${toggle(showedUserLogsOptions, 'show bg-transparent-60')}`}
        >
          <button
            className={
              'btn px-1 btn-block text-left font-14px dropdown-toggle text-gray-500'
            }
            onClick={() => {
              toggleShowNav(groupName);
              setShowedUserLogsOptions(!showedUserLogsOptions);
            }}
          >
            
            <NotificationsIcon type={icon} className={'mr-75'}/>
            
            {head}
          </button>
          
          {
            showedUserLogsOptions && (
              <ul className={'list-group list-unstyled'}>
                {options.map(({type, text}) => (
                  <li
                    key={type}
                    className={'text-gray-500 c-hover-opacity'}
                  >
                    <button
                      onClick={() => onClickNavButton(type)}
                      className={`btn btn-block text-left  ${toggle(
                        urlType === type,
                        'bg-white text-body',
                        'text-gray-500'
                      )}`}
                      style={{paddingLeft: '3.5rem'}}
                    >
                      {text}
                    </button>
                  </li>
                ))}
              </ul>
            )
          }
        </li>
      );
    })}
    
    {baseNotificationsOptions.map(({text, type, disabled, icon}) => (
      <li key={type}>
        <button
          disabled={disabled}
          className={`btn px-1 btn-block text-left font-14px c-hover-opacity ${toggle(
            urlType === type,
            'bg-white text-body',
            'text-gray-500'
          )}`}
          onClick={() => {
            onClickNavButton(type);
            setShowedUserLogsOptions(false);
          }}
        >
          <NotificationsIcon type={icon}/>
          
          <span className={'ml-75 align-middle'}>{text}</span>
        </button>
      </li>
    ))}
  </ul>
  )
}