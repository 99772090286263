import React from 'react';
import {getFullNameFromObj} from '../../../../../js-helpers/helpers';

export const NameAndInfoRule = (
	{
		valueName, setIsActiveSaveBtn, setValueName, edit, infoRule
	}) => (
	<>
		<label>НАЗВАНИЕ ПРАВИЛА</label>
		
		<input required
		       className={'form-control mb-2 width-600'}
		       value={valueName}
		       maxLength={50}
		       onChange={({target: {value}}) => {
			       setIsActiveSaveBtn(Boolean(value))
			       setValueName(value)
		       }}
		       placeholder={'Введите название правила'}
		/>
		
		{edit &&
		<div>
			<label>АВТОР ПРАВИЛА</label>
			<input disabled
			       className={'form-control mb-2 width-600'}
			       value={`${getFullNameFromObj(infoRule?.admin)}`}/>
			
			<label>ДАТА СОЗДАНИЯ ПРАВИЛА</label>
			<input disabled
			       className={'form-control mb-2 width-600'}
			       value={new Date(infoRule?.date).toLocaleString()}/>
		</div>
		}
	</>
)
