import { getShortFullNameFromObj } from "../../../../js-helpers/helpers"
import { ButtonWithIcon } from "../../../button/ButtonWithIcon"

export const deviceSetupTableOptions = [
	{
		head: 'ФИО администратора',
		cell: (row) => row?.admin ? getShortFullNameFromObj(row.admin) : '-'
	}, {
		head: 'Дата и время настройки',
		cell: (row) => row?.created_at ? new Date(row.created_at).toLocaleString() : '-'
	}, {
		head: 'Статус',
		cell: (row) => row?.is_success ? row?.is_success ? 'Успешно' : 'Незавершено' : '-',
	}, 
  {
		head: 'Действия',
		cell: (row) => {  if(row) {
      return <ButtonWithIcon to={`${row.id}/`} type='info'/> 
    } else {
      return
    }
		}
	}
]