import {useMemo} from 'react';
import { Radio } from '../../../../components/form/Radio';
import { toggle } from '../../../../js-helpers/helpers';

const getItems = (sites, apps, users) => {
	const defaultTitle = users ? 'Согласно Дивизиону' : 'По умолчанию';
	const typeTitle = sites ? 'сайты' : 'приложения';
	
	return [
		{text: defaultTitle, statusValue: 1},
		{text: `Доступны все ${typeTitle}`, statusValue: 2},
		{text: `Запрещены все ${typeTitle}`, statusValue: 3}
	]
}

export function StatusesList(
	{
		apps,
		users,
		sites,
		status,
		setStatus,
		initStatus,
		className = ''
	}) {
	
	const itemsText = useMemo(() => getItems(sites, apps, users), [sites, apps]);
	const getExtraClasses = (statusValue) =>
		toggle(statusValue === status, ' ', 'text-gray-300 ') +
		toggle(statusValue === initStatus, 'bg-rgba-primary')
	
	return (
		<ul className={`list-unstyled ${className}`}>
			
			{itemsText.map(({text, statusValue}) => (
				<li key={text}>
					<Radio
						boxClassName={'mr-50'}
						textLabel={text}
						checked={statusValue === status}
						onChange={() => setStatus(statusValue)}
						className={`btn px-1 text-left w-100 ${getExtraClasses(statusValue)}`}
					/>
				</li>
			))}
		</ul>
	)
}