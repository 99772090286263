import {Link} from 'react-router-dom';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {getBoolFromString, isEmpty, loader} from '../../../js-helpers/helpers';
import {Head} from '../../../components/UI/Head';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {divisionsAPI} from '../../../API/divisionsAPI';
import {useUpdateEffect} from '@react-hookz/web';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {urlParams} from '../../../constants/urlParams';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {ReactComponent as EditSVG} from '../../../assets/icons/services/edit.svg'
import { PageDivisionsFilters } from './PageDivisionsFilters';

const tableOptions = [
	{
		head: 'Наименование дивизиона', cell: 'name'
	}, {
		head: 'Управление', col: {width: 150},
		cell: ({id}) =>
			<Link className={'c-hover-opacity min-c-touch-zone'}
            to={`${id}/apps/`}>
				<EditSVG style={{marginTop: '3px'}}/>
			</Link>
	},
]

const {SEARCH, PAGE, LIMIT, HAS_USERS} = urlParams
export const pageSearchParams = [
  {param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 1},
	{param: LIMIT},
  {param: HAS_USERS, init: 'true'}
]

export function PageDivisions() {
	const [
    [urlSearchQuery, setUrlSearchQuery],
		[urlPage, setUrlPage],
		[urlLimit],
    [urlHasUsers]
	] = useComboSearch(pageSearchParams)
	
	const {data, getData, isReady} = useFetchStates(
		(_page = urlPage) => divisionsAPI.getAll({
      page: _page, 
      search: urlSearchQuery, 
      page_size: urlLimit, 
      have_accounts: getBoolFromString(urlHasUsers)
    }),
	)
	
	useUpdateEffect(() => {
		getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (+urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1)
	}, [urlSearchQuery, urlLimit, urlHasUsers])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)

  const showEmptyFoundDataMessage = urlSearchQuery && isEmpty(data?.results)

	return (
		<>
			<ButtonGoBack className={'float-right ml-1'}/>
			<Head>Управление Дивизионами</Head>

      <PageDivisionsFilters/>
			
      <MyTable  colgroup={tableColgroup} 
                paginationAllCount={data?.count}
                showEmptyFoundDataMessage={showEmptyFoundDataMessage}
                isDataReady={isReady}>
                  
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody
					className={loader(isReady)}
					data={data?.results}
					tableOptions={tableOptions}
					highestRowToFill={38.2}/>
			</MyTable>

		</>
	)
}