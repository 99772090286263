import {getName, WithTooltip} from '../../../../components/UI/WithTooltip';
import {MyAvatar} from '../../../../components/UI/MyAvatar';
import {getFullNameFromObj, getShortFullNameFromObj} from '../../../../js-helpers/helpers';
import React from 'react';
import {Link} from 'react-router-dom';

const PhonesCell = ({phone_numbers}) => {
	const phoneNumber = getName(phone_numbers, 'phone_number')
	const tooltipsPhoneNumbers = phone_numbers.map(({phone_number}) => phone_number)
	return <WithTooltip elem={phoneNumber} showAbove={1} tooltips={tooltipsPhoneNumbers}/>
}

export const baseTableOptions = [
	{
		head: 'ID',
		col: {width: 100},
		cell: ({uuid}) =>
			<WithTooltip elem={uuid.split('-')[0] + '...'}
			             showAbove={0}
			             className={'w-c-content'}
			             tooltips={[uuid]}/>
	}, {
		head: 'Фото',
		col: {width: 100},
		cell: (row) => <MyAvatar src={row.photo} alt={getShortFullNameFromObj(row)}/>
	}, {
		head: 'ФИО',
		sortField: 'contact__last_name',
		col: {width: 250},
		cell: (row) => <Link to={`${row.uuid}/`}>{getFullNameFromObj(row)}</Link>
	}, {
		head: 'Номер телефона',
		col: {width: 150},
		cell: ({phone_numbers}) => <PhonesCell phone_numbers={phone_numbers}/>
	},
]

export const externalContactsColls = [
	{
		head: 'Компания',
		cell: (contact) => <div>{contact?.company?.title || '-'}</div>,
		col: {width: 200}
	}
]

export const internalContactsColls = [
	{
		head: 'Дивизион',
		cell: 'division',
		sortField: 'contact__division',
		col: {width: 300}
	}, {
		head: 'Подразделение',
		cell: 'subdivision',
		sortField: 'contact__subdivision',
		col: {width: 300}
	},
]