import {Card} from '../UI/Card';
import {Head} from '../UI/Head';
import React from 'react';
import {ModalDialogFooter} from './ModalDialogFooter';
import PortalModal from '../PortalModal';

export function ModalDialog(
	{
		head,
		body,
		onCancelClick,
		onSuccessClick,
		cancelText,
		successText,
		showFooter = true,
		className = '',
		isShow,
		setIsShow,
    successHided
	}) {
	const footerProps = {onSuccessClick, onCancelClick, cancelText, successText, successHided}
	
	const _body = typeof body === 'string'
		? <div className={'text-center m-2'}>{body}</div>
		: body
	
	return (
		<PortalModal isOpen={isShow}
		             onClose={() => setIsShow?.(false)}
		             childrenClassName={'js-modal-dialog'}
		             className={'modal-c-backdrop'}>
			
			<Card size={0} className={`text-center pt-3 overflow-hidden js-modal-dialog ${className}`}>
				
				<Head secondary className={'px-1'}>{head}</Head>
				
				{_body}
				
				{showFooter &&
				<ModalDialogFooter {...footerProps} />
				}
			
			</Card>
		</PortalModal>
	
	)
}