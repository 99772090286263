import {adminPermissions} from '../../constants/adminPermissions'
import {ReactComponent as IconAdmin} from './icons/admin.svg'
import {ReactComponent as IconAdminNew} from './icons/admin_new.svg'
import {ReactComponent as IconDevice} from './icons/device.svg'
import {ReactComponent as IconDivision} from './icons/division.svg'
import {ReactComponent as IconContacts} from './icons/contacts.svg'
import {ReactComponent as IconLauncher} from './icons/launcher.svg'
import {ReactComponent as IconApps} from './icons/apps.svg'
import {ReactComponent as IconCalls} from './icons/calls.svg'
import {ReactComponent as IconFeedbacks} from './icons/feedback.svg'
import {ReactComponent as Logs} from './icons/logs.svg'
import {ReactComponent as Screenshots} from './icons/screenshot.svg'
import {ReactComponent as IconSms} from './icons/sms.svg'
import {ReactComponent as IconNotification} from './icons/notification.svg'
import {ReactComponent as IconUsers} from './icons/user.svg'
import {ReactComponent as IconUsersNew} from './icons/user_new.svg'
import {ReactComponent as IconGroups} from './icons/groups.svg'

const {
	USERS, DIVISIONS, CONTACTS, DEVICES, APPS,
	FEEDBACKS, CALL_HISTORY, ADMINS, LAUNCHERS,
	MAILINGS, NOTIFICATIONS, LOGS, SCREENSHOTS, GROUPS
} = adminPermissions

export const membersMenuItems = [
	{
		head: 'Пользователи',
		icon: <IconUsers/>,
		description: 'Список пользователей',
		link: 'users/',
		permission: USERS,
	}, {
		head: 'Новые пользователи',
		icon: <IconUsersNew/>,
		description: 'Обработка новых пользователей',
		link: 'users-new/',
		// permission: USERS,
	}, {
		head: 'Администраторы',
		icon: <IconAdmin/>,
		description: 'Список администраторов',
		link: '',
		permission: ADMINS
	},  {
		head: 'Новые администраторы',
		icon: <IconAdminNew/>,
		description: 'Обработка новых администраторов',
		link: 'admins-new/',
		// permission: ADMINS
	}, {
		head: 'Устройства',
		icon: <IconDevice/>,
		description: 'Список устройств',
		link: 'devices/',
		permission: DEVICES
	},
]

export const tablesMenuItems = [
	{
		head: 'История звонков',
		icon: <IconCalls/>,
		description: 'Таблица звонков',
		link: 'calls/',
		permission: CALL_HISTORY
	}, {
		head: 'Логи',
		icon: <Logs/>,
		description: 'Логи приложений, авторизации, обновления, отключения телефона и Crashlytics',
		link: 'logs/',
		permission: LOGS
	}, {
		head: 'Скриншоты',
		icon: <Screenshots/>,
		description: 'Скриншоты устройств и пользователей',
		link: 'media/',
		permission: SCREENSHOTS
	},
	// {
	// 	head: 'Crashlytics',
	// 	icon: <IconСrashlytics/>,
	// 	description: 'История крашей',
	// 	link: 'media/',
	// 	permission: CRASHLYTICS
	// },

]

export const managementMenuItems = [
	{
		head: 'Дивизионы',
		icon: <IconDivision/>,
		description: 'Список дивизионов, настройка списка доступных приложений',
		link: 'divisions/',
		permission: DIVISIONS
	}, 
  {
		head: 'Настройка групп',
		icon: <IconGroups/>,
		description: 'Управление списком групп для настройки видимости',
		link: 'groups/',
		permission: GROUPS
	},  {
		head: 'Контакты',
		icon: <IconContacts/>,
		description: 'Список контактов, видимость контакта',
		link: 'internal-contacts/',
		permission: CONTACTS
	}, 
  {
		head: 'Обновление версии лаунчера',
		icon: <IconLauncher/>,
		description: 'Управление версиями лаунчера',
		link: 'launcher-versions/',
		permission: LAUNCHERS
  }, 
  {
		head: 'Приложения',
		icon: <IconApps/>,
		description: 'Список приложений, список приложений по умолчанию',
		link: 'apps/',
		permission: APPS
	}, {
		head: 'Запросы от пользователей',
		icon: <IconFeedbacks/>,
		description: 'Обратная связь с пользователем',
		link: 'feedbacks/',
		permission: FEEDBACKS
	}, {
		head: 'Рассылка',
		icon: <IconSms/>,
		description: 'Рассылка событий на электронную почту',
		link: 'mailing/',
		permission: MAILINGS
	}, {
		head: 'Уведомления',
		icon: <IconNotification/>,
		description: 'Рассылка уведомлений на мобильный телефон пользователей',
		link: 'notifications/',
		permission: NOTIFICATIONS
	},
]