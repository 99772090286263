import {WhatsAppViberCard} from './WhatsAppViberCard';
import {TelegramSkypeCard} from './TelegramSkypeCard';


export function UserTrackersMessengersCards(
	{
		onSave, isReadyRequests, initTrackers
	}) {
	
	return (
		<div className={'col'}>
			<WhatsAppViberCard onSave={onSave}
			                   isReadyRequests={isReadyRequests}
			                   initTrackers={initTrackers}/>
			
			<TelegramSkypeCard onSave={onSave}
			                   isReadyRequests={isReadyRequests}
			                   initTrackers={initTrackers}/>
		</div>
	)
}