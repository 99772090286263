import React, {useRef} from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';
import {toggle} from '../../js-helpers/helpers';

export function CheckBox(
	{
		name,
		checked,
		disabled,
		onChange,
		className = '',
		appendLabel,
		prependLabel,
		vertical,
		defaultChecked,
		white,
		id,
		...props
	}) {
	if (checked && !onChange) onChange = () => undefined
	
	const inputProps = {disabled, defaultChecked, name, onChange, checked, ...props}
	const idRef = useRef(id || uniqid())
	
	return (
		<label
			className={classNames(
				'd-inline-flex gap-0-5r text-c-transform-none align-items-center text-body font-small-3 m-0',
				{
					'cursor-pointer min-c-touch-zone': !disabled,
					'flex-column': vertical
				},
				className,
			)}>
			
			{prependLabel}
			
			<fieldset className={`checkbox ${toggle(!white, 'checkbox-primary')}`}>
				
				<input id={idRef.current}
				       type={'checkbox'}
				       tabIndex={0}
				       className={'checkbox-input'}
				       {...inputProps}/>
				
				<label htmlFor={idRef.current}
				       className={`${toggle(!disabled, 'cursor-pointer')} align-middle`}/>
			</fieldset>
			
			{appendLabel}
		
		</label>
	)
}

