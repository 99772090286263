import {PageAdmins} from '../pages/members/pageAdmins/PageAdmins';
import {PageAdminEdit} from '../pages/members/pageAdmins/pageAdminEdit/PageAdminEdit';
import {PageInternalContacts} from '../pages/management/contacts/PageInternalContacts';
import {PageEditInternalContact} from '../pages/management/contacts/edit/PageEditInternalContact';
import {PageNewSharedContact} from '../pages/management/contacts/edit/PageNewSharedContact';
import {PageRules} from '../pages/management/contacts/rules/PageRules';
import {PageRule} from '../pages/management/contacts/rules/pageRule/PageRule';
import {PageApps} from '../pages/management/pageApps/PageApps';
import {PageAppsEdit} from '../pages/management/pageApps/pageAppsEdit/PageAppsEdit';
import {PageDivisions} from '../pages/management/divisions/PageDivisions';
import {PageDivisionEdit} from '../pages/management/divisions/edit/PageDivisionEdit';
import {PageDivisionsDefaults} from '../pages/management/divisions/edit/PageDivisionsDefaults';
import {PageFeedbacks} from '../pages/management/feedbacksUsers/PageFeedbacks';
import {DivisionsLayout} from '../pages/management/divisions/layout/DivisionsLayout';
import {PageUploadApps} from '../pages/management/pageApps/pageAppsUpload/PageUploadApps';
import {PageCallsHistory} from '../pages/dataTables/PageCallsHistory/PageCallsHistory';
import {adminPermissions} from '../constants/adminPermissions';
import React from 'react';
import {PageAdminEditPermissions} from '../pages/members/pageAdmins/pageAdminEdit/PageAdminEditPermissions';
import {AdminEditLayout} from '../pages/members/pageAdmins/pageAdminEdit/layout/AdminEditLayout';
import {MenuAdmin} from '../pages/menuAdmin/MenuAdmin';
import {loggedAdmin} from '../stores/loggedAdmin';
import PageMailing from '../pages/management/pageMailing/PageMailing';
import PageNotifications from '../pages/management/pageNotifications/PageNotifications';
import PageNotificationsCreate
	from '../pages/management/pageNotifications/pageNotificationsCreate/PageNotificationsCreate';
import PageNotificationsItem from '../pages/management/pageNotifications/pageNotificationsItem/PageNotificationsItem';
import PageCommonLogs from '../pages/dataTables/PageCommonLogs/PageCommonLogs';
import {LogsLayout} from '../pages/dataTables/PageCommonLogs/layout/LogsLayout';
import PageCommonMedia from '../pages/dataTables/pageCommonMedia/PageCommonMedia';
import {PageCrashlyticsInfo} from '../pages/logs/PageCrashlyticsInfo/PageCrashlyticsInfo';
import { PageNotificationsRulesCreate } from '../pages/management/pageNotifications/pageNotificationsRules/PageNotificationsRulesCreate';
import { PageNotificationsRulesItem } from '../pages/management/pageNotifications/pageNotificationsRules/PageNotificationsRulesItem';
import { PageGroups } from '../pages/management/pageGroups/PageGroups';
import { PageGroupsCreate } from '../pages/management/pageGroups/PageGroupsCreate/PageGroupsCreate';
import { PageGroupsItem } from '../pages/management/pageGroups/PageGroupsItem/PageGroupsItem';
import { PageAdminEditGroups } from '../pages/members/pageAdmins/pageAdminEdit/PageAdminEditGroups';
import { PageLauncher } from '../pages/management/pageLauncher/PageLauncher';
import { PageLauncherCreate } from '../pages/management/pageLauncher/pageLauncherCreate/PageLauncherCreate';
import { PageLauncherEdit } from '../pages/management/pageLauncher/pageLauncherEdit/PageLauncherEdit';
import { PageAdminsNew } from '../pages/members/pageAdmins/pageAdminsNew/PageAdminsNew';
import { PageLogsErrorInfo } from '../pages/logs/PageLogsErrorInfo/PageLogsErrorInfo';

const {
	ADMINS, DIVISIONS, CONTACTS, APPS, FEEDBACKS,
	LOGS, CALL_HISTORY, MAILINGS, NOTIFICATIONS,
	SCREENSHOTS, GROUPS, LAUNCHERS
} = adminPermissions;

export const adminsRoutes = {
	path: '/admins/',
	children: [
		{path: '', resolve: ADMINS, element: <PageAdmins/>},
		{
			path: 'menu/',
			resolve: () => !loggedAdmin.isEmptyAdminMenu,
			element: <MenuAdmin/>,
		}, {
			path: ':keycloakID/',
			resolve: ADMINS,
			element: <AdminEditLayout/>,
			children: [
				{path: '', element: <PageAdminEdit/>},
				{path: 'permissions/', element: <PageAdminEditPermissions/>},
        {path: 'groups/', element: <PageAdminEditGroups/>},
			],
		},
    {
      path: 'admins-new/',
      resolve: () => loggedAdmin.isSuperAdmin,
      element: <PageAdminsNew/>,
    }, {
			path: 'internal-contacts/',
			resolve: CONTACTS,
			children: [
				{path: '', element: <PageInternalContacts/>},
				{path: ':contactID/', element: <PageEditInternalContact/>},
				{path: 'new/', element: <PageNewSharedContact/>},
				{path: 'rules/', element: <PageRules/>},
				{path: 'rules/new/', element: <PageRule/>},
				{path: 'rules/:ruleID/', element: <PageRule edit/>},
			],
		}, {
			path: 'apps/',
			resolve: APPS,
			children: [
				{path: '', element: <PageApps/>},
				{path: 'upload/', element: <PageUploadApps/>},
				{path: ':appID/', element: <PageAppsEdit/>},
			],
		}, {
			path: 'divisions/',
			resolve: DIVISIONS,
			children: [
				{path: '', element: <PageDivisions/>},
				{
					path: ':divID/',
					element: <DivisionsLayout/>,
					children: [
						{path: 'apps/', element: <PageDivisionEdit key={1} apps/>},
						{path: 'sites/', element: <PageDivisionEdit key={2} sites/>},
					],
				},
				{path: 'defaults/apps/', element: <PageDivisionsDefaults apps/>},
				{path: 'defaults/sites/', element: <PageDivisionsDefaults sites/>},
			],
		}, {
      path: 'groups/',
      resolve: GROUPS,
      children: [
        {path: '', element: <PageGroups/>},
        {path: 'create/', element: <PageGroupsCreate/>},
        {path: ':groupID', element: <PageGroupsItem/>}
      ]
    }, {
      path: 'launcher-versions/',
      resolve: LAUNCHERS,
      children: [
        {path: '', element: <PageLauncher/>},
        {path: 'create/', element: <PageLauncherCreate/>},
        {path: ':versionID', element: <PageLauncherEdit/>}
      ]
    },
		{path: 'feedbacks/', resolve: FEEDBACKS, element: <PageFeedbacks/>},
		{path: 'calls/', resolve: CALL_HISTORY, element: <PageCallsHistory/>},
		{path: 'mailing/', resolve: MAILINGS, element: <PageMailing/>},
		{
			path: 'notifications/',
			resolve: NOTIFICATIONS,
			children: [
				{path: '', element: <PageNotifications/>},
				{path: 'create/', element: <PageNotificationsCreate/>},
				{path: ':notificationID/', element: <PageNotificationsItem/>},
        {path: 'rules/default/', element: <PageNotificationsRulesItem/>},
        {path: 'rules/create/', element: <PageNotificationsRulesCreate/>},
        {path: 'rules/:ruleID/', element: <PageNotificationsRulesItem/>}
			],
		}, {
			path: 'logs/',
			resolve: LOGS,
			children: [
				{
					element: <LogsLayout/>,
					children: [
						{path: '', element: <PageCommonLogs/>},
						{path: ':eventID/', element: <PageCrashlyticsInfo/>,},
            {path: 'error/:errorID/', element: <PageLogsErrorInfo/>,},
					],
				},
			],
		},
		{path: 'media/', resolve: SCREENSHOTS, element: <PageCommonMedia/>},
	],
};
