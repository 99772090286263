import { Link } from "react-router-dom";
import { channelsNamesForLaunchers } from "../../../../constants/constants";
import { getLocaleFullDate, getShortFullNameFromObj } from "../../../../js-helpers/helpers";

export const launcherTableOptions = [
  {
    head: 'ID',
    cell: (id) => id,
    nestedCellField: 'id',
    sortField: 'id',
  }, {
    head: 'Имя версии',
    cell: (data) => <Link to={`${data?.id}`}>{data?.version_name}</Link>,
    sortField: 'version_name',
  }, {
    head: 'Код версии',
    cell: (version_code) => version_code,
    nestedCellField: 'version_code',
    sortField: 'version_code',
  },  {
    head: 'Канал',
    cell: (channel_name) => channelsNamesForLaunchers?.[channel_name] || channel_name,
    nestedCellField: 'channel_name',
    sortField: 'channel__name',
  }, {
    head: 'Дата и время релиза',
    cell: getLocaleFullDate,
    nestedCellField: 'release_date',
    sortField: 'release_date'
  }, {
    head: 'Автор добавления',
    cell: getShortFullNameFromObj,
    nestedCellField: 'admin',
  },
]