import React, {memo} from 'react';
import {useNavigate} from 'react-router-dom';

export const ButtonGoBack = memo(({className = '', onClick, toMenu = false}) => {
	const navigate = useNavigate();
	
	const _onClick = () => {
		onClick ? onClick() : toMenu ? navigate('/admins/menu'): navigate(-1)
	}
	
	return (
		<button type='button'
		        onClick={_onClick}
		        className={`btn btn-outline-primary border-c-transparent bg-white ${className}`}>
			Назад
		</button>
	)
})