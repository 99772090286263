import { Card } from '../../../../components/UI/Card';
import { Head } from '../../../../components/UI/Head';
import { SearchInput } from '../../../../components/form/SearchInput';
import { MyTable } from '../../../../components/table/MyTable/MyTable';
import { MyTableHead } from '../../../../components/table/MyTable/MyTableHead';
import { MyTableBody } from '../../../../components/table/MyTable/MyTableBody';
import React, { useEffect } from 'react';
import { getLocaleFullDate, getShortFullNameFromObj, isEmpty, loader } from '../../../../js-helpers/helpers';
import { urlParams } from '../../../../constants/urlParams';
import { columnsUser } from '../../../../components/table/MyTable/options/columnsUser';
import { columnsDevice } from '../../../../components/table/MyTable/options/columnsDevice';
import { useComboSearch } from '../../../../hooks/url/useComboSearch';
import { useMyTable } from '../../../../components/table/MyTable/useMyTable';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { notificationsAPI } from '../../../../API/notificationsAPI';
import { useUpdateEffect } from '@react-hookz/web';
import { WithTooltip } from '../../../../components/UI/WithTooltip';
import { ReactComponent as Info } from '../../../../components/DescriptionInfoBabble/icons/info-circle.svg'

const { PAGE, LIMIT, TYPE, SEARCH, SORT } = urlParams;

const pageSearchParams = [
  { param: TYPE },
  { param: SEARCH, init: '', debounce: 333 },
  { param: PAGE, init: 1 },
  { param: LIMIT },
  { param: SORT },
];

const getTableOptions = (isEmptySearchQuery) => [columnsUser.getUserNameWithoutSort(isEmptySearchQuery), columnsDevice.serial_number_without_sort];

const UserP2PRule = () => {
  const {keycloakID} = useParams();

  const [
    [urlType],
    [urlSearchQuery, setUrlSearchQuery],
    [urlPage, setUrlPage],
    [urlLimit],
    [urlSortField, setUrlSortField],
  ] = useComboSearch(pageSearchParams);

  const { userStates } = useOutletContext();

  const { data: ruleData, getData: getRuleData, isReady: ruleDataIsReady } = useFetchStates(() => {
    if (userStates?.data?.notification_rule) {
      return notificationsAPI.getOneP2PRule(userStates?.data?.notification_rule)
    } else {
      return notificationsAPI.getDefaultP2PRule()
    }
  }, { noInitFetch: true }
  );

  const {data: ruleUsersData, getData: getRuleUsersData, isReady: ruleUsersDataIsReady} = useFetchStates(
    () => notificationsAPI.getP2PAvailableRecipients(keycloakID, urlPage, urlSearchQuery, urlLimit), {noInitFetch: true}
  );

  useEffect(() => {
    if(userStates.isReady) getRuleData()
  }, [userStates.data]);

  useEffect(() => {
    getRuleUsersData();
  }, [urlPage]);

  useUpdateEffect(() => {
    if (+urlPage !== 1) {
      setUrlPage(1);
      return;
    }
    getRuleUsersData();
  }, [urlSearchQuery, urlLimit, keycloakID]);

  const isEmptySearchQuery = isEmpty(urlSearchQuery);

  const { tableHeads, tableColgroup, tableOptions } = useMyTable( getTableOptions(isEmptySearchQuery),
    { memoDependencies: [isEmptySearchQuery] }
  );

  return (
    <Card className={'col'}>
      <RuleInfoCard title={userStates?.data?.notification_rule ? ruleData?.title : 'Правило по умолчанию'} 
                    admin={ruleData?.admin || null} 
                    created_at={ruleData?.created_at || null} 
                    className={'mb-2'}
      />

      <div className='d-flex mb-1'>
        <Head secondary noMargins
              title={'Список пользователей, доступных для отправки уведомлений:'}/>
               <span style={{position: 'relative', top: '-7px', right: '-5px', fontWeight: '400'}}>
            <WithTooltip
              elem={<Info/>}
              tooltips={['Только пользователи, которые сейчас авторизованы, т.е. за ними закреплено устройство (есть серийный № устройства)']}
              className={'width-400'}
            />
      </span>
      </div>
     

      {ruleData?.type === 2 && isEmpty(ruleData?.except_users_profiles) 
      ? 'Запрет на отправку всем пользователям системы.' :
      <>
        <SearchInput  className={'mb-1'} 
                      placeholder={'Поиск...'}
                      onChange={(e) => setUrlSearchQuery(e.target.value)}
                      defaultValue={urlSearchQuery}
                      resetWhen={!urlSearchQuery}
        />

        <MyTable  isDataReady={ruleUsersDataIsReady}
                  tableClassName={'bg-c-rgba-light'}
                  showEmptyFoundDataMessage={!isEmptySearchQuery}
                  colgroup={tableColgroup}
                  paginationAllCount={ruleUsersData?.count}
                  activePaginationStyle={{border: '1px solid #2F80ED !important'}}
        >
          <MyTableHead heads={tableHeads} />

          <MyTableBody  className={`${loader(ruleUsersDataIsReady)}`}
                        data={ruleUsersData?.results}
                        tableOptions={tableOptions}
                        highestRowToFill={15}
          />
        </MyTable>
      </>}
      <Link className={'btn btn-primary mt-1 float-right'}
            to={'/admins/notifications/?type=rules'}
      >
        Перейти к правилам
      </Link>
    </Card>
  );
};

// UserP2PRule.propTypes = {
// 	name: PropTypes.string,
// 	author: PropTypes.string,
// 	creationDate: PropTypes.any,
// 	showEmptyFoundDataMessage: PropTypes.any,
// 	colgroup: PropTypes.any,
// 	heads: PropTypes.any,
// 	tableOptions: PropTypes.any
// };

function RuleInfoCard({ title, admin, created_at, className = '' }) {
  return (
    <Card secondary className={`pt-2 ${className}`}>
      <label>Название правила:</label> {title}
      <br />
      {admin ? <><label>Автор правила:</label> {getShortFullNameFromObj(admin)} <br /></> : <></>}
      
      {created_at ? <><label>Дата создания правила:</label> {getLocaleFullDate(created_at)}</> : <></>}
    </Card>
  );
}

RuleInfoCard.defaultProps = {
  title: '-',
  admin: '-',
  created_at: '-',
};

export default UserP2PRule;
