import {useRef} from 'react';
import {includeSomeSubstring} from '../../../../js-helpers/helpers';

export const useAbortRequest = () => {
	const prevDataRef = useRef({})
	
	const checkSameRequest = (config) => {
		const controller = new AbortController()
		config.signal = controller.signal
		
		// для отмены предыдущего запроса с переменными в секциях урла
		const abortEndpointsParts = ['globalsearch', 'logs', 'devices/setup/section']
		
		const {url: prevUrl, method: prevMethod} = prevDataRef.current
		const {url, method} = config
		
		const sameRequest = prevUrl === url && prevMethod === method
		
		const sameCustomRequest = () => {
			if (!prevUrl) return false
			return includeSomeSubstring(url, abortEndpointsParts)
				&& includeSomeSubstring(prevUrl, abortEndpointsParts)
		}
		
		if (sameRequest || sameCustomRequest())
			prevDataRef.current?.abort()
		
		prevDataRef.current = {url, method, abort: () => controller.abort()}
		
		return config
	}
	
	return checkSameRequest
}