import {Link} from "react-router-dom"
import {getFullNameFromObj, getShortFullNameFromObj, getShortString, toggle} from "../../../../js-helpers/helpers"
import {MyAvatar} from "../../../UI/MyAvatar"
import {WithTooltip} from "../../../UI/WithTooltip";

export const getAdminsTableOptions = (isEmptySearchQuery) => [
	{
		head: 'Keycloak ID',
		cell: (admin) => admin?.account?.keycloak_id ? 
      <WithTooltip elem={getShortString(admin?.account?.keycloak_id, 5)}
		               tooltips={[admin?.account?.keycloak_id]}/> 
      : '-',
		col: {width: 75},
	}, {
		head: 'Фото',
		col: {width: 100},
		cell: (row) => {
			return row.is_superadmin
				? <div className='position-relative' style={{width: '45px'}}>
					<MyAvatar src={row.account.profile_photo_thumbnail} alt={row.account.name}/>
					<div className={'position-absolute'} style={{top: '-5px', right: '-10px'}}>
						<i className={'bx bxs-star font-small-3 warning'}/>
					</div>
				</div>
				: <MyAvatar src={row.account.profile_photo_thumbnail} alt={row.account.name}/>
		}
	}, {
		head: 'ФИО',
		col: {width: 200},
		cell: (row) =>
			<Link to={`/admins/${row.account.keycloak_id}/`}>
				{(isEmptySearchQuery ? getShortFullNameFromObj(row.account) : getFullNameFromObj(row.account)) +
				toggle(!row.account.is_active, ' (заблокирован)')}
			</Link>,
		sortField: 'account__last_name'
	}, {
		head: 'Подразделение',
		cell: (row) => row.account.subdivision || '-',
		col: {width: 300},
		sortField: 'account__subdivision'
	}, {
		head: 'Дивизион',
		cell: (row) => row.account.division || '-',
		col: {width: 300},
		sortField: 'account__division__name'
	},
]
