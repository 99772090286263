import React, {useRef, useState} from 'react'
import {Card} from '../../../../components/UI/Card'
import {FormAppEdit} from '../formsApps/formAppIcon/FormAppEdit'
import {useForm} from 'react-hook-form'
import {appsAPI} from '../../../../API/appsAPI'
import {useNavigate} from 'react-router-dom'
import {ButtonGoBack} from '../../../../components/button/ButtonGoBack'
import {Head} from '../../../../components/UI/Head'
import {loader} from '../../../../js-helpers/helpers'
import {Button} from '../../../../components/button/Button'
import {toast} from 'react-toastify'
import {FormAppVersion} from '../formsApps/formAppVersion/FormAppVersion'
import {checkIconLengthAngFormat} from '../pageAppsFunctions'


export const PageUploadApps = () => {
	const {control, handleSubmit, formState: {isSubmitting}} = useForm()
	const navigate = useNavigate()
	const toastId = useRef(null)

  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false)
	
	const onSubmit = async (submitData) => {
		const bodyFormDataIcon = new FormData()
		const {name, icon, icon_light, icon_dark, icon_md, type, is_in_other_folder, is_hidden} = submitData
		
		Object.entries({
				name,
				icon: icon[0],
				icon_light: icon_light[0],
				icon_dark: icon_dark[0],
        icon_md: icon_md[0],
				type,
				is_in_other_folder: Boolean(is_in_other_folder),
				is_hidden: Boolean(is_hidden)
			})
			.forEach(([field, value]) => {
				bodyFormDataIcon.append(field, value)
			})
		
		try {
			const {data} = await appsAPI.createNewAppIcons(bodyFormDataIcon)
			
			const bodyFormDataVersion = new FormData()
			
			Object.entries({
					app: data.id,
					release_date: submitData.release_date,
					apk: submitData.apk[0],
					is_in_other_folder
				})
				.forEach(([field, value]) => {
					bodyFormDataVersion.append(field, value)
				})

      setIsDisabledSaveButton(true)

			await appsAPI.createNewAppVersion(bodyFormDataVersion, toastId)
			toast.success(`Приложение "${name}" успешно добавлено!`)
			
			navigate({pathname: `/admins/apps/${data.id}/`})
			
		} catch ({response: {data}}) {

      data?.errors?.forEach((error, index) => {
        if (data?.errors?.[index]?.attr === 'name') {
          toast.error(`Приложение "${submitData.name}" уже есть!`)
        }
        
        if (data?.errors?.[index]?.attr === 'icon') {
          checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки на сайте')
        }
        
        if (data?.errors?.[index]?.attr === 'icon_dark') {
          checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки темной темы')
        }
        
        if (data?.errors?.[index]?.attr === 'icon_light') {
          checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки светлой темы')
        }

        if (data?.errors?.[index]?.attr === 'icon_md') {
          checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки material design')
        }
      })
			
			if (data?.errors?.[0]?.detail.includes('already exists')) {
				toast.error('Такая версия apk уже существует')
			}

      if (data?.errors?.[0]?.detail.includes('не является корректным файлом')) {
				toast.update(toastId.current, {
          type: 'error',
          render: data?.errors?.[0]?.detail,
          autoClose: 5000,
          isLoading: false, 
          closeOnClick: true,
          progress: null
        })
			}

		}  finally {
      setIsDisabledSaveButton(false)
    }
	}
	
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ButtonGoBack className={'float-right'}/>
				<Head>Загрузка приложения</Head>
				
				<Card className={loader(!isSubmitting)}>
					
					<FormAppEdit control={control}/>
					<FormAppVersion control={control} type={'newApp'}/>
					
					<Button className={'mt-1 d-block ml-auto'} save disabled={isDisabledSaveButton}/>
				</Card>
			
			</form>
		</>
	)
}