import { Card } from "../../../../../components/UI/Card";
import { Columns } from "../../../../../components/UI/Columns";
import { loader } from "../../../../../js-helpers/helpers";


const fields = [
  { label: 'Серийный номер', field: 'serial_number' },
  { label: 'БРЕНД ТЕЛЕФОНА', field: 'brand' },
  { label: 'МОДЕЛЬ ТЕЛЕФОНА', field: 'model' },
  { label: 'ANDROID ID', field: 'android_id' },
  { label: 'IMEI 1', field: 'imei1' },
  { label: 'IMEI 2', field: 'imei2' },
  { label: 'ВЕРСИЯ ПРОШИВКИ', field: 'launcher_version_code' },
  { label: 'ВЕРСИЯ СИСТЕМЫ', field: 'system_version' },
  { label: 'API LEVEL', field: 'api_level' },
];

export const DeviceInfoAboutCard = ({device, className = '', isReady }) => {
  return (
    <Card secondary className={`${className} ${loader(isReady)}`}>
      <Columns  as={'fieldset'} 
                cols={3}
                rowGap={1.8}
                sumItems={fields.length} 
                className={'list-unstyled'}>
        {fields?.map(({ field, label }) => (
          <li key={label}>
            <label className={'text-gray-300 d-block'}>{label}</label>
            {device?.[field] || '-'}
          </li>
        ))}
      </Columns>
  </Card>
  )
}