import dayjs from 'dayjs';
import { useMemo } from 'react';
import { MyDatePicker } from '../../../../components/form/MyDatePicker/MyDatePicker';
import { getDateNow } from '../../../../js-helpers/helpers';
import { titlesStyles } from '../../pageGroups/PageGroupsItem/PageGroupsItem';

export const DatesItem = ({ releaseDate, setReleaseDate,
  setInstallDate, installDate, isDisabled = false,
}) => {
  const defaultDate = dayjs(getDateNow()).add(10, 'minutes').toDate()

  const minInstallDate = useMemo(() => {
    return releaseDate 
      ? releaseDate 
      : dayjs(getDateNow()).add(10, 'minutes').toDate();
  }, [releaseDate]);


  return (
    <>
      <div className={'d-flex border-bottom-extra-light py-1'}>
        <h3 className={titlesStyles}>Дата релиза</h3>
        <MyDatePicker
          className={'col-5 px-0'}
          inputClassName={`${
            !isDisabled ? (releaseDate ? '' : 'is-invalid') : ''
          }`}
          onChange={(e) => {
            setReleaseDate(dayjs(e[0]).second(59).toDate());
          }}
          placeholder={'Укажите дату и время'}
          value={releaseDate}
          options={{
            minDate: isDisabled ? '' :  new Date(releaseDate) > new Date(defaultDate)  ? defaultDate : releaseDate,
          }}
          disabled={isDisabled}
        />
      </div>

      <div className={'d-flex py-1'}>
        <h3 className={titlesStyles}>Дата установки</h3>

        <MyDatePicker
          className={'col-5 px-0'}
          inputClassName={`${
            !isDisabled ? (installDate ? '' : 'is-invalid') : ''
          }`}
          onChange={(e) => {
            setInstallDate(dayjs(e[0]).second(59).toDate());
          }}
          placeholder={'Укажите дату и время'}
          value={installDate}
          options={{
            minDate: isDisabled ? '' : minInstallDate,
          }}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};
