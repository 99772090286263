import {usePrevious, useUpdateEffect} from "@react-hookz/web";
import {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {logsAPI} from "../../../../API/logsAPI";
import {logsOptions} from "../../../../constants/logsOptions";
import {urlParams} from "../../../../constants/urlParams";
import {useComboSearch} from "../../../../hooks/url/useComboSearch";
import {useFetchStates} from "../../../../hooks/useFetchStates";
import {isEmpty} from "../../../../js-helpers/helpers";
import PageLogs from "../../../logs/PageLogs";

const {
	PAGE,
	LIMIT,
	SERVICE,
	REASON,
	SEARCH,
	SORT,
	STATUS,
	DATE_FROM,
	DATE_TO,
	USER,
	DEVICE,
	TYPE,
	DATE_OFF_FROM,
	DATE_OFF_TO,
	DATE_ON_FROM,
	DATE_ON_TO,
	APP
} = urlParams;

const pageSearchParams = [
	{param: SERVICE, init: logsOptions[0].service},
	{param: STATUS},
	{param: USER},
	{param: DEVICE},
	{param: TYPE},
	{param: REASON},
	{param: APP},
	{param: PAGE, init: 'last'},
	{param: SORT, init: 'date'},
	{param: LIMIT},
	{param: SEARCH},
	{param: DATE_FROM},
	{param: DATE_TO},
	{param: DATE_OFF_FROM},
	{param: DATE_OFF_TO},
	{param: DATE_ON_FROM},
	{param: DATE_ON_TO},
];
export const PageDeviceLogs = () => {
	const {deviceID} = useParams();
	// console.log({deviceID})
	const [
		[urlService, setUrlService],
		[urlStatus],
		[urlUser],
		[urlDevice],
		[urlType],
		[urlReason],
		[urlApp],
		[urlPage, setUrlPage],
		[urlSortField],
		[urlLimit],
		[urlSearchQuery],
		[urlDateFrom],
		[urlDateTo],
		[urlDateOffFrom],
		[urlDateOffTo],
		[urlDateOnFrom],
		[urlDateOnTo],
	] =
		useComboSearch(pageSearchParams);
	
	const [isPaginationReady, setIsPaginationReady] = useState(true);
	
	const setPageHead = useOutletContext();
	
	const isCrashesService = urlService === 'crashes';
	
	const logs = useFetchStates(() =>
		logsAPI.getDeviceLogs({
			type: urlService,
			page: urlPage,
			apiVersion: logsOptions.find(
				(option) => option.service === urlService
			)?.apiVersion,
			page_size: urlLimit,
			ordering: urlSortField,
			is_success: urlStatus === 'success' ? true : urlStatus === 'error' ? false : null,
			reason: +urlReason || null,
			date__gte: urlDateFrom,
			date__lte: urlDateTo,
			search: urlSearchQuery,
			deviceID,
			user__account__keycloak_id: urlUser,
			logInOutType: urlType === 'login' ? 1 : urlType === 'logout' ? 2 : null,
			switch_off_date__gte: urlDateOffFrom,
			switch_off_date__lte: urlDateOffTo,
			switch_on_date__gte: urlDateOnFrom,
			switch_on_date__lte: urlDateOnTo,
			event_date__gte: urlDateFrom,
			event_date__lte: urlDateTo,
			app: urlApp,
			serial_number: isCrashesService ? deviceID : null
		})
	);
	
	const prevSearchService = usePrevious(urlService);
	
	useUpdateEffect(async () => {
		setIsPaginationReady(false);
		
		if (urlPage !== 'last') {
			setUrlPage('last');
			return;
		}
		
		const res = await logs.getData();
		res && setIsPaginationReady(true);
	}, [
		urlService, urlLimit, urlSortField, urlStatus, urlDateFrom,
		urlDateTo, urlSearchQuery, urlUser, urlReason, urlDevice,
		urlType, urlDateOffFrom, urlDateOffTo, urlDateOnFrom, urlDateOnTo, urlApp
	]);
	
	useEffect(() => {
		const {text, pageTitle} = logsOptions?.find(
			(it) => it.service === urlService
		);
		
		setPageHead({
			head: pageTitle
				? `${pageTitle} (${deviceID})`
				: text
					? `${text} (${deviceID})`
					: ''
		});
		
		if (isCrashesService && prevSearchService !== urlService) logs.setData({});
	}, [urlService]);
	
	useEffect(() => {
		logs.getData(urlPage)
	}, [urlPage])
	
	const onClickNavButton = (groupName) => {
		setUrlService(groupName);
		setUrlPage('last');
	};
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery);
	
	const showEmptyFoundDataMessage =
		!isEmptySearchQuery || urlStatus || urlUser || urlDevice ||
		urlType || urlReason || urlApp || urlDateFrom || urlDateTo ||
		urlDateOffFrom || urlDateOffTo || urlDateOnFrom || urlDateOnTo
	
	return (
		<PageLogs logs={logs}
		          isPaginationReady={isPaginationReady}
		          urlService={urlService}
		          onClickNavButton={onClickNavButton}
		          isEmptySearchQuery={isEmptySearchQuery}
		          filter={'device'}
		          showEmptyFoundDataMessage={showEmptyFoundDataMessage}
		          prevSearchService={prevSearchService}
		/>
	)
}