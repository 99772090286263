import React from 'react';
import PropTypes from 'prop-types';

export const MySwitch = (
	{
		id,
		className = '',
		onChange,
		checked,
		prependLabel,
		appendLabel,
		disabled,
		...inputProps
	}) => {
	
	const extraMargins = prependLabel && appendLabel ? 'mx-75'
		: prependLabel ? 'ml-75'
			: appendLabel ? 'mr-75' : ''
	
	return (
		<label className={`user-select-none custom-switch cursor-pointer text-c-transform-none font-size-base text-body m-0 ${className}`}>
			
			{prependLabel}
			
			<input type='checkbox'
			       checked={checked}
			       onChange={onChange}
			       className='custom-control-input'
			       id={id}
			       disabled={disabled}
			       value={''}
			       {...inputProps}
			/>
			<label className={`custom-control-label align-middle ${extraMargins}`}
			       htmlFor={id}/>
			
			{appendLabel}
		
		</label>
	)
}

MySwitch.propTypes = {
	id: PropTypes
	.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
}