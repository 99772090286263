import {Link, useParams} from 'react-router-dom'
import {useMemo} from 'react'
import {DeviceSetupInfoStep} from './stepReview/DeviceSetupInfoStep'
import {deviceAPI} from '../../../../API/deviceAPI'
import {DeviceSetupInfoStagesNav} from './stages/DeviceSetupInfoStagesNav'
import {useFetchStates} from '../../../../hooks/useFetchStates'
import {Card} from '../../../../components/UI/Card';
import {capitalize, isEmpty, loader, toggle} from '../../../../js-helpers/helpers';
import {useUpdateEffect} from '@react-hookz/web';
import {useCustomSearch} from '../../../../hooks/url/useCustomSearch';

export const PageDeviceSetupInfo = () => {
	const {setupID, deviceID} = useParams()
	const [urlStage, setUrlStage] = useCustomSearch('stage', 1, {toNumber: true})
	
	const {data: device} = useFetchStates(() => deviceAPI.getOneDevice(deviceID), {initData: {}})
	
	const setup = useFetchStates(() => deviceAPI.getDeviceSetupHistory(setupID))
	
	const allSections = useFetchStates(deviceAPI.getDeviceSetupSections,
		{setNestedField: 'count', noInitFetch: true})
	
	const currSection = useFetchStates(
		(query) => deviceAPI.getDeviceSetupOneSection(urlStage, query),
		{noInitFetch: true})
	
	const [currSectionQuery, maxStageCompletedCount] = useMemo(() => {
		if (isEmpty(setup.data)) return [{}, 0]
		
		const {phone_model, version_android, settings, instruction} = setup.data
		
		return [
			{
				phone_model,
				version_android,
				instruction_version: instruction?.id
			},
			Math.max(...settings.map((item) => item.setting.section.sequence_number))
		]
	}, [setup.data])
	
	useUpdateEffect(() => {
		currSection.getData(currSectionQuery)
	}, [urlStage])
	
	useUpdateEffect(async () => {
		await allSections.getData(currSectionQuery)
		currSection.getData(currSectionQuery)
	}, [setup.data])
	
	const isReadyNav = allSections.isReady && maxStageCompletedCount
	
	const {brand, model, serial_number} = device
	
	return (
		<>
			<h2>
				{`${capitalize(brand ?? '-')} ${model ?? '-'}, ${setup.data?.version_android_title ?? '-'} `}
				<i>{`(${serial_number ?? '-'})`}</i>
			</h2>
			
			<DeviceSetupInfoStagesNav currentStage={urlStage}
			                          className={loader(isReadyNav)}
			                          countStages={allSections.data}
			                          maxStageCompleted={maxStageCompletedCount}
			                          setCurrentStage={setUrlStage}/>
			
			<Card className={` ${loader(currSection.isReady)}`}>
				<ul
					className={`list-unstyled overflow-hidden steps rounded-lg ${toggle(isEmpty(currSection.data), 'height-500')}`}>
					
					{currSection.data?.setting?.map((step) => {
						
						const completedSetting = setup.data.settings
							.filter((item) => item.setting.section.sequence_number === urlStage)
						
						return (
							<DeviceSetupInfoStep key={step.sequence_number}
							                     instructionStep={step}
							                     maxStageCompleted={maxStageCompletedCount}
							                     completedSetting={completedSetting}
							                     currentStage={urlStage}/>
						)
					})
					}
				
				</ul>
				
				<div className={'text-right'}>
					{urlStage === allSections.data
						?
						<Link className={'btn btn-primary'} to={`/admins/devices/${deviceID}/info/`}>
							Выход
						</Link>
						:
						<button onClick={() => setUrlStage(urlStage + 1)}
						        className={'btn btn-primary'}>
							Далее
						</button>
					}
				</div>
			</Card>
		</>
	)
}