import {useCustomSearch} from '../../hooks/url/useCustomSearch';
import {PerPageLimiter} from './PerPageLimiter';
import {RangeCounters} from './RangeCounters';
import Pagination from './Pagination';
import {urlParams} from '../../constants/urlParams';

export const FullPagination = ({
	                               allCount,
	                               className = '',
	                               paginationClassName = '',
	                               limiterClassName = '',
	                               rangeCountersClassName = '',
	                               showPagination
                               }) => {
	
	const [urlLimit] = useCustomSearch(urlParams.LIMIT)
	const [urlPage] = useCustomSearch(urlParams.PAGE)
	let finalPAge = urlPage
	
	if (isNaN(+urlPage))
		finalPAge = Math.ceil(+allCount / (urlLimit || 10))
	
	return (
		<div className={`row flex-nowrap no-gutters gap-1r align-items-center ${className}`}>
			<PerPageLimiter show={Boolean(allCount)} className={limiterClassName}/>
			
			<RangeCounters currPage={finalPAge}
			               allItems={allCount}
			               maxItems={urlLimit}
			               classname={`${rangeCountersClassName}`}/>
			
			<Pagination allCount={allCount}
			            isVisible={showPagination}
			            className={`col align-self-end ${paginationClassName}`}/>
		</div>
	)
};