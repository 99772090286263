import classNames from "classnames"
import { useEffect, useMemo, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { adminSmassAPI } from "../../../../API/adminSmassAPI"
import { Button } from "../../../../components/button/Button"
import { MySwitch } from "../../../../components/form/MySwitch"
import { ExceptionsLists } from "../../../../components/listsAccess/ExceptionsLists"
import { Card } from "../../../../components/UI/Card"
import { Head } from "../../../../components/UI/Head"
import { isEqualArrays, loader } from "../../../../js-helpers/helpers"
import { GroupDetailsModal } from "./components/GroupDetailsModal"

export const PageAdminEditGroups = () => {
  const {keycloakID} = useParams();
  const {data, isReady, setReady, setData, getData} = useOutletContext();

  const [isUnauthorizedLauncher, setIsUnauthorizedLauncher] = useState(null)
  const [exceptions, setExceptions] = useState([]);
  const [isDetailsOpened, setIsDetailsOpened] = useState(false)
  const [openedGroupData, setOpenedGroupData] = useState({id: null, title: ''})

  useEffect(() => {
    if (isReady) {
      setExceptions(data?.visibility_groups || [])
      setIsUnauthorizedLauncher(data?.is_unauthorized_launchers_visible)
    }
  }, [isReady, data])

  const onSubmit = async () => {
    const submitData = {}


    if(!isEqualArrays(exceptions, data?.visibility_groups || [])) {
      const visibility_groups = exceptions?.map((exception) => exception.id)
      submitData.visibility_groups_ids = visibility_groups
    }

    if(isUnauthorizedLauncher !== data.is_unauthorized_launchers_visible) {
      submitData.is_unauthorized_launchers_visible = isUnauthorizedLauncher
    }

    try {
      await adminSmassAPI.patchAdmin(keycloakID, submitData)
      toast.success('Изменения сохранены!', {autoClose: 5000});
      getData()
    } catch (e) {
      toast.error(`Ошибка сохранения: ${e?.response?.data?.errors?.[0]?.detail}`, {autoClose: 5000});
    }
  }

  const disableSaveBtn = useMemo(() =>  
    !isReady || isEqualArrays(exceptions, data?.visibility_groups || []) && isUnauthorizedLauncher === data?.is_unauthorized_launchers_visible, 
  [exceptions, isUnauthorizedLauncher, data])

  
  const exceptionsHead = useMemo(() => `Выбрано: ${exceptions?.length}`, [exceptions])

  const onExceptionItemClick = () => {}

  const showGroupDetails = (id, title) => {
    setOpenedGroupData({id, title})
    setIsDetailsOpened(true)
  }

  const closeModal = () => {
    setIsDetailsOpened(false)
  }

  return (
    <>
      <Head secondary className={'d-inline-block'} children={'Видимость контента по группам'}/>

      <Card className={`${loader(isReady)} mb-2`}>
       
        <ExceptionsLists type={'groups'}
                         headExceptions={exceptionsHead}
                         exceptions={exceptions}
                         setExceptions={setExceptions}
                         onItemClick={onExceptionItemClick}
                         showInfo={showGroupDetails}/>

        <Card secondary className={'d-flex align-items-center mt-1'}>
          <Head secondary uppercase noMargins>
            Данные от Лаунчера без авторизации
          </Head>

					<MySwitch
						prependLabel={
							<button className={classNames('btn px-0', {'text-muted': isUnauthorizedLauncher})}
								      onClick={() => {
                        setIsUnauthorizedLauncher(false)
                      }}
								      type={'button'}>
								Скрыто
							</button>
						}
						appendLabel={
							<button className={classNames('btn px-0', {'text-muted': !isUnauthorizedLauncher})}
								      onClick={() => {
                        setIsUnauthorizedLauncher(true)
                      }}
								      type={'button'}>
								Доступно
							</button>
						}
						id={'is_unauthorized_launchers_visible'}
						className={'ml-2'}
						checked={isUnauthorizedLauncher}
						onChange={(e) => {
                        setIsUnauthorizedLauncher(e.target.checked); 
                      }}
					/>
        </Card>

        <Button onClick={onSubmit}
				        disabled={disableSaveBtn}
				        save
				        className={'mt-1 d-block ml-auto'}/>
      </Card>

      {isDetailsOpened && <GroupDetailsModal goBack={closeModal} groupData={openedGroupData}/>}
    </>
  )
}