import { ModalDialogFooter } from "../../../../../../components/modals/ModalDialogFooter"

function DownloadApkModal({onCancelClick, apk}) {
  const onSuccessClick = () => {
   window.open(apk);
   onCancelClick()
  }
  return (
    <div>
      <ModalDialogFooter  onSuccessClick={onSuccessClick}
			                    onCancelClick={onCancelClick}
                          successText={'Скачать'}/>
    </div>
  )
}

export default DownloadApkModal