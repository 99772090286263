/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { notificationsAPI } from "../../../../API/notificationsAPI"
import { userAPI } from "../../../../API/userAPI"
import { Button } from "../../../../components/button/Button"
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { DeleteItemModal } from "../../../../components/modals/DeleteItemModal"
import { Card } from "../../../../components/UI/Card"
import { Head } from "../../../../components/UI/Head"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import useRerender from '../../../../hooks/useRerender';
import { capitalize, isEqualArrays, loader } from "../../../../js-helpers/helpers"
import { loggedAdmin } from "../../../../stores/loggedAdmin"
import { RuleSendAvailable } from "./pageNotificationsRulesComponents/RuleSendAvailable"
import { RuleUsersSelected } from "./pageNotificationsRulesComponents/RuleUsersSelected"
import { restrictionsOptions } from "./PageNotificationsRulesCreate"

export const PageNotificationsRulesItem = () => {
  const {ruleID} = useParams()
 
  const navigate = useNavigate();
  const location = useLocation();

  const defaultRulePage = useMemo(() => 
    location.pathname.includes('default')
  , [location])

  const isSuperAdmin = useMemo(() => loggedAdmin.isSuperAdmin, [loggedAdmin])

  const {control, handleSubmit, setValue, getValues, formState: {errors}} = useForm()

  const [showLoader, setShowLoader] = useState(false)

  const [checkedAllUsers, setCheckedAllUsers] = useState(false);
  const [checkedRule, setCheckedRule] = useState('all');
  const [showUsersList, setShowUsersList] = useState(false);
  const [showRecipientList, setShowRecipientList] = useState(false);
  const [status, setStatus] = useState(1);
  const [defaultStatus, setDefaultStatus] = useState(status)
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(true);

  const [exceptions, setExceptions] = useState([]);
  const [defaultExceptions, setDefaultExceptions] = useState([]);

  const [exceptionsUsers, setExceptionsUsers] = useState([]);
  const [defaultExceptionsUsers, setDefaultExceptionsUsers] = useState([]);
  const [ruleSelectKey, rerenderRuleSelectKey] = useRerender();
  const [restrictionSelectKey, rerenderRestrictionSelectKey] = useRerender();
  const [isShowModal, setIsShowModal] = useState(false)

	const ruleData = useFetchStates(() => 
    defaultRulePage ?  notificationsAPI.getDefaultP2PRule() : notificationsAPI.getOneP2PRule(ruleID)
  )
  const ruleUsers = useFetchStates(() => 
    userAPI.searchUsers({notification_rule: ruleID, page_size: 999}, {allowMultipleRequests: true, noInitFetch: defaultRulePage}), 
  )


  useEffect(() => {
    if (ruleData.isReady) { 
      const exceptions = ruleData.data?.except_users_profiles

      setValue('title', ruleData.data?.title);

      setCheckedRule(exceptions.length === 0 ? 
        ruleData.data?.type === 1  ? 'all' : 
        ruleData.data?.type === 2 ? 'nobody' : 
        null : 'list')

      const status = exceptions.length !== 0 ? 
      ruleData.data?.type === 1 ? 1 
      : ruleData.data?.type === 2 ? 2 : 1 
      : 1

      setStatus(status)
      setDefaultStatus(exceptions.length !== 0 ? 
        ruleData.data?.type === 1 ? 1 
        : ruleData.data?.type === 2 ? 2 : 0 : 0)

      setExceptions(ruleData.data?.except_users_profiles)
      setDefaultExceptions(ruleData.data?.except_users_profiles)
    }
  }, [ruleData.data])

  useEffect(() => {
    if(ruleUsers.isReady) {
      const exceptUsersKeycloak = ruleUsers?.data?.results?.map(user => user.account);
      setExceptionsUsers(exceptUsersKeycloak);
      setDefaultExceptionsUsers(exceptUsersKeycloak);
    }
  }, [ruleUsers.isReady, ruleUsers.data])

  const toggleRecipient = (type) => {
    setCheckedRule(type);
    setShowRecipientList(!showRecipientList);

    if ((type === 'all' && ruleData.data?.type === 1 && ruleData.data?.except_users_profiles.length === 0) || (type === 'nobody' && ruleData.data?.type === 2 && ruleData.data?.except_users_profiles.length === 0) || (type === 'list' && ruleData.data?.except_users_profiles.length !== 0)) {
      setIsDisabledSaveBtn(true)
    } else {
      setIsDisabledSaveBtn(false)
    }
  
  };

  const onRestrictionsSelect = (item) => {
    setStatus(item.value);
    setIsDisabledSaveBtn(item.value === defaultStatus);
    rerenderRuleSelectKey();
  };

  const exceptionsHead = useMemo(() => 
  `${capitalize(restrictionsOptions[status - 1].title)}: ${exceptions?.length || 0}`, 
  [exceptions, status]);

  const onExceptionItemClick = () => {
    rerenderRuleSelectKey();
    setIsDisabledSaveBtn(false);
  };

  const toggleUsers = () => {
    setCheckedAllUsers(!checkedAllUsers);
    setShowUsersList(!showUsersList);
  };

  const onSubmit = async (submitData) => {
    if(!isSuperAdmin && defaultRulePage) {
      toast.error('Редактирование правила по умолчанию недоступно!', {autoClose: 5000})
      return
    }
    const updatedData = {}
    setShowLoader(false)

    if (submitData.title !== ruleData.data.title) {
      updatedData.title = submitData.title
    }

    if (checkedRule === 'all' /*&& ruleData.data.type !== 1*/) {
      updatedData.type = 1
      updatedData.except_users = []
    } else if (checkedRule === 'nobody' /*&& ruleData.data.type !== 2*/) {
      updatedData.type = 2
      updatedData.except_users = []
    } else if (checkedRule === 'list') {
      if (status === 1 && ruleData.data.type !== 1) {
        updatedData.type = 1;
      } else if (status === 2 && ruleData.data.type !== 2) {
        updatedData.type = 2;
      }

      if(!isEqualArrays(defaultExceptions, exceptions)) {
        updatedData.except_users = exceptions?.map(exception => exception.keycloak_id)
      }
    }

    try {
      if(defaultRulePage) {
        await notificationsAPI.patchDefaultP2PRule(updatedData)
      } else {
        await notificationsAPI.patchP2PTRule(ruleID, updatedData)
      }

      if (!checkedAllUsers && !defaultRulePage && !isEqualArrays(defaultExceptionsUsers, exceptionsUsers)) {
        const exceptionsUsersSet = new Set(exceptionsUsers);
        const defaultExceptionsUsersSet = new Set(defaultExceptionsUsers);

        const deletedUsers = defaultExceptionsUsers.filter((e) => !exceptionsUsersSet.has(e))
        const addedUsers = exceptionsUsers.filter((e) => !defaultExceptionsUsersSet.has(e))

        addedUsers.forEach(async (user) => {
          await userAPI.patchUser(user?.keycloak_id, {notification_rule: ruleID})
        })

        deletedUsers.forEach(async (user) => {
          await userAPI.patchUser(user?.keycloak_id, {notification_rule: null})
        })
      }

      toast.success('Правило обновлено!', {autoClose: 5000})

      setIsDisabledSaveBtn(true)

      await ruleData.getData()
      await ruleUsers.getData()
    } catch (e) {
      if (e?.response?.data?.errors?.[0]?.code === 'unique' && e?.response?.data?.errors?.[0]?.attr === 'title') {
        toast.error(<>Правило с названием <b>{submitData.title}</b> уже существует!</>, {autoClose: 5000})
      } else {
        toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
      }
    } finally {
      setShowLoader(true)
    }
  }

  const deleteRule = () => {
    setIsShowModal(true)
  }

  const onClickDeleteRule = async (id, rule) => {
    try {
      await notificationsAPI.deleteP2PRule(id);
      toast.success(`Правило "${rule}" удалено`, {autoClose: 5000})
      navigate('/admins/notifications/?type=rules')
    } catch (e) {
      toast.error('Ошибка удаления правила', {autoClose: 5000})
    } finally {
      setIsShowModal(false)
    }
  }

  useEffect(() => {
    !defaultRulePage && setIsDisabledSaveBtn(isEqualArrays(defaultExceptionsUsers, exceptionsUsers))
  }, [defaultRulePage, exceptionsUsers, defaultExceptionsUsers])

  useEffect(() => {
    setIsDisabledSaveBtn(isEqualArrays(defaultExceptions, exceptions))
  }, [exceptions, defaultExceptions])

  return (
    <>
      <ButtonGoBack className={'float-right'}/>
      <Head>Редактирование правила {defaultRulePage && 'по умолчанию'}</Head>

      <Card as={'form'} className={loader((ruleData.isReady && ruleUsers.isReady) || !showLoader)} onSubmit={handleSubmit(onSubmit)}>
        {!defaultRulePage && <Card secondary className={'d-flex mb-2'}>
          <h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase p-0 pt-50'}
          >
            название правила:
          </h3>
          <div className={'d-flex flex-column w-100'}>
          <Controller 
              name={'title'}
              control={control}
              defaultValue={ruleData.data?.title || ''}
              rules={{maxLength: 50, required: true, pattern: (e) => e.trim().length > 0}}
              render={({field}) => 
                <input  className={`form-control col-6 ${errors?.title || getValues('title')?.trim()?.length === 0 ? 'is-invalid' : ''}`}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setIsDisabledSaveBtn(e.target.value === ruleData.data.title)
                        }}
                />}
            />
          {(errors?.title || getValues('title')?.trim()?.length === 0) && <div className='mt-50 text-danger font-small-1'>{(getValues('title').length === 0 || getValues('title')?.trim()?.length === 0) ? 'Введите название правила' : 'Максимальная длина - 50 символов'}</div>}
          </div>
        </Card>}

        <RuleSendAvailable  checkedRule={checkedRule} 
                            toggleRecipient={toggleRecipient}
                            restrictionSelectKey={restrictionSelectKey} 
                            status={status} 
                            onRestrictionsSelect={onRestrictionsSelect}
                            exceptionsHead={exceptionsHead} 
                            exceptions={exceptions} 
                            onExceptionItemClick={onExceptionItemClick}
                            setExceptions={setExceptions}
        />

        {!defaultRulePage && <RuleUsersSelected  
                            checkedAllUsers={checkedAllUsers} 
                            toggleUsers={toggleUsers} 
                            exceptionsUsers={exceptionsUsers}
                            onExceptionItemClick={onExceptionItemClick}
                            setExceptionsUsers={setExceptionsUsers}  
        />}

        <div className={'mt-1 d-flex justify-content-end ml-auto'}>
          <Button disabled={isDisabledSaveBtn} 
                  className={'mr-1'}  
                  save
                  children={'Обновить'}
          />
          {!defaultRulePage && <Button remove onClick={deleteRule}/>}
        </div>
      </Card>

      {isShowModal && <DeleteItemModal  title={ruleData.data?.title}
                                        onClickAbort={() => setIsShowModal(false)}
                                        onClickDelete={() => onClickDeleteRule(ruleID, ruleData.data?.title)}
                                        titleText={'правило'}/>}
    </>
  )
}