import {useMountEffect, useToggle, useUpdateEffect} from "@react-hookz/web";
import {useImmer} from "use-immer";
import {adminSetUpAPI} from "../../../API/adminSetUpAPI";
import {userAPI} from "../../../API/userAPI";
import {ButtonFilter} from "../../../components/button/ButtonFilter";
import { MySearchMultiSelect } from "../../../components/form/MySelect/MySearchMultiSelect";
import {SearchInput} from "../../../components/form/SearchInput";
import {urlParams} from "../../../constants/urlParams";
import {useComboSearch} from "../../../hooks/url/useComboSearch";
import {useFetchStates} from "../../../hooks/useFetchStates";
import {getFullNameFromObj, toggle} from "../../../js-helpers/helpers";

const {
	SEARCH, SORT, PAGE,
	LIMIT, LAUNCHER_VER, USER,
	SERIAL_NUMBER, SETUP_ADMIN, MODEL, BRAND
} = urlParams;

const pageSearchParams = [
	{param: SEARCH, debounce: 333},
	{param: SORT, init: 'user__account__last_name'},
	{param: PAGE, init: 1},
	{param: LIMIT},
	{param: SERIAL_NUMBER},
	{param: USER},
	{param: LAUNCHER_VER},
	{param: SETUP_ADMIN},
	{param: MODEL},
	{param: BRAND}
];


export const FiltersPageDevices = () => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		,
		,
		,
		[urlSerialNumber, setUrlSerialNumber],
		[urlUser, setUrlUser],
		[urlLauncherVer, setUrlLauncherVer],
		[urlSetupAdmin, setUrlSetupAdmin],
		[urlModel, setUrlModel],
		[urlBrand, setUrlBrand],
	] = useComboSearch(pageSearchParams);
	
	const [showFilters, toggleShowFilters] = useToggle(
		urlUser || urlSerialNumber || urlUser ||
		urlSetupAdmin || urlLauncherVer || urlModel || urlBrand
	);
	
	useUpdateEffect(() => {
		if (!showFilters) {
			setUrlUser('');
			setUrlSerialNumber('');
			setUrlLauncherVer('');
			setUrlSetupAdmin('');
			setUrlModel('');
			setUrlBrand('');
			setInitFilters((draft) => {
				draft.user = null;
				draft.serial_number = null;
				draft.setup_admin = null;
				draft.launcher_ver = null;
				draft.brand = null;
				draft.model = null;
			});
		}
	}, [showFilters]);
	
	const [initFilters, setInitFilters] = useImmer({
		serial_number: null,
		user: null,
		launcher_ver: null,
		device: null,
		setup_admin: null,
		brand: null,
		model: null,
	});
	
	const customUserSetter = ({data}) => {
		const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')
    const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

		setInitFilters((draft) => {
			draft.user = {text,	value};
		});

		initUserState.setReady(true);
	};
	
	const customSetupAdminSetter = ({data}) => {
    const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')
    const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

		setInitFilters((draft) => {
			draft.setup_admin = {text, value};
		});
		
		initSetupAdminState.setReady(true);
	}
	
	const initUserState = useFetchStates(
		() => userAPI.getAllUsersWithCustomParams({account__keycloak_id__in: urlUser}), {
			noInitFetch: true,
			customSetter: customUserSetter,
		});
	
	const initSetupAdminState = useFetchStates(
		() => adminSetUpAPI.searchAdmins({account__keycloak_id__in: urlSetupAdmin}), {
			noInitFetch: true,
			customSetter: customSetupAdminSetter,
		});

	useMountEffect(() => {
		urlUser && initUserState.getData();
		urlSetupAdmin && initSetupAdminState.getData();
		
		urlSerialNumber && setInitFilters((draft) => {
			draft.serial_number = {
				text: urlSerialNumber,
				value: urlSerialNumber,
			};
		});
		
		urlLauncherVer && setInitFilters((draft) => {
			draft.launcher_ver = {
				text: urlLauncherVer,
				value: urlLauncherVer,
			};
		})
		
		urlBrand && setInitFilters((draft) => {
			draft.brand = {
				text: urlBrand,
				value: urlBrand,
			};
		})
		
		urlModel && setInitFilters((draft) => {
			draft.model = {
				text: urlModel,
				value: urlModel,
			};
		})
	});

  const onSelect = (selectedOptions, cd, setReadyCd = null) => {
    if (!selectedOptions) return;

    const resultOptions = selectedOptions.map((option) => option.value)
    cd(resultOptions)

    setReadyCd && setReadyCd()
  }
	
	return (
			<div
				className={
					'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
				}
			>
				<div className={'col-12 row no-gutters align-items-center'}>
					<SearchInput
						resetWhen={!urlSearchQuery}
						className={'col mr-1'}
						onChange={(e) => setUrlSearchQuery(e.target.value)}
						placeholder={'Поиск по пользователю, серийному номеру...'}
						defaultValue={urlSearchQuery}
					/>
					
					<ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
				</div>
				
				{showFilters && (
					<div className={
						'row col-12 no-gutters justify-content-end align-items-center gap-1r'
					}
					>
						<MySearchMultiSelect
							className={'col'}
							type={'allDevices'}
							init={urlSerialNumber}
							initialSelectedItems={initFilters.serial_number}
							resetWhen={!urlSerialNumber}
							onSelect={(items) => onSelect(items, setUrlSerialNumber)}
							initText={'Серийный номер'}
              clearUrl={() => setUrlSerialNumber('')}
							titleClassName={toggle(!urlSerialNumber, 'text-muted')}
						/> 
						
						<MySearchMultiSelect
							className={'col'}
							type={'usersWithKeycloakID'}
							init={urlUser && initUserState.isReady}
							initialSelectedItems={initFilters.user}
							resetWhen={!urlUser}
							onSelect={(items) => onSelect(items, setUrlUser, initUserState.setReady(true))}
							initText={'Все пользователи'}
              clearUrl={() => setUrlUser('')}
							titleClassName={`${toggle(!urlUser, 'text-muted')}`}
						/>

         <MySearchMultiSelect
							className={'col'}
							type={'allBrands'}
							init={urlBrand}
							initialSelectedItems={initFilters.brand}
							resetWhen={!urlBrand}
							onSelect={(items) => onSelect(items, setUrlBrand)}
							initText={'Все бренды'}
							titleClassName={`${toggle(!urlBrand, 'text-muted')}`}
              clearUrl={() => setUrlBrand('')}
							model={urlModel}
						/>
						
						<MySearchMultiSelect
							className={'col'}
							type={'allModels'}
							init={urlModel}
							initialSelectedItems={initFilters.model}
							resetWhen={!urlModel}
							onSelect={(items) => onSelect(items, setUrlModel)}
							initText={'Все модели'}
							titleClassName={`${toggle(!urlModel, 'text-muted')}`}
              clearUrl={() => setUrlModel('')}
							brand={urlBrand}
						/>
						
						<MySearchMultiSelect
							className={'col'}
							type={'launcherVersions'}
							initialSelectedItems={initFilters.launcher_ver}
							resetWhen={!urlLauncherVer}
							onSelect={(items) => onSelect(items, setUrlLauncherVer)}
							initText={'Версия прошивки'}
              clearUrl={() => setUrlLauncherVer('')}
							titleClassName={`${toggle(!urlLauncherVer, 'text-muted')}`}
						/>
						
						<MySearchMultiSelect
							className={'col'}
							type={'allSetupAdmins'}
							init={urlSetupAdmin && initSetupAdminState.isReady}
							initialSelectedItems={initFilters.setup_admin}
							resetWhen={!urlSetupAdmin}
							onSelect={(items) => onSelect(items, setUrlSetupAdmin, initSetupAdminState.setReady(true))}
							initText={'Администратор настройки'}
              clearUrl={() => setUrlSetupAdmin('')}
							titleClassName={`${toggle(!urlSetupAdmin, 'text-muted')}`}
						/>
					
					</div>
				)}
			</div>
	);
}