import React, { useMemo, useState } from 'react';
import {servicesAPI} from '../../../API/servicesAPI';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {getBoolFromString, isEmpty, loader, toggle, translateThisBullshit} from '../../../js-helpers/helpers';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {useUpdateEffect} from '@react-hookz/web/esnext';
import {Head} from '../../../components/UI/Head';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {FiltersPageCallsHistory} from './FiltersPageCallsHistory';
import {urlParams} from '../../../constants/urlParams';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {exportExcel} from './callsHistoryFunctions';
import {callsHistoryTable} from './table';
import {useAudioPlayer} from '../../../components/media/AudioPlayer/useAudioPlayer';
import {useExportData} from '../../../services/export/useExportData';
import {observer} from 'mobx-react-lite';
import { AudioPlayerFullScreen } from '../../../components/media/AudioPlayer/AudioPlayerFullScreen';
import { useImmer } from 'use-immer';
import { callTypes, initStateFullScreenPlayer } from '../../../constants/constants';
import { FullScreenLoader } from '../../FullScreenLoader';
import { Loader } from '../../../components/UI/Loader';

const {SEARCH, SORT, PAGE, LIMIT, USER, TYPE, DEVICE, DATE_FROM, DATE_TO, EXPORT_MODE} = urlParams
const pageSearchParams = [
	{param: SEARCH},
	{param: SORT, init: 'date'},
	{param: PAGE, init: 'last'},
	{param: LIMIT},
	{param: USER},
	{param: TYPE},
  {param: DEVICE},
	{param: DATE_FROM},
	{param: DATE_TO},
	{param: EXPORT_MODE},
]

export const PageCallsHistory = observer(() => {
	const [
		[urlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlUser],
		[urlType],
    [urlDevice],
		[urlDateFrom],
		[urlDateTo],
		[urlIsExportMode],
	] = useComboSearch(pageSearchParams)
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const {playerProps, playerStates} = useAudioPlayer()
	const {badLinks, loadedLinks, playingCallURL} = playerStates
	
  const [audioName, setAudioName] = useState('')

  const [
    { playing: fullScreenPlaying, played: fullScreenPlayed, seeking: fullScreenSeeking, duration: fullScreenDuration, badURL: fullScreenBadURL, isReady: fullScreenIsReady, volume },
    setFullScreenPlayerState,
  ] = useImmer(initStateFullScreenPlayer);


	const requestProps = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		search: urlSearchQuery,
		user__account__keycloak_id__in: urlUser,
		type__in: urlType,
    device__serial_number__in: urlDevice,
		date__gte: urlDateFrom,
		date__lte: urlDateTo
	}
	
	const exportRequestProps = {
		search: urlSearchQuery,
		user__account__keycloak_id__in: urlUser,
		type__in: urlType,
    device__serial_number__in: urlDevice,
		date__gte: urlDateFrom,
		date__lte: urlDateTo
	}
	
	const {data: calls, getData, isReady} = useFetchStates(
		() => servicesAPI.getCalls(requestProps),
		{initData: {results: []}})
	
    const audioArray = useMemo(() => {
      return calls?.results.map((call) =>  (call.normalized_audio || call.audio) && (
       { fileName: `${translateThisBullshit(call.contact_name)
           }_${callTypes[call.type][0]
           }_${call.contact_phone}_${
             new Date(call.date)
               .toLocaleString()
               .replaceAll('.', '-')
               .replaceAll(' ', '_')
               .replaceAll(',', '')
               .replaceAll(':', '-')
           }_${call.duration.replaceAll(':', '-')}`,
           audio: call.normalized_audio || call.audio
       })).filter((call) => call !== null)
     }, [calls])

	const onSaveExport = (exportData) => {
		exportExcel(exportData)
		exportStates.clearIncludedIDS()
	}
	
	const {
		ButtonToggleExport,
		CounterToExport,
		ButtonSaveExport,
		checkBoxesColl,
		...exportStates
	} = useExportData({
		requestMethod: servicesAPI.getCalls,
		requestParams: exportRequestProps,
		pageData: calls.results,
		onSaveExport
	})
	
	useUpdateEffect(async () => {
		await getData()
		playerStates.setBadLinks([])
	}, [urlPage, urlSortField, urlIsExportMode])
	
	useUpdateEffect(async () => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		await getData()
		playerStates.setBadLinks([])
	}, [urlLimit, urlSearchQuery, urlUser, urlType, urlDevice, urlDateFrom, urlDateTo])

	const {tableOptions, tableHeads} = useMyTable(
		callsHistoryTable.getTableCallsHistory({
			playerStates,
			playerProps,
			checkBoxesColl,
			isEmptySearchQuery,
			isExportMode: getBoolFromString(urlIsExportMode),
      fullScreenPlaying,
      setFullScreenPlayerState,
      setAudioName
		}), {
			memoDependencies: [
				playingCallURL, badLinks,
				isEmptySearchQuery, loadedLinks,
				exportStates.includedIDSCount,
				exportStates.isReady,
        fullScreenPlaying
			]
		})
  
  useUpdateEffect(() => {
    playerProps.setPlayingCallURL(false)
  }, [urlPage]);

	const title = urlIsExportMode ? 'Экспорт звонков' : 'История звонков'
	
	const showEmptyFoundDataMessage =
		!isEmptySearchQuery || urlUser || urlType || urlDateFrom || urlDateTo
	
	return (
		<main className={`${loader(exportStates.isReady)} position-relative`} 
          style={{ marginBottom: '120px'}}>
			{!urlIsExportMode &&
			<ButtonGoBack className={'float-right ml-1'}/>}
			
			<ButtonToggleExport className={'float-right'}/>
			
			<Head >{title}</Head>
			
			<FiltersPageCallsHistory/>
			
			<CounterToExport />
			
			<MyTable renderCardContainer={urlIsExportMode}
			         appendElement={<ButtonSaveExport/>}
			         showEmptyFoundDataMessage={showEmptyFoundDataMessage}
			         isDataReady={isReady}
			         paginationAllCount={calls?.count}
               withScroll
      >
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody getRowClassName={(row) => toggle(row?.is_success === false, 'bg-rgba-danger-light')}
				             data={calls?.results}
				             tableOptions={tableOptions}
				             className={loader(isReady)}
				             highestRowToFill={40.5}
                     rowClassName={'showInHover'}
				/>
			</MyTable>

      {playingCallURL && 
        <div  className='position-fixed container p-0' 
              style={{bottom: '0', zIndex: '4'}}>
	        
          <AudioPlayerFullScreen 
              url={playingCallURL} playingCallURL={playingCallURL}
              setFullScreenPlayerState={setFullScreenPlayerState} 
              fullScreenPlaying={fullScreenPlaying} 
              fullScreenPlayed={fullScreenPlayed} 
              fullScreenSeeking={fullScreenSeeking} 
              fullScreenDuration={fullScreenDuration} 
              fullScreenBadURL={fullScreenBadURL} 
              fullScreenIsReady={fullScreenIsReady}
              volume={volume}  audioName={audioName}
              setAudioName={setAudioName}
              audioArray={audioArray}
              {...playerProps}/>
        </div>
      }
		</main>
	)
})
