import { Link } from "react-router-dom"
import { Card } from "../../../../../components/UI/Card"
import { Head } from "../../../../../components/UI/Head"
import { getLocaleFullDate, getShortFullNameFromObj, isEmpty, loader } from "../../../../../js-helpers/helpers"

export const PageDeviceSetupInfo = ({className = '', isReady, setupInfo}) => {
  return (
    <Card secondary className={`${className} ${loader(isReady)}`}>
      <Head secondary>Настройка устройства</Head>
        {isEmpty(setupInfo) ? 
          <span>Устройство не настроенo</span>
          : <Card>
              <h3 className={'font-small-3 font-weight-bold text-body mb-1'}>
                Администратор: {' '}
                {getShortFullNameFromObj(setupInfo?.admin)}
              </h3>
              <div className='text-muted font-small-3'>
              Дата и время настройки:
              <br/>
              {getLocaleFullDate(setupInfo?.created_at)}
            </div>
          </Card>
        }
    </Card>
  )
}