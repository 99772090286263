import {useFetchStates} from '../../../../hooks/useFetchStates';
import {deviceAPI} from '../../../../API/deviceAPI';
import {Card} from '../../../../components/UI/Card';
import {getLocaleFullDate, getShortFullNameFromObj, isEmpty, loader} from '../../../../js-helpers/helpers';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';

export function UserInfoDeviceCard({deviceSN, className = ''}) {
	const deviceStates = useFetchStates(
		() => deviceAPI.getOneDevice(deviceSN),
		{noInitFetch: true}
	)
	
	const {
		brand,
		model,
		system_version,
		api_level,
		launcher_version_code,
		launcher_version_name,
		serial_number,
		setup
	} = deviceStates.data || {};
	
	useEffect(() => {
		if (deviceSN === null) {
			deviceStates.setReady(true)
			return
		}
		deviceSN &&
		deviceStates.getData(deviceSN)
	}, [deviceSN])
	
	return (
		<Card
			secondary
			title={'Информация об устройстве'}
			className={`${loader(deviceStates.isReady)} ${className}`}
		>
			<ul className={'list-unstyled'}>
				{isEmpty(deviceSN) ? (
					<span>Не привязано</span>
				) : (
					<>
						<Card as={'li'} size={1} className={'mt-1'}>
							<h3 className={'font-small-3 mb-1'}>Об устройстве</h3>
							<span className={'font-weight-bold'}>
			                {brand} {model}
			              </span>
							<div className={'font-weight-light mt-50'}>
								{`Android ver. ${system_version}, API Level ${api_level}`}
							</div>
						</Card>
						
						<Card as={'li'} size={1} className={'mt-1'}>
							<h3 className={'font-small-3 mb-1'}>
								Серийный номер и версия прошивки
							</h3>
							<div className={'font-weight-bold mb-50 '}>
								<Link to={`/admins/devices/${serial_number}/info/`}>
									{serial_number}
								</Link>
							</div>
							<div className={'font-weight-light'}>
								Версия: {`${launcher_version_name} (${launcher_version_code})`}
							</div>
						</Card>
						
						<Card as={'li'} size={1} className={`${loader(deviceStates.isReady)} mt-1`}>
							<h3 className={'font-small-3 mb-1'}>
								Настройка устройства
							</h3>
							
							{setup
								? <>
									<div className={'font-weight-bold mb-50 '}>
										<span className={'font-weight-bold'}>Администратор: </span>
										{getShortFullNameFromObj(setup?.admin)}
									</div>
									
									<div className={'font-weight-light'}>
										Дата и время настройки:
										<br/>
										{getLocaleFullDate(setup?.created_at)}
									</div>
								</>
								: 'Устройство не настроено'
							}
						
						</Card>
					</>
				)}
			</ul>
		</Card>
	);
}
