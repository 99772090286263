import { useMountEffect, useUpdateEffect } from "@react-hookz/web"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { groupsAPI } from "../../../../API/groupsAPI"
import { userAPI } from "../../../../API/userAPI"
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { DeleteItemModal } from "../../../../components/modals/DeleteItemModal"
import { MyTable } from "../../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../../components/table/MyTable/MyTableHead"
import { useMyTable } from "../../../../components/table/MyTable/useMyTable"
import { Card } from "../../../../components/UI/Card"
import { Head } from "../../../../components/UI/Head"
import { useComboSearch } from "../../../../hooks/url/useComboSearch"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import { getBoolFromString, getFullNameFromObj, getLocaleFullDate, isEmpty, isEqualArrays, isNotEmpty, loader } from "../../../../js-helpers/helpers"
import { useExportData } from "../../../../services/export/useExportData"
import { loggedAdmin } from "../../../../stores/loggedAdmin"
import { FiltersPageUsers } from "../../../members/pageUsers/FilterPageUsers/FiltersPageUsers"
import { prepareItem } from "../../../members/pageUsers/PageUsers"
import { pageUsersConfig } from "../../../members/pageUsers/pageUsersTables"

const {editGroupSearchParams, getTable} = pageUsersConfig
export const titlesStyles = 'col-2 m-0 font-weight-bold font-small-2 text-uppercase p-0 pt-50'

export const PageGroupsItem = () => {
  const navigate = useNavigate()
  const [
		[urlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlIsExportMode, setIsExportMode],
		[urlDivision],
		[urlSubDivision], 
    ,
    [urlBlocked],	
    [urlHaveDevice], 
    ,
    [urlUser], 
    [urlDevice],
    [urlInGroup]
  ] = useComboSearch(editGroupSearchParams)

  const {groupID} = useParams()
  const [groupTitle, setGroupTitle] = useState('')
  const [groupData, setGroupData] = useState({author: '', created_at: ''})
  const [groupCheckedUsers, setGroupCheckedUsers] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)

  const isSuperAdmin = useMemo(() => loggedAdmin.isSuperAdmin, [loggedAdmin])

  const {data: group, isReady: groupIsReady, getData: groupGetData} = useFetchStates(() => groupsAPI.getItem(groupID))

  useEffect(() => {
    if (groupIsReady) {
      setGroupTitle(group?.title)
      setGroupData({
        author: getFullNameFromObj(group?.created_by), 
        created_at: getLocaleFullDate(group?.created_at)
      })
    }
  }, [group, groupIsReady])

  const urlParamsRequestQuery = {
		search: urlSearchQuery,
    ever_have_device: getBoolFromString(urlHaveDevice),
    account__is_active: getBoolFromString(urlBlocked, {invert: true}),
		account__division__in: urlDivision,
		account__subdivision__in: urlSubDivision,
    account__keycloak_id__in: urlUser,
    device2__serial_number__in: urlDevice,
    user_group: groupID,
    is_in_user_group: getBoolFromString(urlInGroup)
	}
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		...urlParamsRequestQuery
	}

  const {data: users, isReady: usersIsReady, getData: usersGetData} = useFetchStates(
		() => userAPI.getAllUsersWithCustomParams({...requestQuery}, {allowMultipleRequests: true}),
		{noInitFetch: true, initData: {results: []}})

  const {data: usersChecked, isReady: usersCheckedIsReady, getData: usersCheckedGetData} = useFetchStates(
      () => userAPI.getAllUsersWithCustomParams(
        {page: 1, page_size: 999, user_group: groupID, is_in_user_group: true}, 
        {allowMultipleRequests: true}),
      {noInitFetch: true, initData: {results: []}})


  const prepareUsers = useMemo(() => users.results.map(prepareItem), [users])

  useEffect(() => {
    usersCheckedGetData()
  }, [])

  useEffect(() => {
		usersGetData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		usersGetData(1)
	}, [
		urlSearchQuery, urlLimit, urlDivision, urlHaveDevice,
		urlSubDivision, urlUser, urlDevice, urlBlocked, urlInGroup
	])

  const onEditGroup = async (usersData) => {  
    const usersList = [...new Proxy(exportStates.getIcludedIDSArray(), {
      get(target, prop, receiver) {
        return target[prop];
      }
    })]

    const submitData = {}
    if (groupTitle !== group?.title) {
      submitData.title = groupTitle
    }

    if (!isEqualArrays(usersList, groupCheckedUsers)) {
      submitData.users_ids = usersList
    }

    if (isNotEmpty(submitData)) {
      try {
        await groupsAPI.editItem(groupID, submitData)

        toast.success('Группа обновлена!', {autoClose: 5000})

        groupGetData()
        usersCheckedGetData()
      } catch (e) {
        if (e?.response?.data?.errors?.[0]?.attr === 'title') {
          if (e?.response?.data?.errors?.[0]?.code === 'unique') {
            toast.error(
            <>Группа с названием <b>{submitData.title}</b> уже существует!</>, {autoClose: 5000})
          } else if (e?.response?.data?.errors?.[0]?.code === 'blank') {
            toast.error('Введите название группы!', {autoClose: 5000})
          }
        }
        else {
          toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
        }
      }
    }
  }

  const {
      ButtonToggleExport,
      CounterToExport,
      ButtonSaveExport,
      checkBoxesColl,
      ...exportStates
    } = useExportData({
      requestMethod: userAPI.getAllUsersWithCustomParams,
      requestParams: urlParamsRequestQuery,
      prepareItemFunc: prepareItem,
      pageData: prepareUsers,
      onSaveExport: onEditGroup
  })

  useMountEffect(() => {
    exportStates.setMode('create')
  })
  useEffect(() => {
    exportStates.setOnSaveExport(onEditGroup)
  }, [groupTitle])

  useEffect(() => {
    // if (usersIsReady && usersCheckedIsReady) {
      const groupUsers = usersChecked?.results
        ?.filter((user) => user.is_in_user_group)
        .map(({account}) => account.keycloak_id)

      setGroupCheckedUsers(groupUsers)
      exportStates.setIncludedIDS(groupUsers)
    // }
  }, [/*usersIsReady, usersCheckedIsReady, */usersChecked])

  const isEmptySearchQuery = isEmpty(urlSearchQuery)

  const tableOptions = useMemo(() =>
    getTable(isEmptySearchQuery, true, checkBoxesColl, true, !isSuperAdmin),
  [isEmptySearchQuery, isSuperAdmin])

  const {tableHeads, tableColgroup} = useMyTable(tableOptions)

  const closeModal = () => {
    setIsShowModal(false)
  }

  const onDeleteGroup = async (id) => {
    try {
      await groupsAPI.deleteItem(id);
      navigate('/admins/groups')
      toast.success(`Группа "${groupTitle}" удалена`);
    } catch (e) {
      toast.error('Ошибка удаления правила');
    } finally {
      closeModal();
    }
  }

  const isInValid = useMemo(() =>  
    groupTitle.length > 150 || (groupTitle.length > 0 && groupTitle.trim()?.length === 0) || groupIsReady && groupTitle.length === 0, 
  [groupTitle, groupIsReady])


  const isDisabled = useMemo(() => {
     const usersList =  [...new Proxy(exportStates.getIcludedIDSArray(), {
      get(target, prop, receiver) {
        return target[prop];
      }
    })]

    if (isInValid) return true
    if (isEmpty(usersList)) return true
    // if (isEqualArrays(groupCheckedUsers, usersList) && groupTitle === group?.title) return true 

    return false
   
  }, [groupTitle, group, isInValid, groupCheckedUsers, exportStates])  

  const showEmptyFoundDataMessage = !isEmptySearchQuery || urlDivision ||
  urlSubDivision || urlUser || urlDevice || urlBlocked || urlInGroup

  return (
    <>
      <ButtonGoBack className={'float-right'}/>
      <Head children={`${isSuperAdmin ? 'Редактирование' : 'Просмотр'} группы`}/>
   
      <Card secondary className={'mb-50'}>
        <h3 className={titlesStyles}>
          Название группы
        </h3>

        <input  value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
                className={`form-control col-6 mb-50 ${isInValid ? 'is-invalid' : ''}`}
                disabled={!isSuperAdmin}/>

        {(isInValid) && <div className='mt-50 text-danger font-small-1'>{groupTitle.length === 0  || groupTitle.length !== 0 && groupTitle?.trim()?.length === 0 ? 'Введите название группы' : 'Максимальная длина - 150 символов'}</div>}

        <h3 className={titlesStyles}>
          Автор создания группы
        </h3>

        <input  value={groupData.author}
                className={'form-control col-6 mb-50'}
                disabled/>

        <h3 className={titlesStyles}>
          Дата создания группы
        </h3>

        <input  value={groupData.created_at}
                className={'form-control col-6'}
                disabled/>
      </Card>

      <FiltersPageUsers page={'groups'} inGroup={true}/>

      <CounterToExport isCreateMode={true}/>

      <MyTable  colgroup={tableColgroup}
                renderCardContainer={true}
                withScroll
                appendElement={isSuperAdmin ?
                  <div className={'d-flex'}>
                    <ButtonSaveExport mode={'update'} 
                                      isDisabled={isDisabled}/>
                    <DeleteButton showModal={()=> setIsShowModal(true)} isDisable={false}/>
                  </div> : <></>}
                showEmptyFoundDataMessage={showEmptyFoundDataMessage}
                isDataReady={usersCheckedIsReady && usersIsReady}
                paginationAllCount={users?.count}>
      
        <MyTableHead heads={tableHeads}/>
      
        <MyTableBody className={loader(usersCheckedIsReady && usersIsReady)}
                     data={prepareUsers}
                     tableOptions={tableOptions}
                     highestRowToFill={45}
        />
      </MyTable>

      {isShowModal &&  <DeleteItemModal  title={groupTitle}
                          onClickAbort={closeModal}
                          onClickDelete={() => onDeleteGroup(groupID)}
                          titleText={'группу'}
        />}
    </>
  )
}

const DeleteButton = ({showModal, isDisable}) => {
  return (
    <button  onClick={showModal}
                  className={'btn btn-outline-primary bg-white ml-1'}
                  disabled={isDisable}>
      Удалить
    </button>
  )
}