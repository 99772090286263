import axios from 'axios'
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions'

export const notificationsAPI = {
	getAll: (page = 1, search, ordering, page_size) =>
		axios('/notifications/',
			setPreparedAxiosParams({page, search, ordering, page_size})),
  
  getAllToExport() {
    return axios('/notifications/', setPreparedAxiosParams({...arguments[0]}))
  },
	
	createNewNotification: (data) => axios.post('/notifications/', data),
	
	getOneItem: (id) => axios.get(`/notifications/${id}/`),
	
	getOneItemStatuses: (id, page = 1, search, page_size) =>
		axios(`/notifications/${id}/devices/`,
			setPreparedAxiosParams({page, search, page_size})),
  
  getP2PAll: (page = 1, search, ordering, page_size, user__account__keycloak_id__in = null, notify_users__account__keycloak_id__in = null, created_at__gte = null, created_at__lte = null, by_user = null) =>
  axios('/notifications-p2p/',
    setPreparedAxiosParams({page, search, ordering, page_size, user__account__keycloak_id__in, notify_users__account__keycloak_id__in, created_at__gte, created_at__lte, by_user})),

  getP2PAllToExport() {
    return axios('/notifications-p2p/', setPreparedAxiosParams({...arguments[0]}))
  },

  getP2POneItem: (id) => axios(`/notifications-p2p/${id}/`),

  getP2POneItemStatuses: (id, page = 1, search, page_size) => axios(`/notifications-p2p/${id}/users/`, setPreparedAxiosParams({page, search, page_size})),

  getP2PAvailableRecipients: (user, page, search, page_size) => axios('/notifications-p2p/available-recipients/', setPreparedAxiosParams({user, page, search, page_size})),

  getP2PAllRules: (page = 1, search, ordering, page_size) =>
  axios('/notifications-p2p/rules/',
    setPreparedAxiosParams({page, search, ordering, page_size})),

  createP2PRule: (data) =>
  axios.post('/notifications-p2p/rules/', data),

  getOneP2PRule: (id) => axios(`/notifications-p2p/rules/${id}/`),
  
  patchP2PTRule: (id, data) => axios.patch(`/notifications-p2p/rules/${id}/`, data),

  deleteP2PRule: (id) => axios.delete(`/notifications-p2p/rules/${id}/`),
  
  getDefaultP2PRule: () => axios('/notifications-p2p/rules/default/'),

  patchDefaultP2PRule: (data) => axios.patch('/notifications-p2p/rules/default/', data),
}