import {Head} from '../../../../../components/UI/Head';
import {Card} from '../../../../../components/UI/Card';
import {Button} from '../../../../../components/button/Button';
import React, {useCallback, useEffect, useState} from 'react';
import useRerender from '../../../../../hooks/useRerender';
import {useNavigate, useParams} from 'react-router-dom';
import {contactsAPI} from '../../../../../API/contactsAPI';
import {toast} from 'react-toastify';
import {ButtonGoBack} from '../../../../../components/button/ButtonGoBack';
import {askToastDelete} from '../../../../../services/toasts/toasts';
import {capitalize, isEmpty, loader} from '../../../../../js-helpers/helpers';
import {useFetchStates} from '../../../../../hooks/useFetchStates';
import {NameAndInfoRule} from './NameAndInfoRule';
import {RuleAndStatusSelectors} from './RuleAndStatusSelectors';
import {ExceptionsLists} from '../../../../../components/listsAccess/ExceptionsLists';
import { useMountEffect, useUpdateEffect } from '@react-hookz/web';

const initEmptyRule = {id: 0, name: '-'}

const restrictions = [
	{status: 1, selectItem: 'не виден для пользователей'},
	{status: 2, selectItem: 'виден для пользователей'},
]

export const PageRule = ({edit}) => {
	const navigate = useNavigate()
	const [statusKey, updateStatus] = useRerender()
	const [rulesKey, resetRulesList] = useRerender()
	const {ruleID} = useParams()
	
	const [valueName, setValueName] = useState('')
	const [infoRule, setInfoRule] = useState({})
	
	const [rule, setRule] = useState(null)
	const [status, setStatus] = useState(2)
	
	const [exceptions, setExceptions] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isActiveSaveBtn, setIsActiveSaveBtn] = useState(false)

  const allRules = useFetchStates(contactsAPI.getAllRules,
		{
			initData: [initEmptyRule],
			customSetter: ({data}) => {
				allRules.setData([initEmptyRule, ...data.results])
				allRules.setReady(true)
				updateStatus()
			}
		})
	
	useEffect(() => {
		if (!rule && !ruleID)
			return
		setIsLoading(true)
		contactsAPI.getRule(rule || ruleID)
			.then(({data: {status, except_users, name, admin_owner, creation_date}}) => {
				status && setStatus(status)

				setExceptions(except_users)
				updateStatus()
				
				if (ruleID && typeof infoRule['name'] === 'undefined') {
					setValueName(name)
					setInfoRule({admin: admin_owner, name, date: creation_date})
				}
				setIsLoading(false)
        if (rule === ruleID || !rule) setIsActiveSaveBtn(false)
			})
	}, [rule, ruleID])
		
	const onSubmitRule = async () => {
		const exceptionsUsersIDS = exceptions.map((item) => item.keycloak_id)
		
		if (isEmpty(exceptionsUsersIDS)) {
			toast.error('Выберите пользователей для добавления в правило')
			return
		}
		if (isEmpty(valueName)) {
			toast.error('Введите название правила')
			return
		}
		
		setIsLoading(true)
		
		const requestData = {
			name: valueName,
			status,
			except_users: exceptionsUsersIDS
		}
		
		try {
			if (edit) {
				await contactsAPI.patchRule(ruleID, requestData)
				toast.success('Изменения сохранены!')
			} else {
				await contactsAPI.postRule(requestData)
				toast.success(`Создано новое правило "${requestData.name}"!`)
			}
			navigate(-1)
			
		} catch ({response}) {
			if (response?.data?.errors?.[0]?.attr === 'name') {
				toast.error(`Правило с названием "${valueName}" уже существует!`)
				return
			}
			toast.error('Ошибка сохранения правила!')
			
		} finally {
			setIsLoading(false)
		}
	}
	
	const deleteRule = ({target}) => {
		target.disabled = true
		
		askToastDelete({
			callMethod: () => contactsAPI.deleteRule(ruleID),
			askText: 'Правило ' + infoRule.name,
			errorText: `Ошибка удаления правила "${infoRule.name}"`,
			successText: `Правило ${infoRule.name} успешно удалено!`
		}).then(() => {
			navigate(-1)
		}).catch(e => {
			e?.response?.data?.errors?.[0]?.detail && toast.error(e?.response?.data?.errors?.[0]?.detail)
		}).finally(() => {
			target.disabled = false
		})
	}
	
	const onRestrictionsSelect = (e) => {
		!rule && setRule(null)
		setStatus(e.status)

    // setIsActiveSaveBtn(false)
		!rule && resetRulesList()
    // rule ? setIsActiveSaveBtn(false) : setIsActiveSaveBtn(true)
	}
	
	const onRuleSelect = (item) => {
		setRule(item.value)
		updateStatus()
    setIsActiveSaveBtn(true)
	}
	
	let headExceptions = restrictions.find(item => item.status === status).selectItem

  useUpdateEffect(() => {
    setIsActiveSaveBtn(true)
  }, [exceptions, status, rule])

	return (
		<>
			<ButtonGoBack className={'float-right ml-1'}/>
			
			<Head>
				{edit ? `Редактирование правила "${infoRule?.name}"` : 'Создание правила'}
			</Head>
			
			<Card className={'p-2'}>
				<Card className={'p-2'} secondary>
					
					<NameAndInfoRule valueName={valueName}
					                 setIsActiveSaveBtn={setIsActiveSaveBtn}
					                 setValueName={setValueName}
					                 edit={edit}
					                 infoRule={infoRule}/>
					
					<hr/>
					
					<RuleAndStatusSelectors rerenderKey={statusKey}
					                        key={rulesKey}
					                        onRuleSelect={onRuleSelect}
					                        className={loader(allRules.isReady)}
					                        rules={allRules.data}
					                        restrictions={restrictions}
					                        onRestrictionsSelect={onRestrictionsSelect}
					                        status={status}/>
					
					<ExceptionsLists background={'white'}
					                 exceptions={exceptions}
					                 type={'users'}
					                 setExceptions={setExceptions}
					                 className={`mb-1 ${loader(!isLoading)}`}
					                 onItemClick={() => {
						                 resetRulesList()
					                 }}
					                 headExceptions={`${capitalize(headExceptions)}: ${exceptions.length}`}
					/>
					
					{edit &&
					<Button remove className={'float-left'} onClick={deleteRule}/>
					}
					
					<Button className={'d-block ml-auto'}
					        disabled={!isActiveSaveBtn}
					        save
					        onClick={onSubmitRule}/>
				</Card>
			</Card>
		</>
	)
}