import React from 'react';
import {toggle} from '../js-helpers/helpers';

const {NODE_ENV, REACT_APP_BRANCH, REACT_APP_VERSION} = process.env
const environment = toggle(NODE_ENV === 'development' || REACT_APP_BRANCH === 'dev', '-dev')

const appVersion = `${REACT_APP_VERSION}${environment}`

export function AppVersion({className}) {
	return <span className={className}>{appVersion}</span>
}