import { createErrorInfo } from "./createErrorInfo"

export const ErrorInfoFields = ({ error }) => {
  const fieldsError = createErrorInfo(error)

  return (
      <div className={'col-10 p-0 text-break'}>
        {fieldsError.map((item, index) => (
          <p key={index} className={'mb-25'}>
          {item[0]}: {item[1]}
        </p>
        ))}
      </div>
    
  )
}