import { Editor } from 'react-draft-wysiwyg';
import { titlesStyles } from '../../pageGroups/PageGroupsItem/PageGroupsItem';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useMemo } from 'react';

export const ChangelogItem = ({ bodyValue, setBodyValue, changeLogLength, isDisabled }) => {
  const isError = useMemo(
    () =>
      changeLogLength !== 0
        ? changeLogLength > 5000 //|| changeLogLength < 10
        : false,
    [changeLogLength]
  );

  return (
    <div className="d-flex pt-1 pb-4 border-bottom-extra-light">
      <h3 className={titlesStyles}>Список изменений (Changelog)</h3>

      <Editor
        readOnly={isDisabled}
        editorState={bodyValue}
        toolbarClassName={`toolbarClassName mb-0 form-control ${isDisabled ? 'disabled bg-disabled' : ''}`}
        wrapperClassName={`wrapperClassName col-5 px-0 ${isDisabled ? 'disabled' : ''}`}
        editorClassName={`form-control overflow-hidden  editorClassName ${
          isError ? 'is-invalid' : ''
        } ${isDisabled ? 'disabled bg-disabled' : ''}`}
        toolbar={{
          options: ['list'],
          list: {
            inDropdown: false,
            options: ['unordered'],
          },
        }}
        onEditorStateChange={(data) => {
          // setBodyLength(data.getCurrentContent().getPlainText('\u0001').length)

          setBodyValue(data);
        }}
      />
    </div>
  );
};
