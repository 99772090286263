import { Link } from 'react-router-dom';
import {
  getFullNameFromObj,
  getLocaleFullDate,
  getShortFullNameFromObj,
} from '../../../../js-helpers/helpers';
import { getElemText, WithTooltip } from '../../../UI/WithTooltip';
import { ReactComponent as EditSVG } from '../../../../assets/icons/services/edit.svg';
import { ButtonWithIcon } from '../../../button/ButtonWithIcon';

const idPersonal = (isEmptySearchQuery, type) => ({
  head: 'ID',
  cell: (uuid) => {
    const link = `/admins/p2p-notifications/${uuid}/?type=${type}`
    return isEmptySearchQuery ? (
      <WithTooltip
        elem={
          <Link to={link}>
            {uuid?.split('-')[0] + '...'}
          </Link>
        }
        showAbove={0}
        tooltips={[<Link to={link}>{uuid}</Link>]}
      />
    ) : (
      <Link to={link}>{uuid}</Link>
    )},
  col: { width: 80 },
  nestedCellField: 'uuid',
})

const idCommonCol = (isEmptySearchQuery, type) => ({
  head: 'ID',
  cell: (uuid) => {
    const link = `/admins/notifications/${uuid}/?type=${type}`

    return isEmptySearchQuery ? (
      <WithTooltip
        elem={
          <Link to={link}>
            {uuid?.split('-')[0] + '...'}
          </Link>
        }
        showAbove={0}
        tooltips={[<Link to={link}>{uuid}</Link>]}
      />
    ) : (
      <Link to={link}>{uuid}</Link>
    )},
  col: { width: 80 },
  nestedCellField: 'uuid',
});

const titleCol = {
  head: 'Заголовок',
  cell: (title) =>
      <WithTooltip
        elem={getElemText(title, 15)}
        tooltips={title?.length > 15 ? [title] : []}
      />,
  col: { width: 115 },
  nestedCellField: 'title',
};

const titleWithSortCol ={
  head: 'Заголовок',
  cell: (title) =>
      <WithTooltip
        elem={getElemText(title, 15)}
        tooltips={title?.length > 15 ? [title] : []}
      />,
  col: { width: 115 },
  nestedCellField: 'title',
  sortField: 'title'
};

const senderCol = (isEmptySearchQuery) => ({
  head: 'Отправитель',
  cell: (user) =>
    user ? (
      <Link to={`/admins/users/${user?.keycloak_id}/info/`}>
        {isEmptySearchQuery
          ? getShortFullNameFromObj(user)
          : getFullNameFromObj(user)}
      </Link>
    ) : (
      '-'
    ),

  col: { width: 190 },
  nestedCellField: 'user',
})

const receiverCol = () => ({
    head: 'Получатель',
    cell: (data) => 
      data?.notify_users ? (
        data?.notify_users?.length > 1 ? data?.notify_users?.length :  <Link to={`/admins/users/${data?.notify_users?.[0]?.keycloak_id}/info/`}>{getShortFullNameFromObj(data?.notify_users[0])}</Link>
      ) : (
        '-'
      ),
    col: { width: 190 },
})

export const columnsNotifications = {
  id: {
    head: 'ID',
    cell: (uuid) => uuid,
    nestedCellField: 'uuid',
  },
  title: {
    head: 'Заголовок',
    cell: (title) => title ? title : '-',
    nestedCellField: 'title',
  },
  clearText: {
    head: 'Текст уведомления',
    cell: (body) => body ? body : '-',
    col: { width: 175 },
    nestedCellField: 'body',
  },
  text: {
    head: 'Текст уведомления',
    cell: (body) => (
      <WithTooltip
        elem={getElemText(body, 27)}
        tooltips={body?.length > 27 ? [body] : []}
      />
    ),
    col: { width: 175 },
    nestedCellField: 'body',
  },
  textWithSort: {
    head: 'Текст уведомления',
    cell: (body) => (
      <WithTooltip
        elem={getElemText(body, 27)}
        tooltips={body?.length > 27 ? [body] : []}
      />
    ),
    col: { width: 175 },
    nestedCellField: 'body',
    sortField: 'body'
  },
  clearDate: {
    head: 'Дата и время отправки',
    cell: getLocaleFullDate,
    nestedCellField: 'created_at',
  },
  date: {
    head: 'Дата и время отправки',
    col: { width: 145 },
    cell: (date) => (
      <div style={{ maxWidth: '85px' }}>{getLocaleFullDate(date)}</div>
    ),
    nestedCellField: 'created_at',
  },
  dateWithSort: {
    head: 'Дата и время отправки',
    col: { width: 145 },
    cell: (date) => (
      <div style={{ maxWidth: '85px' }}>{getLocaleFullDate(date)}</div>
    ),
    nestedCellField: 'created_at',
    sortField: 'created_at'
  },
  sendCount: {
    head: 'Отправлено',
    cell: (count) => count,
    col: { width: 80 },
    nestedCellField: 'notify_devices_count',
  },
  receiveCount: {
    head: 'Получено',
    cell: (count) => count,
    col: { width: 80 },
    nestedCellField: 'viewed_on_devices_count',
  },
  admin: {
    head: 'Администратор',
    cell: getFullNameFromObj,
    col: { width: 180 },
    nestedCellField: 'admin',
  },
  sender: {
    head: 'Отправитель',
    cell: getFullNameFromObj,
    nestedCellField: 'user',
  },
  receiver: {
    head: 'Получатель',
    cell: (notify_users) => 
      notify_users ? (
        notify_users?.length > 1 ? notify_users?.length :  getFullNameFromObj(notify_users[0])
      ) : (
        '-'
      ),
    nestedCellField: 'notify_users',
    col: { width: 190 },
  }
}

export const getNotificationsTableOptions = (isEmptySearchQuery, type, deleteRule) => {
  const types = {
    admin: [
      idCommonCol(isEmptySearchQuery, type),
      titleCol,
      columnsNotifications.text,
      columnsNotifications.sendCount,
      columnsNotifications.receiveCount,
      columnsNotifications.admin,
      columnsNotifications.date,
    ],
    user: [
      idCommonCol(isEmptySearchQuery, type),
      titleWithSortCol,
      columnsNotifications.textWithSort,
      senderCol(isEmptySearchQuery),
      receiverCol(isEmptySearchQuery),
      columnsNotifications.dateWithSort,
    ],
    user_personal: [
      idPersonal(isEmptySearchQuery, type),
      titleWithSortCol,
      columnsNotifications.textWithSort,
      senderCol(isEmptySearchQuery),
      receiverCol(isEmptySearchQuery),
      columnsNotifications.dateWithSort,
    ],
    rules: [
      {
        head: 'Название правила',
        cell: (title) => title,
        nestedCellField: 'title',
      },
      {
        head: 'Автор правила',
        cell: getShortFullNameFromObj,
        nestedCellField: 'admin_profile'
      },
      {
        head: 'Дата создания',
        cell: getLocaleFullDate,
        nestedCellField: 'created_at'
      },
      {
        head: 'Действия',
        cell: (data) => (<div className='d-flex align-items-center ml-auto'>
							
        <Link to={`/admins/notifications/rules/${data?.id}/`} className={'c-hover-opacity min-c-touch-zone'}>
          <EditSVG style={{marginTop: '3px'}}/>
        </Link>

        <ButtonWithIcon type={'delete'}
                        onClick={() => deleteRule(data?.id, data?.title)}
                        className={'ml-2'}/>
      </div>),
    
      }
    ]
  };

  return types;
};
