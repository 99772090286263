import {isEmpty, isNotEmpty} from '../../js-helpers/helpers';
import {getExtraFullNameOrdering} from '../../API/api_helpers';

export const setPreparedAxiosParams = (params) => {
	const preparedParams = new URLSearchParams();
	
	Object.entries(params).forEach(([key, value]) => {
		if (isEmpty(value)) {
			return
		}
	
		if (Array.isArray(value)) {
			value.forEach((item) => isNotEmpty(item) && preparedParams.append(key, item))
			return
		}
		
		if (key === 'ordering')
			value = getExtraFullNameOrdering(value)
		
		preparedParams.append(key, value);
	})
	
	return ({params: preparedParams})
}

export const setAxiosApiVersion = (apiVersion) => ({apiVersion})

