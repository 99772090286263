import {Head} from '../UI/Head';
import {ReactComponent as SearchIcon} from './search2.svg';
import {loader} from '../../js-helpers/helpers';

export const EmptyFoundDataMessage = ({className = '', isDataReady = true}) => {
	
	return (
		<div
			className={`rounded-lg mx-auto text-center py-2 px-4 text-gray-300 ${className} ${loader(isDataReady)}`}
			style={{marginTop: '10vh', maxWidth: '750px'}}
		>
			<SearchIcon className={/*'svg-curr-color-fill,*/ 'mb-2'}/>
			
			{/*<Head>{isDataReady ? 'По Вашему запросу ничего не найдено' : 'Запрос данных ...'}</Head>*/}
			<Head>{'По Вашему запросу ничего не найдено'}</Head>
		</div>
	)
}