import {useLocation, useNavigate} from 'react-router-dom';
import PortalModal from '../PortalModal';
import React from 'react';
import { SwiperPhoto } from '../../pages/members/pageUsers/SwiperPhoto';

const excludeOnCloseClassNames = ['swiper-pagination', 'swiper-button-next', 'swiper-button-prev', 'js-no-close']

export const ModalSwiper = ({photos, isOpen, type}) => {
	const {pathname, search, hash} = useLocation()
	const navigate = useNavigate()
	
	return (
		<PortalModal
			className={'modal-c-bg zindex-1'}
			isOpen={isOpen}
			excludeOnCloseClassNames={excludeOnCloseClassNames}
			onClose={() => { navigate({pathname, search}, {replace: true}) }}
		>
			
			<SwiperPhoto photo={photos} idPhotoSwiper={hash} type={type}/>
			
		</PortalModal>
	)
}