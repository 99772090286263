// import { adminPermissions } from "../../../constants/adminPermissions";

import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack";
import { Head } from "../../../../components/UI/Head";
import { NavSubPages } from "../../../../components/UI/NavSubPages";
import { adminPermissions } from "../../../../constants/adminPermissions";
import { isEmpty } from "../../../../js-helpers/helpers";
import { loggedAdmin } from "../../../../stores/loggedAdmin";

const {
  DEVICES_GENERAL,
  DEVICES_CALLS,
  DEVICES_LOGS,
  DEVICES_SCREENSHOTS,
  DEVICES_SETTINGS,
} = adminPermissions;

const links = [
  { to: 'info/', text: 'Общая информация', permission: DEVICES_GENERAL },
  { to: 'calls/', text: 'Звонки', permission: DEVICES_CALLS },
  { to: 'logs/', text: 'Логи', permission: DEVICES_LOGS },
  { to: 'media/', text: 'Скриншоты', permission: DEVICES_SCREENSHOTS },
  { to: 'setup-history/', text: 'Настройки', permission: DEVICES_SETTINGS },
];

export const DeviceNavLayout = observer(() => {
  const { deviceID } = useParams();
  
  const [pageTitle, setPageTitle] = useState('Устройство');
  const [withBackBtn] = useState(true);
  const [extraElem, setExtraElem] = useState(null);

  const setPageHead = useCallback(({ head, extraElem }) => {
    setPageTitle(head);
    setExtraElem(extraElem);
  }, []);

  const withPermLinks = links.filter(({ permission }) =>
    loggedAdmin.getIsResolved(permission)
  );
  const emptyLinks = isEmpty(withPermLinks);

  return (
    <>
      {withBackBtn && !emptyLinks && <ButtonGoBack className={'float-right'} />}

      {extraElem && extraElem}

      {pageTitle && <Head children={pageTitle} />}

      <NavSubPages
        links={withPermLinks}
        replace
        className={'mb-1'}
        rootPage={`/admins/devices/${deviceID}/`}
      />

      <Outlet context={setPageHead} />
    </>
  )
})