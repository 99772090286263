import { useMountEffect, useToggle, useUpdateEffect } from "@react-hookz/web";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { userAPI } from "../../../../API/userAPI";
import { ButtonFilter } from "../../../../components/button/ButtonFilter";
import { MyMultiSelect } from "../../../../components/form/MySelect/MyMultiSelect";
import { MySearchMultiSelect } from "../../../../components/form/MySelect/MySearchMultiSelect";
import { RangeDatePicker } from "../../../../components/form/RangeDatePicker";
import { SearchInput } from "../../../../components/form/SearchInput";
import { urlParams } from "../../../../constants/urlParams";
import { useComboSearch } from "../../../../hooks/url/useComboSearch";
import { useFetchStates } from "../../../../hooks/useFetchStates";
import { getFullNameFromObj, getIsoUtcDate, toggle } from "../../../../js-helpers/helpers";
import { notificationsOptions } from "../notificationsOptions";

const { SEARCH, PAGE, SORT, LIMIT, TYPE, RECEIVER, SENDER, DATE_FROM, DATE_TO } = urlParams;
const pageSearchParams = [
  { param: SEARCH, init: '', debounce: 333 },
  { param: PAGE, init: 'last' },
  { param: SORT, init: '' },
  { param: LIMIT },
  { param: TYPE, init: notificationsOptions[0].type },
  { param: SENDER },
  { param: RECEIVER },
  { param: DATE_FROM },
  { param: DATE_TO },
];


export const PageNotificationsFilters = ({prevUrlType}) => {
  const [
    [urlSearchQuery, setUrlSearchQuery],
    [urlPage],
    [urlSort],
    [urlLimit],
    [urlType],
    [urlSender, setUrlSender],
    [urlReceiver, setUrlReceiver],
    [urlDateFrom, setUrlDateFrom],
    [urlDateTo, setUrlDateTo],
  ] = useComboSearch(pageSearchParams);

  const [showFilters, toggleShowFilters] = useToggle(
    urlSender || urlReceiver || urlDateFrom || urlDateTo
  );

  useEffect(() => {
		if (prevUrlType && prevUrlType !== urlType) {
			toggleShowFilters(false);
		}
	}, [prevUrlType]);

  useUpdateEffect(() => {
    if (!showFilters) {
      setUrlSender('');
      setUrlReceiver('');
      setUrlDateFrom('');
      setUrlDateTo('');
      setInitFilters((draft) => {
        draft.sender = null;
        draft.receiver = null;
      });
    }
  }, [showFilters]);

  const [initFilters, setInitFilters] = useImmer({
    sender: null,
    receiver: null,
  });

  // const customSetter = (data, type, initState) => {
  //   const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')

  //   const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

	// 	setInitFilters((draft) => {
	// 		draft[type] = {
	// 			text,
	// 			value,
	// 		};
	// 	});

  //   initState.setReady(true);
  // };

  const customSenderSetter = ({data}) => {
    const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')

    const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

		setInitFilters((draft) => {
			draft.sender = {
				text,
				value,
			};
		});

    initSenderState.setReady(true);
  };


  const customReceiverSetter = ({data}) => {
    const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')

    const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

		setInitFilters((draft) => {
			draft.receiver = {
				text,
				value,
			};
		});

    initReceiverState.setReady(true);
  };
  const initSenderState = useFetchStates(() => userAPI.getAllUsers({account__keycloak_id__in: urlSender}, {allowMultipleRequests: true}), {
    noInitFetch: true,
    // customSetter: ({data}) => customSetter(data, 'sender', initSenderState),
    customSetter: customSenderSetter,
    // allowMultipleRequests: true,
  });

  const initReceiverState = useFetchStates(() => userAPI.getAllUsers({account__keycloak_id__in: urlReceiver}, {allowMultipleRequests: true}), {
    noInitFetch: true,
    customSetter: customReceiverSetter,
    // allowMultipleRequests: true,
    // customSetter: ({data}) => customSetter(data, 'receiver', initReceiverState),
  });

  useMountEffect(() => {
    if(!prevUrlType || prevUrlType === urlType) {
      urlSender && initSenderState.getData();
      urlReceiver && initReceiverState.getData();
    }
  });

  // const onUserSelect = (selectedOptions, urlSet, initState) => {
  //   if (!selectedOptions) return;
  //   const resultOptions = selectedOptions.map((option) => option.value)

  //   urlSet(resultOptions);
  //   initState.setReady(true);
  // };

  const onSenderSelect = (selectedOptions) => {
    if (!selectedOptions) return;
    const resultOptions = selectedOptions.map((option) => option.value)

    setUrlSender(resultOptions);
    initSenderState.setReady(true);
  };

  const onReceiverSelect = (selectedOptions) => {
    if (!selectedOptions) return;
    const resultOptions = selectedOptions.map((option) => option.value)

    setUrlReceiver(resultOptions);
    initReceiverState.setReady(true);
  };
  return (
      <div
        className={
          'row no-gutters text-muted gap-1r align-items-center px-1 py-75 rounded bg-transparent-60 mb-1'
        }
      >
        <div className={'col-12 row no-gutters align-items-center'}>
          <SearchInput
            resetWhen={!urlSearchQuery}
            className={'col mr-1'}
            onChange={(e) => setUrlSearchQuery(e.target.value)}
            placeholder={'Поиск...'}
            defaultValue={urlSearchQuery}
          />

          <ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
        </div>

        {showFilters && (
          <div
            className={
              'row col-12 no-gutters justify-content-end align-items-center gap-1r'
            }
          >
            <MySearchMultiSelect
              className={'col'}
              type={'usersWithKeycloakID'}
              init={urlSender && initSenderState.isReady}
              initialSelectedItems={initFilters.sender}
              resetWhen={!urlSender}
              onSelect={onSenderSelect}
              initText={'Все отправители'}
              clearUrl={() => setUrlSender('')}
              titleClassName={`${toggle(!urlSender, 'text-muted')}`}
            />

            <MySearchMultiSelect
              className={'col'}
              type={'usersWithKeycloakID'}
              init={urlReceiver && initReceiverState.isReady}
              initialSelectedItems={initFilters.receiver}
              resetWhen={!urlReceiver}
              onSelect={onReceiverSelect}
              initText={'Все получатели'}
              clearUrl={() => setUrlReceiver('')}
              titleClassName={`${toggle(!urlReceiver, 'text-muted')}`}
            />
            
            <RangeDatePicker
              fromPickerProps={{
                value: urlDateFrom,
                onChange: ([date]) => {
                  setUrlDateFrom(date && getIsoUtcDate(date));
                },
                options: { maxDate: urlDateTo },
              }}
              toPickerProps={{
                value: urlDateTo,
                onChange: ([date]) => {
                  setUrlDateTo(
                    date && getIsoUtcDate(dayjs(date).second(59).toDate())
                  );
                },
                options: { minDate: urlDateFrom },
              }}
            />
          </div>
        )}
      </div>
  );
}