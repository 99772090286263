import {useController} from 'react-hook-form'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {getInputLocalFullDate, getShortString} from '../../../../../../js-helpers/helpers';
import {WithTooltip} from '../../../../../../components/UI/WithTooltip';
import {useImmer} from 'use-immer';
import {useToggleState} from '../../../../../../hooks/useToggleState';
import {ModalDialog} from '../../../../../../components/modals/ModalDialog';
import DownloadApkModal from './DownloadApkModal';
import {ReleaseDate} from '../../formAppIcon/rowIconAppForm/releaseDate/ReleaseDate';
import {ReactComponent as UploadSVG} from './Upload.svg'
import {ReactComponent as DownloadSVG} from './Download.svg'
import {toast} from 'react-toastify';

export const isResolvedFileType = type => type === 'application/vnd.android.package-archive'
export const getFileName = (refFile) =>
	refFile.split('/').pop().split('?')[0].slice()
export const showFileExtensionErr = wrongExtension => {
  wrongExtension && toast.error(`Расширение файлов “${wrongExtension}” не поддерживается. Разрешенные расширения: apk.`)
};

export const OneAppVersion = ({data, isDisabled, control}) => {
	const {field} = useController({control, name: 'apk'})
	const [valueFile, setValueFile] = useState('')
	const [file, setFile] = useState(null)
	
	const [isShowModal, toggleShowModal] = useToggleState(false)
	const [modalDialogProps, setModalDialogProps] = useImmer({})
	
	const [drag, setDrag] = useState(false)
	const [fileSize, setFileSize] = useState(null)
	
	const getShortNameFile = (refFile) => {
		const nameFile = getFileName(refFile);
		return getShortString(nameFile, 40, {startTrim: true})
	}
	
	useEffect(() => {
		if (data && !isDisabled)
			setFile(data.apk)
	}, [data, isDisabled])
	
	const resetFileData = useCallback((target) => {
		target &&
		(target.value = null)
		setFile(null)
		setValueFile('')
		setFileSize(null)
		field.onChange([])
	}, [])
	
	function onChangeAPK({target}) {
		const file = target.files[0]
		// console.dir(file)
		
		if (!isResolvedFileType(file.type)) {
			const extension = file.name.split('.').at(-1)
			resetFileData(target)
			showFileExtensionErr(extension)
			return
		}
		
		if (target.files.length > 0) {
			setFile(file.name)
			setValueFile(target.value)
			setFileSize(file.size)
			field.onChange(target.files)
		}
	}
	
	function downloadAPK() {
		setModalDialogProps({
			head: `Вы действительно хотите скачать файл ${getFileName(data.apk)} на компьютер?`,
			body: <DownloadApkModal
				apk={data && data.apk}
				onCancelClick={() => toggleShowModal(false)}/>,
			showFooter: false,
			className: 'width-600',
		})
		
		toggleShowModal()
	}
	
	const dragStartHandler = (e) => {
		e.preventDefault()
		setDrag(true)
	}
	
	const dragLeaveHandler = (e) => {
		e.preventDefault()
		setDrag(false)
	}
	
	const onDropHandler = (e) => {
		e.preventDefault()
		let file = [...e.dataTransfer.files];
		const formatArr = file[0].name.split('.')
		
		if (formatArr[formatArr.length - 1] !== 'apk') {
			setDrag(false)
			resetFileData()
			showFileExtensionErr(formatArr[formatArr.length - 1])
			// return
		} else {
			setFile(file[0].name)
			setFileSize(file[0].size)
			setValueFile(file[0])
			field.onChange(file)
			setDrag(false)
		}
	}
	
	const dropNDownTitle = useMemo(() => {
		if (!drag && !file) {
			return 'Перетащите файл';
		} else if (drag) {
			return 'Отпустите файл'
		} else if (file) {
			return file
		}
	}, [drag, file])
	
	const dropNDownSubtitle = useMemo(() => {
		if (fileSize) {
			const size = (fileSize / (1024 * 1024)).toFixed(2);
			return size === '0.00' ? '< 0.01 МБ' : `${size} МБ`
		} else {
			return 'Поддерживаемые форматы: .apk'
		}
	}, [fileSize])
	
	if (!isDisabled) {
		return (
			<div className='d-flex gap-3r border-bottom w-100'>
				<div className='d-flex flex-column mr-5 width-400'>
					
					<ReleaseDate control={control}
					             isDisabled={isDisabled}
					             data={data?.release_date}/>
					
					<div className='d-flex flex-column mt-1'>
						<span className='font-small-3'>APK</span>
						<label className='d-flex flex-column p-75 align-items-center uploader cursor-pointer'
						       htmlFor={'uploadFile'}
						       onDragStart={dragStartHandler}
						       onDragLeave={dragLeaveHandler}
						       onDragOver={dragStartHandler}
						       onDrop={onDropHandler}
						>
							<UploadSVG className='mb-75'/>
							<span className='mb-25 text-bold-500 font-small-3 text-body'>
								{dropNDownTitle}
							</span>
							<span className='font-small-2'>{dropNDownSubtitle}</span>
							
							{/* {!data
							&&
							<label
								className={'btn px-1 text-c-transform-none btn-light-primary mr-2'}>
								{file ? 'Изменить' : 'Добавить Файл'}
								
								<input type='file'
								       required={!valueFile}
								       accept='.apk'
								       disabled={false}
								       onChange={onChangeAPK}
								       className={'visually-hidden'}
								/>
							</label>}
							
							<span className='align-self-center'>
                           {file ? getShortNameFile(file) : ''}
							</span> */}
						
						</label>
						<input type='file'
						       className={'visually-hidden'}
						       required={!valueFile}
						       onChange={onChangeAPK}
						       accept='application/vnd.android.package-archive'
						       id={'uploadFile'}
						/>
					</div>
				</div>
				
				<div className='d-flex flex-column justify-content-end align-items-start mr-5' style={{width: '316px'}}>
					<label>
						ИМЯ ПАКЕТА
						<WithTooltip tooltips={[data?.package]}
						             show={data?.package.length > 40}>
							<input disabled
							       value={data ? getShortString(data.package, 40) : ''}
							       className={'form-control mb-1 width-400'}/>
						</WithTooltip>
					
					</label>
					<label>
						КОД ВЕРСИИ
						<input disabled
						       value={data ? data.version_code : ''}
						       className={'form-control mb-1 width-400'}/>
					</label>
					
					<label>
						ИМЯ ВЕРСИИ
						
						<WithTooltip tooltips={[data?.version_name]}
						             show={data?.version_name.length > 40}>
							<input value={data ? getShortString(data.version_name, 40) : ''}
							       disabled
							       className={'form-control mb-1 width-400'}/>
						</WithTooltip>
					
					</label>
				</div>
			</div>
		)
	}
	
	return (
		<>
			<ModalDialog isShow={isShowModal} {...modalDialogProps} />
			<div className='d-flex gap-2-5r border-bottom mt-2'>
				<div className='d-flex flex-column mr-5'>
					
					<label>
						Дата и время установки
						<input type='datetime-local'
						       disabled={isDisabled}
						       className={'form-control mb-75 width-400'}
						       value={getInputLocalFullDate(data.release_date)}/>
					</label>
					
					<div className='d-flex flex-column mb-2'>
						<span className='font-small-3'>APK</span>
						
						<div className='d-flex flex-column p-75 align-items-center uploader cursor-pointer'
						     onClick={downloadAPK}
						>
							<DownloadSVG className='mb-75'/>
							<span className='mb-25 text-bold-500 font-small-3'>
							{data ? getShortNameFile(data.apk) : ''}
						  </span>
							<span className='font-small-2 text-gray-300'>Скачать</span>
						</div>
					</div>
				
				</div>
				
				<div className='d-flex flex-column justify-content-end align-items-start mr-5'>
					<label>
						ИМЯ ПАКЕТА
						<input value={data.package}
						       disabled
						       className={'form-control mb-1 width-400'}/>
					</label>
					
					<label>
						КОД ВЕРСИИ
						<input value={data.version_code}
						       disabled
						       className={'form-control mb-1 width-400'}/>
					</label>
					
					<label>
						ИМЯ ВЕРСИИ
						<input value={data.version_name}
						       disabled
						       className={'form-control mb-1 width-400'}/>
					</label>
				</div>
			</div>
		</>
	)
}