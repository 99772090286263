import {MyContainerWithCondition} from '../UI/MyContainerWithCondition';

export const MyNewInput = (
	{
		type,
		label,
		value = '',
		onChange,
		placeholder,
		className = '',
		labelClassName = '',
		inputClassName = '',
		appendComponent,
		prependComponent,
		...inputProps
	}) => {
	
	const _inputProps = {
		type, value, onChange, placeholder, ...inputProps
	}
	
	return (
		<fieldset className={className}>
			
			{label &&
			<label className={labelClassName}>{label}</label>
			}
			
			<MyContainerWithCondition isWrap={prependComponent || appendComponent}
			                          className={'d-flex'}>
				
				{prependComponent}
				
				<input className={`form-control ${inputClassName}`} {..._inputProps}/>
				
				{appendComponent}
			
			</MyContainerWithCondition>
		</fieldset>
	)
}


