import React from 'react';
import { Head } from '../../components/UI/Head';
import { ButtonGoBack } from '../../components/button/ButtonGoBack';
import {ReactComponent as NotFoundIcon} from './404.svg';

export const NotFoundPage = () => {
  return (
    <div className={'text-center w-100 text-gray-300'}>
      <ButtonGoBack className={'d-block ml-auto'} />
      <div
        style={{ height: '70vh' }}
        className={'d-flex justify-content-center flex-column'}
      >
        <NotFoundIcon className='ml-auto mr-auto'/>
        <Head className={'text-body text-uppercase font-weight-bold'}>
          Упс! Что-то пошло не так…
        </Head>
        <Head>
          Страница, которую Вы запрашиваете, не существует. Возможно она <br />
          устарела, была удалена, или был введен неверный адрес в адресной
          строке
        </Head>
      </div>
    </div>
  );
};
