import {createElement} from "react";
import * as PropTypes from "prop-types";

/**
 * @description Компонент-контейнер для отображения колонок.
 *
 * @param as {string} - html-элемент контейнера
 * @param rowGap
 * @param colGap
 * @param sumItems {number} - кооличество элементов
 * @param cols {number} - количество колонок
 * @param children
 * @param props
 * @returns {React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>}
 */

// row-gap: 1rem;
// column-gap: 2rem;

export function Columns({
                           as = 'ul',
                           rowGap = 1,
                           colGap = 2,
                           sumItems,
                           cols = 2,
                           children,
                           ...props
                        }
) {
   const halfSumItems = Math.ceil(sumItems / cols)
   
   props.className += ' list-unstyled c-grid-cols'
   props.style = {
      ...props.style,
      gridTemplate: `repeat(${halfSumItems}, 1fr) / ${`calc(${100/cols}% - ${((colGap * cols) - 1) / cols}rem) `.repeat(cols)}`,
      rowGap: `${rowGap}rem`,
      columnGap: `${colGap}rem`
   }
   
   return createElement(as, props, children)
}

Columns.propTypes = {
   cols: PropTypes.number,
   className: PropTypes.string,
   children: PropTypes.node
}