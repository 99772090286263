import React from 'react'
import { Img } from '../../UI/Img'

export const CellAttachmentsPhoto = ({files, openSwiper}) => {
	
	const typeFilesImage = ['.png', '.jpg', '.jpeg']
	// const typeFilesDocs = ['doc', 'docx']
	// const typeFilesVideo = ['mp4']
	
	// получаем картинки из массива файлов
	// тк там могут быть в будущем другие типы файлов
	const onlyPhotos = files.filter(({file}) => typeFilesImage.some(type => file.includes(type)))
	
	// создаем массив картинок для таблицы(5 макс и кол-во скрытых картинок)
	const finalImgsRow = onlyPhotos.length > 5
		? onlyPhotos.slice(0, 5).concat(onlyPhotos.length - 5)
		: onlyPhotos
	
	//возвращаем img и div(если фото > 5)
	return finalImgsRow.map((item, ind) =>
		ind === 5
			?
			<button onClick={() => openSwiper(onlyPhotos, onlyPhotos[5].id)}
			        key={item}
			        style={{height: '2.5em', width: '2.5em',}}
			        className={'btn btn-primary font-weight-bold rounded-circle p-0'}>
				+{item.toString()}
			</button>
			:
			<Img className={'cursor-pointer mr-50 shadow'}
			     style={{objectFit: 'contain'}}
			     key={item.id}
			     src={item.file}
			     width={24}
			     height={40}
			     alt={'=('}
			     onClick={() => openSwiper(onlyPhotos, item.id)}/>
	)
}