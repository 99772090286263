import {useEffect, useMemo} from "react";
import {SearchInput} from "../../../../components/form/SearchInput";
import {urlParams} from "../../../../constants/urlParams";
import {useComboSearch} from "../../../../hooks/url/useComboSearch";
import {isEmpty, loader} from "../../../../js-helpers/helpers";
import {useMyTable} from "../../../../components/table/MyTable/useMyTable";
import {MyTableBody} from "../../../../components/table/MyTable/MyTableBody";
import {MyTableHead} from "../../../../components/table/MyTable/MyTableHead";
import {MyTable} from "../../../../components/table/MyTable/MyTable";
import {useFetchStates} from "../../../../hooks/useFetchStates";
import {notificationsAPI} from "../../../../API/notificationsAPI";
import {useParams} from "react-router-dom";
import {useUpdateEffect} from "@react-hookz/web";
import {getNotificationReceiversTableOptionsAdmin, getNotificationReceiversTableOptionsUser} from "../../../../components/table/MyTable/options/columnsNotificationReceivers";
import {Card} from '../../../../components/UI/Card';

const {SEARCH, PAGE, LIMIT, TYPE} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 1},
	{param: LIMIT},
  {param: TYPE}
]

function AdminNotificationReceiversTable() {
	const {notificationID} = useParams();
	
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlPage, setUrlPage],
		[urlLimit],
    [urlType]
	] = useComboSearch(pageSearchParams)
	
	const {data: dataStatuses, getData: setStatuses, isReady: statusesIsReady} = useFetchStates(
		() => {
      if(urlType === 'admin') {
        return notificationsAPI.getOneItemStatuses(notificationID, urlPage, urlSearchQuery, urlLimit)
      } else if (urlType === 'user') {
        return notificationsAPI.getP2POneItemStatuses(notificationID, urlPage, urlSearchQuery, urlLimit)
      }
    },
		{initData: {},}
	);
	
	useEffect(() => {
		setStatuses()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (+urlPage !== 1) {
			setUrlPage(1)
			return
		}
		setStatuses()
	}, [urlSearchQuery, urlLimit])
	
	const showEmptyFoundDataMessage = isEmpty(urlSearchQuery);
	
	const tableOptions = useMemo(
		() => {
      if (urlType === 'admin') {
        return getNotificationReceiversTableOptionsAdmin(showEmptyFoundDataMessage)
      } else if (urlType === 'user') {
        return getNotificationReceiversTableOptionsUser(showEmptyFoundDataMessage)
      }
    },
		[showEmptyFoundDataMessage, urlType]
	);
	const {tableHeads, tableColgroup} = useMyTable(tableOptions);
	
	return (
		<Card secondary size={1} className='mt-1'>
			<p className='text-bold-500'>Уведомления доставлены для:</p>
			
      <SearchInput withBorder
			             className={'mb-50'}
			             onChange={e => setUrlSearchQuery(e.target.value)}
			             placeholder={'Поиск...'}
			             defaultValue={urlSearchQuery}/>
			
			{/*<div className='px-1 pb-1'>*/}
			<MyTable colgroup={tableColgroup}
			         paginationAllCount={dataStatuses?.count}
			         showEmptyFoundDataMessage={!showEmptyFoundDataMessage}
			         isDataReady={statusesIsReady}
				// tableClassName={'bg-c-rgba-light'}
			>
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(statusesIsReady)}
				             data={dataStatuses?.results}
				             tableOptions={tableOptions}
				             rowClassName={'border-top border-bottom'}
				/>
			</MyTable>
			{/*</div>*/}
		</Card>
	)
}

export default AdminNotificationReceiversTable