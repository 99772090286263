import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

export const servicesAPI = {
	getUserCalls: (page, user__account__keycloak_id, page_size, search, ordering) =>
		axios(
			'/calls/',
			setPreparedAxiosParams({
				page,
				page_size,
				user__account__keycloak_id,
				search,
				ordering
			})),
	
	getDeviceCalls: (page, deviceID, page_size, search, ordering) =>
		axios(
			'/calls/',
			setPreparedAxiosParams({
				page,
				page_size,
				device__serial_number: deviceID,
				search,
				ordering
			}),
		),
	
	getCalls() {
		return axios('/calls/', setPreparedAxiosParams(arguments[0]));
	},
	
	getSites({page, search, page_size}) {
		return axios('/sites/', setPreparedAxiosParams(arguments[0]))
	},
	
	getAllSMSDialogs: ({keycloakID, ...args}) =>
		axios(
			`/users/${keycloakID}/sms/conversations/`,
			setPreparedAxiosParams({...args})
		),
	
	getSMSDialog: (accountID, phone_number, page = 1) =>
		axios(
			`/users/${accountID}/sms/`,
			setPreparedAxiosParams({phone_number, page})
		),
	
	createNewSites: (url) => axios.post('/sites/', {url},),
	
	deleteSite: (id) => axios.delete(`/sites/${id}/`,),
	
	getScreenShots: (search, keycloakID, deviceID, page = 1, page_size, urlDateFrom, urlDateTo) =>
		axios(
			'/screenshots/',
			setPreparedAxiosParams({
				page,
				page_size,
				date__gte: urlDateFrom,
				date__lte: urlDateTo,
				device__serial_number: deviceID,
				user__account__keycloak_id: keycloakID,
				search,
				ordering: 'date'
			}),
		),
	
	getMailingLists({page, page_size}) {
		return axios('/mailings/settings/', setPreparedAxiosParams(arguments[0]))
	},
	
	postMailingItem: (data) => axios.post('/mailings/settings/', data),
	
	patchMailingItem: (id, data) => axios.patch(`/mailings/settings/${id}/`, data),
	
	deleteMailingItem: (id) => axios.delete(`/mailings/settings/${id}/`),
}






