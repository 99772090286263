import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';


//da eba*y in mouth
export const logsAPI = {
	getAll: ({type, ...params}) =>
		axios(`/logs/${type}/`, setPreparedAxiosParams(params),),
	
	getUserLogs: ({type, ...params}) =>
		axios(`/logs/${type}/`,
			setPreparedAxiosParams({
				user__account__keycloak_id: params?.keycloakID,
				type: params?.logInOutType,
				serial_number: params?.device__serial_number,
				...params
			})
		),
	
	getDeviceLogs: ({type, ...params}) =>
		axios(`/logs/${type}/`,
			setPreparedAxiosParams({
				...params,
				device__serial_number: params?.deviceID,
				type: params?.logInOutType,
			}),
		),

  getLogErrorInfo: (id, type) => axios(`/logs/${type}/${id}/`),
	
	getCrashlytics: ({userID, ...params}) =>
		axios('/logs/crashes/',
			setPreparedAxiosParams({
				user__account: userID,
				...params
			})
		),
	
	getCrashEvent: (event_id, app) =>
		axios(`/logs/crashes/${event_id}/?app=${app}`),
	
	getCrashExport: (event_id, app) =>
		axios(`/logs/crashes/${event_id}/raw/?app=${app}`),
};
