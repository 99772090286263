import exportFromJSON from "export-from-json"
import { getFileNameWithDatesRange, getLocaleFullDate, getShortFullNameFromObj } from "../../../js-helpers/helpers"

export const exportExcel = ({arrExport, dateFrom, dateTo}) => {
	const keys = ['id', 'date', 'text', 'user', 'status', 'device_brand', 'device_system_version', 'launcher_version_name']
	
	const dataToExcel = arrExport.map((obj) =>
		keys.reduce((acc, key) => {
			switch (key) {
				case 'user':
					acc['Автор запроса'] = getShortFullNameFromObj(obj.user)
					break
				case 'date':
					acc['Дата запроса'] = getLocaleFullDate(obj.date)
					break
				case 'launcher_version_name':
					acc['Версия прошивки'] = `${obj.launcher_version_name}(${obj.launcher_version_code})`
					break
				case 'device_brand':
					acc['Устройство'] = `${obj.device_brand} ${obj.device_model}`
					break
				case 'text':
					acc['Текст запроса'] = obj.text
					break
				case 'id':
					// noinspection JSNonASCIINames
					acc['ID запроса'] = obj.id
					break
				default:
					break
			}
			return acc
		}, {}))
	
	exportFromJSON({
		data: dataToExcel,
		withBOM: true,
		fileName: getFileNameWithDatesRange(dateFrom, dateTo, 'Запросы_пользователей'),
		exportType: exportFromJSON.types.xls,
	})
}