/* eslint-disable max-lines */
import { useOutletContext, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { logsAPI } from '../../../../API/logsAPI';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { isEmpty } from '../../../../js-helpers/helpers';
import { useUpdateEffect } from '@react-hookz/web';
import { usePrevious } from '@react-hookz/web/esnext';
import { useComboSearch } from '../../../../hooks/url/useComboSearch';
import { urlParams } from '../../../../constants/urlParams';
import { logsOptions } from '../../../../constants/logsOptions';
import PageLogs from '../../../logs/PageLogs';

const { PAGE, LIMIT, SERVICE, REASON, SEARCH, SORT, STATUS, DATE_FROM, DATE_TO, USER, DEVICE, TYPE, DATE_OFF_FROM, DATE_OFF_TO, DATE_ON_FROM, DATE_ON_TO, APP } = urlParams;
const pageSearchParams = [
  { param: SERVICE, init: logsOptions[0].service },
  { param: STATUS },
  { param: USER },
  { param: DEVICE },
  { param: TYPE},
  { param: REASON },
  { param: APP },
  { param: PAGE, init: 'last' },
  { param: SORT, init: 'date' },
  { param: LIMIT },
  { param: SEARCH },
  { param: DATE_FROM },
  { param: DATE_TO },
  { param: DATE_OFF_FROM },
  { param: DATE_OFF_TO },
  { param: DATE_ON_FROM },
  { param: DATE_ON_TO },
];

export function PageUserLogs() {
  const { keycloakID } = useParams();
  const [
    [urlService, setUrlService], 
    [urlStatus], 
    [urlUser],
    [urlDevice],
    [urlType],
    [urlReason],
    [urlApp],
    [urlPage, setUrlPage], 
    [urlSortField],
    [urlLimit], 
    [urlSearchQuery,_, clearUrlSearchQuery],
    [urlDateFrom],
    [urlDateTo],    
    [urlDateOffFrom],
    [urlDateOffTo],
    [urlDateOnFrom],
    [urlDateOnTo],
  ] =
    useComboSearch(pageSearchParams);

  const [isPaginationReady, setIsPaginationReady] = useState(true);

  const {setPageHead} = useOutletContext();
  const isCrashesService = urlService === 'crashes';

  const logs = useFetchStates(() => 
       logsAPI.getUserLogs({
        type: urlService,
        page: urlPage,
        page_size: urlLimit,
        ordering: urlSortField,
        is_success: urlStatus === 'success' ? true : urlStatus === 'error' ? false : null,
        reason: +urlReason || null,
        date__gte: urlDateFrom,
        date__lte: urlDateTo,
        search: urlSearchQuery,
        device__serial_number: urlDevice,
        keycloakID,
        logInOutType: urlType === 'login' ? 1 : urlType === 'logout' ? 2 : null,
        switch_off_date__gte: urlDateOffFrom,
        switch_off_date__lte: urlDateOffTo,
        switch_on_date__gte: urlDateOnFrom,
        switch_on_date__lte: urlDateOnTo,
        event_date__gte: urlDateFrom,
        event_date__lte: urlDateTo,
        app: urlApp,
    }) 
  );

  const prevSearchService = usePrevious(urlService);

  useUpdateEffect(async () => {
    setIsPaginationReady(false);

    if (urlPage !== 'last') {
      setUrlPage('last');
      return;
    }

    const res = await logs.getData();
    res && setIsPaginationReady(true);
  }, [urlService, urlLimit, urlReason,  urlStatus, urlDateFrom, urlDateTo, urlSearchQuery, urlUser, urlDevice, urlType, urlDateOffFrom, urlDateOffTo, urlDateOnFrom, urlDateOnTo, urlApp]);

  useEffect(() => {
    const { text, pageTitle } = logsOptions?.find(
      (it) => it.service === urlService
    );

    setPageHead({ head: pageTitle || text});

    if (isCrashesService && prevSearchService !== urlService) logs.setData({});
  }, [urlService]);

  useEffect(() => {
    logs.getData(urlPage);
  }, [urlPage, urlSortField]);

  const isEmptySearchQuery = isEmpty(urlSearchQuery);

  const showEmptyFoundDataMessage =
  !isEmptySearchQuery || urlStatus|| urlUser || urlDevice || urlType || urlReason ||urlApp ||  urlDateFrom || urlDateTo || urlDateOffFrom || urlDateOffTo || urlDateOnFrom || urlDateOnTo

  const onClickNavButton = (groupName) => {
    setUrlService(groupName);
    clearUrlSearchQuery()
    setUrlPage('last');
  };
  

  return (
    <PageLogs logs={logs} isPaginationReady={isPaginationReady}
              urlService={urlService} 
              onClickNavButton={onClickNavButton} 
              isEmptySearchQuery={isEmptySearchQuery}
              filter={'user'}
              showEmptyFoundDataMessage={showEmptyFoundDataMessage}
              prevSearchService={prevSearchService}
    />
  );
}
