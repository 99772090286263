//хук принимает массив опций MyTable и
// возвращает мемоизированные данные для
// отрисовки и синхронизации таблицы

import {useMemo} from 'react';

export const useMyTable = (
	myTableOptions,
	{memoDependencies = [myTableOptions]} = {}
) => {
	
	return useMemo(() => {
			let tableHeads = []
			let tableColgroup = []
			
			myTableOptions.forEach(({head, col, headClassName, sortField}) => {
				tableHeads.push({head, sortField, headClassName})
				tableColgroup.push(col)
			})
			
			return {tableHeads, tableColgroup, tableOptions: myTableOptions}
		}
		, memoDependencies)
}
