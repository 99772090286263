import {Head} from '../UI/Head';
import {ReactComponent as EmptyIcon} from './empty.svg';
import {loader} from '../../js-helpers/helpers';

export const EmptyDataWithoutSearch = ({className = '', isDataReady = true}) => {
	
	return (
		<div
			className={`rounded-lg mx-auto text-center py-2 px-4 text-gray-300 ${className} ${loader(isDataReady)}`}
			style={{marginTop: '15vh', marginBottom: '15vh', maxWidth: '750px'}}
		>
			<EmptyIcon className={'mb-2'}/>
			
			<Head>{'По Вашему запросу данных нет'}</Head>
		</div>
	)
}