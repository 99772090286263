import {Navigate} from 'react-router-dom';
import {AppLayout} from '../components/layout/AppLayout';
import {usersRoutes} from './usersRoutes';
import {adminsRoutes} from './adminsRoutes';
import {devicesRoutes} from './devicesRoutes';
import {NotResolvedPage} from '../pages/notResolvedPage/NotResolvedPage';
import {NotFoundPage} from '../pages/notFoundPage/NotFoundPage';

const routes = [
	{
		element: <AppLayout/>,
		children: [
			adminsRoutes,
			devicesRoutes,
			usersRoutes,
			{path: '/403/', element: <NotResolvedPage/>},
			{path: '/404/', element: <NotFoundPage/>},
		],
	}, {
		path: '*',
		element: <Navigate to='/admins/menu/' replace/>,
		// element: <Navigate to="/404/" replace />,
	},
];

export const getRoutes = () => routes;
