import {useMemo, useState} from 'react';

export const useAudioPlayer = () => {
	const [playingCallURL, setPlayingCallURL] = useState(false)
	const [loadedLinks, setLoadedLinks] = useState([])
	const [badLinks, setBadLinks] = useState([])
	
	// const states = {playingCallURL, loadedLinks, badLinks}
	// const setStates = {setPlayingCallURL, setLoadedLinks, setBadLinks}
	
	const playerProps = {setLoadedLinks, setBadLinks, playingCallURL, setPlayingCallURL}
	const playerStates = {...playerProps, badLinks, setBadLinks, loadedLinks}
	
	return useMemo(() => ({playerProps, playerStates}),
		[playingCallURL, loadedLinks, badLinks])
}