import {adminSmassAPI} from '../API/adminSmassAPI';
import {makeAutoObservable} from 'mobx';
import {adminPermissions} from '../constants/adminPermissions';

export const loggedAdmin = makeAutoObservable({
	data: {
		account: {
			first_name: '-',
			last_name: '-',
			login: '-',
		},
		available_sections: [],
    visibility_groups: [],
		is_superadmin: false,
	},
	isReady: false,
	
	get isSuperAdmin() {
		return this.data.is_superadmin === true;
	},
	
	* fetchAdmin() {
		this.isReady = false
		this.error = null
		
		try {
			const {data} = yield adminSmassAPI.getLoggedNow()
			this.data = data
			
		} finally {
			this.isReady = true
		}
	},
	
	getIsResolved(permission) {
		return this.data.is_superadmin
			? true
			: this.data.available_sections.some(p => p === permission)
	},
	
	get isEmptyAdminMenu() {
		return Object.entries(adminPermissions)
		.filter(([key]) => /*!key.startsWith('USERS') &&*/ key !== 'GLOBAL_SEARCH')
		.every(([, value]) => !this.getIsResolved(value))
	},

  get isEmptyVisibilityGroups() {
    return this.data.visibility_groups.length === 0 && !this.data.is_unauthorized_launchers_visible
  }
	
}, {}, {autoBind: true})