/* eslint-disable max-lines */
import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { servicesAPI } from '../../../../API/servicesAPI';
import PortalModal from '../../../../components/PortalModal';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { MyTable } from '../../../../components/table/MyTable/MyTable';
import {
  loader,
  toggle,
  translateThisBullshit,
} from '../../../../js-helpers/helpers';
import { MyTableBody } from '../../../../components/table/MyTable/MyTableBody';
import { MyTableHead } from '../../../../components/table/MyTable/MyTableHead';
import { useToggle } from '@react-hookz/web';
import { useMyTable } from '../../../../components/table/MyTable/useMyTable';
import { useUpdateEffect } from '@react-hookz/web/esnext';
import { useComboSearch } from '../../../../hooks/url/useComboSearch';
import { urlParams } from '../../../../constants/urlParams';
import { callsHistoryTable } from '../../../dataTables/PageCallsHistory/table';
import { useAudioPlayer } from '../../../../components/media/AudioPlayer/useAudioPlayer';
import { AudioPlayerFullScreen } from '../../../../components/media/AudioPlayer/AudioPlayerFullScreen';
import { useImmer } from 'use-immer';
import { userAPI } from '../../../../API/userAPI';
import { callTypes, initStateFullScreenPlayer } from '../../../../constants/constants';

export function CallTextModal({ head, className, modalClassName, ...props }) {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <>
      <button
        className={`font-small-3 form-control text-left position-relative js-open ${
          isOpen ? 'zindex-1' : 'zindex-0'
        } ${className}`}
        onClick={toggleOpen}
        {...props}
      >
        <svg
          className={'abs-c-right-center mr-1'}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          fill="none"
          viewBox="0 0 8 5"
        >
          <path
            className={`${!isOpen ? 'rotate-180 trans-c-center' : ''}`}
            fill="#B3C0CE"
            fillRule="evenodd"
            d="M.94 5L4 1.91 7.06 5 8 4.049 4 0 0 4.049.94 5z"
            clipRule="evenodd"
          />
        </svg>

        {head?.slice(0, 12) + '...'}

        <div
          onClick={(e) => e.stopPropagation()}
          className={` ${
            isOpen ? 'modal-c-body scroll-c' : 'd-none'
          } ${modalClassName}`}
        >
          {head?.split('\n').map((text) => (
            <p>
              &#8212; {text}
              <br />
            </p>
          ))}
        </div>
      </button>

      <PortalModal
        isOpen={isOpen}
        className={'modal-c-bg'}
        onClose={toggleOpen}
        childrenClassName={'js-open'}
      />
    </>
  );
}

const { PAGE, LIMIT, SORT } = urlParams;
const pageSearchParams = [
  { param: PAGE, init: 'last' },
  { param: SORT, init: 'date' },
  { param: LIMIT },
];

export function PageUserCalls() {
  const { keycloakID } = useParams();

  const [[urlPage, setUrlPage], [urlSortField], [urlLimit]] =
    useComboSearch(pageSearchParams);

  const userStates = useFetchStates(() => userAPI.getUserInfo(keycloakID),
		{setNestedField: 'account', initData: {}})
  
  const {setPageHead} = useOutletContext();

  useEffect(() => {
    setPageHead({ head: 'Звонки' });
  }, []);


  const {
    data: calls,
    getData,
    isReady: callsIsReady,
  } = useFetchStates(() =>
    servicesAPI.getUserCalls(urlPage, keycloakID, urlLimit, null, urlSortField)
  );

  const audioArray = useMemo(() => {
   return calls?.results.map((call) =>  (call.normalized_audio || call.audio) && (
    { fileName: `${translateThisBullshit(call.contact_name)
				}_${callTypes[call.type][0]
				}_${call.contact_phone}_${
					new Date(call.date)
						.toLocaleString()
						.replaceAll('.', '-')
						.replaceAll(' ', '_')
						.replaceAll(',', '')
						.replaceAll(':', '-')
				}_${call.duration.replaceAll(':', '-')}`,
        audio: call.normalized_audio || call.audio
    })).filter((call) => call !== null)
  }, [calls])

  useUpdateEffect(async () => {
    await getData();
    playerStates.setBadLinks([]);
  }, [urlPage, urlSortField]);

  useUpdateEffect(async () => {
    if (urlPage !== 'last') {
      setUrlPage('last');
      return;
    }
    await getData();
    playerStates.setBadLinks([]);
  }, [urlLimit]);

  const { playerProps, playerStates } = useAudioPlayer();
  const { badLinks, loadedLinks, playingCallURL } = playerStates;

  const [audioName, setAudioName] = useState('')

  const [
    { playing: fullScreenPlaying, played: fullScreenPlayed, seeking: fullScreenSeeking, duration: fullScreenDuration, badURL: fullScreenBadURL, isReady: fullScreenIsReady, volume },
    setFullScreenPlayerState,
  ] = useImmer(initStateFullScreenPlayer);

  const { tableOptions, tableHeads, tableColgroup } = useMyTable(
    callsHistoryTable.getTableCallsHistory({
      playerStates,
      playerProps,
      userNotNeeded: true,
      setFullScreenPlayerState,
      fullScreenPlaying,
      setAudioName
    }),
    {
      memoDependencies: [
        playingCallURL,
        badLinks,
        loadedLinks,
        fullScreenPlaying
      ],
    }
  );

  useUpdateEffect(() => {
    playerProps.setPlayingCallURL(false)
  }, [urlPage]);
// eslint-disable-next-line max-lines

  return (
    <div style={{ marginBottom: '120px'}}>
      <MyTable colgroup={tableColgroup} paginationAllCount={calls?.count} addScroll={true}>
        <MyTableHead heads={tableHeads} />

        <MyTableBody
          getRowClassName={(row) =>
            toggle(row?.is_success === false, 'bg-rgba-danger-light')
          }
          data={calls?.results}
          tableOptions={tableOptions}
          className={loader(callsIsReady)}
          highestRowToFill={40.5}
          rowClassName={'showInHover'}
        />
      </MyTable>

      {playingCallURL &&
        <div  className='position-fixed container p-0' 
              style={{bottom: '0', zIndex: '4'}}>
          <AudioPlayerFullScreen 
              url={playingCallURL} playingCallURL={playingCallURL}
              setFullScreenPlayerState={setFullScreenPlayerState} 
              fullScreenPlaying={fullScreenPlaying} 
              fullScreenPlayed={fullScreenPlayed} 
              fullScreenSeeking={fullScreenSeeking} 
              fullScreenDuration={fullScreenDuration} 
              fullScreenBadURL={fullScreenBadURL} 
              fullScreenIsReady={fullScreenIsReady}
              volume={volume}  audioName={audioName}
              setAudioName={setAudioName}
              audioArray={audioArray}
              {...playerProps}/>
        </div>
      }
    </div>
  );
}
