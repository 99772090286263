import {useImmer} from 'use-immer';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Card} from '../../../components/UI/Card';
import {isEmpty, isEqualObjects, loader} from '../../../js-helpers/helpers';
import {MyNewInput} from '../../../components/form/MyNewInput';
import {CheckBox} from '../../../components/form/CheckBox';
import {Button} from '../../../components/button/Button';
import {servicesAPI} from '../../../API/servicesAPI';
import { ButtonWithIcon } from '../../../components/button/ButtonWithIcon';

export function MailingCard(
	{
		email,
		crashes,
		feedbacks,
		id,
		onDeleteClick,
	}) {
	
	const [initData, setInitData] = useImmer({})
	const [currentData, setCurrentData] = useImmer({})
	const [isReady, setIsReady] = useState(true)
	
	useEffect(() => {
		const props = {email, crashes, feedbacks, id}
		setInitData(props)
		setCurrentData(props)
	}, [id])
	
	const toggleCrashes = e => {
		setCurrentData(draft => {
			draft.crashes = e.target.checked
		})
	}
	
	const toggleFeedbacks = e => {
		setCurrentData(draft => {
			draft.feedbacks = e.target.checked
		})
	}
	
	const setEmail = e => {
		setCurrentData(draft => {
			draft.email = e.target.value
		})
	}

	const patchItem = async () => {
		setIsReady(false)
		try {
			const {data} = await servicesAPI.patchMailingItem(id, currentData)
			setInitData(data)
			toast('Изменения сохранены', {type: 'success'})
		} catch (e) {
			toast(`Ошибка сохранения: ${e?.response?.data?.errors?.[0]?.detail}`, {type: 'error'})
		} finally {
			setIsReady(true)
		}
	}
	
	const {
		email: _email,
		crashes: _crashes,
		feedbacks: _feedbacks,
	} = currentData
	
	const isDisabledSaveBtn = (!_crashes && !_feedbacks) || isEmpty(_email)
	
	return (
		<Card as={'li'}
		      className={`row no-gutters hover-c-gray-50 align-items-center ${loader(isReady)}`}
		>
			<label className={'m-0 mr-1'}>e-mail</label>
			
			<MyNewInput className={'col-5 mr-2'}
			            value={_email || ''}
			            onChange={setEmail}
			            placeholder={'Введите e-mail'}/>
			
			<CheckBox appendLabel={'Запросы от пользователей'}
			          checked={_feedbacks ?? true}
			          onChange={toggleFeedbacks}
			          className={'mr-1'}/>
			
			<CheckBox appendLabel={'Crashlytics'}
			          checked={_crashes ?? true}
			          onChange={toggleCrashes}/>
			
			<div className={'col d-flex gap-1r justify-content-end'}>
				
				{!isEqualObjects(initData, currentData) &&
				<Button onClick={patchItem} save disabled={isDisabledSaveBtn}/>
				}
				
        <ButtonWithIcon type={'delete'}
                        onClick={(e) => onDeleteClick(initData, id, e.target)}
                        className={'mr-1'}/>
			</div>
		</Card>
	)
}