import {Button} from '../button/Button';
import React from 'react';

export function ModalDialogFooter(
	{
		onCancelClick,
		onSuccessClick,
		cancelText = 'Отмена',
		successText = 'Сохранить',
		successDisabled = false,
    successHided = false
	}) {
	return (
		<div className={`bg-c-gray-50 p-2 ${successHided ? '' : ''}`}>
			<button onClick={onCancelClick} className={`btn btn-outline-light ${successHided ? '' : 'mr-3'}`}>
				{cancelText}
			</button>
			
			<Button save disabled={successDisabled} onClick={onSuccessClick} className={successHided ? 'd-none' : ''}>
				{successText}
			</Button>
		</div>
	)
}