import {Link} from 'react-router-dom';
import {getBrandAndModelFromObj, getFullLauncherVersionFromObj} from '../../../../js-helpers/helpers';

export const columnsDevice = {
	android_id: {
		head: 'Android ID',
		cell: id => <Link to={`/admins/devices/${id}/`}>{id}</Link>,
		nestedCellField: 'android_id',
		sortField: 'device__android_id'
	},
	serial_number: {
		head: 'Серийный №',
		cell: serial_number => serial_number
			? <Link to={`/admins/devices/${serial_number}/info/`}>{serial_number}</Link>
			: '-',
		nestedCellField: 'serial_number',
		sortField: 'device2__serial_number'
	},
  serial_number_without_sort: {
    head: 'Серийный №',
		cell: device => device 
			? <Link to={`/admins/devices/${device}/info/`}>{device}</Link>
			: '-',
		nestedCellField: 'device',
  },
	launcher_version: {
		head: 'Версия прошивки',
		cell: getFullLauncherVersionFromObj,
		sortField: 'device2__launcher_version_code'
	},
	android_model: {
		head: 'Марка и модель телефона',
		cell: getBrandAndModelFromObj,
		cellClassName: 'text-nowrap',
	},
	system_version: {
		head: 'Версия Android',
		cell: 'system_version',
		sortField: 'device2__system_version'
	},
	api_level: {
		head: 'API Level',
		cell: 'api_level',
		sortField: 'device2__api_level'
	},
}
