import exportFromJSON from 'export-from-json';
import {
  getLocaleFullDate,
  isEmpty,
  loader,
} from '../../../js-helpers/helpers';
import { logsAPI } from '../../../API/logsAPI';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonWithIcon } from '../../../components/button/ButtonWithIcon';
import { useSearchParams } from 'react-router-dom';
import { createApp } from './createApp';


export const CrashLogFields = ({ log, setLogIsDownload }) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const fieldsLog = createApp(log);

  const exportLog = async (e) => {
    e.stopPropagation();
    !setLogIsDownload && setIsLoaded(false);
    setLogIsDownload && setLogIsDownload(true);
    try {
      const logExport = await logsAPI.getCrashExport(
        log.event_id,
        searchParams.get('app') || log?.app
      );

      exportFromJSON({
        data: logExport?.data,
        fileName: `crash_${getLocaleFullDate(log.event_date)}`,
        exportType: 'txt',
      });

      toast.success('Лог успешно сохранен');
      setIsDownloaded(true);
    } catch (e) {
      toast.error('Ошибка загрузки лога');
    } finally {
      !setLogIsDownload  && setIsLoaded(true);
      setLogIsDownload && setLogIsDownload(false)
    }
  };

  return (
    <div className={loader(isLoaded)}>
      <div
        className={'pt-1 mb-1 rounded overflow-auto scroll-c'}
        style={{ maxHeight: '600px' }}
        onClick={(e) => e.stopPropagation()}
      >
        {fieldsLog.map((item, index) => (
          <p key={index} className={'mb-25'}>
            {item[0]}: {item[1]}
          </p>
        ))}

        <div className={'mt-1'}>
          <strong>
            {isEmpty(log?.exceptions) ? 'Нет данных об ошибке' : 'Ошибки:'}
          </strong>

          {log?.exceptions.map(
            ({ title, subtitle, type, exception_message }, ind) => {
              return (
                <div
                  key={ind}
                  className={'border border-1 rounded-lg p-1 mt-50 bg-white'}
                >
                  <strong>
                    {title}
                    <br />
                    {subtitle}
                  </strong>
                  <p className={'mt-0'}>{type}</p>
                  <strong>Сообщение об ошибке: </strong> {exception_message}
                </div>
              );
            }
          )}
        </div>
      </div>

      <ButtonWithIcon
        disabled={isDownloaded}
        className={'d-block ml-auto'}
        type={'save'}
        onClick={exportLog}
      >
        Скачать
      </ButtonWithIcon>
    </div>
  );
};
