import { WithTooltip } from '../UI/WithTooltip';
import { ReactComponent as Info } from './icons/info-circle.svg'

export const DescriptionBabble = ({text, tooltip, textClassName = '',  rightPosition = '-2px'}) => {
  return (
    <>
      <span className={textClassName}>{text}</span>
      <span style={{position: 'relative', top: '-7px', right: rightPosition, fontWeight: '400'}}>
            <WithTooltip
              elem={<Info/>}
              tooltips={[tooltip]}
            />
      </span>
    </>
   
  )
}