import {useUpdateEffect} from "@react-hookz/web"
import {useMemo} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {servicesAPI} from "../../../API/servicesAPI"
import {ButtonGoBack} from "../../../components/button/ButtonGoBack"
import {EmptyDataWithoutSearch} from "../../../components/EmptyDataWithoutSearch/EmptyDataWithoutSearch"
import {EmptyFoundDataMessage} from "../../../components/EmptyFoundDataMessage/EmptyFoundDataMessage"
import {ModalSwiper} from "../../../components/modals/ModalSwiper"
import {FullPagination} from "../../../components/paginations/FullPagination"
import {Card} from "../../../components/UI/Card"
import {Head} from "../../../components/UI/Head"
import {urlParams} from "../../../constants/urlParams"
import {useComboSearch} from "../../../hooks/url/useComboSearch"
import {useFetchStates} from "../../../hooks/useFetchStates"
import {getLocaleFullDate, getShortFullNameFromObj, isEmpty, loader} from "../../../js-helpers/helpers"
import {Img} from "../../../components/UI/Img"
import FiltersPageCommonMedia from "./FiltersPageCommonMedia"

const {SEARCH, PAGE, LIMIT, USER, DEVICE, DATE_FROM, DATE_TO} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 'last'},
	{param: LIMIT},
	{param: USER},
	{param: DEVICE},
	{param: DATE_FROM},
	{param: DATE_TO}
]

const PageCommonMedia = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const {pathname, search, hash} = location
	
	const [
		[urlSearchQuery],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlUser],
		[urlDevice],
		[urlDateFrom],
		[urlDateTo],
	] = useComboSearch(pageSearchParams);
	
	const media = useFetchStates(
		() => servicesAPI.getScreenShots(
			urlSearchQuery, urlUser, urlDevice, urlPage, urlLimit, urlDateFrom, urlDateTo
		),
		{initData: {results: []}})
	
	useUpdateEffect(() => {
		media.getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		media.getData()
	}, [urlSearchQuery, urlLimit, urlUser, urlDevice, urlLimit, urlDateFrom, urlDateTo])
	
	const onPhotoClick = (id) => () => {
		navigate({pathname, search, hash: id.toString()}, {replace: true})
	}
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const filtersAndSearchIsEmpty = useMemo(() => {
		return !isEmptySearchQuery || urlUser || urlDevice || urlDateFrom || urlDateTo
	}, [isEmptySearchQuery, urlUser, urlDevice, urlDateFrom, urlDateTo])
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head children={'Скриншоты'}/>
			
			<FiltersPageCommonMedia type={'all'}/>
			
			<Card>
				
				{
					filtersAndSearchIsEmpty && media.data.results.length === 0 ?
						<div style={{marginBottom: '10vh'}}>
							<EmptyFoundDataMessage isDataReady={media.isReady}/>
						</div> : !filtersAndSearchIsEmpty && media.data.results.length === 0 && media.isReady ?
							<EmptyDataWithoutSearch isDataReady={media.isReady}/>
							:
							<Card size={0}
							      as={'ul'}
							      className={`row mx-0 row-cols-5 list-unstyled ${loader(media.isReady)}`}
							      style={{minHeight: '25vh'}}
							>
								
								{media.data.results.map(({id, thumbnail, date, device, user}) => {
									const localeDate = getLocaleFullDate(date)
									const screenshotData = <div>
										{localeDate}
										<br/>
										{device ?
											<>
												<Link to={`/admins/devices/${device?.serial_number}/info/`}>{device?.serial_number}</Link>
												<br/>
											</> : ''
										}
										{
											user ?
												<>
													<Link to={`/admins/users/${user?.keycloak_id}/info/`}>{getShortFullNameFromObj(user)}</Link>
													<br/>
												</> : ''
										}
									</div>
									
									return (
										<li key={id} className={'col mb-1'}>
											<Img className={'rounded-md hover-c-scale cursor-pointer obj-fit-contain'}
											     height={550}
											     width={'100%'}
											     src={thumbnail}
											     alt={localeDate}
											     holderClassName={'w-100'}
											     onClick={onPhotoClick(id)}
											/>
											<figcaption className={'font-small-3 mt-50 text-gray-500'}
											            children={screenshotData}/>
										</li>
									)
								})}
							
							</Card>}
				
				<ModalSwiper isOpen={hash && media.data} photos={media.data?.results} type={'all'}/>
			</Card>
			
			<FullPagination allCount={media.data?.count} className={'ml-auto mt-1 px-1'}/>
		</>
	)
}

export default PageCommonMedia