import { getFullNameFromObj, getLocaleFullDate } from "../../../js-helpers/helpers"

export const typesData = [
  { 
    type: 'name',
    key: 'admin',
    title: 'АВТОР ДОБАВЛЕНИЯ ВЕРСИИ'
  }, { 
    type: 'date',
    key: 'release_date',
    title: 'ДАТА И ВРЕМЯ СОЗДАНИЯ'
  },
]

export const VersionCreatedData = ({type, data, title}) => {
  return (
    <>
      <label className={'text-gray-300'}>{title}</label>
      <input value={type === 'name' ? getFullNameFromObj(data) : type === 'date' ? getLocaleFullDate(data) : data}
             className={'col-6 form-control mb-1'}
             disabled/>
    </>
  )
}
