import {Card} from '../../../../components/UI/Card';
import {getFullNameFromObj, isEmpty, isFunction, loader} from '../../../../js-helpers/helpers';
import {MyAvatar} from '../../../../components/UI/MyAvatar';
import {Head} from '../../../../components/UI/Head';
import {Columns} from '../../../../components/UI/Columns';
// import {Link} from 'react-router-dom';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {userAPI} from '../../../../API/userAPI';
import {LOGIN_STATUS} from '../../../../constants/constants';
import { useMemo } from 'react';

const prepareUserInfo = userData => {
	const userInfoOptions = [
		// {label: 'фио', field: getFullNameFromObj},
    {label: 'Дивизион', field: 'division'},
    {label: 'подразделение', field: 'subdivision'},
    {
			label: 'Корпоративный номер телефона',
			field: ({phone_numbers}) => isEmpty(phone_numbers) ? '-' : phone_numbers.join(', '),
		},
		// {label: 'адрес прописки', field: 'register_address'},
		// {label: 'Должность', field: 'position'},
		// {label: 'Адрес проживания', field: 'residence_address'},
		{label: 'Адрес электронной почты', field: 'email'},
		// {label: 'Дата регистрации', field: ({date_joined}) => getLocaleFullDate(date_joined)},
	]
	
	const emptyUserInfo = isEmpty(userData)
	
	return userInfoOptions.map(option => {
		const value =
			emptyUserInfo
				? '-'
				: isFunction(option.field)
					? option.field(userData)
					: userData[option.field] || '-'
		
		return ({...option, value})
	})
}


export const UserInfoAboutCard = ({userID, className = '', userStates}) => {
	const {account = {}} = userStates.data,
		{first_name, profile_photo} = account,
		prepareColumns = prepareUserInfo(account)
	
	const userData = useFetchStates(
		() => userAPI.getUserInfo(userID),
		{initData: {}})

  const loginStatus = useMemo(() => userData?.data?.login_status, [userData.data])
	
	return (
		<Card secondary className={`${className} ${loader(userStates.isReady)}`}>
			
			<div className='mb-2 pb-1 border-bottom-extra-light d-flex'>

        <MyAvatar size={120}
			            className={'mr-2'}
			            alt={first_name}
			            src={profile_photo}/>

        <div>
				  <div className={`${loginStatus === 2 ? 'badge-light-warning' : loginStatus === 1 ? 'badge-light-success' : 'badge-muted'} badge-custom-pill font-small-3 mt-50 mb-1`}>
					{LOGIN_STATUS[loginStatus]}
				  </div>
				  
          <Head secondary noMargins>
            {getFullNameFromObj(account)}
          </Head>
          <div className={`${!account?.position ? 'text-muted' : ''} font-small-3 mt-50`}>
            {account?.position ? account?.position : 'Должность не указана'}
          </div>
        </div>
			</div>
			
			<Columns cols={2}
			         sumItems={prepareColumns.length}
			         className={'list-unstyled mb-0'}>
				
				{prepareColumns?.map(({label, value = '-'}) => (
					<li key={label}>
						<label className={'text-gray-300 d-block'}>{label}</label>
						{value}
					</li>
				))}
			</Columns>
			
			{/* <Link to={`/admins/users/${userID}/settings/`}
			      className={'text-underline float-right'}>
				Подробнее
			</Link> */}
			<br/>
		</Card>
	)
}