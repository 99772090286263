import React from 'react';

import {ReactComponent as LogoSvg} from './logo.svg';

const Logo = ({className, height = '1rem'}) => {
	
	return (
		<LogoSvg height={height}
		         width={null}
		         className={className}
		/>
	)
}

export default Logo;
