import { useState } from "react";
import { Card } from "../../UI/Card";

const Sound = ({volume, setPlayerState, className}) => {
  const [showVolumeRange, setShowVolumeRange] = useState(false);

  return (
    <div className={`d-flex position-relative ${className ? className : ''}`}>
      {showVolumeRange &&
        <Card style={{width: '200px', right: '40px', top: '5px'}} className={'position-absolute p-50'}>
        <div className='w-100 d-flex position-relative'>
          <input
            min={0}
            max={1}
            step='any'
            type='range'
            value={volume}
            onChange={(e) => {
              setPlayerState((draft) => {
                draft.volume = +e.target.value
              })
              localStorage.setItem('callsVolume', `${e.target.value}`)
            }}
            // disabled={badURL}
            className={'audio-c-range min-c-touch-zone'}
            // {...inputProps}
          />

          <progress max={'1'} value={volume} className={'w-100'} />
        </div>
        </Card>
      }

      <button type='button'
              className='p-0 btn rounded-circle text-primary text-primary-custom' 
              onClick={() => {setShowVolumeRange(!showVolumeRange)}}>
        <i className={`bx ${volume !== '0' && volume <= '0.5' ?'bx-volume-low' : volume !== '0' && volume > '0.5' ? 'bx-volume-full' : 'bx-volume-mute'} font-large-1`} ></i>
      </button>
    </div>
  )
}

export default Sound