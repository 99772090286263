import { useUpdateEffect } from "@react-hookz/web"
import { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { launchersAPI } from "../../../API/launchersAPI"
import { ButtonGoBack } from "../../../components/button/ButtonGoBack"
import { MyTable } from "../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../components/table/MyTable/MyTableHead"
import { launcherTableOptions } from "../../../components/table/MyTable/options/columnsLauncher"
import { useMyTable } from "../../../components/table/MyTable/useMyTable"
import { Head } from "../../../components/UI/Head"
import { urlParams } from "../../../constants/urlParams"
import { useComboSearch } from "../../../hooks/url/useComboSearch"
import { useFetchStates } from "../../../hooks/useFetchStates"
import { loader } from "../../../js-helpers/helpers"
import { PageLauncherFilters } from "./pageLauncherFilters/PageLauncherFilters"

const {SEARCH, PAGE, SORT, LIMIT, LAUNCHER_VER, CHANNEL, ADMIN, DATE_FROM, DATE_TO} = urlParams
const pageSearchParams = [
  {param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 'last'},
  {param: SORT, init: 'release_date'},
	{param: LIMIT},
  {param: LAUNCHER_VER},
  {param: CHANNEL},
  {param: ADMIN},
  {param: DATE_FROM},
  {param: DATE_TO},
]


export const PageLauncher = () => {
  const [
    [urlSearchQuery, setUrlSearchQuery],
		[urlPage, setUrlPage],
    [urlSortField],
		[urlLimit],
    [urlLauncherVer],
    [urlChannel],
    [urlAdmin],
    [urlDateFrom],
    [urlDateTo],
	] = useComboSearch(pageSearchParams)

  const { data, getData, isReady } = useFetchStates(() => 
    launchersAPI.getAll(urlPage, urlSearchQuery, urlSortField, urlLimit, urlLauncherVer, urlChannel, urlDateFrom, urlDateTo, urlAdmin)
  );

  useEffect(() => {
    getData();
  }, [urlPage, urlSortField]);

  useUpdateEffect(() => {
    if (urlPage !== 'last') {
      setUrlPage('last');
      return;
    }
    getData();
  }, [urlSearchQuery, urlLimit, urlLauncherVer, urlChannel,urlDateFrom, urlDateTo, urlAdmin]);

  const {tableHeads, tableOptions} = useMyTable(launcherTableOptions)

  const showEmptyFoundDataMessage = useMemo(() => 
    urlSearchQuery || urlLauncherVer || urlChannel || urlAdmin || urlDateFrom || urlDateTo, 
  [urlSearchQuery, urlLauncherVer, urlChannel, urlAdmin, urlDateFrom, urlDateTo])

  return (
    <>
      <ButtonGoBack className={'float-right'} toMenu/>
      <Link to={'create/'} className={'btn btn-primary float-right mr-1'}>Добавить версию</Link>

      <Head children={'Обновление версии лаунчера'}/>

      <PageLauncherFilters/>

      <MyTable isDataReady={isReady}
			         showEmptyFoundDataMessage={!!showEmptyFoundDataMessage}
			         paginationAllCount={data?.count}
      >
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={data?.results}
				             tableOptions={tableOptions}
				             className={loader(isReady)}
				             highestRowToFill={25}
				/>
			</MyTable>

    </>
  )
}