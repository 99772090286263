import {useCustomSearch} from '../../hooks/url/useCustomSearch';
import {toggle as toggleHelper} from '../../js-helpers/helpers';

export function ListServiceButtons({arrServices, toggle, searchName,}) {
	const [serviceSearch, setServiceSearch, removeService] =
		useCustomSearch(searchName || 'service')
	
	let toggleClassName = (service) =>
		toggleHelper(serviceSearch === service, 'bg-white text-body', 'text-gray-500')
	
	const onClick = (service) => {
		if (toggle && serviceSearch === service) {
			removeService()
			return
		}
		setServiceSearch(service)
	}
	
	return (
		arrServices.map(({text, service, disabled, Icon}) => (
			<li key={service}>
				<button disabled={disabled}
				        className={`btn c-hover-opacity px-1 btn-block text-left font-14px ${toggleClassName(service)}`}
				        onClick={() => onClick(service)}
				>
					{Icon
						? <Icon className={'svg-curr-color svg-service-size'}/>
						: <Img type={service}/>}
					<span className={'ml-75 align-middle'}>{text}</span>
				</button>
			</li>
		)))
}

function Img({type}) {
	// const [svgWidth, svgHeight] = ['1.66em', '1.66em']
	const sizeProps = {width: '1.66em', height: '1.66em'}
	
	switch (type) {
		case 'telegram':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 30 30'>
					<path stroke='currentColor'
					      d='M29.5 15c0 8.008-6.492 14.5-14.5 14.5S.5 23.008.5 15 6.992.5 15 .5 29.5 6.992 29.5 15z'/>
					<path fill='currentColor'
					      d='M6.865 14.675l14.462-5.576c.672-.243 1.258.163 1.04 1.178l.002-.001-2.463 11.601c-.182.823-.671 1.023-1.355.635l-3.75-2.764-1.809 1.743c-.2.2-.368.369-.756.369l.266-3.816 6.95-6.28c.303-.265-.067-.415-.466-.15l-8.589 5.407-3.702-1.155c-.804-.255-.821-.804.17-1.191z'/>
				</svg>
			)
		
		case 'skype':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 30 30'>
					<path stroke='currentColor'
					      d='M29.5 15c0 8.008-6.492 14.5-14.5 14.5S.5 23.008.5 15 6.992.5 15 .5 29.5 6.992 29.5 15z'/>
					<path fill='currentColor' stroke='currentColor' strokeWidth='.4'
					      d='M24.636 15.866h0l.004-.004a.94.94 0 00-.127-1.399l-.27-.231c-.712-.613-1.437-1.236-2.21-1.778a12.84 12.84 0 00-4.652-2.04h0l-.002-.001a12.979 12.979 0 00-11.736 3.45c-.09.08-.174.164-.254.253a.954.954 0 00.103 1.428h0l.007.005c.3.21.6.181.854.068.21-.094.407-.253.562-.38l.058-.046h0c.177-.144.347-.288.516-.431.366-.31.724-.612 1.114-.883a10.858 10.858 0 018.672-1.609h.001c1.425.332 2.765.959 3.933 1.839.579.439 1.127.917 1.68 1.4l.339.294h0c.191.165.425.291.674.322a.86.86 0 00.734-.257z'/>
					<path fill='currentColor'
					      d='M21.07 17.545c.099.103.185.217.258.34a.707.707 0 01-.887.99 2.069 2.069 0 01-.473-.371 7.747 7.747 0 00-2.655-1.771 7.163 7.163 0 00-.676-.221 7 7 0 00-4.026.129c-.77.277-1.499.661-2.162 1.142-.292.211-.567.439-.853.656a.677.677 0 01-.986-.081.67.67 0 01.058-.996 9.74 9.74 0 013.614-2.118 8.174 8.174 0 015.034-.03 9.24 9.24 0 012.632 1.38c.109.072.215.15.316.234.22.187.431.384.7.619.024.024.058.05.105.098z'/>
				</svg>
			)
		
		case 'whatsapp':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 30 30'>
					<path stroke='currentColor'
					      d='M3.33 23.95l.082-.244-.152-.208A14.39 14.39 0 01.5 15C.5 7.004 7.002.5 14.996.5h.008C22.998.5 29.5 7.005 29.5 15s-6.502 14.5-14.496 14.5a14.37 14.37 0 01-7.976-2.396l-.2-.132-.228.073-4.826 1.543L3.33 23.95z'/>
					<path fill='currentColor'
					      d='M23.732 21.182c-.362 1.021-1.798 1.869-2.944 2.117-.784.166-1.807.3-5.254-1.13-4.408-1.826-7.246-6.305-7.468-6.596-.211-.29-1.78-2.371-1.78-4.524 0-2.152 1.092-3.2 1.533-3.65.362-.37.96-.539 1.534-.539.185 0 .352.01.502.017.44.019.662.045.953.74.361.873 1.243 3.025 1.348 3.247.107.22.213.52.063.811-.14.3-.264.433-.485.688-.221.255-.431.45-.653.724-.202.238-.43.493-.176.934.255.431 1.136 1.87 2.434 3.024 1.674 1.491 3.032 1.967 3.517 2.17.362.15.793.114 1.058-.167.335-.362.75-.962 1.172-1.553.3-.423.679-.476 1.076-.326.405.14 2.548 1.2 2.989 1.42.44.22.731.326.838.511.105.186.105 1.058-.257 2.082z'/>
				</svg>
			)
		
		case 'sms':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 30 30'>
					<path stroke='currentColor'
					      d='M29.5 15c0 8.008-6.492 14.5-14.5 14.5S.5 23.008.5 15 6.992.5 15 .5 29.5 6.992 29.5 15z'/>
					<g clipPath='url(#clip0_894_29744)'>
						<path fill='currentColor' stroke='currentColor' strokeLinecap='round'
						      d='M22.375 9.375H6.625v12.25h15.75V9.375z'/>
						<path stroke='#C1C1C1' strokeLinecap='round' d='M4 10l10.5 7L25 10'/>
					</g>
					<defs>
						<clipPath id='clip0_894_29744'>
							<path fill='currentColor' d='M0 0h21v21H0z' transform='translate(4 5)'/>
						</clipPath>
					</defs>
				</svg>
			)
		
		case 'viber':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 30 30'>
					<path stroke='currentColor'
					      d='M29.5 15c0 8.008-6.492 14.5-14.5 14.5S.5 23.008.5 15 6.992.5 15 .5 29.5 6.992 29.5 15z'/>
					<path fill='currentColor'
					      d='M23.664 10.133v-.022c-.201-.815-.726-1.657-1.415-2.366-.804-.832-1.829-1.484-2.807-1.696h-.02a23.468 23.468 0 00-8.834 0h-.02c-1.817.394-3.79 2.293-4.23 4.062v.022a17.31 17.31 0 000 7.447v.02c.314 1.262 1.405 2.588 2.663 3.39.411.267.858.475 1.328.615v1.974a.794.794 0 001.364.55l2.001-2.08a23.545 23.545 0 005.719-.382h.02c1.812-.395 3.786-2.293 4.222-4.063v-.02a17.31 17.31 0 00.009-7.45zm-1.584 7.083c-.293 1.154-1.79 2.59-2.98 2.855a21.907 21.907 0 01-4.699.383.1.1 0 00-.082.034l-1.46 1.5-1.555 1.594a.182.182 0 01-.28-.023.18.18 0 01-.032-.101v-3.273a.112.112 0 00-.092-.11 2.889 2.889 0 01-.712-.273c-1.013-.54-2.034-1.649-2.27-2.586a15.682 15.682 0 010-6.732c.292-1.148 1.798-2.582 2.98-2.848a21.818 21.818 0 018.201 0c.656.147 1.406.646 1.993 1.26.482.5.852 1.075.988 1.593.487 2.216.487 4.511 0 6.727z'/>
					<path fill='currentColor'
					      d='M19.142 17.387a2.527 2.527 0 01-1.085 1.193 2.592 2.592 0 01-.468.148c-.183-.055-.357-.092-.512-.159a12.935 12.935 0 01-3.562-2.094 8.963 8.963 0 01-.892-.875A11.557 11.557 0 0110.92 13c-.218-.445-.402-.905-.59-1.364-.17-.417.082-.852.34-1.164.255-.29.566-.521.916-.682a.59.59 0 01.735.17c.435.49.82 1.023 1.145 1.591a.757.757 0 01-.213 1.023c-.085.06-.17.128-.243.194a.768.768 0 00-.184.194.534.534 0 00-.041.473c.329.905.828 1.66 1.575 2.19.252.179.524.328.81.445.188.102.398.153.611.15.372-.044.495-.455.754-.667a.715.715 0 01.852-.039c.276.17.542.363.808.55.263.18.516.374.758.58a.6.6 0 01.19.744zM15.49 9.218h-.219c.07-.003.144 0 .219 0z'/>
					<path fill='currentColor'
					      d='M19.262 14.326c-.17 0-.254-.145-.266-.303-.022-.312-.04-.626-.082-.936a3.92 3.92 0 00-3.16-3.278c-.245-.044-.496-.055-.743-.08-.157-.017-.363-.027-.397-.222a.268.268 0 01.264-.303 4.531 4.531 0 013.873 2.045 4.48 4.48 0 01.644 1.652c.065.368.089.747.118 1.122a.263.263 0 01-.251.303z'/>
					<path fill='currentColor'
					      d='M18.339 13.53a.65.65 0 01-.02.15.25.25 0 01-.471.026.736.736 0 01-.026-.211 2.745 2.745 0 00-.221-1.11 2.482 2.482 0 00-1.156-1.202 3.066 3.066 0 00-.829-.255c-.126-.02-.252-.033-.378-.05a.24.24 0 01-.227-.267.238.238 0 01.265-.235c.5.019.99.147 1.436.375.545.274.99.713 1.27 1.254.159.316.264.656.314 1.006.01.046.016.091.02.138.009.102.012.204.02.33 0 .013.003.028.003.052z'/>
					<path fill='currentColor'
					      d='M17.172 13.295a.221.221 0 01-.181.17h-.038a.257.257 0 01-.259-.17.342.342 0 01-.02-.087 2.422 2.422 0 00-.051-.341.99.99 0 00-.365-.571.965.965 0 00-.295-.143c-.135-.04-.276-.03-.409-.062a.252.252 0 01-.205-.291.264.264 0 01.278-.211c.78.056 1.364.43 1.514 1.268.015.077.026.156.032.235a.686.686 0 01-.001.203z'/>
				</svg>
			)
		
		case 'photo':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
					<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'
					      d='M21 17.444c0 .86-.733 1.556-1.636 1.556H4.636C3.733 19 3 18.304 3 17.444V8.89c0-.86.733-1.556 1.636-1.556H7.91L9.545 5h4.91l1.636 2.333h3.273c.903 0 1.636.697 1.636 1.556v8.555z'
					      clipRule='evenodd'/>
					<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'
					      d='M12 16a3 3 0 100-6 3 3 0 000 6z' clipRule='evenodd'/>
				</svg>
			)
		
		case 'video':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
					<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'
					      d='M21.882 7.398l-6.288 4.286 6.288 4.286V7.398zM2.117 7.685a2 2 0 012-2h9.475a2 2 0 012 2v8a2 2 0 01-2 2H4.117a2 2 0 01-2-2v-8z'
					      clipRule='evenodd'/>
				</svg>
			)
		
		case 'screenshots':
			return (
				<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
					<path fill='currentColor'
					      d='M8.444 4.5a.5.5 0 000-1v1zM3.5 8.444a.5.5 0 001 0h-1zm16 0a.5.5 0 001 0h-1zM15.556 3.5a.5.5 0 100 1v-1zm0 16a.5.5 0 100 1v-1zm4.944-3.944a.5.5 0 10-1 0h1zm-16 0a.5.5 0 10-1 0h1zM8.444 20.5a.5.5 0 000-1v1zm0-17H5.778v1h2.666v-1zm-2.666 0A2.278 2.278 0 003.5 5.778h1c0-.706.572-1.278 1.278-1.278v-1zM3.5 5.778v2.666h1V5.778h-1zm17 2.666V5.778h-1v2.666h1zm0-2.666A2.278 2.278 0 0018.222 3.5v1c.706 0 1.278.572 1.278 1.278h1zM18.222 3.5h-2.666v1h2.666v-1zm-2.666 17h2.666v-1h-2.666v1zm2.666 0a2.278 2.278 0 002.278-2.278h-1c0 .706-.572 1.278-1.278 1.278v1zm2.278-2.278v-2.666h-1v2.666h1zm-17-2.666v2.666h1v-2.666h-1zm0 2.666A2.278 2.278 0 005.778 20.5v-1A1.278 1.278 0 014.5 18.222h-1zM5.778 20.5h2.666v-1H5.778v1z'/>
				</svg>
			)
		
		// case 'web-history':
		// 	return (
		// 		<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
		// 			<path fill='currentColor'
		// 			      d='M17.59 5.47c-3.464-3.465-8.988-3.645-12.667-.542L4.732 3.1a.469.469 0 10-.933.097l.31 2.961a.469.469 0 00.411.417l3.005.354a.469.469 0 00.11-.931l-2.217-.261c3.31-2.889 8.357-2.758 11.51.395 3.289 3.29 3.289 8.642 0 11.932-3.29 3.29-8.643 3.29-11.933 0a.469.469 0 00-.663.663 9.346 9.346 0 006.629 2.741c2.4 0 4.801-.913 6.63-2.741 3.655-3.655 3.655-9.603 0-13.258z'/>
		// 			<path fill='currentColor'
		// 			      d='M6.042 13.55a.468.468 0 00.083-.007l5.634-.994a.469.469 0 00.376-.359l1.051-4.64a.469.469 0 10-.914-.207l-.982 4.336-5.328.94a.469.469 0 00.08.931z'/>
		// 		</svg>
		// 	)
		//
		// case 'app-notifications':
		// 	return (
		// 		<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
		// 			<path stroke='currentColor' d='M12 5c-3.24 0-6 2.745-6 6v3l-2 4h16l-2-4v-3c0-3.255-2.76-6-6-6z'/>
		// 			<path stroke='currentColor' strokeLinecap='round' d='M10 5a2 2 0 114 0m1 13a3 3 0 11-6 0h6z'/>
		// 		</svg>
		// 	)
		//
		// case 'keyboards':
		// 	return (
		// 		<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
		// 			<path stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10'
		// 			      d='M9 15h6m5 3H4a1 1 0 01-1-1V7a1 1 0 011-1h16a1 1 0 011 1v10a1 1 0 01-1 1z'/>
		// 			<path stroke='currentColor' strokeDasharray='.01 4.5' strokeLinecap='round' strokeLinejoin='round'
		// 			      strokeMiterlimit='10' d='M9 12V6m-3 9V6v9zm12 0V6v9zm-3-3V6v6zm-3 0V6v6z'/>
		// 		</svg>
		// 	)
		//
		// case 'clipboards':
		// 	return (
		// 		<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
		// 			<path stroke='currentColor' strokeLinecap='round'
		// 			      d='M15.625 4H18a1 1 0 011 1v15a1 1 0 01-1 1H6a1 1 0 01-1-1V5a1 1 0 011-1h2.375'/>
		// 			<path stroke='currentColor' strokeLinecap='round'
		// 			      d='M15 4a1 1 0 00-1-1h-4a1 1 0 00-1 1v1a1 1 0 001 1h4a1 1 0 001-1V4z'/>
		// 		</svg>
		// 	)
		
		// case 'sim-cards':
		// 	return (
		// 		<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
		// 			<path stroke='currentColor'
		// 			      d='M19 21.5H5a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h10.086a.5.5 0 01.353.146l1.707 1.708 2.208 2.207a.5.5 0 01.146.353V21a.5.5 0 01-.5.5z'/>
		// 			<rect width='9' height='7' x='7.5' y='11.5' stroke='currentColor' rx='.5'/>
		// 		</svg>
		// 	)
		//
		// case 'device-shutdowns':
		// 	return (
		// 		<svg {...sizeProps} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
		// 			<path
		// 				d='M15.6578 4.8335C16.7763 5.9123 17.538 7.28665 17.8465 8.78279C18.1549 10.2789 17.9964 11.8296 17.3908 13.2389C16.7852 14.6481 15.7598 15.8526 14.4443 16.7C13.1288 17.5475 11.5821 17.9998 10 17.9998C8.41786 17.9998 6.87125 17.5475 5.55571 16.7C4.24018 15.8526 3.21479 14.6481 2.60922 13.2389C2.00364 11.8296 1.84506 10.2789 2.15353 8.78279C2.462 7.28665 3.22367 5.9123 4.34223 4.8335'
		// 				stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'/>
		// 			<path d='M10.0039 2V9.42729' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'/>
		// 		</svg>
		// 	)
		//
		// case 'app-launch':
		// 	return (
		// 		<svg xmlns='http://www.w3.org/2000/svg' {...sizeProps} fill='none' viewBox='0 0 24 24'>
		// 			<path stroke='currentColor' strokeLinecap='round' d='M9 10.625L12.375 14 21 4.5'/>
		// 			<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'
		// 			      d='M16 3.936A8.986 8.986 0 1020.775 10'/>
		// 		</svg>
		// 	)
		
		case 'outer':
			return (
				<svg {...sizeProps} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M10.0007 2.0835C10.2308 2.0835 10.4173 2.27004 10.4173 2.50016C10.4173 2.71384 10.242 2.91683 10.0007 2.91683H4.16732C3.50162 2.91683 2.91732 3.45383 2.91732 4.16683V15.8335C2.91732 16.4992 3.45522 17.0835 4.16732 17.0835H15.834C16.4997 17.0835 17.084 16.5485 17.084 15.8335V10.0002C17.084 9.77004 17.2706 9.58349 17.5007 9.58349C17.7144 9.58349 17.9173 9.75523 17.9173 10.0002V15.8335C17.9173 16.9538 16.9991 17.9168 15.834 17.9168H4.16732C3.047 17.9168 2.08398 16.9923 2.08398 15.8335V4.16683C2.08398 3.04651 3.00072 2.0835 4.16732 2.0835H10.0007ZM17.5027 2.50016C17.7129 2.50016 17.9194 2.66834 17.9194 2.91683V7.08349C17.9194 7.31361 17.7329 7.50016 17.5027 7.50016C17.289 7.50016 17.0861 7.33228 17.0861 7.08349V3.92349L9.88068 11.1281C9.71796 11.2908 9.45414 11.2908 9.29143 11.1281C9.14122 10.9779 9.11137 10.712 9.29143 10.5389L16.4969 3.33349H13.3361C13.0839 3.33349 12.9194 3.13051 12.9194 2.91683C12.9194 2.70315 13.0839 2.50016 13.3361 2.50016H17.5027Z'
						fill='currentColor'/>
				</svg>
			)
		
		case 'personal':
			return (
				<svg {...sizeProps} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M10.5775 10.6724C7.8772 10.6721 3.33754 12.1422 3 18.0763' stroke='currentColor'/>
					<path d='M10.4225 10.6724C13.1228 10.6721 17.6625 12.1422 18 18.0763' stroke='currentColor'/>
					<path
						d='M14.6504 6.53823C14.6504 8.80003 12.7697 10.6536 10.4248 10.6536C8.07988 10.6536 6.19922 8.80003 6.19922 6.53823C6.19922 4.27642 8.07988 2.42285 10.4248 2.42285C12.7697 2.42285 14.6504 4.27642 14.6504 6.53823Z'
						stroke='currentColor'/>
				</svg>
			)
		
		case 'shared':
			return (
				<svg {...sizeProps} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
					
					<path
						d='M2 8V4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V12'
						stroke='currentColor' strokeWidth='0.8' strokeLinecap='round'/>
					<path
						d='M14.9039 9.6839C15.0528 9.83225 15.0803 10.0971 14.9049 10.2732L11.9636 13.2244C11.8012 13.3874 11.5373 13.3878 11.3744 13.2254C11.223 13.0745 11.1977 12.8123 11.3734 12.6361L13.6041 10.3979L3.41466 10.4145C3.18454 10.4149 2.99768 10.2287 2.99729 9.99855C2.99693 9.78613 3.16349 9.57672 3.41326 9.58118L13.6032 9.56411L11.3644 7.33283C11.1859 7.15485 11.2126 6.89492 11.3634 6.74357C11.5143 6.59222 11.7741 6.5646 11.9527 6.74258L14.9039 9.6839Z'
						fill='currentColor'/>
				</svg>
			)
		// case 'crashes':
		// 	return (
		// 		<svg {...sizeProps} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		// 			<path
		// 				d='M15.8333 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V5.00004C17.5 4.07957 16.7538 3.33337 15.8333 3.33337Z'
		// 				stroke='#757575' strokeLinecap='round' strokeLinejoin='round'/>
		// 			<path d='M13.334 1.66663V4.99996' stroke='#757575' strokeLinecap='round' strokeLinejoin='round'/>
		// 			<path d='M6.66602 1.66663V4.99996' stroke='#757575' strokeLinecap='round' strokeLinejoin='round'/>
		// 			<path d='M2.5 8.33337H17.5' stroke='#757575' strokeLinecap='round' strokeLinejoin='round'/>
		// 		</svg>
		// 	)
		
		default:
			return null
	}
}
