import {useEffect} from 'react';
import {servicesAPI} from '../../API/servicesAPI';
import {useNewFind} from '../../hooks/useNewFind';
import {SearchInput} from '../form/SearchInput';
import {ExceptionList} from './ExceptionList';
import {isNotEmpty, loader} from '../../js-helpers/helpers';
import {userAPI} from '../../API/userAPI';
import {oneOf} from 'prop-types';
import {appsAPI} from '../../API/appsAPI';
import { backgroundClassName, getItemClassName } from './ExceptionsListsFunctions';
import { groupsAPI } from '../../API/groupsAPI';


const options = {
  sites: ['Все сайты', servicesAPI.getSites],
  users: ['Все пользователи', userAPI.searchUsers, 'account'],
  apps: ['Все приложения', appsAPI.getNoSystemApps],
  groups: ['Все группы', groupsAPI.getGroups]
}

export function ExceptionsLists(
	{
		type,
		setExceptions,
		className = '',
		headExceptions,
		exceptions = [],
		disabled = false,
		headAllItems,
		onItemClick,
		background,
    showInfo
	}) {
	const enabled = !disabled

	const [defaultHeadAllItems, requestMethod, nestedFieldToSet] = options[type]
	const finalHeadAllItems = headAllItems || defaultHeadAllItems
	
	const {
		lastSearchPage,
		searchQuery,
		setSearchQuery,
		searchPage,
		setSearchPage,
		searchItemsStates
	} = useNewFind(requestMethod, nestedFieldToSet)
	
	useEffect(() => {
		setSearchPage(1)
		searchItemsStates.getData({page: 1, search: searchQuery})
	}, [searchQuery])


	const onAllItemsClick = item => {
		if (disabled) return
		onItemClick && onItemClick()
		
    if (type === 'users') {
      const haveExceptionsListItem = exceptions.find(({keycloak_id}) => keycloak_id === item.keycloak_id)

      setExceptions(prevItems => haveExceptionsListItem
        ? prevItems.filter(({keycloak_id}) => keycloak_id !== item.keycloak_id)
        : [item, ...prevItems]
      )
    } else {
      const haveExceptionsListItem = exceptions.find(({id}) => id === item.id)

      setExceptions(prevItems => haveExceptionsListItem
        ? prevItems.filter(({id}) => id !== item.id)
        : [item, ...prevItems]
      )
    }
	
	}
	
	const onExItemClick = item => {
		if (disabled) return
		
		onItemClick && onItemClick()

    if (type === 'users') {
      setExceptions(prevEx => prevEx.filter(({keycloak_id}) => keycloak_id !== item.keycloak_id));
    } else {
      setExceptions(prevEx => prevEx.filter(({id}) => id !== item.id));
    }
		
	}
	
	const isActiveItem = ID => {
    if (type === 'users') {
      return exceptions.some(({keycloak_id}) => keycloak_id === ID)
    } else {
      return exceptions.some(({id}) => id === ID)
    }
  }
	
	const onLastItemVisible = inView => {
		inView && searchItemsStates.getData({page: searchPage, search: searchQuery})
	}
	
	const autoPaginate = {
		skip: !searchItemsStates.isReady || searchItemsStates?.error || lastSearchPage,
		onChange: onLastItemVisible
	}

	return (
		<div className={`row no-gutters rounded ${backgroundClassName[background || 'default']} ${className}`}>
			<ul
				className={'list-group list-unstyled col height-600 float-left disable-rounded-right border-right'}>
				
				<li className={`border-bottom-0 border-right-0 list-group-item bg-transparent ${type==='devices' ? 'p-50' : 'p-1'}`}>
          <h3 className={'font-weight-bold m-0'}>
            {finalHeadAllItems}
          </h3>
				</li>
				
				<li className={'p-0 border-0 '}>
					<SearchInput
						classNameInput={'rounded-0 bg-transparent'}
						placeholder={'Поиск...'}
						onChange={e => setSearchQuery(e.target.value)}
						defaultValue={searchQuery}
					/>
				</li>
				
				<ExceptionList
					isDataReady={searchItemsStates.isReady}
					type={type}
					itemClassName={getItemClassName(background, disabled)}
					isActiveItem={isActiveItem}
					onClick={onAllItemsClick}
					className={loader(searchItemsStates.isReady)}
					items={searchItemsStates.data}
					autoPaginate={autoPaginate}
          showInfo={showInfo}
				/>
			</ul>
			
			<ul className={'list-group list-unstyled col height-600 disable-rounded-left'}>
				<li className={' list-group-item px-1 bg-transparent'}>
					
					{isNotEmpty(exceptions) && enabled &&
					<button className={'btn btn-link btn-sm float-right p-0 text-uppercase'}
					        onClick={() => setExceptions([])}>
						очистить
					</button>
					}
					
					<h3 className={'font-weight-bold m-0'}>
						{headExceptions}
					</h3>
				</li>
				<ExceptionList
					itemClassName={getItemClassName(background, disabled)}
					type={type}
					onClick={onExItemClick}
					items={exceptions}
					delBtn={enabled}
          showInfo={showInfo}
				/>
			</ul>
		</div>
	)
}

ExceptionsLists.propTypes = {
	type: oneOf(['apps', 'sites', 'users', 'groups']).isRequired,
	background: oneOf(['white']),
}