import {isEmpty, loader} from '../../../js-helpers/helpers';
import {useEffect, useMemo} from 'react';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {deviceAPI} from '../../../API/deviceAPI';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {Head} from '../../../components/UI/Head';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {useUpdateEffect} from '@react-hookz/web';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {exportExcel, pageDevicesConfig} from './PageDevicesFunctions';
import {FiltersPageDevices} from './FiltersPageDevices';
import { useExportData } from '../../../services/export/useExportData';
import { observer } from 'mobx-react-lite';

export const deviceStatuses = {1: 'Активный', 2: 'В архиве'}

const {getDevicesTable, pageSearchParams} = pageDevicesConfig

export const PageDevices = observer(() => {
	const [
		[urlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlSerialNumber],
		[urlUser],
		[urlLauncherVer],
		[urlSetupAdmin],
		[urlModel],
		[urlBrand],
    [urlIsExportMode]
	] = useComboSearch(pageSearchParams)
	
  const exportRequestQuery = {
		search: urlSearchQuery,
		serial_number__in: urlSerialNumber,
		user__account__keycloak_id__in: urlUser,
		setup__admin__account__keycloak_id__in: urlSetupAdmin,
	  brand__in: urlBrand,
		model__in: urlModel,
		launcher_version_name__in: urlLauncherVer
  }

  const requestQuery = {
    page: urlPage || 1,
    ordering: urlSortField,
    page_size: urlLimit,
    ...exportRequestQuery
  }

	const {data: dataState, isReady, getData} = useFetchStates(
		() =>  deviceAPI.getDevices(requestQuery),
		{noInitFetch: true})
	
	useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (+urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData()
	}, [urlSearchQuery, urlLimit, urlSerialNumber,
		urlUser, urlSetupAdmin, urlBrand,
		urlModel, urlLauncherVer])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)

  const pageHead = useMemo(() => {
    return urlIsExportMode ? 'Экспорт Устройств' : 'Устройства'
  }, [urlIsExportMode])
  
  const onSaveExport = (exportData) => {
		exportExcel(exportData)
		exportStates.clearIncludedIDS()
	}

  const {
		ButtonToggleExport,
		CounterToExport,
		ButtonSaveExport,
		checkBoxesColl,
		...exportStates
	} = useExportData({
		requestMethod: deviceAPI.getDevices,
		requestParams: exportRequestQuery,
		pageData: dataState?.results,
		onSaveExport
	})

  const tableOptions = useMemo(() => getDevicesTable(isEmptySearchQuery, urlIsExportMode, checkBoxesColl)
  , [isEmptySearchQuery, urlIsExportMode, checkBoxesColl])

  const {tableHeads} = useMyTable(tableOptions)
	
	const showEmptyFoundDataMessage = useMemo(() => {
		return !urlSearchQuery || urlSerialNumber ||	urlUser || 
    urlSetupAdmin || urlBrand || urlModel || urlLauncherVer
	}, [urlSearchQuery, urlSerialNumber, urlUser, 
    urlSetupAdmin, urlBrand, urlModel, urlLauncherVer])
	
	return (
		<>
      {!urlIsExportMode && <ButtonGoBack className={'float-right ml-1'}/>}
      <ButtonToggleExport className={'float-right'}/>
			<Head>{pageHead}</Head>
			
			<FiltersPageDevices/>
			
      <CounterToExport/>

			<MyTable paginationAllCount={dataState?.count}
			         isDataReady={isReady}
			         showEmptyFoundDataMessage={!!showEmptyFoundDataMessage}
               withScroll
               renderCardContainer={urlIsExportMode}
               appendElement={<ButtonSaveExport/>}
			>
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody
					className={loader(isReady)}
					data={dataState?.results}
					tableOptions={tableOptions}
					highestRowToFill={40.51}/>
			</MyTable>
		</>
	)
})