import {Link} from 'react-router-dom';
import {useEffect, useMemo} from 'react';
import {getFullNameFromObj, getShortFullNameFromObj, isEmpty, loader} from '../../../js-helpers/helpers';
import {getName, WithTooltip} from '../../../components/UI/WithTooltip';
import {contactsAPI} from '../../../API/contactsAPI';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {Head} from '../../../components/UI/Head';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {MyAvatar} from '../../../components/UI/MyAvatar';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {SearchInput} from '../../../components/form/SearchInput';
import {useUpdateEffect} from '@react-hookz/web';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {urlParams} from '../../../constants/urlParams';
import {getInternalContactsTableOptions} from '../../../components/table/MyTable/options/columnsInternalContacts';


const {SEARCH, SORT, PAGE, LIMIT} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: 'last_name'},
	{param: PAGE, init: 1},
	{param: LIMIT}
]

export function PageInternalContacts() {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(pageSearchParams)
	
	const {data, getData, isReady} = useFetchStates(
		contactsAPI.getAllShared
		, {noInitFetch: true})
	
	useEffect(() => {
		getData(urlPage, urlSearchQuery, urlSortField, urlLimit)
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (+urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1, urlSearchQuery, urlSortField, urlLimit)
	}, [urlSearchQuery, urlLimit])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const tableOptions = useMemo(() => getInternalContactsTableOptions(isEmptySearchQuery)
		, [isEmptySearchQuery])
	
	const {tableHeads} = useMyTable(tableOptions)
	
	return (
		<>
			<Head className={'d-inline-block'}>Внутренние контакты</Head>
			
			<ButtonGoBack className={'float-right ml-1'}/>
			<Link to={'/admins/internal-contacts/rules/'}
			      className={'btn btn-outline-primary bg-white px-3 float-right'}>
				Управление правилами
			</Link>
			
			<div className={'px-1 py-75 mb-1 rounded bg-transparent-60'}>
				<SearchInput
					placeholder={'Поиск по внутренним контактам...'}
					defaultValue={urlSearchQuery}
					onChange={e => setUrlSearchQuery(e.target.value)}/>
			</div>
			
			<MyTable paginationAllCount={data?.count}
			         showEmptyFoundDataMessage={!isEmptySearchQuery}
			         isDataReady={isReady}
			>
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={data?.results}
				             className={loader(isReady)}
				             tableOptions={tableOptions}
				             highestRowToFill={45}/>
			</MyTable>
		</>
	)
}