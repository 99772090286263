import {useEffect, useState} from 'react';
import {servicesAPI} from '../../API/servicesAPI';
import {useNewFind} from '../../hooks/useNewFind';
import {SearchInput} from '../form/SearchInput';
import {ExceptionList} from './ExceptionList';
import {isNotEmpty, loader, toggle} from '../../js-helpers/helpers';
import {userAPI} from '../../API/userAPI';
import {oneOf} from 'prop-types';
import {appsAPI} from '../../API/appsAPI';
import { deviceAPI } from '../../API/deviceAPI';
import { MySelect } from '../form/MySelect/MySelect';
import { useUpdateEffect } from '@react-hookz/web';
import { backgroundClassName, getItemClassName } from './ExceptionsListsFunctions';

const devicesOptionsArr = [
  {text: 'Все устройства', value: 0}, 
  {text: 'С пользователем', value: 1}, 
  {text: 'Без пользователя', value: 2}
]


export function ChooseAllExceptionsLists(
	{
		type,
		setExceptions,
		className = '',
		headExceptions,
		exceptions = [],
		disabled = false,
		headAllItems,
		onItemClick,
		background,
	}) {
	const enabled = !disabled

  const [devicesOption, setDevicesOption] = useState(0);

  const options = {
    sites: ['Все сайты', servicesAPI.getSites],
    users: ['Все пользователи', userAPI.searchUsers, 'account'],
    apps: ['Все приложения', appsAPI.getNoSystemApps],
    devices: devicesOption === 0 ? ['Все устройства', (params) => deviceAPI.getDevices({...params, is_notifiable: true})] : devicesOption === 1 ? ['С пользователем', (params) => deviceAPI.getDevices({...params, 'is_free': false, is_notifiable: true})] : devicesOption === 2 ? ['Без пользователя', (params) => deviceAPI.getDevices({...params, 'is_free': true, is_notifiable: true})] : null
  }

	const [defaultHeadAllItems, requestMethod, nestedFieldToSet] = options[type]
	const finalHeadAllItems = headAllItems || defaultHeadAllItems
	
	const {
		lastSearchPage,
		searchQuery,
		setSearchQuery,
		searchPage,
		setSearchPage,
		searchItemsStates
	} = useNewFind(requestMethod, nestedFieldToSet)
	
	useEffect(() => {
		setSearchPage(1)
		searchItemsStates.getData({page: 1, search: searchQuery})
	}, [searchQuery])
	
  useUpdateEffect(() => {
    setSearchPage(1)
		searchItemsStates.getData({page: 1})
  }, [devicesOption])


	const onAllItemsClick = (item) => {
  
		if (disabled) return
		onItemClick && onItemClick()
		
    if (type === 'devices') {
      const haveExceptionsListItem = exceptions.find(({serial_number}) => serial_number === item.serial_number)

      setExceptions(prevItems => haveExceptionsListItem
        ? prevItems.filter(({serial_number}) => serial_number !== item.serial_number)
        : [item, ...prevItems]
      )
    } else if (type === 'users') {
      const haveExceptionsListItem = exceptions.find(({keycloak_id}) =>  keycloak_id === item.keycloak_id)
      setExceptions(prevItems => haveExceptionsListItem
        ? prevItems.filter(({keycloak_id}) => keycloak_id !== item.keycloak_id)
        : [item, ...prevItems]
      )

    } else {
      const haveExceptionsListItem = exceptions.find(({id}) => id === item.id)

      setExceptions(prevItems => haveExceptionsListItem
        ? prevItems.filter(({id}) => id !== item.id)
        : [item, ...prevItems]
      )
    }
	}
	
	const onExItemClick = item => {
		if (disabled) return
		
		onItemClick && onItemClick()

    if (type === 'devices') {
      setExceptions(prevEx => prevEx.filter(({serial_number}) => serial_number !== item.serial_number));
    } else if (type === 'users') {
      setExceptions(prevEx => prevEx.filter(({keycloak_id}) => keycloak_id !== item.keycloak_id));
    } else {
      setExceptions(prevEx => prevEx.filter(({id}) => id !== item.id));
    }
	}

	const isActiveItem = ID => {
    if (type === 'devices') {
      return exceptions.some(({serial_number}) => serial_number === ID)
    } if (type === 'users') {
      return exceptions.some(({keycloak_id}) => keycloak_id === ID)
    } else {
      return exceptions.some(({id}) => id === ID)
    }
  }
	
	const onLastItemVisible = (inView) => {
		inView && searchItemsStates.getData({page: searchPage, search: searchQuery})
	}
	
	const autoPaginate = {
		skip: !searchItemsStates.isReady || searchItemsStates?.error || lastSearchPage,
		onChange: onLastItemVisible
	}

  useUpdateEffect(() => {
    setSearchQuery('')
  }, [devicesOption])

	return (
		<div className={`row no-gutters rounded ${backgroundClassName[background || 'default']} ${className}`}>
			<ul
				className={'list-group list-unstyled col height-600 float-left disable-rounded-right border-right'}>
				
				<li className={`border-bottom-0 border-right-0 list-group-item bg-transparent ${type==='devices' ? 'p-50' : 'p-1'}`}>
          {type === 'devices' ? 
              <MySelect
                initText={'Все устройства'}
                onSelect={(item) => setDevicesOption(item.value)}
                initialSelectedItem={0}
                optionsArr={devicesOptionsArr}
                className={'col-5 p-0'}
                titleClassName={'border-0 font-weight-bold bg-c-rgba-light font-medium-1 rounded-top'}
                selectListClassName={'bg-c-rgba-light m-0 p-0 border-left-primary border-right-primary border-bottom-primary font-medium-1'}w
                activeTitleClassName={'border-primary'}
              />
            : <h3 className={'font-weight-bold m-0'}>
                {finalHeadAllItems}
              </h3>
          }	
				</li>
				
				<li className={'p-0 border-0 '}>
					<SearchInput
						classNameInput={'rounded-0 bg-transparent'}
						placeholder={'Поиск...'}
						onChange={e => setSearchQuery(e.target.value)}
            defaultValue={searchQuery}
            resetWhen={!searchQuery}
					/>
				</li>
				
				<ExceptionList
					isDataReady={searchItemsStates.isReady}
					type={type}
					itemClassName={getItemClassName(background, disabled)}
					isActiveItem={isActiveItem}
					onClick={onAllItemsClick}
					className={loader(searchItemsStates.isReady)}
					items={searchItemsStates.data}
					autoPaginate={autoPaginate}
				/>
			</ul>
			
      <ul className={'list-group list-unstyled col height-600 disable-rounded-left'}>
				<li className={' list-group-item px-1 bg-transparent d-flex flex-row-reverse justify-content-between align-items-center'}>

          {!isNotEmpty(exceptions) && <span className={'text-primary btn-sm float-right p-0 text-uppercase'}>{type === 'devices' ? 'Выберите устройство' : type === 'users' ? 'Выберите Пользователя' : ''}</span>}

					{isNotEmpty(exceptions) && enabled &&
					<button className={'btn btn-link btn-sm float-right p-0 text-uppercase'}
					        onClick={() => setExceptions([])}>
						очистить
					</button>
					}
					
					<h3 className={'font-weight-bold m-0'}>
						{headExceptions}
					</h3>
				</li>
				<ExceptionList
					itemClassName={getItemClassName(background, disabled)}
					type={type}
					onClick={onExItemClick}
					items={exceptions}
					delBtn={enabled}
				/>
			</ul>
		</div>
	)
}

ChooseAllExceptionsLists.propTypes = {
	type: oneOf(['apps', 'sites', 'users', 'devices']).isRequired,
	background: oneOf(['white']),
}