import { EditorState, ContentState, convertToRaw } from "draft-js"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { launchersAPI } from "../../../../API/launchersAPI"
import { Button } from "../../../../components/button/Button"
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack"
import { DeleteItemModal } from "../../../../components/modals/DeleteItemModal"
import { Card } from "../../../../components/UI/Card"
import { Head } from "../../../../components/UI/Head"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import { getDateNow, getIsoUtcDate, isValidDate, loader } from "../../../../js-helpers/helpers"
import { titlesStyles } from "../../pageGroups/PageGroupsItem/PageGroupsItem"
import { typesData, VersionCreatedData } from "../PageLauncherFunctions"
import { ChangelogItem } from "../pageLauncherItemComponents/ChangelogItem"
import { ChannelItem } from "../pageLauncherItemComponents/ChannelItem"
import { DatesItem } from "../pageLauncherItemComponents/DatesItem"
import { VersionItem } from "../pageLauncherItemComponents/VersionItem"
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from "draftjs-to-html"
import dayjs from "dayjs"
import { toast } from "react-toastify"


export const PageLauncherEdit = () => {
  const {versionID} = useParams()
  const navigate = useNavigate()

  const [valueFile, setValueFile] = useState('')
	const [file, setFile] = useState(null)
  const [apk, setApk] = useState(null)

  const [bodyValue, setBodyValue] = useState(EditorState.createEmpty())
  const [channel, setChannel] = useState(null)
  const [releaseDate, setReleaseDate] = useState(null)
  const [installDate, setInstallDate] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [isDisabled, setIsDisabled] = useState()

  const { data: versionData, getData, isReady } = useFetchStates(() => 
    launchersAPI.getVersion(versionID)
  );

  const bodyValuechangeLogPlain = useMemo(() => bodyValue.getCurrentContent().getPlainText('\u0001'), [bodyValue])

  const versionDataEditorState = useMemo(() => {
    if (isReady) {
      const changelogFromHtml = htmlToDraft(versionData?.changelog);
      const contentState = ContentState.createFromBlockArray(changelogFromHtml.contentBlocks, changelogFromHtml.entityMap);
      const editorState = EditorState.createWithContent(contentState);
      return editorState
    }
  }, [isReady, versionData])

  useEffect(() => {
    setIsLoaded(isReady)
  }, [isReady])

  useEffect(() => {
    if (isReady) {
      setChannel(versionData?.channel)
      setFile(versionData?.apk)
      setApk(versionData?.apk)
      setBodyValue(versionDataEditorState)
      setReleaseDate(dayjs(versionData.release_date).toDate())
      versionData.install_date 
        ? setInstallDate(dayjs(versionData.install_date).toDate()) 
        : setInstallDate(dayjs(versionData.release_date).toDate())
    }
  }, [versionData, isReady])

  
  useEffect(() => {
    if (isReady) {
      if (apk === versionData?.apk && bodyValuechangeLogPlain === versionDataEditorState.getCurrentContent().getPlainText('\u0001') 
        && channel === versionData?.channel 
        && new Date(releaseDate).getTime() === new Date(versionData?.release_date).getTime() 
        && new Date(installDate).getTime() === new Date(versionData?.install_date).getTime()) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }

    if (!file || bodyValuechangeLogPlain.length === 0 ||
      (bodyValuechangeLogPlain.trim().length === 0 && bodyValuechangeLogPlain.length !== 0) 
      || !channel || !isValidDate(releaseDate) || !isValidDate(installDate)) {
      setIsDisabled(true)
    }

    if(!isReady) {
      setIsDisabled(false)
    }
  }, [isReady, apk, file, bodyValuechangeLogPlain, channel,  releaseDate, installDate,  bodyValue, versionData, versionDataEditorState])


  const isInstalled = useMemo(() => 
    new Date(getDateNow()) > new Date(versionData?.install_date) 
    // || new Date(dayjs(getDateNow()))
  , [versionData])

  const onSubmit = async () => {
    setIsLoaded(false)
    const releaseVersionFormData = new FormData()
    const changelogRaw = draftToHtml(convertToRaw(bodyValue.getCurrentContent()))

    const submitData = {}

    if (apk !== versionData.apk) submitData.apk = apk[0]

    if (bodyValuechangeLogPlain !== versionDataEditorState.getCurrentContent().getPlainText('\u0001') ) submitData.changelog = changelogRaw
    if (channel !== versionData.channel) submitData.channel = channel
    if (releaseDate !== dayjs(versionData.release_date).toDate()) {
      submitData.release_date = getIsoUtcDate(dayjs(releaseDate).toDate())
    } 
    if (installDate !== dayjs(versionData.install_date).toDate()) {
      submitData.install_date = getIsoUtcDate(dayjs(installDate).toDate())
    }

    Object.entries(submitData).forEach(([field, value]) => {
      releaseVersionFormData.append(field, value)
    })

    try {
      await launchersAPI.patchVersion(versionID, releaseVersionFormData);

      toast.success('Версия обновлена!', {autoClose: 5000})

      await getData()
    } catch (e) {
      toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
    } finally {
      setIsLoaded(true)
    }

  }

  const onDeleteVersion = async (id) => {
    try {
      await launchersAPI.deleteVersion(id)
      toast.success('Версия удалена!', {autoClose: 5000})
      navigate('/admins/launcher-versions/')
    } catch (e) {
      toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
    }
  }

  return (
    <>
      <ButtonGoBack className={'float-right'}/>
      <Head children={`Редактирование версии лаунчера ${versionData?.version_name}`}/>

      <Card className={`${loader(isLoaded)}`}>

        <Card secondary className={'mb-1'}>

          <VersionItem setApk={setApk}
                       file={file} 
                       setFile={setFile} 
                       valueFile={valueFile} 
                       setValueFile={setValueFile}
                       versionData={versionData}
                       onlySave={isInstalled} />

          <ChangelogItem bodyValue={bodyValue}
                         setBodyValue={setBodyValue}
                         isDisabled={isInstalled}
                         changeLogLength={bodyValuechangeLogPlain?.length} />
          
          <div className='d-flex py-1 border-bottom-extra-light'>
            <h3 className={titlesStyles}>Имя версии</h3>
            <input value={versionData?.version_name}
                   className={'col-5 form-control'}
                   disabled/>
          </div>

          <div className='d-flex py-1 border-bottom-extra-light'>
            <h3 className={titlesStyles}>Код версии</h3>
            <input value={versionData?.version_code}
                   className={'col-5 form-control'}
                   disabled/>
          </div>
          
          <ChannelItem isDisabled={isInstalled} 
                       versionData={versionData} 
                       versionDataIsReady={isReady}
                       channel={channel}
                       setChannel={setChannel}/>

          <DatesItem isDisabled={isInstalled} 
                     setReleaseDate={setReleaseDate}
                     releaseDate={releaseDate}
                     setInstallDate={setInstallDate}
                     installDate={installDate}/>

        </Card>

        <Card secondary>
          {typesData.map(({type, key, title}) => (
             <VersionCreatedData type={type} data={versionData?.[key]} title={title} key={title}/>
          ))}
        </Card>
      

        <div className={'d-flex justify-content-end mt-1'}>
          {!isInstalled && 
            <Button className={'mr-1'} 
                    children={'Обновить'} 
                    save 
                    onClick={onSubmit}
                    disabled={isDisabled}/>}
          <Button remove onClick={() => setIsShowModal(true)}/>
        </div>

      </Card>
      
      {isShowModal && 
        <DeleteItemModal  title={versionData?.version_name}
                          onClickAbort={() => setIsShowModal(false)}
                          onClickDelete={() => onDeleteVersion(versionID)}
                          titleText={'версию'}
        />}
    </>
  )
}