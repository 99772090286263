import {Img} from './Img';
import {icons} from '../../assets/icons/icons';

//todo добавь значки от пропсов (суперадмин, разлогинен и пр)
export const MyAvatar = ({src = icons.userDefaultIcon, alt = '', size = 45, className = ''}) =>
	<Img width={size}
	     height={size}
	     src={src}
	     alt={alt}
	     noSrcImage={icons.userDefaultIcon}
	     className={`rounded-circle obj-c-cover ${className}`}
	/>