import { Card } from "../UI/Card"

export const DeleteItemModal = ({className = '', extraBodyElem, onClickAbort, title, onClickDelete, titleText = ''}) => {
  return (
    <div
			className={`position-fixed position-top-0 position-left-0 zindex-2 full-width full-height bg-rgba-dark d-flex justify-content-center align-items-center ${className}`}>
			
			<Card size={0} className={'text-center px-4 py-3 bg-white'}>
				<h3 className={'font-weight-bold mb-2 width-400'} style={{overflowWrap: 'break-word'}}>
          Вы действительно хотите удалить {titleText} “{title}”?
				</h3>
				
				{extraBodyElem}
				
				<button className={'btn mr-3 btn-outline-light px-3 mt-1'}
				        onClick={onClickAbort}>
					Отмена
				</button>
				
				<button className={'btn btn-primary px-3 mt-1'}
				         onClick={onClickDelete}> 
					Удалить
				</button>
			
			</Card>
		</div>
  )
}