import {useCountState} from './useCountState';
import {useFetchStates} from './useFetchStates';
import {useDebouncedState, useSafeState} from '@react-hookz/web';

/**
 * @description
 * Хук для поиска с автопагинацией от последнего елемента
 * * @param {promise} searchMethod - метод поиска api c query аргументами в объекте
 ** @param nestedFieldToSet - поле в объекте для записи поискового результата
 @returns
 * lastSearchPage {number} - последняя страница поиска
  setLastSearchPage - установить последнюю страницу поиска
  searchQuery {string} - поисковая строка
  setSearchQuery - установить поисковую строку
  searchPage {number} - страница поиска
  setSearchPage - установить страницу поиска
  nextSearchPage - следующая страница поиска
  prevSearchPage - предыдущая страница поиска
  searchItemsStates {useFetchStates} - состояние запроса и данных поиска
 */

export const useNewFind = (searchMethod, nestedFieldToSet) => {
	const {
		count: searchPage,
		setCount: setSearchPage,
		setNextCount: nextSearchPage,
		setPrevCount: prevSearchPage,
	} = useCountState(1)
	
	const [lastSearchPage, setLastSearchPage] = useSafeState(false)
	const [searchQuery, setSearchQuery] = useDebouncedState('', 333)
	
	const searchItemsStates = useFetchStates(searchMethod,
		{noInitFetch: true, customSetter, initData: []})
	
	function customSetter({data}, {page}) {
		setLastSearchPage(!data?.next)
		
		const results = nestedFieldToSet
			? data?.results.map(item => item[nestedFieldToSet])
			: data?.results
		
		searchItemsStates.setData(prevState =>
			+page === 1
				? results
				: [...prevState, ...results]
		)
		
		searchItemsStates.setReady(true)
		nextSearchPage()
	}
	
	return {
		lastSearchPage,
		setLastSearchPage,
		searchQuery,
		setSearchQuery,
		searchPage,
		setSearchPage,
		nextSearchPage,
		prevSearchPage,
		searchItemsStates
	}
}