import React, { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { deviceAPI } from '../../../../API/deviceAPI';
import { Card } from '../../../../components/UI/Card';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { loader, toggle } from '../../../../js-helpers/helpers';
import { Head } from '../../../../components/UI/Head';
import { DeviceInfoAboutCard } from './components/DeviceInfoAboutCard';
import { PageDeviceUserInfo } from './components/PageDeviceUserInfo';
import { PageDeviceSetupInfo } from './components/PageDeviceSetupInfo';
import { InfoCallsCard } from '../../../../components/infoCards/InfoCallsCard';
import { InfoLogInOutCard } from '../../../../components/infoCards/InfoLogInOutCard';
import { deviceStatuses } from '../PageDevices';

export const PageDeviceInfo = () => {
  const { deviceID } = useParams();
  const setPageHead = useOutletContext();

  const {
    data: device,
    setData,
    isReady,
    setReady,
  } = useFetchStates(() => deviceAPI.getOneDevice(deviceID), {
    customSetter: ({ data }) => {
      data.launcher_version_code = `${data.launcher_version_name} (${data.launcher_version_code})`;
      setData(data);
      setReady(true);
    },
  });

  useEffect(() => {
		setPageHead({head:`Информация об устройстве (${deviceID})`});
	}, [deviceID])

  const inArchive = device?.status === 2;
  const statusStyle = toggle(
    !inArchive,
    'badge-light-success',
    'badge-light-secondary'
  );

  return (
      <Card className={loader(isReady)}>
        {/* <Head secondary className={'d-inline-block '} children={'Статус'} /> */}

        {/* <span
          className={`badge badge-pill font-weight-bold ml-1 ${statusStyle}`}
        >
          {deviceStatuses[device?.status]}
        </span> */}
        
        <div className='d-flex'>
          <div className='col-8 pl-0'>
            <DeviceInfoAboutCard device={device} isReady={isReady} className={'mb-1'}/>

            <div className='d-flex gap-1r'>
              <InfoCallsCard pageType={'devices'} deviceID={deviceID} className={'col'}/>
              <InfoLogInOutCard pageType={'devices'} deviceID={deviceID} className={'col'}/>
            </div>
          </div>
          <div className='col-4  p-0'> 
            <PageDeviceUserInfo device={device} isReady={isReady} className={'mb-1'}/>
            <PageDeviceSetupInfo setupInfo={device?.setup} isReady={isReady}/>
          </div>
        </div>
      </Card>
  );
};