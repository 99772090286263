import { useMountEffect, useToggle, useUpdateEffect } from "@react-hookz/web";
import { useImmer } from "use-immer";
import { ButtonFilter } from "../../../components/button/ButtonFilter";
import { MySelect } from "../../../components/form/MySelect/MySelect";
import { SearchInput } from "../../../components/form/SearchInput";
import { useComboSearch } from "../../../hooks/url/useComboSearch"
import { toggle } from "../../../js-helpers/helpers";
import { pageSearchParams } from "./PageDivisions"

const hasUserOptions = [
  {text: 'Есть пользователи', value: 'true'},
  {text: 'Нет пользователей', value: 'false'}
]

export const PageDivisionsFilters = () => {
  const [
    [urlSearchQuery, setUrlSearchQuery],
		[urlPage],
		[urlLimit],
    [urlHasUsers, setUrlHasUsers]
	] = useComboSearch(pageSearchParams)

  const [showFilters, toggleShowFilters] = useToggle(urlHasUsers && urlHasUsers !== 'null');
		
	useUpdateEffect(() => {
		if (!showFilters) {
			setUrlHasUsers('null');
			setInitFilters((draft) => {
				draft.has_users = null;
			});
		}
	}, [showFilters]);

  const [initFilters, setInitFilters] = useImmer({
    has_users: null,
  });

  useMountEffect(() => {
    if (urlHasUsers && urlHasUsers !== 'null') {
      const {text, value} = hasUserOptions.find(({value}) => value === urlHasUsers)
      
      setInitFilters((draft) => {
        draft.has_users = {text, value};
      });
    }
  });

  const onHasUserSelect = (selectedOption) => {
    if (!selectedOption) return;

    const {text, value} = selectedOption;
    setUrlHasUsers(value);
  };

  return (
    <div className={'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'}>
      <div className={'col-12 row no-gutters align-items-center'}>
        <SearchInput
            resetWhen={!urlSearchQuery}
            className={'col mr-1'}
            onChange={(e) => setUrlSearchQuery(e.target.value)}
            placeholder={'Поиск...'}
            defaultValue={urlSearchQuery}
        />

        <ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
      </div>
    
      {showFilters && (
        <div className={'row col-12 no-gutters align-items-center gap-1r'}>
          <MySelect
              initText={'Наличие пользователей'}
              resetWhen={urlHasUsers === 'null'  || !urlHasUsers}
              titleClassName={toggle(urlHasUsers === 'null' || !urlHasUsers, 'text-muted')}
              onSelect={onHasUserSelect}
              initialSelectedItem={initFilters.has_users}
              optionsArr={hasUserOptions}
              activeItemClassName={'active_gray'}
              closeButtonHandled={() => setUrlHasUsers('null')}
              isSelectedNow={urlHasUsers}
              closeButton={true}
              className={'col-2'}
            />
        </div>
      )}
    </div>

  )
}