import { Link } from "react-router-dom";
import { getFullNameFromObj, getShortFullNameFromObj } from "../../../../js-helpers/helpers";
import { MyAvatar } from "../../../UI/MyAvatar";
import { getName, WithTooltip } from "../../../UI/WithTooltip";

export const getInternalContactsTableOptions = (isEmptySearchQuery) => [
	{
		head: 'ID контакта',
		cell: (uuid) => isEmptySearchQuery
			? <WithTooltip elem={uuid.split('-')[0] + '...'}
			               showAbove={0}
			               tooltips={[uuid]}/>
			: uuid,
		nestedCellField: 'uuid',
	}, {
		head: 'Фото',
		cell: (contact) =>
			<MyAvatar src={contact.photo} alt={getShortFullNameFromObj(contact)}/>
	}, {
		head: 'ФИО',
		sortField: 'last_name',
		cell: (row) =>
			<Link to={`${row.uuid}/`}>
				{isEmptySearchQuery ? getShortFullNameFromObj(row) : getFullNameFromObj(row)}
			</Link>
	}, {
		head: 'Номер телефона', cell: (phone_numbers) =>
			<WithTooltip
				elem={getName(phone_numbers, 'phone_number')}
				showAbove={1}
				tooltips={phone_numbers.map(({phone_number}) => phone_number)}/>,
		nestedCellField: 'phone_numbers',
	}, {
		head: 'Должность', cell: 'position', sortField: 'position',
	}, {
		head: 'Подразделение', cell: 'subdivision', sortField: 'subdivision',
	}, {
		head: 'Юридическое лицо', 
    cell: (company) => <div>{company?.title || '-'}</div>, nestedCellField: 'company', sortField: 'company',
	}
]