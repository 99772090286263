import {useEffect, useMemo, useState} from 'react';
import {MySelect} from '../../../../components/form/MySelect/MySelect';
import useRerender from '../../../../hooks/useRerender';
import {contactsAPI} from '../../../../API/contactsAPI';
import {toast} from 'react-toastify';
import {capitalize, isEmpty, isEqualArrays} from '../../../../js-helpers/helpers';
import {ExceptionsLists} from '../../../../components/listsAccess/ExceptionsLists';
import {MySearchSelect} from '../../../../components/form/MySelect/MySearchSelect';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { useMountEffect, useUpdateEffect } from '@react-hookz/web';

const restrictionsOptions = [
	{value: 1, text: 'не виден для пользователей'},
	{value: 2, text: 'виден для пользователей'},
]

export const SettingVisible = (
	{
		isSendForm,
		isChangedAvatar,
		uuid,
		nameNewRule,
		setIsSaveRule,
		setIsSendForm,
		initExceptions,
		initStatus,
		isSaveRule,
		reFetchContactInfo,
		setIsEnabledSaveBtn,
    setSelectedRule,
    selectedRule,
    setChangedRule,
    changedRule
	}) => {
	const [ruleSelectKey, rerenderRuleSelectKey] = useRerender()
	const [restrictionSelectKey, rerenderRestrictionSelectKey] = useRerender()
	
	const [exceptions, setExceptions] = useState([])
	const [status, setStatus] = useState(2)
  const [initialStatus, setInitialStatus] = useState(0)
	
	useEffect(() => {
		if (isChangedAvatar)
			return
		
		if (isEmpty(exceptions))
			setIsEnabledSaveBtn(false)
		
		const currentExceptionsIDS = exceptions?.map(({keycloac_id}) => keycloac_id)
		const initExceptionsIDS = initExceptions?.map(({keycloac_id}) => keycloac_id)
		
		if (isEqualArrays(currentExceptionsIDS, initExceptionsIDS))
			setIsEnabledSaveBtn(false)
		
		if (initStatus !== status)
			setIsEnabledSaveBtn(true)
		
	}, [exceptions, status])
	
	useEffect(() => {
		if (initExceptions) {
			setExceptions(initExceptions)
			setStatus(initStatus)
			rerenderRestrictionSelectKey()
		}
	}, [initExceptions])
	
	useEffect(() => {
		
		if (isSendForm) {
			const dataSubmit = {
				status: status,
				except_users: exceptions.map(item => item.keycloak_id),
			}
			
			if (nameNewRule) {
				
				if (!nameNewRule.trim()) {
					toast.error('Введите название!')
          setIsSendForm(false)
				} else {
					dataSubmit.name = nameNewRule
					
					contactsAPI.postRule(dataSubmit)
						.then(() => {
							toast.success('Изменения сохранены!')
							setIsSaveRule(false)
						})
						.catch((e) => {
							setIsSendForm(false)
							
							const err = Object.values(e?.response.data?.errors?.[0]?.detail)
							const errMessage = err.some(item => String(item).endsWith('уже существует.'))
								? 'Такое правило уже существует!'
								: err.join(', ')
							
							toast.error('Ошибка сохранения! ' + errMessage)
						})
				}
			}
			
			if (!nameNewRule) {
				
				if (isSaveRule) {
					toast.error('Введите название!')
					setIsSendForm(false)
					
				} else {
          dataSubmit.visibility_rule = changedRule
          console.log(dataSubmit)
					contactsAPI.patchVisibility(uuid, dataSubmit)
						.then(() => {
							reFetchContactInfo()
							toast.success('Изменения сохранены!')
							setIsSendForm(false)
						})
				}
			}
		}
	}, [isSendForm])
	
	const onRestrictionsSelect = (item) => {
		setIsEnabledSaveBtn(true)
		setStatus(item.value)

    if (item.value !== initialStatus) {
      setChangedRule(null)
      setSelectedRule({name: '-', id: null})
    }
		rerenderRuleSelectKey()
	}
	
	const onRuleSelect = item => {
		if (item.value) {
			const {status, except_users, name, id} = item.value

      setChangedRule(id)
			setIsEnabledSaveBtn(true)
			setStatus(status)
			setExceptions(except_users)
			rerenderRestrictionSelectKey()
		} 
	}

	const exceptionsHead = `${capitalize(restrictionsOptions[status - 1].text)}: ( ${exceptions.length} )`
	
	const onExceptionItemClick = () => {
		rerenderRuleSelectKey()
		setIsEnabledSaveBtn(true)
	}

  const [initSelectRule, setInitSelectRule] = useState({value: null, text: '-'}) 
	const customRuleSetter = ({data}) => {
  	const {status, except_users, name, id} = data
      setSelectedRule({name, id})

			setIsEnabledSaveBtn(true)
			setStatus(status)
      setInitialStatus(status)
			setExceptions(except_users)
			rerenderRestrictionSelectKey()

      setInitSelectRule({value: id, text: name}) 

  }

  const initRuleState = useFetchStates(
		() => contactsAPI.getRule(selectedRule?.id), {
			noInitFetch: true,
			customSetter: customRuleSetter,
		});

    useMountEffect(() => {
      selectedRule?.id && initRuleState.getData()
    })

	return (
		<>
			<div className={'mt-2 d-flex mb-1'}>
				
				<fieldset className='width-350 mr-2'>
					<label>ВЫБЕРИТЕ ПРАВИЛО</label>
					
					<MySearchSelect type={'rulesWithFullData'}
					                key={ruleSelectKey}
					                onSelect={onRuleSelect}
					                initialSelectedItem={initSelectRule}/>

				</fieldset>
				
				<fieldset className='width-350'>
					<label>ВЫБЕРИТЕ ОГРАНИЧЕНИЕ</label>
					
					<MySelect key={restrictionSelectKey}
					          optionsArr={restrictionsOptions}
					          initialSelectedItem={restrictionsOptions[status - 1]}
					          onSelect={onRestrictionsSelect}
					/>
				
				</fieldset>
			</div>
			
			<ExceptionsLists type={'users'}
			                 background={'white'}
			                 headExceptions={exceptionsHead}
			                 exceptions={exceptions}
			                 onItemClick={onExceptionItemClick}
			                 setExceptions={setExceptions}/>
		</>
	)
}