import { ReactComponent as LogInOut } from './log-out.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as History } from './history.svg';
import { ReactComponent as Clipboard } from './clipboard.svg';
import { ReactComponent as Key } from './key.svg';
import { ReactComponent as Power } from './power.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Sim } from './sim-card.svg';
import { ReactComponent as Keyboard } from './keyboard.svg';
import { ReactComponent as Crashes } from './crashes.svg';
import { ReactComponent as Refresh2 } from './refresh-2.svg';
import { ReactComponent as Apps } from './apps.svg';
import PropTypes from 'prop-types';

const icons = {
  sim: Sim,
  key: Key,
  bell: Bell,
  power: Power,
  refresh: Refresh,
  history: History,
  calendar: Calendar,
  keyboard: Keyboard,
  'login-logout': LogInOut,
  clipboard: Clipboard,
  'check-circle': CheckCircle,
  crashes: Crashes,
  refresh2: Refresh2,
  apps: Apps,
};

export const UserLogsIcon = ({ type, className = '' }) => {
  const Icon = icons[type];
  return <Icon className={`svg-curr-color svg-service-size ${className}`} />;
};

UserLogsIcon.propTypes = {
  type: PropTypes.oneOf([
    'sim',
    'key',
    'bell',
    'power',
    'refresh',
    'history',
    'calendar',
    'keyboard',
    'login-logout',
    'clipboard',
    'check-circle',
    'crashes',
    'refresh2',
    'apps',
  ]),
};
