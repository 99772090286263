import {ReactKeycloakProvider} from '@react-keycloak/web'
import {createContext, useEffect, useMemo, useState} from 'react';
import {FullScreenLoader} from '../pages/FullScreenLoader';
import {keycloak} from '../services/keycloak/kcInit';
import {observer} from 'mobx-react-lite';

export const KeycloakProvider = observer(({children}) => {
	const [onLoad, setOnLoad] = useState('check-sso')
	const [kcEvent, setKcEvent] = useState(null)
	const [logoutMSG, setLogoutMSG] = useState(() =>
		sessionStorage.getItem('kcError') || null)
	
	const kcEventLogger = (event, error) => {
		setKcEvent({event, error})
		if (error) appLogoutWithMsg(error)
	}
	
	const appLogoutWithMsg = (msg) => {
		setOnLoad('check-sso')
		sessionStorage.setItem('kcError', msg)
		msg && setLogoutMSG(msg)
		keycloak.logout()
	}
	
	const {authenticated, token} = keycloak
	
	const initKC = () => {
		sessionStorage.removeItem('kcError')
		setOnLoad('login-required')
	}
	// console.log({keycloak})
	const isKcAdmin = useMemo(
		() => keycloak.hasRealmRole('smass-admin') || keycloak.hasRealmRole('smass-superadmin'),
		[authenticated, token])
	
	const adminLoggedInKC = authenticated && isKcAdmin
	
	useEffect(() => {
		if (authenticated)
			!isKcAdmin && appLogoutWithMsg('Доступно только smass администраторам')
	}, [authenticated, token])
	
	const showKSLoader = !kcEvent?.error
	
	return (
		<ReactKeycloakProvider
			initOptions={{onLoad, checkLoginIframe: false}}
			authClient={keycloak}
			onEvent={kcEventLogger}
			LoadingComponent={showKSLoader && <FullScreenLoader text={'Инициализация KEYCLOAK...'}/>}
		>
			<KeycloakContext.Provider
				value={{
					appLogoutWithMsg, adminLoggedInKC, initKC,
					kcEvent, logoutMSG, isKcAdmin
				}}
			>
				{children}
			</KeycloakContext.Provider>
		</ReactKeycloakProvider>
	
	)
})

export const KeycloakContext = createContext({})
