import exportFromJSON from "export-from-json"
import { toast } from "react-toastify"
import { getCell } from "../../../components/table/MyTable/MyTableBody"
import { getDateNow, getLocaleFullDate } from "../../../js-helpers/helpers"
import { pageUsersConfig } from "./pageUsersTables"

const {exportColumns} = pageUsersConfig

//todo вынести эти функции в хук, передавать колонки для экспорта, ограничить cell этих колонок - не реакт элемент
export const exportExcel = (exportList) => {
	const dataToExcel = exportList.map(item => {
		const finalObject = {}
		
		exportColumns.forEach((col) => {
			if (col) {
				finalObject[col.head] = getCell(col, item)
			}
		})
		
		return finalObject
	})
	const getFileNameFormatDate = (date) =>
		getLocaleFullDate(date).replace(/[.:]/g, '-').replace(', ', '_')
	
	const fileName = `Пользователи_${getFileNameFormatDate(getDateNow())}`

	exportFromJSON({
		data: dataToExcel,
		withBOM: true,
		fileName,
		exportType: exportFromJSON.types.xls,
	})
	
	toast.success(`Файл создан!
	Экспортировано пользователей: ${dataToExcel.length}`)
}