/* eslint-disable no-loop-func */
/* eslint-disable max-lines */
import {
	getFileNameWithDatesRange,
	isEmpty,
	loader,
} from '../../../js-helpers/helpers'
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useFetchStates} from '../../../hooks/useFetchStates';
import {userAPI} from '../../../API/userAPI';
import {useUpdateEffect} from '@react-hookz/web';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {toast} from 'react-toastify';
import {ModalSwiper} from '../../../components/modals/ModalSwiper';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {ButtonWithIcon} from '../../../components/button/ButtonWithIcon';
import {Head} from '../../../components/UI/Head';
import {SearchInput} from '../../../components/form/SearchInput';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {DatesRangeModal} from '../../../components/modals/DatesRangeModal';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {urlParams} from '../../../constants/urlParams';
import { exportExcel } from './PageFeedbacksFunctions';
import { getFeedbacksTableOptions } from '../../../components/table/MyTable/options/columnsFeedbacks';

const {SEARCH, SORT, PAGE, LIMIT} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: 'date'},
	{param: PAGE, init: 'last'},
	{param: LIMIT}
]

export const PageFeedbacks = () => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(pageSearchParams)
	
	const location = useLocation()
	const navigate = useNavigate()
	const {pathname, search, hash} = location
	
	const [isShowModal, setIsShowModal] = useState(false)
	
	const [arrPhotoSwiper, setArrPhotoSwiper] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const openFiles = (files, id) => {
		const photosForSwiper = files.map(({id, file}) => ({id, image: file}))
		setArrPhotoSwiper(photosForSwiper)
		navigate({pathname, search, hash: id.toString()}, {replace: true})
	}
	
	const {data, isReady, getData} = useFetchStates(
		() => userAPI.getFeedbacks(
			{page: urlPage, search: urlSearchQuery, ordering: urlSortField, page_size: urlLimit}
		), {noInitFetch: true})
	
	useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		getData()
	}, [urlSearchQuery, urlLimit])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	const tableOptions = useMemo(
		() => getFeedbacksTableOptions(openFiles, isEmptySearchQuery), [isEmptySearchQuery, search])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)
	
	const exportToExcel = async (dateFrom, dateTo) => {
		let page = 1
		let arrExport = []
		let nextPage = true
    let validData = true
		setIsLoading(false)
		
      while (nextPage) {
        await userAPI.getFeedbacks({
            date__gte: dateFrom,
            date__lte: dateTo,
            page,
            page_size: 25
          })
          .then(({data}) => {
            arrExport = [...arrExport, ...data.results]
            
            if (data.next) {
              page++
            } else {
              nextPage = false
              setIsLoading(true)
            }
          }).catch((e) => {
              nextPage = false

              if (e?.response?.data?.errors?.[0]?.attr === 'date__lte' || e?.response?.data?.errors?.[0]?.attr === 'date__gte') {
                toast.error(e?.response?.data?.errors?.[0]?.detail);
                validData = false;
                return;
              } else {
                toast.error('Не удалось загрузить данные')
              }   
          })
      }
		
		
		if (validData) {
      if (arrExport.length === 0) {
        toast.success('За указанный период нет данных')
        setIsLoading(false)
        setIsShowModal(false)
        return
      }
      
      exportExcel({arrExport, dateFrom, dateTo})
      toast.success(`Файл "${getFileNameWithDatesRange(dateFrom, dateTo, 'Запросы_пользователей')}" сохранен!`)
    }
		setIsShowModal(false)
	}
	
	const closeModal = () => { setIsShowModal(false) }
	
	return (
		<>
			<ModalSwiper isOpen={hash && arrPhotoSwiper} photos={arrPhotoSwiper}/>
			
			{isShowModal &&
			<DatesRangeModal className={loader(isLoading)}
			                 onClickAbort={closeModal}
			                 onClickDownload={exportToExcel}/>
			}
			
			<ButtonGoBack className={'float-right'}/>
			<ButtonWithIcon type={'export'} className={'float-right mr-1'}
			                onClick={() => {
                        setIsShowModal(true);
                        setIsLoading(true)
                        }}/>
			
			<Head children={'Запросы пользователей'}/>
			<SearchInput placeholder={'Поиск по ID, автору, тексту...'}
			             className={'mb-1'}
			             defaultValue={urlSearchQuery}
			             onChange={(e) => setUrlSearchQuery(e.target.value)}
			             withBorder
			/>
			
			<MyTable paginationAllCount={data?.count}
			         colgroup={tableColgroup}
			         isDataReady={isReady}
			         showEmptyFoundDataMessage={!isEmptySearchQuery}>
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(isReady)}
				             data={data?.results}
				             tableOptions={tableOptions}
				             highestRowToFill={45}
				/>
			</MyTable>
		</>
	)
}