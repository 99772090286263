import {useMemo} from 'react';
import {MyTable} from '../../components/table/MyTable/MyTable';
import {MyTableBody} from '../../components/table/MyTable/MyTableBody';
import {MyTableHead} from '../../components/table/MyTable/MyTableHead';
import {useMyTable} from '../../components/table/MyTable/useMyTable';
import {appsOptions, baseLogsOptions, userLogsOptions,} from '../../constants/logsOptions';
import {loader} from '../../js-helpers/helpers';
import FiltersPageCrashlyticsList from './filters/FiltersPageCrashlyticsList';
import {getLogsTableOptions} from '../../components/table/MyTable/options/columnsLogs';
import {UserLogsNav} from './PageLogsNav';
import FiltersPageLogs from './filters/FiltersPageLogs';

const PageLogs = ({
  logs, urlService, onClickNavButton, isPaginationReady, isEmptySearchQuery, filter, showEmptyFoundDataMessage, prevSearchService,
	}) => {
	const {tableColgroup, tableHeads, tableOptions} = useMyTable(
		getLogsTableOptions(isEmptySearchQuery, filter)?.[urlService] || [],
		{memoDependencies: [logs.data]}
	)
	
	const initShowNavs = useMemo(
		() => Object.fromEntries(userLogsOptions
			.map(({groupName, options}) => {
				const show = options.find((it) => it.service === urlService);
				return [groupName, show];
			})
		), [])

	return (
		<>
			<div className={'d-flex gap-1r no-gutters'}>
				<UserLogsNav
					urlService={urlService}
					initShowNavs={initShowNavs}
					userLogsOptions={userLogsOptions}
					baseLogsOptions={baseLogsOptions}
					onClickNavButton={onClickNavButton}
					appsOptions={appsOptions}
				/>
				
				<div className={'col overflow-hidden'}>
					{urlService === 'crashes'
						?
						<FiltersPageCrashlyticsList
							urlService={urlService}
							filter={filter}
							prevSearchService={prevSearchService}
						/>
						:
						<FiltersPageLogs
							// key={urlService}
							urlService={urlService}
							filter={filter}
							prevSearchService={prevSearchService}
						/>
					}
					
					<MyTable
						paginationAllCount={logs.data?.count}
						colgroup={tableColgroup}
						withScroll
						showEmptyFoundDataMessage={showEmptyFoundDataMessage}
						paginationClassName={loader(isPaginationReady, {size: 'small'})}
					>
						<MyTableHead heads={tableHeads}/>
						
						<MyTableBody
							className={loader(logs.isReady)}
							data={logs.data?.results}
							tableOptions={tableOptions}
							highestRowToFill={45}
						/>
					</MyTable>
				</div>
			</div>
		</>
	);
};

export default PageLogs;
