import { useEffect, useState } from "react"
import { ChooseAllExceptionsLists } from "../../../../components/listsAccess/ChooseAllExceptionsLists";

function FormsNotificationsRecipient({setRecipientsIdArray}) {
  const [exceptions, setExceptions] = useState([]);

  useEffect(() => {
    setRecipientsIdArray(exceptions.map(exception => exception.serial_number))
  }, [exceptions]);

  return (
      <ChooseAllExceptionsLists
        type={'devices'}
        exceptions={exceptions}
        setExceptions={setExceptions}
        headExceptions={'Выбрано: ' + exceptions.length}
      />
  )

}

export default FormsNotificationsRecipient