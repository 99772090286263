import React, {useCallback, useRef} from 'react';

export const useAbortController = () => {
	let {current: controllerRef} = useRef()
	
	const withController = useCallback(func => {
		if (controllerRef) controllerRef.abort()
		controllerRef = new AbortController()
		func(controllerRef?.signal)
	}, []);
	
	return withController
}

export const useNewAbortController = (func) => {
	 let {current: controllerRef} = useRef()
	 
	 const withController = useCallback(func => {
			if (controllerRef) controllerRef.abort()
			controllerRef = new AbortController()
			return Promise.resolve(func(controllerRef?.signal))
	 }, []);
	 
	 return withController
}
