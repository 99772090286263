import React from 'react';
import {useCustomSearch} from '../../hooks/url/useCustomSearch';
import {toggle} from '../../js-helpers/helpers';

/**
 * Компонент пагинации для переключения страниц в url (параметр 'page')
 * и отображения счетчика элементов на текущей странице и общего количества элементов
 * @component
 *
 * @param {object} props - /
 * @param isVisible {boolean} - показывать ли компонент
 * @param allCount {number} - общее количество страниц
 * @param className {string} - класс для компонента
 * @returns {JSX.Element|null} - компонент пагинации
 *
 */

const Pagination = ({isVisible = true, allCount = 0, className = ''}) => {
	const [urlPage, setUrlPage] = useCustomSearch('page')
	const [urlLimit] = useCustomSearch('limit')
	
	const totalButtons = Math.ceil(+allCount / (urlLimit || 10))
	
	if (!isVisible || allCount < (+urlLimit || 11) || totalButtons < 2) return null
	
	const isInitLastPage = urlPage === 'last'
	const page = isInitLastPage ? totalButtons : +urlPage
	
	const showPrevBtn = page && page > 1

	return (
		<nav className={className}>
			<ul
				className={'pagination position-relative m-0 pagination-borderless justify-content-end user-select-none font-small-3'}>
				
				{showPrevBtn &&
				<li className={`page-item previous  ${toggle(page === 1, 'disabled')}`}>
					<button className='page-link width-100'
					        disabled={page === 1}
					        onClick={() => {setUrlPage(page - 1)}}>
						Назад
					</button>
				</li>}
				
				<ButtonsList setPageStr={setUrlPage} totalButtons={totalButtons} page={page}/>
				
				{page &&
				<li className={`page-item next ${toggle(page === totalButtons, 'disabled')}`}>
					<button className='page-link width-100'
					        disabled={page === totalButtons}
					        onClick={() => {setUrlPage(page + 1)}}>
						Вперед
					</button>
				</li>
				}
			</ul>
		
		</nav>
	)
}

function ButtonsList({setPageStr, totalButtons, page}) {
	
	if (totalButtons <= 7) {
		return new Array(totalButtons).fill(null).map((_, i) => (
			<Btn key={i} i={i + 1} page={page} setPageStr={setPageStr}/>
		))
	}
	
	let arrBtns = []
	if (page <= 7) arrBtns = [1, 2, 3, 4, 5, 6, 7, '...', totalButtons]
	if (page > 5) arrBtns = [1, '...', page - 2, page - 1, page, page + 1, page + 2, '...', totalButtons]
	if (totalButtons - 5 <= page) arrBtns = [1, '...', totalButtons - 6, totalButtons - 5, totalButtons - 4, totalButtons - 3, totalButtons - 2, totalButtons - 1, totalButtons]
	
	return arrBtns.map((p, ind) => (
		<Btn key={p + ind}
		     disabled={p === '...'}
		     i={p}
		     page={page}
		     setPageStr={setPageStr}
		/>
	))
}

function Btn({i, disabled = false, setPageStr, page}) {
	return (
		<li className={`page-item zindex-0 ${toggle(page === i, 'active')}`}
		    onClick={() => setPageStr(i)}>
			
			<button disabled={disabled} className='page-link'>
				{i}
			</button>
		</li>
	)
}

export default Pagination