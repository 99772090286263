export const NOTIFICATIONS_TYPES = {
  admin: 'admin',
  user: 'user',
  rules: 'rules',
  template: 'template'
}

export const historyNotificationsOptions = [
  {
    text: 'От администратора',
    type: NOTIFICATIONS_TYPES.admin,
    pageTitle: 'История уведомлений - От администратора',
  },
  {
    text: 'Личные',
    type: NOTIFICATIONS_TYPES.user,
    pageTitle: 'История уведомлений - Личные',
  }
]

export const baseNotificationsOptions = [
  {
    text: 'Управление правилами',
    type: NOTIFICATIONS_TYPES.rules,
    icon: 'rules',
  },
  // {
  //   text: 'Шаблоны уведомлений',
  //   type: NOTIFICATIONS_TYPES.template,
  //   icon: 'rules',
  // },
]

export const historyNotificationsGroup = [
  {
    head: 'История уведомлений',
    groupName: 'history',
    options: historyNotificationsOptions,
    icon: 'notifications',
  },
];

export const notificationsOptions = [
  ...historyNotificationsOptions,
  ...baseNotificationsOptions,
];