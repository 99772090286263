import React, {useEffect, useState} from 'react';
import {Card} from '../../../../components/UI/Card';
import {adminPermissions} from '../../../../constants/adminPermissions';
import {Button} from '../../../../components/button/Button';
import {useOutletContext} from 'react-router-dom';
import {isEqualArrays, loader} from '../../../../js-helpers/helpers';
import {adminSmassAPI} from '../../../../API/adminSmassAPI';
import {toast} from 'react-toastify';
import {Head} from '../../../../components/UI/Head';
import {SwitchersList} from './SwitchersList/SwitchersList';

const {
	DIVISIONS, CONTACTS, APPS, FEEDBACKS, CALL_HISTORY,
	MAILINGS, NOTIFICATIONS, LOGS, GROUPS, LAUNCHERS,
	SCREENSHOTS, ADMINS, USERS, USERS_CALLS, USERS_GENERAL,
	USERS_LOGS, USERS_SCREENSHOTS, USERS_PHONEBOOK, USERS_SETTINGS, USERS_NOTIFICATIONS,
	USERS_TRACKERS, DEVICES, DEVICES_CALLS, DEVICES_GENERAL,
	DEVICES_SCREENSHOTS, DEVICES_LOGS, DEVICES_SETTINGS
} = adminPermissions;

const memberSwitchers = [
	{
		label: 'Пользователи', permission: USERS, children: [
			{label: 'Общая информация', subPermission: USERS_GENERAL},
			{label: 'Звонки', subPermission: USERS_CALLS},
			{label: 'Логи', subPermission: USERS_LOGS},
			{label: 'Скриншоты', subPermission: USERS_SCREENSHOTS},
			{label: 'Телефонная книга', subPermission: USERS_PHONEBOOK},
			{label: 'Сбор данных', subPermission: USERS_TRACKERS},
      {label: 'Уведомления', subPermission: USERS_NOTIFICATIONS},
			{label: 'Настройки', subPermission: USERS_SETTINGS},
		]
	}, {
		label: 'Устройства', permission: DEVICES, children: [
			{label: 'Общая информация', subPermission: DEVICES_GENERAL},
			{label: 'Звонки', subPermission: DEVICES_CALLS},
			{label: 'Логи', subPermission: DEVICES_LOGS},
			{label: 'Скриншоты', subPermission: DEVICES_SCREENSHOTS},
			{label: 'Настройки', subPermission: DEVICES_SETTINGS},
		]
	}, {
		label: 'Администраторы', permission: ADMINS
	},
]

const tableSwitchers = [
	{label: 'История звонков', permission: CALL_HISTORY},
	{label: 'Логи', permission: LOGS},
	{label: 'Скриншоты', permission: SCREENSHOTS},
]

const managementSwitchers = [
	{label: 'Дивизионы', permission: DIVISIONS},
  {label: 'Настройка групп', permission: GROUPS},
	{label: 'Контакты', permission: CONTACTS},
  // {label: 'Обновление версии лаунчера', permission: LAUNCHERS},
	{label: 'Приложения', permission: APPS},
	{label: 'Запросы от пользователей', permission: FEEDBACKS},
	{label: 'Рассылка', permission: MAILINGS},
	{label: 'Уведомления', permission: NOTIFICATIONS},
	// {label: 'Глобальный поиск', permission: GLOBAL_SEARCH},
]

export const PageAdminEditPermissions = () => {
	const {data, isReady, setReady, setData} = useOutletContext();
	const [permissions, setPermissions] = useState([]);
	
	useEffect(() => {
		isReady && setPermissions(data?.available_sections || []);
	}, [isReady]);
	
	const togglePermission = (checked, permission) =>
		checked
			? setPermissions((curr) => [...curr, permission])
			: setPermissions((curr) => curr.filter((p) => p !== permission));
	
	const disableSaveBtn =
		!isReady || isEqualArrays(permissions, data?.available_sections || []);
	
	const onSave = async () => {
		setReady(false);
		
		try {
			const res = await adminSmassAPI.patchAdmin(data.account.keycloak_id, {
				available_sections: permissions,
			});
			toast('Изменения сохранены!', {type: 'success'});
			setData(data =>  ({...data, ...res.data}) );
			
		} catch (e) {
			toast(`Ошибка сохранения: ${e?.response?.data?.errors?.[0]?.detail}`, {
				type: 'error',
			});
		} finally {
			setReady(true);
		}
	};
	
	return (
		<>
			<Head secondary className={'d-inline-block'}>
		    Видимость разделов
			</Head>
			
			<Card className={`${loader(isReady)} mb-2`}>
				<Head secondary className={'d-inline-block'}>
					Участники Системы
				</Head>
				<div className={'row no-gutters gap-1r'}>
					
					
					<Card
						titleUppercase
						secondary
						className={'col'}
					>
						<SwitchersList list={memberSwitchers}
						               togglePermission={togglePermission}
						               permissions={permissions}
						               className={'d-flex gap-7r'}
						/>
					</Card>
				</div>
			
			</Card>
			
			<Card className={`${loader(isReady)} mb-2`}>
				<Head secondary className={'d-inline-block'}>
					Таблица данных
				</Head>
				
				<Card
					titleUppercase
					secondary
					className={'col'}
				>
					<SwitchersList list={tableSwitchers}
					               togglePermission={togglePermission}
					               permissions={permissions}
					/>
				</Card>
			</Card>
			
			<Card className={loader(isReady)}>
				<Head secondary className={'d-inline-block'}>
					Управление системой
				</Head>
				
				<Card
					titleUppercase
					secondary
					className={'col'}
				>
					<SwitchersList list={managementSwitchers}
					               togglePermission={togglePermission}
					               permissions={permissions}
					/>
				</Card>
			</Card>
			
			<Button
				onClick={onSave}
				disabled={disableSaveBtn}
				save
				className={'mt-1 d-block ml-auto'}
			/>
		</>
	);
};
