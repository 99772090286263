import React from "react";
import {MySelect} from "../../../../../components/form/MySelect/MySelect";
import {MySearchSelect} from "../../../../../components/form/MySelect/MySearchSelect";

export const RuleAndStatusSelectors = (
	{
		className, rules, onRuleSelect, rerenderKey, restrictions, onRestrictionsSelect, status,
	}) => (
	
	<div className={`mt-2 d-flex mb-2 ${className}`}>
		<fieldset className='width-350 mr-6'>
			<label>ВЫБЕРИТЕ ПРАВИЛО</label>
			<MySearchSelect type={'rules'} noSearchInput onSelect={onRuleSelect} initText={'-'} />
		</fieldset>
		
		<fieldset className='width-350'>
			<label>ВЫБЕРИТЕ ОГРАНИЧЕНИЕ</label>
			<MySelect
				key={rerenderKey}
				optionsArr={restrictions}
				keyValue={'status'}
				keyText={'selectItem'}
				initialSelectedItem={restrictions[status - 1]}
				onSelect={onRestrictionsSelect}
			/>
		</fieldset>
	</div>
)
