import { SearchInput } from '../../../../components/form/SearchInput';
import { MyTableHead } from '../../../../components/table/MyTable/MyTableHead';
import { MyTableBody } from '../../../../components/table/MyTable/MyTableBody';
import { isEmpty, loader } from '../../../../js-helpers/helpers';
import { MyTable } from '../../../../components/table/MyTable/MyTable';
import React, { useEffect, useMemo } from 'react';
import { useMyTable } from '../../../../components/table/MyTable/useMyTable';
import { notificationsAPI } from '../../../../API/notificationsAPI';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { useComboSearch } from '../../../../hooks/url/useComboSearch';
import { urlParams } from '../../../../constants/urlParams';
import { useParams } from 'react-router-dom';
import { useUpdateEffect } from '@react-hookz/web';
import { getNotificationsTableOptions } from '../../../../components/table/MyTable/options/columnsNotifications';
import { PageNotificationsFilters } from '../../../management/pageNotifications/pageNotificationsFilters/PageNotificationsFilters';

const { SEARCH, SORT, PAGE, LIMIT, TYPE, SENDER, RECEIVER, DATE_FROM, DATE_TO } = urlParams;
const pageSearchParams = [
  { param: TYPE },
  { param: SEARCH, init: '', debounce: 333 },
  { param: PAGE, init: 'last' },
  { param: SORT, init: 'created_at' },
  { param: LIMIT },
  { param: SENDER },
  { param: RECEIVER },
  { param: DATE_FROM },
  { param: DATE_TO },
];

const UserP2PHistory = () => {
  const { keycloakID } = useParams();

  const [
    [urlType],
    [urlSearchQuery, setUrlSearchQuery, clearUrlSearchQuery],
    [urlPage, setUrlPage],
    [urlSortField],
    [urlLimit],
    [urlSender, setUrlSender],
    [urlReceiver, setUrlReceiver],
    [urlDateFrom],
    [urlDateTo],
  ] = useComboSearch(pageSearchParams);

  const isEmptySearchQuery = isEmpty(urlSearchQuery);

  const { data, getData, isReady } = useFetchStates(() =>
    notificationsAPI.getP2PAll(urlPage, urlSearchQuery, urlSortField, urlLimit, urlSender, urlReceiver, urlDateFrom, urlDateTo, keycloakID)
  );

  const { tableHeads, tableColgroup, tableOptions } = useMyTable(
    getNotificationsTableOptions(isEmptySearchQuery, 'user', null)?.['user_personal'] ||
      [],
    { memoDependencies: [data] }
  );

  useEffect(() => {
    getData();
  }, [urlPage, urlSortField]);

  useUpdateEffect(() => {
    if (+urlPage !== 1) {
      setUrlPage(1);
      return;
    }
    getData();
  }, [urlSearchQuery, urlLimit, keycloakID, urlSender, urlReceiver, urlDateFrom, urlDateTo]);

  const showEmptyFoundDataMessage = useMemo(() => {
		return urlSearchQuery ||  urlDateFrom || urlDateTo || urlSender ||	urlReceiver 
	}, [urlSearchQuery, urlSender, urlReceiver, urlDateFrom, urlDateTo])

  return (
    <main className={'col'}>
      {/* <SearchInput  withBorder
                    className={'mb-1'}
                    onChange={(e) => setUrlSearchQuery(e.target.value)}
                    defaultValue={urlSearchQuery}
                    resetWhen={!urlSearchQuery}
      /> */}
      <PageNotificationsFilters/>
      <MyTable  isDataReady={isReady}
                showEmptyFoundDataMessage={!!showEmptyFoundDataMessage}
                colgroup={tableColgroup}
                paginationAllCount={data?.count}
      >
        <MyTableHead heads={tableHeads} />

        <MyTableBody  className={`${loader(isReady)}`}
                      data={data?.results}
                      tableOptions={tableOptions}
                      highestRowToFill={45}
        />
      </MyTable>
    </main>
  );
};

export default UserP2PHistory;
