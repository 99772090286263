import { useMountEffect, useToggle, useUpdateEffect } from "@react-hookz/web";
import dayjs from "dayjs";
import { useImmer } from "use-immer";
import { adminSmassAPI } from "../../../../API/adminSmassAPI";
import { ButtonFilter } from "../../../../components/button/ButtonFilter";
import { MySearchMultiSelect } from "../../../../components/form/MySelect/MySearchMultiSelect";
import { RangeDatePicker } from "../../../../components/form/RangeDatePicker";
import { SearchInput } from "../../../../components/form/SearchInput";
import { urlParams } from "../../../../constants/urlParams";
import { useComboSearch } from "../../../../hooks/url/useComboSearch";
import { useFetchStates } from "../../../../hooks/useFetchStates";
import { getFullNameFromObj, getIsoUtcDate, toggle } from "../../../../js-helpers/helpers";

const { SEARCH, PAGE, SORT, LIMIT, CHANNEL, LAUNCHER_VER, ADMIN, DATE_FROM, DATE_TO } = urlParams;
const pageSearchParams = [
  { param: SEARCH, init: '', debounce: 333 },
  { param: PAGE, init: 'last' },
  { param: SORT, init: 'release_date' },
  { param: LIMIT },
  { param: LAUNCHER_VER },
  { param: CHANNEL },
  { param: ADMIN },
  { param: DATE_FROM },
  { param: DATE_TO },
];

export const PageLauncherFilters = () => {
  const [
    [urlSearchQuery, setUrlSearchQuery],
    [urlPage],
    [urlSortField],
    [urlLimit],
    [urlLauncherVer, setUrlLauncherVer],
    [urlChannel, setUrlChannel],
    [urlAdmin, setUrlAdmin],
    [urlDateFrom, setUrlDateFrom],
    [urlDateTo, setUrlDateTo],
  ] = useComboSearch(pageSearchParams);


  const [showFilters, toggleShowFilters] = useToggle(
    urlLauncherVer || urlChannel || urlAdmin || urlDateFrom || urlDateTo
  );

  useUpdateEffect(() => {
    if (!showFilters) {
      setUrlLauncherVer('');
      setUrlChannel('');
      setUrlAdmin('');
      setUrlDateFrom('');
      setUrlDateTo('');
      setInitFilters((draft) => {
        draft.admin = null;
        draft.launcher_ver = null;
        draft.channel = null;
      });
    }
  }, [showFilters]);

  const [initFilters, setInitFilters] = useImmer({
    admin: null,
    launcher_ver: null,
    channel: null,
  });

	const customAdminSetter = ({data}) => {
    const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')

    const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

		setInitFilters((draft) => {
			draft.admin = {
				text,
				value,
			};
		});
		
		initAdminState.setReady(true);
	}

  const initAdminState = useFetchStates(
		() => adminSmassAPI.searchAdmins({account__keycloak_id__in: urlAdmin}, {allowMultipleRequests: true}), {
			noInitFetch: true,
			customSetter: customAdminSetter,
		});

  useMountEffect(() => {
		urlAdmin && initAdminState.getData();
		
		urlLauncherVer && setInitFilters((draft) => {
			draft.launcher_ver = {
				text: urlLauncherVer,
				value: urlLauncherVer,
			};
		})
		
		urlChannel && setInitFilters((draft) => {
			draft.channel = {
				text: urlChannel,
				value: urlChannel,
			};
		})
	});

  const onSelect = (selectedOptions, cd, setReadyCd = null) => {
    if (!selectedOptions) return;

    const resultOptions = selectedOptions.map((option) => option.value)
    cd(resultOptions)

    setReadyCd && setReadyCd()
  }

  return (
    <div
      className={
        'row no-gutters text-muted gap-1r align-items-center px-1 py-75 rounded bg-transparent-60 mb-1'
      }
    >
      <div className={'col-12 row no-gutters align-items-center'}>
        <SearchInput
          resetWhen={!urlSearchQuery}
          className={'col mr-1'}
          onChange={(e) => setUrlSearchQuery(e.target.value)}
          placeholder={'Поиск...'}
          defaultValue={urlSearchQuery}
        />

        <ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
      </div>

      {showFilters && (
        <div
          className={
            'row col-12 no-gutters align-items-center gap-1r'
          }
        >
          <MySearchMultiSelect
						type={'launcherVersions'}
						initialSelectedItems={initFilters.launcher_ver}
						resetWhen={!urlLauncherVer}
						onSelect={(items) => onSelect(items, setUrlLauncherVer)}
						initText={'Установленная версия'}
            clearUrl={() => setUrlLauncherVer('')}
						titleClassName={`${toggle(!urlLauncherVer, 'text-muted')}`}
            className={'col'}/>

          <MySearchMultiSelect
            noSearchInput
						type={'channels'}
						initialSelectedItems={initFilters.channel}
						resetWhen={!urlChannel}
						onSelect={(items) => onSelect(items, setUrlChannel)}
						initText={'Канал'}
            clearUrl={() => setUrlChannel('')}
						titleClassName={`${toggle(!urlChannel, 'text-muted')}`}
            className={'col'}/>

          <MySearchMultiSelect
							className={'col'}
							type={'allSmassAdmins'}
							init={urlAdmin && initAdminState.isReady}
							initialSelectedItems={initFilters.admin}
							resetWhen={!urlAdmin}
							onSelect={(items) => onSelect(items, setUrlAdmin, initAdminState.setReady(true))}
							initText={'Администратор'}
              clearUrl={() => setUrlAdmin('')}
							titleClassName={`${toggle(!urlAdmin, 'text-muted')}`}
						/>
          
          <RangeDatePicker
            fromPickerProps={{
              value: urlDateFrom,
              onChange: ([date]) => {
                setUrlDateFrom(date && getIsoUtcDate(date));
              },
              options: { maxDate: urlDateTo },
              className: 'width-200'
            }}
            toPickerProps={{
              value: urlDateTo,
              onChange: ([date]) => {
                setUrlDateTo(
                  date && getIsoUtcDate(dayjs(date).second(59).toDate())
                );
              },
              options: { minDate: urlDateFrom },  
              className: 'width-200'
            }}
          />
        </div>
      )}
    </div>
);
}