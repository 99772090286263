import {toast} from 'react-toastify';

const serverErrorsStatuses = {
	500: 'Сервер столкнулся с ситуацией, которую он не знает как обработать. Попробуйте позже.',
	501: 'Сервер не поддерживает данный метод запроса.',
	502: '"Плохой шлюз". Эта ошибка означает что сервер, во время работы в качестве шлюза для получения ответа, нужного для обработки запроса, получил недействительный (недопустимый) ответ. Попробуйте позже.',
	503: '"Сервис недоступен". Сервер не готов обрабатывать запрос. Зачастую причинами являются отключение сервера или то, что он перегружен. Попробуйте позже.',
	504: 'Этот ответ об ошибке предоставляется, когда сервер действует как шлюз и не может получить ответ вовремя.',
	505: '"HTTP-версия не поддерживается". HTTP-версия, используемая в запросе, не поддерживается сервером.',
}

export const showRequestErrorMessage = (response, message, isOnline) => {
	const options = {
		toastId: message,
		type: 'error',
		position: 'top-center',
		autoClose: false,
		updateId: message,
	}
	
	const {status} = response
	
	if (status > 499) {
		// showServerError(response?.status, options)
		toast(`Произошла внутренняя ошибка сервера! Статус код: ${status}`, options)
		toast(serverErrorsStatuses[status], options)
		return
	}
	
	if (status === 400)
		return
	
	if (message !== 'canceled' && message) {
		
		if (message === 'Network Error')
			message = isOnline
				? 'Сервер недоступен. Попробуйте позже.'
				: 'Нет подключения к интернету.'
		
		toast.error(message, options)
	}
}