import {debounce, isEmpty, isNotEmpty} from '../../js-helpers/helpers';
import {useMountEffect} from '@react-hookz/web';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {urlParams} from '../../constants/urlParams';
import {useLSSavedLimit} from './useSearchLimit';
import {useSearchParamsUpdate} from './useSearchParamsUpdate';

//options = [
// 		{param: SEARCH, init: '', debounce: 333, paramHandler: (val) => val},
// 		{param: SORT, init: 'last_name'},
// 		{param: PAGE, init: 1},
// 		{param: LIMIT}
// 	]

//todo как не сетить blocked у юзеров, если остаешься на странице и перезапускаешь ее, а значении пустое
export const useComboSearch = options => {
	const currentUrlLimit = useLSSavedLimit()
	const {setNewSearch, deleteSearch, updateUrl, searchParams} = useSearchParamsUpdate()
	
	const withSavedInitOptions = useMemo(() =>
		options.map((_option) => _option.param === urlParams.LIMIT
			? {..._option, init: currentUrlLimit || 10}
			: _option
		), [])
	
	useMountEffect(() => {
		setInitParams(searchParams, withSavedInitOptions)
		updateUrl()
	})
	
	// const isFirstMounted = useFirstMountState()
	const searchString = searchParams.toString()
	
	// console.log({isFirstMounted})
	return useMemo(() => withSavedInitOptions
		.map(({param, init, debounce, paramHandler}) => [
				getFinalParam(
					// isFirstMounted
					// 	?
					searchParams.get(param) || init?.toString()
					// : searchParams.get(param)
					, paramHandler
				),
				getFinalParamSetter(val => { setNewSearch(param, val) }, debounce),
				() => deleteSearch(param)
			]
		), [searchString])
}

function getFinalParam(param, paramHandler) {
	return paramHandler?.(param) ?? param
}

function getFinalParamSetter(cb, debTime) {
	return debTime ? debounce(cb, debTime) : cb
}

function setInitParams(searchParams, options) {
	options.forEach(({param, init}) => {
		const emptyCurrentValue = isEmpty(searchParams.get(param))
		
		if (emptyCurrentValue && isNotEmpty(init))
			searchParams.set(param, init)
	})
}

useComboSearch.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			param: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			init: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			debounce: PropTypes.number,
			paramHandler: PropTypes.func,
		}))
}