import {modelsOptions} from './item/useGlobalSearchItem/modelsOptions';
import {ResultsFields} from './item/ResultsFields';
import {Head} from '../../../components/UI/Head';

const logsName = {
  loginlogoutlog: 'входа/выхода',
  launcherupdatelog: 'обновления лаунчера',
  appinstalllog: 'установки приложения',
  appupdatelog: 'обновления приложения',
  applaunchlog: 'запуска приложения'
}

const getModelName = (item) => {
	if (item.model.endsWith('log'))
		return `${modelsOptions['log']?.modelName} ${logsName[item.model]}`
	
	const isAdminModel = item.fields.is_admin && item.model === 'account'
	if (isAdminModel)
		return modelsOptions['admin']?.modelName
	
	return modelsOptions[item.model]?.modelName
}

export function GlobalSearchResultsList({className = '', results}) {
	
	return (
		<ul className={className}>
			
			{results.map((item, index) => (
				<li key={`${index} ${item.fields?.id}`} className={'list-group-item font-small-3'}>
					
					<Head secondary uppercase>{getModelName(item)}</Head>
					
					<ResultsFields item={item}/>
					
					{/*<nav className={'mt-50'}>*/}
					{/*	<GlobalSearchBreadCrumbs item={item}/>*/}
					{/*</nav>*/}
				</li>
			))
			}
		
		</ul>
	)
}