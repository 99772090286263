import React, {useEffect} from 'react';
import {Card} from '../../../../../components/UI/Card';
import {MySwitch} from '../../../../../components/form/MySwitch';
import {Button} from '../../../../../components/button/Button';
import {useToggle, useUpdateEffect} from '@react-hookz/web';
import classNames from 'classnames';
import {CheckBox} from '../../../../../components/form/CheckBox';
import {useImmer} from 'use-immer';
import {Head} from '../../../../../components/UI/Head';
import {loader} from '../../../../../js-helpers/helpers';

const wifiTrack = 'screenshots_wifi'
const wifiAndMobileTrack = 'screenshots_wifi_and_cellular'

const dataTransit = [
	{label: 'Wi-Fi', field: wifiTrack},
	{label: 'Мобильный интернет', field: wifiAndMobileTrack},
]

const prepareTransit = (initTrackers) =>
	Object.fromEntries(dataTransit.map(({field}) => ([field, initTrackers[field]])))

const getIsEnableTransit = (initTrackers) => dataTransit.some(({field}) => initTrackers[field])

export default ({onSave, isReadyRequests, initTrackers}) => {
	
	const [isEnableTracking, toggleIsTrackingShots] = useToggle(false)
	const [trackers, setTrackers] = useImmer({})
	
	useEffect(() => {
		setTrackers(prepareTransit(initTrackers))
		toggleIsTrackingShots(getIsEnableTransit(initTrackers))
	}, [initTrackers])
	
	useUpdateEffect(() => {
		isEnableTracking &&
		setTrackers(draft => {
			draft[wifiTrack] = true
		})
		
		!isEnableTracking &&
		setTrackers(draft => {
			draft[wifiTrack] = false
			draft[wifiAndMobileTrack] = false
		})
	}, [isEnableTracking])
	
	const isDisabledSaveBtn = isReadyRequests
		&& Object.entries(trackers)?.every(([field, val]) => initTrackers[field] === val)
	
	const onTransitChange = ({target}) => {
		setTrackers(draft => {
			draft[target.id] = target.checked
			draft[wifiTrack] = true
		})
	}
	
	return (
		<Card className={classNames(
			'flex-grow-1 d-flex gap-1r pt-3 align-items-start',
			loader(isReadyRequests),
			{
				'justify-content-between ': !isEnableTracking,
				'flex-column': isEnableTracking,
			}
		)}>
			
			<MySwitch checked={isEnableTracking}
			          id={'screenshots'}
			          onChange={e => toggleIsTrackingShots(e.target.checked)}
			          appendLabel={'Скриншоты'}/>
			
			{isEnableTracking &&
			<div className={'w-100 mt-1'}>
				<Head thirdly noMargins className={'mb-50'}>Передача данных</Head>
				
				<Card secondary
				      headClassName={'font-medium-1'}
				      className={'font-small-3'}>
					
					{dataTransit.map(({label, field}) => (
						<CheckBox checked={trackers[field]}
						          prependLabel={label}
						          className={'font-weight-bold mr-5'}
						          vertical
						          key={field}
						          id={field}
						          onChange={onTransitChange}/>
					))
					}
				
				</Card>
			</div>
			}
			
			<Button onClick={() => onSave(trackers)}
			        disabled={isDisabledSaveBtn}
			        className={'align-self-end'} save/>
		</Card>
	)
}

