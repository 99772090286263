import axios from 'axios';

export const appAPI = {
	getAppVersion:
		() => axios(window.location.origin, {
			headers: {'Cache-Control': 'no-cache'}
		})
			.then(({data}) => ({
				data: data
					.split('<meta')
					.find(item => item.includes('name="version"'))
					.split(' ')
					.find(item => item.includes('content'))
					.replaceAll('content="', '')
					.replaceAll('"', '')
			}))
}