import {Card} from '../../../../../components/UI/Card';
import {AvatarWithChange} from '../../../../../components/UI/AvatarWithChange';
import {Head} from '../../../../../components/UI/Head';
import {getFullNameFromObj, isEmpty} from '../../../../../js-helpers/helpers';
import {MySelect} from '../../../../../components/form/MySelect/MySelect';
import React, {useEffect, useMemo, useState} from 'react';
import {contacts} from '../../../../../API/constants/contacts';
import {MyNewInput} from '../../../../../components/form/MyNewInput';

export const inputsOptions = {
	leftInputs: [
		{label: 'Фамилия*', name: 'last_name'},
		{label: 'Имя*', name: 'first_name'},
		{label: 'Отчество*', name: 'middle_name'},
		{label: 'дата рождения*', name: 'birth_date'},
	],
	phone_number: {label: 'Номер телефона*', name: 'phone_number'},
	email: {label: 'адрес электронной почты', name: 'email'},
	rightInputs: [
		{label: 'дивизион', name: 'division'},
		{label: 'Подразделение', name: 'subdivision'},
		{label: 'должность', name: 'position'},
		{label: 'Юридическое лицо', name: 'company'},
		{label: 'Заметка', name: 'note'},
	]
}

const {leftInputs, rightInputs} = inputsOptions
const emailTypesOptions = Object.values(contacts.email)
const phoneNumberTypesOptions = Object.values(contacts.phone_number)

function getInitSelectItem(data, ind) {
	const typeField = Object.keys(data[0])[0]
	const type = data.find((data, i) => i === ind).type
	if (type) return contacts[typeField][type]
}

function prepareData(data) {
	const getVal = (input) => ({
		...input,
		value: data && data[input.name] || '-'
	})
	return [leftInputs.map(getVal), rightInputs.map(getVal)]
}

function prepareSubData(data, field) {
	if (isEmpty(data)) return []
	
	const newArr = data.map((item, ind) => ({
		name: `${field}s.${ind}.${field}`,
		value: item[field]
	}))
	newArr[0].label = inputsOptions[field].label
	
	return newArr
}

export function SectionPersonalInfo({avatar, data}) {
	const [phonesState, setPhonesState] = useState([{'phone_number': '', 'type': 1}])
	const [emailsState, setEmailsState] = useState([])
	const [isFullInfo, setIsFullInfo] = useState(false)
	
	useEffect(() => {
		if (data) {
			setPhonesState(data?.phone_numbers)
			setEmailsState(data?.emails)
		}
	}, [data])
	
	const [leftPreparedData, rightPreparedData] = useMemo(
		() => prepareData(data), [data])
	
	const preparedPhones = useMemo(
		() => prepareSubData(phonesState, 'phone_number')
		, [phonesState])
	
	const preparedEmails = useMemo(
		() => prepareSubData(emailsState, 'email')
		, [emailsState])
	
	const phonesString = data?.phone_numbers?.map(({phone_number}) => phone_number).join(', ')
	
	return (
		<Card as={'form'} secondary className={'mb-2 d-flex'}>
			
			<AvatarWithChange {...avatar}
			                  url={data?.photo}
			                  className={'mr-3 ml-1'}/>
			
			{isFullInfo &&
			<div className={'w-100'}>
				<Head secondary as={'h3'}>Личная информация</Head>
				
				<div className={'row'}>
					<fieldset className={'col'}>
						
						{leftPreparedData?.map(data => (
							<MyNewInput
								disabled
								key={data.name}
								className={'mb-1'}
								{...data}
							/>
						))}
						
						{preparedPhones?.map((data, ind, arr) => (
							<div key={data.name}>
								
								<MyNewInput
									appendComponent={
										<MySelect
											disabled
											className={'width-40-per'}
											optionsArr={phoneNumberTypesOptions}
											initialSelectedItem={
												getInitSelectItem(phonesState, ind) || phoneNumberTypesOptions[0]
											}
											keyText={'name'}
											keyValue={'type'}
										/>
									}
									{...data}
									disabled
									className={'mb-1'}
								/>
							</div>
						))}
					</fieldset>
					
					<fieldset className={'col'}>
						
						{rightPreparedData?.map((data) => {
              if(data.name !== 'company') {
                return <MyNewInput disabled
							            className={'mb-1'}
							            {...data}
								          key={data.name}
							/>
              } else {
                return <MyNewInput disabled
							            className={'mb-1'}
                          name={data.name}
                          value={data?.value?.title || '-'}
							            label={data?.label}
								          key={data.name}
							/>
              }
            }
					)}
						
						{preparedEmails?.map((data, ind, arr) => (
							<div key={data.name}>
								<MyNewInput
									appendComponent={
										<MySelect
											disabled
											className={'width-35-per'}
											optionsArr={emailTypesOptions}
											initialSelectedItem={getInitSelectItem(
												emailsState, ind) || emailTypesOptions[0]}
											keyText={'name'}
											keyValue={'type'}
										/>
									}
									disabled
									className={'mb-1'}
									{...data}
									key={data.name}
								/>
							</div>
						))}
						<button className={'btn btn-outline-primary float-right mt-2'}
						        onClick={() => setIsFullInfo(false)}>
							Скрыть
						</button>
					</fieldset>
				</div>
			</div>
			}
			
			{!isFullInfo &&
			<div>
				<h3 className={'font-weight-bold'}>{getFullNameFromObj(data)}</h3>
				<p>{`моб: ${phonesString || '-'}`}</p>
				<button className={'btn btn-outline-primary align-self-end'}
				        onClick={() => setIsFullInfo(true)}>Просмотреть
				</button>
			</div>
			}

		</Card>
	)
}