import axios from 'axios'
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions'

export const deviceAPI = {
	// getUserDeviceInfo: (keycloakID) =>
	// 	axios(`/users/${keycloakID}/device/`, {skipErrorHandling: true}),
	
	// getAllDevices: (page = 1) => axios('/devices/', setPreparedAxiosParams({page})),
	
	getDevices() {
		return axios('/devices/', setPreparedAxiosParams({...arguments[0]}))
	},
	
	// searchDevices: (
	// 	page = 1,
	// 	search,
	// 	ordering,
	// 	page_size,
	// 	serial_number,
	// 	user__account__keycloak_id,
	// 	setup__admin__account__keycloak_id,
	// 	brand,
	// 	model,
	// 	launcher_version_name
	// ) => {
	// 	return axios('/devices/', {
	// 		...setPreparedAxiosParams({
	// 			page,
	// 			search,
	// 			ordering,
	// 			page_size,
	// 			serial_number,
	// 			user__account__keycloak_id,
	// 			setup__admin__account__keycloak_id,
	// 			brand,
	// 			model,
	// 			launcher_version_name
	// 		}),
	// 	})
	// },
	
	getOneDevice: (serial) => axios(`/devices/${serial}/`),
	
	// getAllFreeDevices({page_size = 25, ...params}) {
	// 	return this.getDevices({...arguments[0], 'no-user': true})
	// },
	
	// getAllLauncherVersions({page, search, page_size = 25}) {
	// 	return axios('/launchers/', setPreparedAxiosParams(arguments[0]))
	// },
	
	// getAllHistoriesSetup: (page = 1) =>
	// 	axios('/devices/setup/', setPreparedAxiosParams({page})),
	
	getDeviceSetupHistory: (id) => axios(`/devices/setup/${id}/`),
	
	getDeviceSetupSections({phone_model, instruction_version}) {
		return axios('/devices/setup/sections/', setPreparedAxiosParams(arguments[0]));
	},
	
	getDeviceSetupOneSection(sequence_number, {phone_model, version_android, instruction_version}) {
		return axios(`devices/setup/sections/${sequence_number}/`,
			setPreparedAxiosParams(arguments[1]));
	},
	
	getDevicesBrands() {
		return axios('/devices/brands/', setPreparedAxiosParams({...arguments[0]}),)
	},
	
	getDevicesModels() {
		return axios('/devices/models/', setPreparedAxiosParams({...arguments[0]}),)
	},
}