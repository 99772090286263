import {PageUsers} from '../pages/members/pageUsers/PageUsers';
import {PageUserEdit} from '../pages/members/pageUsers/PageUserEdit/PageUserEdit';
import {PageGlobalSearch} from '../pages/globalSearch/PageGlobalSearch';
import {UserNavLayout} from '../pages/members/pageUsers/layout/UserNavLayout';
import {PageUserInfo} from '../pages/members/pageUsers/PageUsersInfo/PageUserInfo';
import {PageMedia} from '../pages/members/pageUsers/PageMedia/PageMedia';
import {PageUserCalls} from '../pages/members/pageUsers/PageCalls/PageUserCalls';
import {PageUserLogs} from '../pages/members/pageUsers/PageLogs/PageUserLogs';
import {PageUserContacts} from '../pages/members/pageUsers/PageUserContacts/PageUserContacts';
import {PageContactInfo} from '../pages/management/contacts/PageContactInfo';
import {adminPermissions} from '../constants/adminPermissions';
import {PageUserTrackers} from '../pages/members/pageUsers/pageUserTrackers/PageUserTrackers';
import {PageUserP2PNotifications} from '../pages/members/pageUsers/PageUserP2PNotifications/PageUserP2PNotifications';
import PageNotificationsItem from '../pages/management/pageNotifications/pageNotificationsItem/PageNotificationsItem';
import { PageUsersNew } from '../pages/members/pageUsers/PageUsersNew/PageUsersNew';
import { loggedAdmin } from '../stores/loggedAdmin';

const {
	USERS, USERS_GENERAL,
	USERS_CALLS, USERS_LOGS,
	USERS_SCREENSHOTS, USERS_PHONEBOOK,
	USERS_SETTINGS,
	USERS_TRACKERS, USERS_NOTIFICATIONS, NOTIFICATIONS
} = adminPermissions

export const usersRoutes = {
	path: '/admins/', children: [
		{
			path: 'users/',
			children: [
				{path: '', resolve: USERS, element: <PageUsers/>},
				{
					path: 'search/',
					// resolve: GLOBAL_SEARCH,
					element: <PageGlobalSearch/>,
				}, {
					path: ':keycloakID/',
					children: [
						{
							element: <UserNavLayout/>,
							children: [
								{
									path: 'info/',
									resolve: USERS_GENERAL,
									element: <PageUserInfo/>,
								},
								// {
								// 	path: 'messages/',
								// 	resolve: USERS_MESSAGES,
								// 	element: <PageMessages/>,
								// },
								{
									path: 'media/',
									resolve: USERS_SCREENSHOTS,
									element: <PageMedia/>,
								}, {
									path: 'phone-book/',
									resolve: USERS_PHONEBOOK,
									element: <PageUserContacts/>,
								}, {
									path: 'calls/',
									resolve: USERS_CALLS,
									element: <PageUserCalls/>,
								}, {
									path: 'logs/',
									resolve: USERS_LOGS,
									element: <PageUserLogs/>,
								}, {
									path: 'trackers/',
									resolve: USERS_TRACKERS,
									element: <PageUserTrackers/>,
								}, {
									path: 'p2p-notifications/',
									resolve: USERS_NOTIFICATIONS,
									element: <PageUserP2PNotifications/>,
								}, {
									path: 'settings/',
									resolve: USERS_SETTINGS,
									element: <PageUserEdit/>,
								},
							],
						}, {
							path: 'phone-book/:contactID/',
							resolve: USERS_PHONEBOOK,
							element: <PageContactInfo/>,
						},
					],
				},
			],
		},
    {
      path: 'users-new/',
      element: <PageUsersNew/>,
      resolve: () => loggedAdmin.isSuperAdmin,
    },
    {
      resolve: USERS_NOTIFICATIONS,
      path: 'p2p-notifications/:notificationID/', 
      element: <PageNotificationsItem/>,
    }
	]
}