export const LOG_IN_OUT_REASONS = { 
  1: 'Инициирован пользователем',
  2: 'Истек токен',
  3: 'Невалидный токен',
};

export const LOG_IN_OUT_STATUSES = { 
  1: 'Выполнен вход в Систему',
  2: 'Выполнен выход из Системы',
};

export const initStateFullScreenPlayer = {
  playing: false,
  played: 0,
  seeking: false,
  duration: 0,
  badURL: false,
  isReady: false,
  volume: localStorage.getItem('callsVolume') || 1,
};

export const USER_TYPE = 'user'
export const DEVICE_TYPE = 'device'
export const ALL_TYPE = 'all'
export const ADMIN_TYPE = 'admin'

export const SUCCESS_ERROR_STATUSES = {
  'success': 'Успешно',
  'error': 'Ошибка',
};

export const APPS = {
  1: 'Launcher Service',
  2: 'Screenshots Service',
  3: 'Monitor Apps Service',
  4: 'Contacts Service',
  5: 'Calls Recorder Service',
};

export const LOG_IN_OUT_TYPES = {
  'login': 'Вход в Систему',
  'logout': 'Выход из Системы',
};

export const callTypes = {
  1: ['Исходящий', 'text-primary'],
  2: ['Входящий', 'text-success'],
  3: ['Пропущенный', 'text-danger'],
  4: ['Отклонен', 'text-warning'],
};

export const LOGIN_STATUS = {
  1: 'В сети',
  2: 'Разлогинен',
  3: 'Нет информации о логине'
}

export const updateChanelOptions = [
	{value: 3, text: 'Альфа (для разработчиков)',},
	{value: 2, text: 'Бета (предварительный выпуск)'},
	{value: 1, text: 'Стабильный'},
]

export const channelsNamesForLaunchers = {
  dev: 'Альфа (для разработчиков)',
	beta: 'Бета (предварительный выпуск)',
  release: 'Стабильный',
}

export const INSTALL_ERROR_NAMES = {
  1: "Ошибка интернет соединения",  
  2: "Истек токен",
  3: "Ошибка сервера",
  4: "Установка прервана",
  5: "Невозможно обновить (версии приложений несовместимы)",
  6: "Закончилась память"
}