export const translatedFields = {
	'app': 'Приложение',
	'first_name': 'Имя',
	'middle_name': 'Отчество',
	'last_name': 'Фамилия',
	'email': 'Электронная почта',
	'contactemailv2_set': 'Электронная почта',
	'division': 'Дивизион',
	'subdivision': 'Подразделение',
	'position': 'Должность',
	'passport': 'Номер паспорта',
	'register_address': 'Адрес регистрации',
	'residence_address': 'Адрес проживания',
	'text': 'Текст',
	'topics.name': 'Ключевые слова',
	'phone_number': 'Номер телефона',
	'contactphonenumberv2_set': 'Номер телефона',
	'android_id': 'Android ID',
	'serial_number': 'Серийный №',
	'brand': 'Бренд',
	'model': 'Модель',
	'system_version': 'Версия системы',
	'api_level': 'Версия API',
	'launcher_version_code': 'Код версии лаунчера',
	'launcher_version_name': 'Имя версии лаунчера',
	'sender': 'Отправитель',
	'url': 'Адрес',
	'name': 'Имя',
	'birth_date': 'Дата рождения',
	'date': 'Дата',
	'uuid': 'ID',
	'id': 'ID',
	'type': 'Тип',
	'keycloak_id': 'KEYCLOAK ID',
	'company': 'Компания',
	'note': 'Заметка',
	'status_code': 'Код статуса',
	'response_message': 'Сообщение ответа',
	'old_phone_number': 'Старый номер телефона',
	'new_phone_number': 'Новый номер телефона',
  'app_name': 'Приложение',
  'user': 'Пользователь',
  'device': 'Серийный №',
  'is_success': 'Статус',
  'from_version': 'C версии',
  'to_version': 'На версию',
  'version': 'Версия',
  'version_code': 'Код версии',
  'from_version_code': 'С кода версии',
  'to_version_code': 'На код версии',
  'reason': 'Причина'
}