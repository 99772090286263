import { Link } from "react-router-dom"
import { getFullNameFromObj, getShortFullNameFromObj, toggle } from "../../../../js-helpers/helpers"
import { deviceStatuses } from "../../../../pages/members/pageDevices/PageDevices"

// const isArchiveStatus = status => status === 2;

export const columnsDevices = {
  serial_number: {
    head: 'Серийный номер',
    cell: ({serial_number}) => <Link to={`/admins/devices/${serial_number}/info/`}>{serial_number}</Link>,
    cellClassName: 'py-2',
    sortField: 'serial_number',
  },
  serialNumberClear: {
    head: 'Серийный номер',
    cell: ({serial_number}) => serial_number,
  },
  user: (isEmptySearchQuery) => ({
    head: 'Пользователь',
    sortField: 'user__account__last_name',
    cell: ({user}) => user
      ? <Link to={`/admins/users/${user.keycloak_id}/info/`}>{(isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user))}</Link>
      : 'Не привязан',
    cellClassName: ({user}) => toggle(!user, 'text-muted')
  }),
  userClear: {
    head: 'Пользователь',
    cell: ({user}) => user ?  getFullNameFromObj(user) : 'Не привязан',
  }, 
  brand: {
    head: 'Бренд',
    cell: ({brand}) => brand
  },
  model: {
    head: 'Модель',
    cell: ({model}) => model
  },
  launcher_version: {
    head: 'Версия прошивки',
    cell: ({launcher_version_name}) => launcher_version_name
  },
  admin: (isEmptySearchQuery) => ({
    head: 'Администратор настройки',
    cell: ({admin}) => admin
      ? (isEmptySearchQuery ? getShortFullNameFromObj(admin) : getFullNameFromObj(admin))
      : 'Не настроено',
    cellClassName: ({admin}) => toggle(!admin, 'text-muted'),
    sortField: 'setup__admin__account__last_name'
  }), 
  adminClear: {
    head: 'Администратор настройки',
    cell: ({admin}) => admin ? getFullNameFromObj(admin) : 'Не настроено',
  },
  imei1: {
    head: 'IMEI 1',
    cell: ({imei1}) => imei1 ? imei1 : '-',
    col: { width: 100 },
  },
  imei2: {
    head: 'IMEI 2',
    cell: ({imei2}) => imei2 ? imei2 : '-',
    col: { width: 100 },
  },


  //brand_and_model: {
  //   head: 'Устройство',
  //   cell: ({brand_and_model}) => brand_and_model
  // },
  // status: {
  //   head: 'Статус',
  //   sortField: 'status',
  //   cell: ({status}) => deviceStatuses[status],
  //   cellClassName: ({status}) => toggle(isArchiveStatus(status), 'text-muted', 'text-success')
  // },
}

export const getDevicesTableOptions = (isEmptySearchQuery) => {

  return (
    [columnsDevices.serial_number,
     columnsDevices.user(isEmptySearchQuery),
     columnsDevices.brand,
     columnsDevices.model,
     columnsDevices.launcher_version,
     columnsDevices.admin(isEmptySearchQuery)
    ]
  )
}