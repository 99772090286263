/* eslint-disable max-lines */
import {useMountEffect, useToggle, useUpdateEffect} from '@react-hookz/web';
import dayjs from 'dayjs';
import {useSearchParams} from 'react-router-dom';
import {useImmer} from 'use-immer';
import {userAPI} from '../../../API/userAPI';
import {ButtonFilter} from '../../../components/button/ButtonFilter';
import {FilterPills} from '../../../components/FilterPills/FilterPills';
import {MySearchSelect} from '../../../components/form/MySelect/MySearchSelect';
import {MySelect} from '../../../components/form/MySelect/MySelect';
import {RangeDatePicker} from '../../../components/form/RangeDatePicker';
import {SearchInput} from '../../../components/form/SearchInput';
import {ALL_TYPE, DEVICE_TYPE,	LOG_IN_OUT_TYPES,	LOG_IN_OUT_REASONS,	SUCCESS_ERROR_STATUSES,	USER_TYPE} from '../../../constants/constants';
import {urlParams} from '../../../constants/urlParams';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {getFullNameFromObj, getIsoUtcDate, getLocaleFullDate, toggle} from '../../../js-helpers/helpers';
import {mapToOptions} from './filtersFunctions';
import {pageSearchParams} from './FiltersParams';
import {LOGS_SERVICES} from '../../../constants/logsOptions';
import {useEffect} from 'react';

const successErrorStatusFilter = mapToOptions(SUCCESS_ERROR_STATUSES);
const logInOutTypesFilter = mapToOptions(LOG_IN_OUT_TYPES);
const LOG_IN_OUT_REASONSFilter = mapToOptions(LOG_IN_OUT_REASONS)

const {
	SEARCH, DATE_FROM, DATE_TO, STATUS, USER,
	DEVICE, TYPE, REASON, DATE_OFF_FROM, DATE_OFF_TO,
	DATE_ON_FROM, DATE_ON_TO
} = urlParams;

const {
	appInstalls, appUpdates, appLaunch,
	logInLogOut, launcherUpdates, deviceShutdowns
} = LOGS_SERVICES;

const FiltersPageAppInstallsUpdates = ({urlService, filter, prevSearchService}) => {
	const [
		[urlStatus, setUrlStatus],
		[urlType, setUrlType],
		[urlReason, setUrlReason],
		[urlUser, setUrlUser],
		[urlDevice, setUrlDevice],
		[urlApp, setUrlApp],
		[urlPage],
		[urlSortField],
		[urlLimit],
		[urlSearchQuery, setUrlSearchQuery],
		[urlDateFrom, setUrlDateFrom],
		[urlDateTo, setUrlDateTo],
		[urlDateOffFrom, setUrlDateOffFrom],
		[urlDateOffTo, setUrlDateOffTo],
		[urlDateOnFrom, setUrlDateOnFrom],
		[urlDateOnTo, setUrlDateOnTo],
	] = useComboSearch(pageSearchParams);
	
	const [search, setSearch] = useSearchParams();
	
	const [showFilters, toggleShowFilters] = useToggle(
		urlStatus || urlType || urlUser || urlDevice || urlApp
		|| urlReason || urlDateFrom || urlDateTo || urlDateOffFrom
		|| urlDateOffTo || urlDateOnFrom || urlDateOnTo
	)
	
	useUpdateEffect(() => {
		// console.log({showFilters});
		if (!showFilters) {
			setUrlStatus('');
			setUrlDateFrom('');
			setUrlDateTo('');
			setUrlDateOffFrom('');
			setUrlDateOffTo('');
			setUrlDateOnFrom('');
			setUrlDateOnTo('');
			setUrlDevice('');
			setUrlUser('');
			setUrlType('');
			setUrlReason('');
			
			setInitFilters((draft) => {
				draft.user = {text: '', value: '',};
				draft.device = {text: '', value: '',};
				draft.status = {text: '', value: '',};
				draft.type = {text: '', value: '',};
				draft.reason = {text: '', value: '',};
			});
		}
	}, [showFilters]);
	
	useEffect(() => {
		if (prevSearchService && prevSearchService !== urlService) {
			toggleShowFilters(false);
		}
	}, [urlService]);
	
	const [pillsOptions, setPillsOptions] = useImmer(() => [
		{urlKey: SEARCH, label: null,},
		{urlKey: USER, label: null,},
		{urlKey: DEVICE, label: null,},
		{urlKey: STATUS, label:  SUCCESS_ERROR_STATUSES[urlStatus],},
		{urlKey: TYPE, label: LOG_IN_OUT_TYPES[urlType],},
		{urlKey: REASON, label: LOG_IN_OUT_REASONS[urlReason],},
		{urlKey: DATE_FROM, label: (date) => `От  ${getLocaleFullDate(date)}`,},
		{urlKey: DATE_TO, label: (date) => `До  ${getLocaleFullDate(date)}`,},
		{urlKey: DATE_OFF_FROM, label: (date) => `Отключение От  ${getLocaleFullDate(date)}`,},
		{urlKey: DATE_OFF_TO, label: (date) => `Отключение До  ${getLocaleFullDate(date)}`,},
		{urlKey: DATE_ON_FROM, label: (date) => `Включение От  ${getLocaleFullDate(date)}`,},
		{urlKey: DATE_ON_TO, label: (date) => `Включение До  ${getLocaleFullDate(date)}`,},
	]);
	
	const [initFilters, setInitFilters] = useImmer({
		user: null,
		device: null,
		status: urlStatus
			? {text: SUCCESS_ERROR_STATUSES[urlStatus], value: urlStatus}
			: '',
		type: urlType ? {text: LOG_IN_OUT_TYPES[urlType], value: urlType} : '',
		reason: urlReason ? {text: LOG_IN_OUT_REASONS[urlReason], value: urlReason} : ''
	});
	
	const customSetter = ({data: {account}}) => {
		const text = getFullNameFromObj(account);
		
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === USER).label = text;
		});
		
		setInitFilters((draft) => {
			draft.user = {
				text,
				value: account.keycloak_id,
			};
		});
		
		initUserState.setReady(true);
	};
	
	const initUserState = useFetchStates(
		() => userAPI.getUserInfo(urlUser),
		{noInitFetch: true, customSetter,});
	// console.log({urlUser})
	useMountEffect(() => {
		if (!prevSearchService || prevSearchService === urlService) {
			urlUser && initUserState.getData();
      urlDevice && setInitFilters((draft) => {
        draft.device = {
          text: urlDevice,
          value: urlDevice,
        };
      });
		}
	});
	
	const onUserSelect = (selectedOption) => {
		if (!selectedOption) return;
		const {text, value, extraText} = selectedOption;
		
		setUrlUser(value);
		
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === USER).label = text;
		});
		initUserState.setReady(true);
	};
 
  const onFilterSelect = (selectedOption, type, setUrl) => {
    if (!selectedOption) return;

    const {text, value} = selectedOption;
    setUrl(value)
    setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === type).label = text;
		});
  }
	
	const onClosePill = (urlKey) => {
		search.delete(urlKey);
		setSearch(search.toString(), {replace: true});
	};
	
	return (
		<>
			<div
				className={
					'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
				}
			>
				<div className={'col-12 row no-gutters align-items-center'}>
					<SearchInput
						resetWhen={!urlSearchQuery}
						className={'col mr-1'}
						onChange={(e) => setUrlSearchQuery(e.target.value)}
						placeholder={'Поиск...'}
						defaultValue={urlSearchQuery}
					/>
					
					<ButtonFilter
						showFilters={showFilters}
						toggleShowFilters={toggleShowFilters}
					/>
				</div>
				
				{showFilters && (
					<div className={'row col-12 no-gutters align-items-center gap-1r'}>
						{(filter === ALL_TYPE || filter === DEVICE_TYPE) && (
							<MySearchSelect
								className={`${urlService === logInLogOut && filter !== ALL_TYPE ? 'col-3  width-250' : 'col width-200'}`}
								type={'usersWithKeycloakID'}
								init={urlUser && initUserState.isReady}
								initialSelectedItem={initFilters.user}
								resetWhen={!urlUser}
								onSelect={onUserSelect}
								initText={'Все пользователи'}
								titleClassName={`${toggle(!urlUser, 'text-muted')}`}
							/>
						)}
						
						{(filter === ALL_TYPE || filter === USER_TYPE) && (
							<MySearchSelect
								className={`${urlService === logInLogOut && filter !== ALL_TYPE ? 'col-2' : 'col width-200'}`}
								type={'allDevices'}
								init={urlDevice}
								initialSelectedItem={initFilters.device}
								resetWhen={!urlDevice}
								onSelect={(items) => onFilterSelect(items, DEVICE, setUrlDevice)}
								initText={'Все устройства'}
								titleClassName={`${toggle(!urlDevice, 'text-muted')}`}
							/>
						)}
						
						{urlService === appInstalls ||
						urlService === appUpdates ||
						urlService === logInLogOut ||
						urlService === launcherUpdates ? (
							<MySelect
								initText={'Статус'}
								resetWhen={urlService !== logInLogOut ? !urlStatus : !urlType}
								titleClassName={toggle(
									urlService !== logInLogOut ? !urlStatus : !urlType,
									'text-muted'
								)}
								onSelect={
									urlService !== logInLogOut 
                    ? (items) => onFilterSelect(items, STATUS, setUrlStatus) 
                    : (items) => onFilterSelect(items, TYPE, setUrlType)
								}
								initialSelectedItem={
									urlService !== logInLogOut
										? initFilters.status
										: initFilters.type
								}
								optionsArr={
									urlService !== logInLogOut
										? successErrorStatusFilter
										: logInOutTypesFilter
								}
								className={`${urlService === logInLogOut ? 'col' : 'col-2'}`}
							/>
						) : (
							<></>
						)}
						
						{urlService === logInLogOut ? (
							<MySelect
								initText={'Причина'}
								resetWhen={!urlReason}
								titleClassName={toggle(!urlReason, 'text-muted')}
								onSelect={(items) => onFilterSelect(items, REASON, setUrlReason)}
								initialSelectedItem={initFilters.reason}
								optionsArr={LOG_IN_OUT_REASONSFilter}
								className={'col'}
							/>
						) : (
							<></>
						)}
						{urlService !== deviceShutdowns ? (
							<RangeDatePicker
								className={`${
									filter === ALL_TYPE && urlService !== logInLogOut
										? 'col-4'
										: filter !== ALL_TYPE && urlService === logInLogOut
											? 'col-5'
											: 'col-6'
								} flex-nowrap`}
								fromPickerProps={{
									value: urlDateFrom,
									onChange: ([date]) => {
										setUrlDateFrom(date && getIsoUtcDate(date));
									},
									options: {maxDate: urlDateTo},
									className: urlService === logInLogOut && filter === ALL_TYPE ? 'width-220' : '',
								}}
								toPickerProps={{
									value: urlDateTo,
									onChange: ([date]) => {
										setUrlDateTo(
											date && getIsoUtcDate(dayjs(date).second(59).toDate())
										);
									},
									options: {minDate: urlDateFrom},
									className: urlService === logInLogOut && filter === ALL_TYPE ? 'width-220' : '',
								}}
							/>
						) : (
							<div
								className={
									'row col-12 no-gutters align-items-center gap-1r flex-nowrap'
								}
							>
								<RangeDatePicker
									prependLabel={'Период отключения: '}
									className={'col'}
									fromPickerProps={{
										value: urlDateOffFrom,
										onChange: ([date]) => {
											setUrlDateOffFrom(date && getIsoUtcDate(date));
										},
										options: {maxDate: urlDateOffTo},
										className: 'width-169',
									}}
									toPickerProps={{
										value: urlDateOffTo,
										onChange: ([date]) => {
											setUrlDateOffTo(
												date && getIsoUtcDate(dayjs(date).second(59).toDate())
											);
										},
										options: {minDate: urlDateOffFrom},
										className: 'width-169',
									}}
								/>
								
								<RangeDatePicker
									prependLabel={'Период включения: '}
									className={'col'}
									fromPickerProps={{
										value: urlDateOnFrom,
										onChange: ([date]) => {
											setUrlDateOnFrom(date && getIsoUtcDate(date));
										},
										options: {
											maxDate: urlDateOnTo,
											minDate: urlDateOffFrom,
										},
										className: 'width-169',
									}}
									toPickerProps={{
										value: urlDateOnTo,
										onChange: ([date]) => {
											setUrlDateOnTo(
												date && getIsoUtcDate(dayjs(date).second(59).toDate())
											);
										},
										options: {minDate: urlDateOnFrom || urlDateOffFrom},
										className: 'width-169',
									}}
								/>
							</div>
						)}
					</div>
				)}
			</div>
			
			<FilterPills pillsOptions={pillsOptions}
			             search={search}
			             onClosePill={onClosePill}
			/>
		</>
	);
};

export default FiltersPageAppInstallsUpdates;
