import exportFromJSON from "export-from-json";
import { useEffect, useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { logsAPI } from "../../../API/logsAPI";
import { ButtonWithIcon } from "../../../components/button/ButtonWithIcon";
import { Card } from "../../../components/UI/Card";
import { useFetchStates } from "../../../hooks/useFetchStates";
import { getLocaleDate, getLocaleFullDate, getLocaleTime, loader } from "../../../js-helpers/helpers";
import { ErrorInfoFields } from "./ErrorInfoFields";

const typesHeadAndFileName = {
  'app-installs': {
    file: 'установки_приложения_',
    title: 'Установки приложения'
  },
  'app-updates': {
    file: 'обновления_приложения_',
    title: 'Обновления приложения'
  }
}

export const PageLogsErrorInfo = () => {
  const { errorID } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [searchParams] = useSearchParams();

  const setPageHead = useOutletContext();
  const pageType = searchParams.get('type')

  const { data, isReady } = useFetchStates(
    () => logsAPI.getLogErrorInfo(errorID, searchParams.get('type'))
  );

  useEffect(() => {
    setPageHead({head: `Наименование ошибки: Необработанная ошибка ${typesHeadAndFileName[pageType].title}`})
  }, [])

  const exportError = async () => {
    setIsLoaded(false);
    try {
      const logExport = await logsAPI.getLogErrorInfo(errorID, pageType)

      exportFromJSON({
        data: logExport?.data,
        fileName: `Ошибка_${typesHeadAndFileName[pageType].file}${data?.app_name || data?.app_package_name
        }_${getLocaleFullDate(data.date)}`,
        exportType: 'txt',
      });

      setIsDownloaded(true);
      toast.success('Ошибка успешно сохранена!');
    } catch (e) {
      toast.error('Ошибка загрузки');
    } finally {
       setIsLoaded(true);
    }
  }

  return (
      <Card
        className={`${loader(isReady && isLoaded)}`}
        size={1}
        secondary
      >
        {isReady &&
          <>
            <div className={'d-flex justify-content-between'}>
              <ErrorInfoFields error={data} />
              <div>
                {getLocaleDate(data?.date)},
                <br/>
					      {getLocaleTime(data?.date)}
				      </div>
            </div>

            {data?.error_message && 
              <div className={'border border-1 rounded-lg p-1 mt-50 bg-white mb-1'}>
                {data?.error_message}
              </div> 
            }
         
            <ButtonWithIcon disabled={isDownloaded}
                            className={'d-block ml-auto'}
                            type={'save'}
                            onClick={exportError}>
              Скачать
            </ButtonWithIcon>
          </>
        }
      </Card>
  )
}