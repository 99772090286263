import {useSafeState, useUpdateEffect} from '@react-hookz/web';
import React, {useMemo} from 'react';
import {toast} from 'react-toastify';
import { userAPI } from '../../../../API/userAPI';
import { Button } from '../../../../components/button/Button';
import { ExceptionsLists } from '../../../../components/listsAccess/ExceptionsLists';
import { Card } from '../../../../components/UI/Card';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { loader } from '../../../../js-helpers/helpers';
import { StatusesList } from '../../../management/divisions/edit/StatusesList';

const {
	getAppsAccess, putAppsAccess, getOnUserDivisionAppsAccess
} = userAPI

const getPageOptions = (status) => {
	const getUserAccessWithType = getAppsAccess
	const putItems = putAppsAccess
	const availabilityText = status === 2 ? 'Запрещённые ' : 'Доступные '
	const headExceptions = `${availabilityText + 'приложения'}: `
	
	return {getUserAccessWithType, putItems, headExceptions}
}

export function UserEditAppsExceptionsCard({keycloakID}) {
	const [status, setStatus] = useSafeState(null)
	
	const [initData, setInitData] = useSafeState({exceptions: []})
	const [exceptions, setExceptions] = useSafeState([])
	
	const defaultExceptions = useFetchStates(
		getOnUserDivisionAppsAccess,
		{initData: {exceptions: []}, noInitFetch: true,})
	
	const defaultStatus = status === 1
	
	const {putItems, headExceptions, getUserAccessWithType} = useMemo(() =>
			getPageOptions(defaultStatus ? defaultExceptions.data?.status : status)
		, [status, defaultExceptions.data])
	
	const {isReady, setReady, getData, setData} =
		useFetchStates(() => getUserAccessWithType(keycloakID),
		{initData: {name: ''}, customSetter})
	
	function customSetter({data}) {
		setData(data)
		setStatus(data.status)
		setExceptions(data.exceptions)
		setInitData(data)
		setReady(true)
	}
	
	useUpdateEffect(async () => {
		if (defaultStatus) {
			setReady(false)
			await defaultExceptions.getData(keycloakID)
			setReady(true)
		}
	}, [status])
	
	const onSubmit = async () => {
		
		setReady(false)
		const exceptionsIDS = defaultStatus ? [] : exceptions.map(item => item.id)
		const data = {status, exceptions: exceptionsIDS}
		
		try {
			await putItems(keycloakID, data)
			toast.success('Настройки доступа сохранены')
			getData(keycloakID)
		} catch (e) {
			toast.error('Ошибка сохранения настроек доступа')
			setReady(true)
		}
	}
	
	const getExceptionsOnStatus = () =>
		defaultStatus ? defaultExceptions.data.exceptions : exceptions
	
	const isSameInitExceptions = () => {
		if (!exceptions || !initData?.exceptions) return
		
		const likeInitExceptionsIDS =
			JSON.stringify(exceptions.map(item => item.id).sort()) ===
			JSON.stringify(initData.exceptions.map(item => item.id).sort())
		const likeInitStatus = status === initData.status
		
		return likeInitStatus && likeInitExceptionsIDS
	}
	
	const exceptionsWithInitField = useMemo(() =>
			getExceptionsOnStatus().map(exceptionsItem => {
				const haveIDinInit = initData.exceptions.some(({id}) => id === exceptionsItem.id)
				
				return (haveIDinInit && status === initData.status)
					? {...exceptionsItem, isInit: true}
					: exceptionsItem
			})
		, [status, exceptions, initData, defaultExceptions.data])
	
	
	return (
		<Card className={'mt-1'} title={'Доступные приложения'}>
			
			<StatusesList
				users
				apps
				status={status}
				setStatus={setStatus}
				initStatus={initData?.status}
				className={`d-flex my-2 ${loader(isReady)}`}
			/>
			
			<ExceptionsLists
				type={'apps'}
				disabled={defaultStatus}
				exceptions={exceptionsWithInitField}
				className={`${loader(defaultExceptions.isReady || isReady)}`}
				setExceptions={setExceptions}
				headExceptions={`${headExceptions} ${exceptionsWithInitField?.length}`}
			/>
			
			<Button save
			        disabled={isSameInitExceptions()}
			        onClick={onSubmit}
			        className={'d-block ml-auto mt-1'}
			        children={defaultStatus && 'Применить'}
			/>
		
		</Card>
	)
}