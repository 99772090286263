import React, {useMemo} from 'react';
import {useOutletContext} from 'react-router-dom';
import {Head} from '../../../../components/UI/Head';
import {Card} from '../../../../components/UI/Card';
import {getFullNameFromObj, getLocaleFullDate, isEmpty, isFunction, isValidDate, loader, toggle} from '../../../../js-helpers/helpers';
import {Columns} from '../../../../components/UI/Columns';
import {MyAvatar} from '../../../../components/UI/MyAvatar';
import { WithTooltip } from '../../../../components/UI/WithTooltip';
import dayjs from 'dayjs';

const prepareAdminInfo = (adminData) => {
  const adminInfoOptions = [
    {label: 'фио', field: getFullNameFromObj},
    {label: 'Дивизион', field: 'division'},
    {
			label: 'Корпоративный номер телефона',
			field: ({phone_numbers}) => isEmpty(phone_numbers) ? '-' : phone_numbers.join(', '),
		},
    {label: 'подразделение', field: 'subdivision'},
    {label: 'Должность', field: 'position'},
    {label: 'Адрес электронной почты', field: 'email'},
  ]

  const emptyAdminInfo = isEmpty(adminData)

  return adminInfoOptions.map(option => {
		const value =
      emptyAdminInfo
				? '-'
				: isFunction(option.field)
					? option.field(adminData)
					: adminData[option.field] || '-'
		
		return ({...option, value})
	})
};


export function PageAdminEdit() {
	const {data, isReady} = useOutletContext();

  const prepareColumns = useMemo(
    () => prepareAdminInfo(data?.account), 
    [data])

  const lastLogin = useMemo(() => data?.account?.last_login, [data])
	const dateJoined = useMemo(() => data?.account?.date_joined, [data])

	return (
		<Card as={'form'} className={`${loader(isReady)} row no-gutters gap-1r`}>
			<Card secondary className={'col-8'}>
				<MyAvatar src={data?.account.profile_photo}
				          className={'float-left mr-2 mt-50'}
				          size={120}
				/>
				
			  <div className={'d-flex align-items-center mb-2'}> 
          <Head secondary noMargins>Информация об администраторе</Head>
          <span className={'ml-auto text-warning text-uppercase font-small-3'}>
          {data && data.is_superadmin ? 'Суперадминистратор' : ''}
        </span>
        </div>
				
        <Columns  cols={2}
			            sumItems={prepareColumns.length}
			            className={'list-unstyled list-gap-1r'}>

          {prepareColumns?.map(({label, value = '-'}) => (
					<li key={label}>
						<label className={'text-gray-300 d-block'}>{label}</label>
						{value}
					</li>
				))}
				</Columns>
			</Card>
			
      <Card secondary className={'col'} title={'Администратор в Системе'}>
				<ul className={'list-unstyled list-gap-1r'}>
					<Card as={'li'} size={1} className={''}>
						<h3 className={'font-size-base font-weight-bold'}>
							Последняя активность:
						</h3>
						
						<WithTooltip tooltips={[getLocaleFullDate(lastLogin)]}>
							{toggle(
								isValidDate(lastLogin),
								dayjs(dayjs(lastLogin).subtract(10, 'seconds')).fromNow(),
								'-'
							)}
						</WithTooltip>
					</Card>
					
					<Card as={'li'} size={1} className={'font-small-3'}>
						<h3 className={'font-size-base font-weight-bold'}>
							Дата регистрации администратора в системе:
						</h3>
						
						<span className={'font-weight-light'}>
							{getLocaleFullDate(dateJoined)}
						</span>
					</Card>
				</ul>
			</Card>

		</Card>
	);
}