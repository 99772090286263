import {ButtonGoBack} from '../../../../components/button/ButtonGoBack';
import {useParams} from 'react-router-dom';
import React, {useState} from 'react';
import {useAvatarWithChange} from '../../../../components/UI/AvatarWithChange';
import {Card} from '../../../../components/UI/Card';
import {contactsAPI} from '../../../../API/contactsAPI';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {Head} from '../../../../components/UI/Head';
import {toast} from 'react-toastify';
import {useUpdateEffect} from '@react-hookz/web';
import {userAPI} from '../../../../API/userAPI';
import {isNotEmpty, loader} from '../../../../js-helpers/helpers';
import {ModalDialog} from '../../../../components/modals/ModalDialog';
import {MyNewInput} from '../../../../components/form/MyNewInput';
import {Radio} from '../../../../components/form/Radio';
import {SettingVisible} from '../../../members/pageUsers/settingsVisible/SettingVisible';
import {Button} from '../../../../components/button/Button';
import {SectionPersonalInfo} from './components/SectionPersonalInfo';

const visibilityStatusesList = [
	{textLabel: 'виден всем', status: 1},
	{textLabel: 'не виден никому', status: 2},
	{textLabel: 'настраиваемый', status: 0}
]

export function PageEditInternalContact() {
	const {contactID} = useParams()
	const [visibleStatus, setVisibleStatus] = useState(null)
	const [initVisibleStatus, setInitVisibleStatus] = useState(null)
	const [isSendForm, setIsSendForm] = useState(false)
	const [isSaveRule, setIsSaveRule] = useState(false)
	const [nameNewRule, setNameNewRule] = useState('')
	const [isEnabledSaveBtn, setIsEnabledSaveBtn] = useState(false)
  const [selectedRule, setSelectedRule] = useState({name: '-', id: null})
  const [changedRule, setChangedRule] = useState(null)
	
	const contact = useFetchStates(() => contactsAPI.getShared(contactID))
	
	const avatar = useAvatarWithChange([contact.data])
	const isChangedAvatar = avatar.deletePhotoState || avatar.photoState

	useUpdateEffect(() => {
		const {visibility: {except_users, status, visibility_rule} = {}} = contact.data || {}
		const _status = isNotEmpty(except_users) ? 0 : status

		setVisibleStatus(_status)
		setInitVisibleStatus(_status) 
    visibility_rule ? setSelectedRule({name: visibility_rule?.name, id: visibility_rule?.id}) : setSelectedRule({name: '-', id: null})
    

	}, [contact.data])

	useUpdateEffect(() => {
		if (isChangedAvatar)
			setIsEnabledSaveBtn(true)
	}, [isChangedAvatar])
	
	useUpdateEffect(() => {
		if (isChangedAvatar)
			return
		
		const isVisibilityChanged =
			isNotEmpty(visibleStatus) &&
			isNotEmpty(initVisibleStatus) &&
			(visibleStatus !== initVisibleStatus)
		
		setIsEnabledSaveBtn(isVisibilityChanged)
	}, [visibleStatus, initVisibleStatus])
	
	const onSubmitVisible = async () => {
		
		if (isChangedAvatar) {
			const {deletePhotoState, photoState} = avatar
			
			try {
				if (deletePhotoState)
					await userAPI.deleteUserAvatar(contactID)
				
				if (photoState)
					await userAPI.putUserAvatar(contactID, photoState)
				
				contact.getData()
				toast.success('Аватар успешно обновлен!')
				
			} catch (e) {
				toast.error('Ошибка обновления аватара!')
			}
		}
		
		if (visibleStatus === 1 || visibleStatus === 2) {
			const dataSubmit = {status: visibleStatus, except_users: [], visibility_rule: null}

      setSelectedRule({name: '-', id: null})
      setChangedRule(null)
			try {
				await contactsAPI.patchVisibility(contactID, dataSubmit)
				contact.getData()
				toast.success('Изменения сохранены!')
			} catch (e) {
				toast.error('Ошибка сохранения изменений!')
			}
			
		} else {
			setIsSendForm(true)
		}
	}
	
	const isEditVisibility = visibleStatus === 0
	
	return (
		<>
			<ButtonGoBack className={'float-right ml-1'}/>
			<Head>Информация о Контакте</Head>
			
			<Card className={`p-2 ${loader(contact.isReady)}`}>
				
				<Head secondary uppercase>
					Просмотр Контакта
				</Head>
				
				<SectionPersonalInfo avatar={avatar} data={contact.data}/>
				
				<Card secondary className={loader(contact.isReady)} title={'Видимость'}>
					<ul className={'list-group list-unstyled list-gap-075r'}>
						
						{visibilityStatusesList.map(({textLabel, status}) => (
							<li key={status}>
								<Radio
									textLabel={textLabel}
									checked={visibleStatus === status}
									onChange={() => {
										setVisibleStatus(status)
									}}/>
							</li>
						))
						}
					</ul>

					{isEditVisibility &&
					<SettingVisible
						isChangedAvatar={isChangedAvatar}
						setIsEnabledSaveBtn={setIsEnabledSaveBtn}
						isSendForm={isSendForm}
						uuid={contact.data.uuid}
						nameNewRule={nameNewRule}
						setIsSaveRule={setIsSaveRule}
						setIsSendForm={setIsSendForm}
						isSaveRule={isSaveRule}
						reFetchContactInfo={contact.getData}
						initStatus={contact.data.visibility?.status}
						initExceptions={contact.data.visibility?.except_users}
            setSelectedRule={setSelectedRule}
            selectedRule={selectedRule}
            changedRule={changedRule}
            setChangedRule={setChangedRule}/>
					}
					
					<div className={'d-flex justify-content-end mt-2'}>
						
						{isEditVisibility &&
						<button disabled={!isEnabledSaveBtn}
						        onClick={() => {setIsSendForm(false); setIsSaveRule(true)}}
						        className={'btn-outline-primary btn bg-white mr-2'}>
							Сохранить как правило
						</button>
						}
						
						<Button disabled={!isEnabledSaveBtn}
						        onClick={() => {
							        onSubmitVisible()
							        setIsEnabledSaveBtn(false)
						        }}
						        save
						/>
					</div>
				</Card>
			</Card>
			
			<ModalDialog
				isShow={isSaveRule}
				setIsShow={setIsSaveRule}
				head={'Введите название Правила'}
				body={
					<MyNewInput
						className={'mx-auto my-2 width-450'}
						maxLength={50}
						required
						autoFocus
            value={nameNewRule}
						onChange={(e) => setNameNewRule(e.target.value)}/>
				}
				onSuccessClick={() => { setIsSendForm(true) }}
				onCancelClick={() => { setIsSaveRule(false) }}
				className={`width-600 ${loader(!isSendForm)}`}/>
		</>
	)
}