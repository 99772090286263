import { getFullNameFromObj, getShortFullNameFromObj } from "../../../../js-helpers/helpers";

export const getNotificationReceiversTableOptionsAdmin = (isEmptySearchQuery) => [
  {
    head: 'Устройство',
    cell: (serial_number) => serial_number,
    col: { width: 150 },
    // sortField: 'serial_number',
    nestedCellField: 'serial_number',
  },
  {
    head: 'Пользователь',
    cell: (user) => user ? isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user) : 'Нет пользователя',
    col: { width: 150 },
    // sortField: 'user__account__last_name',
    nestedCellField: 'user',
  },
  // {
  //   head: 'Дата и время',
  //   col: { width: 175 },
  //   cell: getLocaleFullDate,
  //   sortField: 'event_date',
  //   nestedCellField: 'event_date',
  // },
  {
    head: 'Статус',
    cell: (status) => status,
    col: { width: 200 },
    // sortField: 'status',
    nestedCellField: 'status',
  },
];


export const getNotificationReceiversTableOptionsUser = (isEmptySearchQuery) => [
  {
    head: 'Просмотрено на устройстве',
    cell: (viewed_on_device) => viewed_on_device ? viewed_on_device : '-',
    col: { width: 150 },
    nestedCellField: 'viewed_on_device',
  },
  {
    head: 'Пользователь',
    cell: (user) => user ? isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user) : 'Нет пользователя',
    col: { width: 150 },
  },
  {
    head: 'Статус',
    cell: (viewed_status) => viewed_status,
    col: { width: 200 },
    nestedCellField: 'viewed_status',
  },
];