import { Link } from "react-router-dom";
import { getFullNameFromObj, getLocaleFullDate, getShortFullNameFromObj } from "../../../../js-helpers/helpers";
import { ReactComponent as EditSVG } from '../../../../assets/icons/services/edit.svg';

export const getRulesTableOptions = (isEmptySearchQuery) => [
	{
		head: 'Название правила',
		cell: 'name',
		sortField: 'name',
	}, {
		head: 'Автор правила',
		cell: (row) =>
				<Link to={`/admins/${row.keycloak_id}/`}>
					{(isEmptySearchQuery ? getShortFullNameFromObj(row) : getFullNameFromObj(row))}
				</Link>,
		nestedCellField: 'admin_owner',
		sortField: 'admin_owner__account__last_name',
	}, {
		head: 'Дата создания',
		cell: getLocaleFullDate,
		nestedCellField: 'creation_date',
		sortField: 'creation_date',
	}, {
		head: 'Действия',
		cell: id =>
			<Link to={`${id}/`} className='btn btn-link px-0'>
			  <EditSVG style={{marginTop: '3px'}}/>
			</Link>,
		nestedCellField: 'id',
	},
]