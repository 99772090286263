import React from 'react';
import {oneOf} from 'prop-types';

export const Button = (
	{
		save, remove, position, onClick, className = '', disabled, type = 'submit', children
	}) => {
	
	const getBtnProps = () =>
		save ? {btn: {children: children || 'Сохранить', disabled}, style: 'btn-primary '}
			: remove ? {btn: {children: children || 'Удалить', type: 'button', disabled}, style: 'btn-outline-primary '}
				: {}
	
	let {btn, style} = getBtnProps()
	
	position === 'right' && (style += ' d-block ml-auto ')
	
	return (
		<button
			onClick={onClick}
			className={`btn ${style + className}`}
			type={type}
			{...btn}/>
	)
}

Button.propTypes = {
	position: oneOf(['right']),
}

