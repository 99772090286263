import React, {useMemo, useState} from 'react';
import uniqid from 'uniqid';

const useReRender = () => {
	const [key, setKey] = useState(() => uniqid())
	const rerender = () => setKey(uniqid())
	
	return useMemo(() => [key, rerender], [key])
}

export default useReRender;
