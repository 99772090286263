import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonGoBack } from '../../../../components/button/ButtonGoBack';
import { Card } from '../../../../components/UI/Card';
import { Head } from '../../../../components/UI/Head';
import useRerender from '../../../../hooks/useRerender';
import { capitalize, loader } from '../../../../js-helpers/helpers';
import { Button } from '../../../../components/button/Button';
import { RuleSendAvailable } from './pageNotificationsRulesComponents/RuleSendAvailable';
import { RuleUsersSelected } from './pageNotificationsRulesComponents/RuleUsersSelected';
import { notificationsAPI } from '../../../../API/notificationsAPI';
import { toast } from 'react-toastify';
import { userAPI } from '../../../../API/userAPI';


export const restrictionsOptions = [
  { value: 1, text: 'Доступные пользователи', title: 'Недоступно' },
  { value: 2, text: 'Недоступные пользователи', title: 'Доступно' },
];

export const PageNotificationsRulesCreate = () => {
  const {control, handleSubmit, reset, getValues, setValue, formState: {isDirty, errors, isSubmitting}} = useForm()

  const [checkedAllUsers, setCheckedAllUsers] = useState(false);
  const [checkedRule, setCheckedRule] = useState('all');
  const [showUsersList, setShowUsersList] = useState(false);
  const [showRecipientList, setShowRecipientList] = useState(false);
  const [status, setStatus] = useState(1);
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);

  const [exceptions, setExceptions] = useState([]);
  const [exceptionsUsers, setExceptionsUsers] = useState([]);
  const [ruleSelectKey, rerenderRuleSelectKey] = useRerender();
  const [restrictionSelectKey, rerenderRestrictionSelectKey] = useRerender();

  const [isReady, setIsReady] = useState(true)

  const toggleRecipient = (type) => {
    setCheckedRule(type);
    setShowRecipientList(!showRecipientList);
  };

  const onRestrictionsSelect = (item) => {
    setIsDisabledSaveBtn(false);
    setStatus(item.value);
    rerenderRuleSelectKey();
  };

  const exceptionsHead = `${capitalize(
    restrictionsOptions[status - 1].title
  )}: ${exceptions.length}`;

  const onExceptionItemClick = () => {
    rerenderRuleSelectKey();
    setIsDisabledSaveBtn(false);
  };

  const toggleUsers = () => {
    setCheckedAllUsers(!checkedAllUsers);
    setShowUsersList(!showUsersList);
  };

  const onSubmit = async (submitData) => {
    setIsReady(false)

    if (checkedRule === 'all') {
      submitData.type = 1
      submitData.except_users = []
    } else if (checkedRule === 'nobody') {
      submitData.type = 2
      submitData.except_users = []
    } else if (checkedRule === 'list') {
      if (status === 1) {
        submitData.type = 1;
      } else if (status === 2) {
        submitData.type = 2;
      }
      submitData.except_users = exceptions.map(exception => exception.keycloak_id)
    }

    try {
      const response = await notificationsAPI.createP2PRule(submitData)

      const ruleID = response.data.id

      if (!checkedAllUsers) {
        exceptionsUsers.forEach(async (user) => {
          await userAPI.patchUser(user?.keycloak_id, {notification_rule: ruleID})
        })
      }

      setExceptions([])
      setExceptionsUsers([])
      reset()
      setCheckedRule('all')
      setCheckedAllUsers(true)
      setValue('title', undefined)

      toast.success(<>Правило <b>{submitData.title}</b> создано!</>, {autoClose: 5000})
    } catch (e) {
      if (e?.response?.data?.errors?.[0]?.code === 'unique' && e?.response?.data?.errors?.[0]?.attr === 'title') {
        toast.error(<>Правило с названием <b>{submitData.title}</b> уже существует!</>, {autoClose: 5000})
      } else {
        toast.error(e?.response?.data?.errors?.[0]?.detail, {autoClose: 5000})
      }
    } finally {
      setIsReady(true)
    }
  }

  return (
    <>
      <ButtonGoBack className={'float-right'} />
      <Head children={'Создание правила'} />

      <Card className={loader(isReady)} 
            as={'form'}
            onSubmit={handleSubmit(onSubmit)}
      >
        <Card secondary className={'d-flex mb-2'}>
          <h3
            className={
              'col-2 m-0 font-weight-bold font-small-2 text-uppercase p-0 pt-50'
            }
          >
            название правила:
          </h3>
          
          <div className={'d-flex flex-column w-100'}>
          <Controller  
              name={'title'}
              control={control}
              defaultValue={''}
              rules={{maxLength: 50, required: true}}
              render={({field}) => 
              <input {...field} className={`form-control col-6 ${errors?.title || getValues('title')?.trim()?.length === 0 ? 'is-invalid' : ''}`}/>}
              />

          {(errors?.title || getValues('title')?.trim()?.length === 0) && <div className='mt-50 text-danger font-small-1'>{getValues('title').length === 0  || getValues('title')?.trim()?.length === 0 ? 'Введите название правила' : 'Максимальная длина - 50 символов'}</div>}
          </div>
        </Card>

        <RuleSendAvailable  checkedRule={checkedRule} 
                            toggleRecipient={toggleRecipient}
                            restrictionSelectKey={restrictionSelectKey} 
                            status={status} 
                            onRestrictionsSelect={onRestrictionsSelect}
                            exceptionsHead={exceptionsHead} 
                            exceptions={exceptions} 
                            onExceptionItemClick={onExceptionItemClick}
                            setExceptions={setExceptions}
        />

        <RuleUsersSelected  checkedAllUsers={checkedAllUsers} 
                            toggleUsers={toggleUsers} 
                            exceptionsUsers={exceptionsUsers}
                            onExceptionItemClick={onExceptionItemClick}
                            setExceptionsUsers={setExceptionsUsers}
        />

        <Button disabled={!isDirty || isDisabledSaveBtn} 
                className={'mt-1 d-block ml-auto'}  
                save
        />
      </Card>
    </>
  );
};
