import {toggle} from '../../../../js-helpers/helpers';
import React from 'react';

export function MySelectItem({children, className = '', itemProps = {}}) {
	const {activeItem = false, activeItemClassName = '', getItemProps} = itemProps
	
	return (
		<li className={`dropdown-item text-wrap font-small-3 px-1 text-c-transform-none cursor-pointer ${
			className} ${toggle(activeItem, activeItemClassName)}`}
		    {...getItemProps}>
			
			{children}
		
		</li>
	)
}