import {SubStepReview} from './subStepReview/SubStepReview'
import {CheckBox} from '../../../../../components/form/CheckBox';
import {Head} from '../../../../../components/UI/Head';
import {getLocaleFullDate, isNotEmpty} from '../../../../../js-helpers/helpers';
import './step.scss'

const searchSettingDate = (arr = [], instructionStep) => {
	const preparedData = arr.filter((item) =>
		item.setting.sequence_number === instructionStep.sequence_number &&
		item.setting.subitem === 0
	)
	
	return getLocaleFullDate(preparedData[0]?.created_at)
}

const filterSubSettingDate = (arr, instructionStep) => {
	return arr.filter((item) =>
		item.setting.sequence_number === instructionStep.sequence_number &&
		item.setting.subitem !== 0
	)
}

export const DeviceSetupInfoStep = (
	{
		instructionStep,
		currentStage,
		maxStageCompleted,
		completedSetting
	}) => {
	
	const {
		id, subitem, description, sequence_number, attachments
	} = instructionStep || {}
	
	const checked = completedSetting.some((item) => item.setting.id === instructionStep.id)
	
	return (
		<li className={'d-flex font-small-3'}>
			<div className={'step d-flex flex-column align-items-center bg-primary py-2 col-1'}>
				
				<Head as={'h5'}
				      noMargins
				      className={'text-white font-weight-bold mb-50'}>
					{`Шаг ${sequence_number}`}
				</Head>
				
				<CheckBox checked={checked} readOnly white/>
			</div>
			
			<div className={'w-100 px-2 pt-2 bg-c-gray-50'}>
				
				{description}
				
				{isNotEmpty(attachments) &&
				attachments.map(({url, file, title}) =>
					<a key={url}
					   href={url ?? file}
					   className={'ml-50 text-c-nowrap'}
					   download={file}
					>
						{title}
					</a>
				)
				}
				
				<br/>
				
				<p className={'text-muted font-small-2 mt-50'}>
					{searchSettingDate(completedSetting, instructionStep)}
				</p>
				
				{isNotEmpty(subitem) &&
				<SubStepReview subInstruction={subitem}
				               completedSubSetting={
					               filterSubSettingDate(completedSetting, instructionStep)
				               }
				               maxStageCompleted={maxStageCompleted}
				               currentStage={currentStage}
				               idStepNull={id}
				               countSubitems={subitem.length}
				/>
				}
			</div>
		</li>
	)
}