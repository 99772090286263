import React from 'react';

export const Loader = ({className = 'bg-white'}) => {
	return (
		<div className={'lds-ellipsis'}>
			<div className={className}/>
			<div className={className}/>
			<div className={className}/>
			<div className={className}/>
		</div>
	)
}

