import GetFullTable from '../GetFullTable';
import {isEmpty} from '../../../js-helpers/helpers';
import classNames from 'classnames';

//const tableOptions = [
// 	{head: 'ID', cell: 'id', cellClassName: 'py-1'},
// 	{
// 		head: 'Фото',
// 		cell: (row) =>
// 			<MyAvatar src={row.profile_photo_thumbnail} alt={row.name}/>,
// 				cellClassName: 'py-1'
// 	},
// 	{
// 		head: 'ФИО',
// 		cell: (row) => getShortFullNameFromObj(row)
// 	},
// 	{head: 'Подразделение', cell: 'subdivision'},
// 	// { head: 'ID устройства', cell: ''},
// 	{head: 'Номер телефона', cell: 'phone_number'},
// 	// { head: 'IMEI', cell: ''},
// 	// { head: 'Статус', cell: ''},
// 	{
// 		head: 'Действия',
// 		cell: row =>
// 			<BtnWithIcon to={row.id + /info/} type='info'>Полная информация</BtnWithIcon>
// 	},
// ]

export const getCell = ({nestedCellField, cell}, dataRow) => {
	if (typeof cell === 'string')
		return isEmpty(dataRow[cell]) ? '-' : dataRow[cell]
	
	const arg = nestedCellField ? dataRow?.[nestedCellField] : dataRow
	return cell(arg)
}

const getCellClassName = ({cellClassName, cell, nestedCellClassNameField}, dataRow) => {
	if (typeof cellClassName === 'string')
		return cellClassName
	
	const arg = nestedCellClassNameField
		? dataRow?.[nestedCellClassNameField]
		: dataRow
	
	return cellClassName(arg)
}

export const MyTableBody = (
	{
		data = [],
		tableOptions,
		children,
		highestRowToFill,
		maxFillItems,
		className = '',
		getRowClassName,
		rowClassName
	}) => (
	
	<tbody className={`table-c-footer ${className}`}>
	
	{data?.map((dataRow, rowInd) => {
			
			return <tr key={rowInd}
			           className={classNames(getRowClassName?.(dataRow), rowClassName)}>
				
				{tableOptions?.map(
					({
						 cell,
						 staticCell,
						 head,
						 cellClassName = '',
						 nestedCellField,
						 nestedCellClassNameField
					 }) => {
						
						const finalCell = staticCell
							|| getCell({nestedCellField, cell}, dataRow)
						
						const finalCellClassName = getCellClassName(
							{cellClassName, cell, nestedCellClassNameField},
							dataRow
						)
						
						return (
							<td key={rowInd + head} className={finalCellClassName}>
								{finalCell}
							</td>
						)
					})}
			</tr>
		}
	)}
	
	{children}
	
	{highestRowToFill &&
	<GetFullTable maxItems={maxFillItems}
	              cols={tableOptions?.length}
	              length={data?.length}
	              highestElem={highestRowToFill}/>
	}
	</tbody>
)