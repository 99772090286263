import { columnsCalls } from '../../../components/table/MyTable/options/columnsCalls';
import { getFullNameFromObj } from '../../../js-helpers/helpers';

const {
  contact_phone,
  contact_name,
  date,
  player,
  user,
  type,
  fromAppType,
  status,
  device,
  device_serial
} = columnsCalls;

export const callsHistoryTable = {
  exportTable: [
    type,
    contact_phone,
    contact_name,
    date,
    fromAppType,
    status,
    device_serial,
    { ...user(), cell: getFullNameFromObj },
  ],

  getTableCallsHistory: ({
    playerStates,
    playerProps,
    isEmptySearchQuery,
    isExportMode,
    checkBoxesColl,
    userNotNeeded,
    deviceNotNeeded = false,
    fullScreenPlaying,
    setFullScreenPlayerState,
    setAudioName
  } = {}) => {
    const commonCols = [
      type,
      contact_phone,
      contact_name,
      date,
      player({ playerProps, playerStates, fullScreenPlaying, setFullScreenPlayerState, setAudioName }),
      fromAppType,
      status,
    ];
    if (!userNotNeeded) {
      commonCols.push(user(isEmptySearchQuery));
    }
    if (!deviceNotNeeded) {
      commonCols.push(device);
    }
    const getExportTable = () => [checkBoxesColl(), ...commonCols];

    const baseTable = [...commonCols];

    return isExportMode ? getExportTable() : baseTable;
  },
};
