import {columnsDevice} from '../../../components/table/MyTable/options/columnsDevice';
import {columnsUser} from '../../../components/table/MyTable/options/columnsUser';
import {urlParams} from '../../../constants/urlParams';
import {getFullNameFromObj} from '../../../js-helpers/helpers';

const {
	launcher_version, android_model, system_version,
	api_level, serial_number
} = columnsDevice

const {
	keycloak_id, full_keycloak_id, user_photo, user_photo_without_babble,
	getUserName, user_subdivision,
	user_division, user_phone_numbers, user_mail
} = columnsUser

const {
	SEARCH, SORT, PAGE, LIMIT, EXPORT_MODE, DIVISION,
	SUB_DIVISION, LAUNCHER_VER, BLOCKED, HAVE_DEVICE, IS_ONLINE, USER, DEVICE, IN_GROUP
} = urlParams

export const pageUsersConfig = {
	searchParams: [
		{param: SEARCH},
		{param: SORT, init: 'account__last_name'},
		{param: PAGE, init: 1, paramHandler: Number},
		{param: LIMIT},
		{param: EXPORT_MODE},
		{param: DIVISION},
		{param: SUB_DIVISION},
		{param: LAUNCHER_VER},
		{param: BLOCKED, init: 'false'},
		{param: HAVE_DEVICE, init: 'true'},
    {param: IS_ONLINE},
    {param: USER},
    {param: DEVICE},
    {param: IN_GROUP, init: 'true'}
	],

  editGroupSearchParams: [
		{param: SEARCH},
		{param: SORT, init: 'account__last_name'},
		{param: PAGE, init: 1, paramHandler: Number},
		{param: LIMIT},
		{param: EXPORT_MODE},
		{param: DIVISION},
		{param: SUB_DIVISION},
		{param: LAUNCHER_VER},
		{param: BLOCKED, init: 'false'},
		{param: HAVE_DEVICE},
    {param: IS_ONLINE},
    {param: USER},
    {param: DEVICE},
    {param: IN_GROUP, init: 'true'}
	],

  newUsersSearchParams: [
    {param: SEARCH},
		{param: SORT, init: 'account__last_name'},
		{param: PAGE, init: 1, paramHandler: Number},
		{param: LIMIT},
		{param: EXPORT_MODE},
		{param: DIVISION},
		{param: SUB_DIVISION},
		{param: LAUNCHER_VER},
		{param: BLOCKED},
		{param: HAVE_DEVICE},
    {param: IS_ONLINE},
  ],
	
	getTable(isEmptySearchQuery, isExportMode, boxesColl, isGroupsMode = false, isDisabled) {
		const baseOptions = [
      keycloak_id,
			user_photo,
			getUserName(isEmptySearchQuery),
			user_subdivision,
			user_division,
			user_phone_numbers,
			serial_number,
			launcher_version,
		]
		
		const exportModeOptions = [
			boxesColl(),
			...baseOptions,
			android_model,
			system_version,
			api_level,
			user_mail
		]

    const groupsMode = [
      boxesColl('', isDisabled),
      user_photo_without_babble,
      getUserName(isEmptySearchQuery),
      serial_number,
      user_division,
      user_subdivision
    ]
		
		return isExportMode ? isGroupsMode ? groupsMode : exportModeOptions : baseOptions
	},
	exportColumns: [
    full_keycloak_id,
		{...getUserName(false), cell: getFullNameFromObj},
		user_subdivision,
		user_division,
		user_phone_numbers,
		{...serial_number, cell: serial => serial || '-'},
		launcher_version,
		android_model,
		system_version,
		api_level,
		user_mail
	]
}

