import {toast} from 'react-toastify';

const DeleteToast = ({text, onClick, closeToast, onAbort, ...props}) => (
	<div className={'py-1 px-2 text-center'}>
		
		<h2 className={'mb-1 font-size-base'}>
			<strong>Удалить {text} ?</strong>
		</h2>
		
		<button
			className={'btn btn-primary mr-1'}
			onClick={() => {
				onAbort()
				closeToast()
			}}
			type='button'
		>
			Отмена
		</button>
		
		<button
			className={'btn btn-danger '}
			onClick={() => {
				onClick()
				closeToast()
			}}
			type='button'
		>
			Удалить
		</button>
	</div>
)

export function askToastDelete({
	                               askText,
	                               onClose,
	                               errorText,
	                               callMethod,
	                               successText
                               } = {}) {
	
	const call = async (res) => {
		await toast.promise(
			callMethod,
			{
				pending: 'Удаление...',
				success: successText,
				error: errorText,
			})
		res()
	}
	
	return new Promise((res, rej) => {
		toast.error(
			<DeleteToast
				onClick={() => call(res)}
				onAbort={() => rej()}
				text={askText}
			/>
			, {
				closeOnClick: false,
				position: 'top-center',
				icon: false,
				onClose: () => {
					rej()
					onClose && onClose()
				},
			})
	})
}