import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

export const groupsAPI = {
  getAll: (page = 1, search, ordering, page_size) => axios('/users/groups/', setPreparedAxiosParams({page, search, ordering, page_size})),
  
  getGroups({page, search, page_size}) {
		return axios('/users/groups/', setPreparedAxiosParams(arguments[0]))
	},

  createGroup: (data) => axios.post('/users/groups/', data),

  getItem: (id) => axios(`/users/groups/${id}/`),

  editItem: (id, data) => axios.patch(`/users/groups/${id}/`, data),

  deleteItem: (id) => axios.delete(`/users/groups/${id}/`),
}