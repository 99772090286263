import {MyDatePicker} from './MyDatePicker/MyDatePicker';
import React from 'react';

export function RangeDatePicker(
	{
		className = '',
		prependLabel = 'Период: ',
		fromPickerProps = {},
		toPickerProps = {},
	}) {
	
	const _toPickerOptions = {
		autoFillDefaultTime: true,
		defaultHour: 23,
		defaultMinute: 59,
		...toPickerProps.options,
	}
	
	return (
		<fieldset className={`row gap-0-5r no-gutters align-items-center ${className}`}>
			
			{prependLabel}
			
			<MyDatePicker value={fromPickerProps.value}
			              className={fromPickerProps.className || 'width-250'}
			              onChange={fromPickerProps.onChange}
			              placeholder={fromPickerProps.placeholder || 'C'}
			              options={{...fromPickerProps.options,}}
			/>
			-
			<MyDatePicker value={toPickerProps.value}
			              className={toPickerProps.className || 'width-250'}
			              onChange={toPickerProps.onChange}
			              placeholder={toPickerProps.placeholder || 'По'}
			              options={_toPickerOptions}
			/>
		</fieldset>
	)
}