import {loggedAdmin} from '../../stores/loggedAdmin'
import {useMemo} from 'react'
import {observer} from 'mobx-react-lite'
import MenuAdminCard from './MenuAdminCard'
import {managementMenuItems, membersMenuItems, tablesMenuItems} from './menuItems'
import {isNotEmpty} from '../../js-helpers/helpers';

const allSections = [membersMenuItems, managementMenuItems, tablesMenuItems]


export const MenuAdmin = observer(() => {
	const {getIsResolved, data} = loggedAdmin
	
	// const filteredMembersMenuItems = useMemo(() =>
	// 	membersMenuItems.filter(({permission}) => getIsResolved(permission)), [data])
	//
	// const filteredManagementItemsMenu = useMemo(() =>
	// 	managementMenuItems.filter(({permission}) => getIsResolved(permission)), [data])
	//
	// const filteredTablesMenuItemsMenu = useMemo(() =>
	// 	tablesMenuItems.filter(({permission}) => getIsResolved(permission)), [data])
	//
	const [
		filteredMembersMenuItems,
		filteredManagementItemsMenu,
		filteredTablesMenuItemsMenu
	] = useMemo(() =>	allSections.map(section =>
		section.filter(({permission}) => getIsResolved(permission))), [data])
	
	return (
		<>
			{/*rock - isNotEmpty*/}
			{/*{filteredMembersMenuItems.length > 0 &&*/}
			{isNotEmpty(filteredMembersMenuItems) &&
			<MenuAdminCard menuItems={filteredMembersMenuItems} title={'Участники Системы'}/>
			}
			
			{isNotEmpty(filteredTablesMenuItemsMenu) &&
			<MenuAdminCard menuItems={filteredTablesMenuItemsMenu} title={'Таблицы данных'}/>
			}
			
			{isNotEmpty(filteredManagementItemsMenu) &&
				<MenuAdminCard menuItems={filteredManagementItemsMenu} title={'Управление Системой'}/>
			}
		</>
	)
})