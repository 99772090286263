import { Img } from "./Img";

export function AppWimg({icon_url, name = '', size = 40, className = '', textClassName = 'ml-4'}) {
	return (
		<div className={`d-flex position-relative align-items-center h-100 ${className}`}>
			<Img className={'abs-c-left-center'}
			     src={icon_url}
			     alt={name}
			     width={size}
			     height={size}
			     holderClassName={'rounded-circle'}
			/>
			<span className={textClassName}>
					 {name}
			</span>
		</div>
	)
}