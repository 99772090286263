import {Link, useLocation, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import React, {useEffect, useMemo} from 'react';
import {servicesAPI} from '../../../../API/servicesAPI';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {getLocaleFullDate, loader, isEmpty} from '../../../../js-helpers/helpers';
import {Card} from '../../../../components/UI/Card';
import {useUpdateEffect} from '@react-hookz/web';
import {ModalSwiper} from '../../../../components/modals/ModalSwiper';
import {FullPagination} from '../../../../components/paginations/FullPagination';
import {useComboSearch} from '../../../../hooks/url/useComboSearch';
import {urlParams} from '../../../../constants/urlParams';
import { EmptyFoundDataMessage } from '../../../../components/EmptyFoundDataMessage/EmptyFoundDataMessage';
import FiltersPageCommonMedia from '../../../dataTables/pageCommonMedia/FiltersPageCommonMedia';
import { EmptyDataWithoutSearch } from '../../../../components/EmptyDataWithoutSearch/EmptyDataWithoutSearch';
import { Img } from '../../../../components/UI/Img';

const listServices = [
	{text: 'Скриншоты', service: 'screenshots'},
	// {text: 'Фото', service: 'photo', disabled: true},
	// {text: 'Видео', service: 'video', disabled: true}
]

const USER_TYPE = 'user'

const {PAGE, LIMIT, SERVICE, DATE_FROM, DATE_TO, SEARCH, DEVICE} = urlParams
const pageSearchParams = [
	{param: SERVICE, init: listServices[0].service},
  {param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 'last'},
	{param: LIMIT},
  {param: DEVICE},
  {param: DATE_FROM},
  {param: DATE_TO},
]

export function PageMedia() {
	const location = useLocation()
	const navigate = useNavigate()
	const {pathname, search, hash} = location
	const {keycloakID} = useParams()
	const {setPageHead} = useOutletContext()

	const [
		[urlService],
    [urlSearchQuery],
		[urlPage, setUrlPage],
		[urlLimit],
    [urlDevice],
    [urlDateFrom],
    [urlDateTo],
	] = useComboSearch(pageSearchParams)
	
	const media = useFetchStates(
		() => servicesAPI.getScreenShots(
			urlSearchQuery, keycloakID, urlDevice, urlPage, urlLimit, urlDateFrom, urlDateTo
		),
		{initData: {results: []}})
	
	useEffect(() => {
		const head = listServices.find(it => it.service === urlService).text

    setPageHead({head})
	}, [urlService])
	
	useUpdateEffect(() => {
		media.getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		media.getData()
	}, [urlLimit, urlDateFrom, urlDateTo, urlSearchQuery, urlDevice])
	
	const onPhotoClick = (id) => () => {
		navigate({pathname, search, hash: id.toString()}, {replace: true})
	}

  const isEmptySearchQuery = isEmpty(urlSearchQuery)

  const filtersAndSearchIsEmpty = useMemo(() => {
    return !isEmptySearchQuery || urlDevice || urlDateFrom || urlDateTo
  }, [isEmptySearchQuery, urlDevice, urlDateFrom, urlDateTo])

	return (
    <>
    <FiltersPageCommonMedia type={USER_TYPE}/>
		<Card>

      {media.data.results.length === 0 && filtersAndSearchIsEmpty ?
      <EmptyFoundDataMessage isDataReady={media.isReady} className={'mt-3 w-100'}/>
        : !filtersAndSearchIsEmpty && media.data.results.length === 0 && media.isReady?
        <EmptyDataWithoutSearch isDataReady={media.isReady}/>
        :
			<Card size={0}
			      as={'ul'}
			      className={`row mx-0 row-cols-5 list-unstyled ${loader(media.isReady)}`}
			      style={{minHeight: '25vh'}}
			>
          
          {media.data.results.map(({id, thumbnail, date, device}) => {
					const localeDate = getLocaleFullDate(date)

          const screenshotData = <div>
            {localeDate}
            {device ?
              <>
                <br/>
                <Link to={`/admins/devices/${device?.serial_number}/info/`}>{device?.serial_number}</Link>
              </> : ''
            }
            </div>
					
					return (
						<li key={id} className={'col mb-1'}>
							<Img className={'rounded-md hover-c-scale cursor-pointer obj-fit-contain'}
							     height={550}
							     width={'100%'}
							     src={thumbnail}
							     alt={localeDate}
							     holderClassName={'w-100'}
							     onClick={onPhotoClick(id)}
							/>
							<figcaption className={'font-small-3 mt-50 text-gray-500'}
							            children={screenshotData}/>
						</li>
					)
				})}

			
			</Card>
}
			<FullPagination allCount={media.data?.count} className={'ml-auto mt-1 px-1'}/>
			
			<ModalSwiper isOpen={hash && media.data} photos={media.data?.results} type={USER_TYPE}/>
		</Card>
    </>
	)
}