import { useEffect, useMemo, useRef, useState } from 'react';
import Duration from './Duration';
import ReactPlayer from 'react-player';
import { loader } from '../../../js-helpers/helpers';
import { ReactComponent as PlaySVG } from './play.svg';
import { ReactComponent as PauseSVG } from './pause.svg';
import Sound from './Sound';

export const AudioPlayerFullScreen = ({
  url,
  playingCallURL,
  setPlayingCallURL,
  setLoadedLinks,
  setBadLinks,
  showRange = true,
  className,
  setFullScreenPlayerState, 
  fullScreenPlaying,
  fullScreenPlayed, 
  fullScreenSeeking, 
  fullScreenDuration, 
  fullScreenBadURL,
  fullScreenIsReady,
  volume,
  audioName, 
  audioArray,
  setAudioName
}) => {
  const player = useRef();

  const [currentAudio, setCurrentAudio] = useState({})

  useEffect(() => {
    setCurrentAudio(audioArray.find((audio) => audio.audio === playingCallURL) || {})
  }, [audioArray, playingCallURL])

  useEffect(() => {
    setFullScreenPlayerState((draft) => {
      draft.playing = true;
    });
  }, [playingCallURL])

  const handlePlayPause = () => {
    setFullScreenPlayerState((draft) => {
      draft.playing = !draft.playing;
    });
  };

  const inputProps = {
    onChange: (e) => {
      setFullScreenPlayerState((draft) => {
        draft.played = parseFloat(e.target.value);
      });
    },
    onMouseUp: (e) => {
      setFullScreenPlayerState((draft) => {
        draft.seeking = false;
        draft.played = parseFloat(e.target.value);
      });
      player.current.seekTo(parseFloat(e.target.value));
    },
    onMouseDown: () => {
      setFullScreenPlayerState((draft) => {
        draft.seeking = true;
      });
    },
  };

  const playerProps = {
    onProgress: ({ played, playedSeconds }) => {
      setFullScreenPlayerState((draft) => {
        draft.duration = playedSeconds;
        fullScreenSeeking ? (draft.seeking = played) : (draft.played = played);
      });
    },
    onError: () => {
      setFullScreenPlayerState((draft) => {
        draft.badURL = true;
        draft.isReady = true;
      });
      setBadLinks && setBadLinks((badURL) => [...badURL, url]);
    },
    onReady: () => {
      setFullScreenPlayerState((draft) => {
        draft.isReady = true;
      });
      setLoadedLinks && setLoadedLinks((loadedLinks) => [...loadedLinks, url]);
    },
    onEnded: () => {
      setFullScreenPlayerState((draft) => {
        draft.playing = false;
      });
    },
  };

  const changeAudio = (value) => {
    const currentAudioIndex = audioArray.findIndex((file) => file.audio === currentAudio.audio);

    if (value === 'next') {
      setPlayingCallURL(audioArray[currentAudioIndex + 1].audio)
      setAudioName(audioArray[currentAudioIndex + 1].fileName)
    } else if (value === 'prev') {
      setPlayingCallURL(audioArray[currentAudioIndex - 1].audio)
      setAudioName(audioArray[currentAudioIndex - 1].fileName)
    }
  }

  const disablePrevButton = useMemo(() => {
    return audioArray?.findIndex((file) => 
      file.audio === currentAudio.audio) === 0
  }, [audioArray, currentAudio])

  const disableNextButton = useMemo(() => {
    return audioArray?.findIndex((file) => 
      file.audio === currentAudio.audio) === audioArray.length - 1
  }, [audioArray, currentAudio])

  return (
  <div  className={'bg-c-rgba-light rounded-lg p-1 pb-2 position-relative'} style={{width: 'calc(100% - 30px)'}}>
    <div
      className={`d-flex flex-column position-relative align-items-center ${loader(
        fullScreenIsReady,
        { size: 'small' }
      )} ${className ? className : ''}`}
    >
      <div className='d-flex align-items-center mb-50 w-100'>

        <div style={{width: '600px'}}>{ currentAudio?.filename ?(currentAudio?.filename?.length >= 60 ? currentAudio?.filename?.slice(0, 60) + '...' : currentAudio?.filename) : (audioName?.length >= 60 ? audioName?.slice(0, 60) + '...'  : audioName)}</div>

        <div className='d-flex align-items-center ml-1'>
          <button type='button'
                  disabled={disablePrevButton}
                  onClick={() => changeAudio('prev')}
                  style={{height: '1.8rem', width: '1.8rem'}}
                  className='rounded-circle text-primary text-primary-custom p-0 mr-50 border-0 bg-c-rgba-light'  >
            <i className='bx bx-skip-previous font-large-1'></i>
          </button>

          <button className={'btn btn-primary btn-c-audio'}
                  disabled={fullScreenBadURL}
                  onClick={handlePlayPause}
          >
            {fullScreenPlaying ? <PauseSVG /> : <PlaySVG />}
          </button>

          <button type='button'
                  disabled={disableNextButton}
                  onClick={() => changeAudio('next')}
                  style={{height: '1.8rem', width: '1.8rem'}}
                  className='rounded-circle text-primary text-primary-custom p-0 ml-50 border-0 bg-c-rgba-light' >
            <i className='bx bx-skip-next font-large-1'></i>
          </button>
        </div>
          <Sound volume={volume} setPlayerState={setFullScreenPlayerState} className={'ml-auto'}/>
      </div>

      {showRange && (
        <div className='w-100 d-flex position-relative'>
          <input
            min={0}
            max={1}
            step='any'
            type='range'
            value={fullScreenPlayed}
            disabled={fullScreenBadURL}
            className={'audio-c-range min-c-touch-zone'}
            {...inputProps}
          />

          <progress max={'1'} value={fullScreenPlayed} className={'w-100'} />
        </div>
      )}
      
      {!!fullScreenPlaying && showRange && (
        <Duration className={'audio-c-time audio-c-time-lower'} seconds={fullScreenDuration} />
      )}

      <ReactPlayer
        className={'d-none'}
        progressInterval={25}
        url={playingCallURL}
        ref={player}
        playing={fullScreenPlaying}
        volume={volume}
        {...playerProps}
      />
    </div>
  </div>
  );
};
