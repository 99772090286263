import { Link } from "react-router-dom";
import { getFullNameFromObj } from "../../../js-helpers/helpers";

const arrErrorFields = [
  'user',
  'app_name',
  'device',
  'serial_number',
  'system_version',
  'launcher_version',
  'error_message',
]

export const createErrorInfo = (data) => {
  const {
    user,
    app_name,
    app_package_name,
    device,
    version
  } = data;

  const abc = {};

  arrErrorFields.map((item) => {
    switch (item) {
      case 'user':
        abc['ФИО пользователя'] = user?.keycloak_id
          ? <Link to={`/admins/users/${user.keycloak_id}/info/`}>{getFullNameFromObj(user)}</Link>
          : '-';
        break;
      case 'app_name':
        abc['Приложение'] = app_name || app_package_name;
        break;
      case 'device':
        abc['Устройство'] = device?.brand || '-' ;
        break;
      case 'serial_number':
        abc['Серийный №'] = device?.serial_number ? <Link to={`/admins/devices/${device.serial_number}/info/`}>{device.serial_number}</Link> : '-';
        break;
      case 'system_version':
        abc['Версия операционной системы'] = device?.system_version || '-';
        break;
      case 'launcher_version':
        abc['Версия лаунчера'] = version ? version : `${device?.launcher_version_name} (${device?.launcher_version_code})`;
        break;
      default:
        break;
    }
    return null;
  });
  return Object.entries(abc);
}