import {useLocation} from 'react-router-dom';
import {limitsStore} from '../../stores/limitsOptionsStore';
import {useCustomSearch} from './useCustomSearch';

export function useSearchLimit(init = 10) {
	const {pathname} = useLocation()
	const clearedUrl = pathname.split('/').filter(section => !section.match(/^\d+$/)).join('/')
	const finalLimit = limitsStore.limits?.[clearedUrl] || init
	
	return useCustomSearch('limit', finalLimit)
}

export function useLSSavedLimit() {
	const {pathname} = useLocation()
	const clearedUrl = pathname.split('/').filter(section => !section.match(/^\d+$/)).join('/')
	
	return limitsStore.limits?.[clearedUrl]
}