import { useFirstMountState, useUpdateEffect } from '@react-hookz/web';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { isNotEmpty } from '../../../js-helpers/helpers';
import { MySelectContainer } from './components/MySelectContainer';
import { MySelectItem } from './components/MySelectItem';
import { MySelectList } from './components/MySelectList';
import { MySelectToggleButton } from './components/MySelectToggleButton';
import { MultiDownshift } from './MultiDownshift';


export const MyMultiSelect = ({
  type,
  init = true,
  initText,
  dropList,
  className = '',
  disabled,
  resetWhen,
  inputProps = {},
  optionsArr = [],
  keyText = 'text',
  titleClassName = '',
  keyValue = 'value',
  initialSelectedItems,
  defaultIsOpen = false,
  onSelect,
  activeItemClassName = 'active_gray',
  selectListClassName,
  activeTitleClassName,
  clearUrl
}) => {
// const [val, setVal] = useState((initialSelectedItem && initialSelectedItem[keyValue]) || '')

// const onChange = (selectItem) => {
//   setVal(selectItem?.[keyValue] || selectItem)
//   onSelect && onSelect(selectItem)
// }

const isFirstMount = useFirstMountState()
const downshiftActionsRef = useRef()

// useEffect(() => {
//   downshiftActionsRef.current?.selectItem(initialSelectedItem)
// }, [initialSelectedItem])

useUpdateEffect(() => {
  resetWhen &&
  downshiftActionsRef.current?.clearSelection()
}, [resetWhen])

const prepareInitArray = () => {
  const textArray = initialSelectedItems?.text?.split(',');

  if (type === 'call_type') {
    const valuesArray = initialSelectedItems?.value?.split(',');

    const initArray =  textArray?.map((text, index) => ({text, value: valuesArray[index]}))

    return initArray
  } else {
   
    const initArray =  textArray?.map((text) => ({text, value: text}))

    return initArray
  }

}

if (!init)
  return <input className={`form-control ${className}`}
                disabled={disabled}
                value={initText}/>

return (
  <MultiDownshift
    onChange={onSelect}
    defaultIsOpen={defaultIsOpen}
    itemToString={(item) => (item?.text)}
    initialSelectedItems={prepareInitArray()}
    clearUrl={clearUrl}
  >
   {/* <Downshift
     onChange={onChange}
     defaultIsOpen={defaultIsOpen}
     initialSelectedItem={initialSelectedItem}
     itemToString={item => (item && item[keyText])}
   > */}
    {({
        isOpen, getRootProps, selectedItems, getToggleButtonProps,
        getMenuProps, getItemProps, closeButtonHandled, ...actions
      }) => {
      
      if (isFirstMount) downshiftActionsRef.current = actions
      const selectedItemsValues = selectedItems.map(item => item.value);

      const selectedItemsSet = new Set(selectedItemsValues);

      const filteredOptionsArr = optionsArr.filter((item) => !selectedItemsSet.has(item.value))

      return (
        <MySelectContainer
          containerProps={{
            getRootProps: getRootProps({}, {suppressRefError: true}),
            className,
            isOpen
          }}>
          
          {/* <input hidden
                 onChange={() => undefined}
                 {...inputProps}
                 value={val || ''}
          /> */}
          
          <MySelectToggleButton
            buttonProps={{disabled, titleClassName: activeTitleClassName && isOpen  ? `${titleClassName} ${activeTitleClassName}` : titleClassName, getToggleButtonProps, isOpen, closeButton: isNotEmpty(selectedItems), closeButtonHandled}}
          >
            {initText} {' '} {selectedItems?.length ? `(${selectedItems?.length})` : ''}
            {/* {dropList
              ? initText
              : (selectedItem && selectedItem[keyText]) || initText
            } */}
          </MySelectToggleButton>
          
          <MySelectList getMenuProps={getMenuProps} className={selectListClassName}>
          {selectedItems.map((item, index) => {
              const {text, value} = item;

              const activeItem = selectedItems.find(item => item?.value === value);

              return (
                <MySelectItem
                  key={(item && item[keyValue]) || index}
                  itemProps={{
                    activeItem,
                    activeItemClassName,
                    getItemProps: getItemProps({item, index})
                  }}
                >
                  {item && item[keyText]}
                </MySelectItem>
              )
            })}
            {filteredOptionsArr.map((item, index) => {
              const {text, value} = item;
              // const activeItem =
              //   (selectedItem && selectedItem[keyValue]) === item[keyValue]
              const activeItem = selectedItems.find(item => item?.value === value);
              
              const newIndex = selectedItems?.length + index

              return (
                <MySelectItem
                  key={(item && item[keyValue]) || index}
                  itemProps={{
                    activeItem,
                    activeItemClassName,
                    getItemProps: getItemProps({item, index: newIndex})
                  }}
                >
                  {item && item[keyText]}
                </MySelectItem>
              )
            })}
          </MySelectList>
        </MySelectContainer>
      );
    }}
  {/* </Downshift> */}
  </MultiDownshift>
)
}


MyMultiSelect.propTypes = {
	init: PropTypes.bool,
	initText: PropTypes.string,
	dropList: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	optionsArr: PropTypes.arrayOf(PropTypes.object),
}