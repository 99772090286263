import React, {useRef} from 'react';
import uniqid from 'uniqid';

export function Radio({
	                      name,
	                      onChange,
	                      disabled,
	                      className = '',
	                      checked,
	                      defaultChecked,
	                      textLabel = '',
	                      boxClassName = '',
                      }) {
	const inputProps = {disabled, defaultChecked, checked, name, onChange}
	
	const idRef = useRef(uniqid())
	
	return (
		<label
			className={`radio d-inline-block text-body min-c-touch-zone align-bottom m-0 cursor-pointer text-c-transform-none font-size-base ${className}`}>
			<input id={idRef.current} type='radio' {...inputProps}/>
			<label className={`float-left ${boxClassName}`} htmlFor={idRef.current}/>
			
			{textLabel}
		</label>
	)
}