import React, {useEffect, useRef, useState} from 'react';
import Downshift from 'downshift';
import {MySelectContainer} from './components/MySelectContainer';
import {MySelectItem} from './components/MySelectItem';
import {MySelectToggleButton} from './components/MySelectToggleButton';
import {MySelectList} from './components/MySelectList';
import PropTypes from 'prop-types';
import {useFirstMountState, useUpdateEffect} from '@react-hookz/web';

export function MySelect(
	{
    type,
		init = true,
		initText,
		dropList,
		className = '',
		disabled,
		resetWhen,
		inputProps = {},
		optionsArr = [],
		keyText = 'text',
		titleClassName = '',
		keyValue = 'value',
		initialSelectedItem,
		defaultIsOpen = false,
		onSelect,
		activeItemClassName = 'active',
    selectListClassName,
    activeTitleClassName, 
    closeButtonHandled,
    closeButton = false,
    isSelectedNow
	}) {
	const [val, setVal] = useState((initialSelectedItem && initialSelectedItem[keyValue]) || '')
	
	const onChange = (selectItem) => {
    setVal(selectItem?.[keyValue] || selectItem);

    onSelect && onSelect(selectItem)

    // const clear = selectItem?.value === isSelectedNow
    // if (type === 'blocked') {
    //   setVal(clear ? {value: 'null', text: 'null' } : (selectItem?.[keyValue] || selectItem))
    //   if (onSelect) {
    //     onSelect(clear ? {value: 'null', text: 'null' } : selectItem )
    //   }
    // } else {
    //   setVal(selectItem?.[keyValue] || selectItem)
    //   if (onSelect) {
    //     onSelect(clear ? {value: '', text: ''} : selectItem )
    //   }
    // }
		

//     const clearFilter = type === 'blocked' ? {value: 'null', text: 'null' } : {value: '', text: ''}
//     console.log({isSelectedNow})
// console.log({selectItem})
//     console.log({clearFilter})
//     if (onSelect) {
//       onSelect(selectItem?.value === isSelectedNow ? clearFilter : selectItem )
//     }

// if (onSelect) {
//   onSelect(selectItem)
// }
	}


	
	const isFirstMount = useFirstMountState()
	const downshiftActionsRef = useRef()
	
	useEffect(() => {
		downshiftActionsRef.current?.selectItem(initialSelectedItem)
	}, [initialSelectedItem])
	
	useUpdateEffect(() => {
		resetWhen &&
		downshiftActionsRef.current?.clearSelection()
	}, [resetWhen])
	
	if (!init)
		return <input className={`form-control ${className}`}
		              disabled={disabled}
		              value={initText}/>
	
	return (
		<Downshift
			onChange={onChange}
			defaultIsOpen={defaultIsOpen}
			initialSelectedItem={initialSelectedItem}
			itemToString={item => (item && item[keyText])}
		>
			{({
				  isOpen, getRootProps, selectedItem, getToggleButtonProps,
				  getMenuProps, getItemProps, ...actions
			  }) => {
				
				if (isFirstMount) downshiftActionsRef.current = actions
				
				return (
					<MySelectContainer
						containerProps={{
							getRootProps: getRootProps({}, {suppressRefError: true}),
							className,
							isOpen
						}}>
						
						<input hidden
						       onChange={() => undefined}
						       {...inputProps}
						       value={val || ''}
						/>
						
						<MySelectToggleButton
							buttonProps={{disabled, titleClassName: activeTitleClassName && isOpen  ? `${titleClassName} ${activeTitleClassName}` : titleClassName, getToggleButtonProps, isOpen, closeButton: selectedItem && closeButton, closeButtonHandled}}
						>
							{dropList
								? initText
								: (selectedItem && selectedItem[keyText]) || initText
							}
						</MySelectToggleButton>
						
						<MySelectList getMenuProps={getMenuProps} className={selectListClassName}>
							{optionsArr.map((item, index) => {
								const activeItem =
									(selectedItem && selectedItem[keyValue]) === item[keyValue]
								
								return (
									<MySelectItem
										key={(item && item[keyValue]) || index}
										itemProps={{
											activeItem,
											activeItemClassName,
											getItemProps: getItemProps({item, index})
										}}
									>
										{item && item[keyText]}
									</MySelectItem>
								)
							})}
						</MySelectList>
					</MySelectContainer>
				);
			}}
		</Downshift>
	)
}

MySelect.propTypes = {
	init: PropTypes.bool,
	initText: PropTypes.string,
	dropList: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	optionsArr: PropTypes.arrayOf(PropTypes.object),
}