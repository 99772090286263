import {Swiper, SwiperSlide} from 'swiper/react';
import {A11y, HashNavigation, Keyboard, Mousewheel, Navigation, Pagination, Zoom} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {ButtonIcon} from '../../../components/button/ButtonIcon';
import React from 'react';
import {getLocaleFullDate, getShortFullNameFromObj} from '../../../js-helpers/helpers';
import {Img} from '../../../components/UI/Img';
import { Link } from 'react-router-dom';
import { ALL_TYPE, USER_TYPE, DEVICE_TYPE } from '../../../constants/constants';

export const SwiperPhoto = ({photo = [], idPhotoSwiper, type}) => {
	const indexInitSlide = photo.findIndex((item) => item.id === idPhotoSwiper)
  
	return (
		<div className={'bg-rgba-black position-relative vh-100 vw-100'}>
      <ButtonIcon close
                   className={'position-absolute position-right-0 mt-6 mr-2 zindex-2 font-medium-4'}
                   // style={{right: 0}}
			/>
     
			<Swiper
				zoom={true}
				navigation
				slidesPerView={1}
				initialSlide={indexInitSlide}
				pagination={{clickable: true}}
				hashNavigation={{replaceState: true}}
				keyboard={true}
				mousewheel={true}
				modules={[Zoom, Navigation, Pagination, A11y, HashNavigation, Keyboard, Mousewheel]}
			>
				<>
					{photo.map((item, index) => {
						const {image, date, id, user, device} = item
						return (
							<SwiperSlide data-hash={id} key={index}>
								<div className={'swiper-zoom-container d-flex flex-column align-c-center vh-100 vw-100 swiper-zoom-target'}>
									<Img className={'cursor-pointer zindex-4 js-no-close obj-fit-contain'}
									     height={'80%'}
									     width={'auto'}
									     style={{maxWidth: '80%'}}
									     src={image}
									     alt={'Нет изображения'}
									/>
									
									{date &&
									<figcaption
										className={'position-absolute bg-c-rgba-light d-inline-block p-2 w-100 js-no-close text-center d-flex align-items-center'} style={{top: 0}}>
                      <div className={'container d-flex'} >
                      {getLocaleFullDate(date)}
                      <div className={'ml-auto'}>
                        {(type === ALL_TYPE || type === USER_TYPE) && (device ? 
                        <>
                          <Link to={`/admins/devices/${device?.serial_number}/info/`} className={'mr-2'}>{device?.serial_number}</Link>
                        </> : '')
                        }
                        {(type === ALL_TYPE || type === DEVICE_TYPE) && 
                          user ? 
                            <>
                              <Link to={`/admins/users/${user?.keycloak_id}/info/`}>{getShortFullNameFromObj(user)}</Link>
                        
                            </> : ''
                        }
                      </div>
                    </div>
									</figcaption>
									}
								</div>
							</SwiperSlide>
						)
					})}
				</>
			</Swiper>
		</div>
	)
}