import {useImmer} from 'use-immer';
import {useMyTable} from '../../../../../../components/table/MyTable/useMyTable';
import {Card} from '../../../../../../components/UI/Card';
import {MyTable} from '../../../../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../../../../components/table/MyTable/MyTableBody';
import {Button} from '../../../../../../components/button/Button';
import {loader} from '../../../../../../js-helpers/helpers';
import {CheckBox} from '../../../../../../components/form/CheckBox';
import {AppWimg} from '../../../../../../components/UI/AppWimg';
import {skype_2x, telegram_2x} from '../../../../../../assets/icons/apps/appsLogos';
import React, {useEffect} from 'react';
import { DescriptionBabble } from '../../../../../../components/DescriptionInfoBabble/DescriptionInfoBabble';

const disabledTrackers = [
	'Внутренние', 'Внешние', 'Остальные', 'Личные'
]


const tableData = [
	{
		app: <AppWimg name={'Telegram'} icon_url={telegram_2x}/>,
		tracks: ['messengers_telegram_personal', 'messengers_telegram_other'],
	}, {
		app: <AppWimg name={'Skype'} icon_url={skype_2x}/>,
		tracks: ['messengers_skype_personal', 'messengers_skype_other'],
	}]

const getTableOptions = (trackers, setTrackers) => {
	
	const getDisabledColls = () =>
		disabledTrackers.map((head) => ({
			head: head === 'Остальные' ? <DescriptionBabble text={head} tooltip={'Контакты, которые не внесены в телефонную книгу пользователя'}/> : head,
			nestedCellField: 'tracks',
			cell: (tracks) =>
				<CheckBox checked={getIsChecked(tracks)}
				          disabled
				          onChange={e => toggleTrack(tracks, e.target.checked)}/>,
		}))
	
	const toggleTrack = (track, checked) => {
		setTrackers(draft => {
			Array.isArray(track)
				? track.forEach(track => {
					draft[track] = checked
				})
				: draft[track] = checked
		})
	}
	
	function getIsChecked(other) {
		return Boolean(Array.isArray(other)
			? other.every(track => trackers[track])
			: (trackers[other]))
	}
	
	return [
		{
			head: 'Название мессенджера',
			headClassName: 'text-left',
			cellClassName: 'py-2 text-left',
			cell: 'app',
		}, {
			head: 'Все',
			nestedCellField: 'tracks',
			cell: (tracks) =>
				<CheckBox checked={getIsChecked(tracks)}
				          onChange={e => toggleTrack(tracks, e.target.checked)}/>,
		},
		...getDisabledColls(),
	]
}

export function TelegramSkypeCard({
	                                  onSave,
	                                  isReadyRequests,
	                                  initTrackers
                                  }) {
	const [trackers, setTrackers] = useImmer({})
	
	useEffect(() => {
		isSameInitTrackers() &&
		setTrackers(
			Object.fromEntries(
				Object.entries(initTrackers)
				.filter(([key]) => key.includes('telegram') || key.includes('skype'))
			))
	}, [initTrackers])
	
	const {tableColgroup, tableHeads, tableOptions} = useMyTable(
		getTableOptions(trackers, setTrackers) || [],
		{memoDependencies: [isReadyRequests, trackers]})
	
	function isSameInitTrackers() {
		return Object.entries(trackers).every(([track, checked]) => initTrackers[track] === checked)
	}
	
	function getIsDisabledSaveBtn() {
		return !isReadyRequests || isSameInitTrackers()
	}
	
	return (
		<Card className={'col'}>
			
			<MyTable colgroup={tableColgroup}
			         tableClassName={'bg-c-rgba-light text-center'}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={tableData}
				             tableOptions={tableOptions}
				             className={loader(isReadyRequests)}
				/>
			</MyTable>
			
			<Button save
			        disabled={getIsDisabledSaveBtn()}
			        position={'right'}
			        onClick={() => onSave(trackers)}/>
		</Card>
	)
}