import { useEffect, useMemo } from "react"
import InView from "react-intersection-observer"
import { userAPI } from "../../../../../API/userAPI"
import { ButtonGoBack } from "../../../../../components/button/ButtonGoBack"
import { useNewFind } from "../../../../../hooks/useNewFind"
import { getFullNameFromObj, loader } from "../../../../../js-helpers/helpers"


export const GroupDetailsModal = ({className = '', goBack, groupData}) => {
  const groupParams = useMemo(() => ({
    user_group: groupData.id, 
    is_in_user_group: true
  }), [groupData])

  const {
		lastSearchPage,
		searchPage,
		setSearchPage,
		searchItemsStates,
	} = useNewFind(userAPI.getAllUsersWithCustomParams);

  useEffect(() => {
		setSearchPage(1);
		searchItemsStates.getData({page: 1,	page_size: 25, ...groupParams});
	}, [groupParams]);

  const onLastItemVisible = (inView) => {
		inView && searchItemsStates.getData({page: searchPage, page_size: 25, ...groupParams});
	};

  const preventLoadNextPage =
  !searchItemsStates.isReady || lastSearchPage || searchItemsStates.error;

  // const {data: users, isReady: usersIsReady, getData: usersGetData} = useFetchStates(
	// 	(_page = urlPage) => userAPI.getAllUsersWithCustomParams({...requestQuery, page: _page,}),
	// 	{noInitFetch: true, initData: {results: []}})

  return (
    <div
			className={`position-fixed position-top-0 position-left-0 zindex-2 full-width full-height bg-rgba-dark d-flex justify-content-center align-items-center ${className}`}>
        <div>
          <div className={'py-2 bg-white width-500'} style={{ borderRadius : '0.6rem 0.6rem 0 0 '}}>

            <h3 className={'mx-auto text-center font-weight-bold mb-2 width-400'} style={{overflowWrap: 'break-word'}}>
              {groupData.title}
				    </h3>

            <ul className={'list-unstyled scroll-c overflow-y-auto height-350 scrollbar-width '}>
              <div className={'px-4'}>
              {searchItemsStates?.data?.map((user) => (
                <li className={'border-bottom-extra-light py-50'}>{getFullNameFromObj(user?.account)}</li>
              ))}
              </div>

              <InView
									as={'li'}
									delay={333}
									className={`py-25 ${loader(searchItemsStates.isReady, {
										size: 'small',
									})}`}
									skip={preventLoadNextPage}
									onChange={onLastItemVisible}
								/>
            </ul>

          </div>

          <div className={'bg-c-rgba-light p-2 text-center'}
               style={{ borderRadius : '0 0 0.6rem 0.6rem'}}>
            <ButtonGoBack onClick={goBack}/>
          </div>
        </div>
    </div>
  )
}