import bgLogin from './login-img.svg'
import {Head} from '../../components/UI/Head';
import {useContext, useMemo} from 'react';
import {KeycloakContext} from '../../context/KeycloakProvider';
import Logo from '../../components/Logo/Logo';
import {AppVersion} from '../../components/AppVersion';
import { useNavigate } from 'react-router-dom';
import { loggedAdmin } from '../../stores/loggedAdmin';

const kcEventsText = {
	// onReady: 'Успешная авторизация',
	onInitError: 'Ошибка инициализации',
	onAuthError: 'Ошибка авторизации',
	onAuthLogout: 'Вы разлогинены',
	onAuthSuccess: 'Успешная авторизация',
	onTokenExpired: 'Токен Доступа устарел',
	onAuthRefreshError: '',
	onAuthRefreshSuccess: ''
}

export function PageLogin() {
	
	// const {setAuth} = useContext(AuthContext)
	// const [err, setErr] = useState(null)
	
	// function getAuthToken(e) {
	// 	e.preventDefault()
	// 	const [loginVal, passVal]
	// 		= [e.target.email.value, e.target.password.value]
	//
	// 	if (!loginVal || !passVal) return
	//
	// 	const dataForm = {
	// 		"email": loginVal,
	// 		"password": passVal
	// 	}
	//
	// 	// fetch('https://keycloak-efko-ru.azurewebsites.net/auth/realms/ucp/protocol/openid-connect/token',
	// 	//  {method: 'POST'})
	//
	// 	// fetch(BASE_URL_API + '/auth/site/', {
	// 	// method: 'POST', headers: {'Content-Type': 'application/json'},
	// 	// body: JSON.stringify(dataForm)
	// 	// }).then(res => res.json())
	// 	// .then(({token, non_field_errors}) => {
	// 	//
	// 	//   if (token) {
	// 	// 	 setAuth(true, token)
	// 	//   } else {
	// 	// 	 setAuth(false)
	// 	// 	 setErr(non_field_errors[0])
	// 	//   }
	// 	//
	// 	//   if (non_field_errors) {
	// 	// 	 setErr(non_field_errors[0])
	// 	//   }
	// 	// }).catch(err => setErr(err.message))
	// }
	
	// function clearErr() {
	// 	setErr(null)
	// }
  const navigate  = useNavigate();

	const {initKC, kcEvent, logoutMSG} = useContext(KeycloakContext)


	function login() {
		initKC()
    navigate('/admins/menu/')  
	}
	
	return (
		<>
			<div className={'min-vh-100 d-flex align-c-center'}>
				<div className={'d-flex w-100 p-4 shadow-50 rounded-lg align-items-center'}
				     style={{
					     maxWidth: 1024, minHeight: 600,
					     background: 'rgba(255, 255, 255, .6) no-repeat right 4rem center / 50%',
					     backgroundImage: `url(${bgLogin})`
				     }}>
					
					<div className={'width-35-per'}>
						
						<Logo height={'2.5rem'} className='mb-2'/>
						
						<Head>Добро пожаловать</Head>
						
						<p className={'text-danger'}>{logoutMSG}</p>
						
						<p className={'text-danger'}>
							{kcEventsText[kcEvent?.event || kcEvent?.error]}
						</p>
						
						{/*<div className={'height-100'}>*/}
						
						{/*</div>*/}
						{/*<label className="text-gray-500">Адрес электронной почты</label>*/}
						{/*<input name={'email'} type={'email'}*/}
						{/*		 className={`form-control c-input mb-75 ${err ? 'error' : ''}`}*/}
						{/*		 placeholder={'Адрес электронной почты'}/>*/}
						
						{/*<label className={'text-gray-500'}>пароль</label>*/}
						{/*<input name={'password'} type={'password'}*/}
						{/*		 className={`form-control c-input mb-75 ${err ? 'error' : ''}`}*/}
						{/*		 placeholder={'пароль'}/>*/}
						
						{/*{err ? <span id="basic-default-email-error" className="error">{err}</span> : null}*/}
						
						<button
							className={'btn btn-primary d-flex py-25 align-items-center justify-content-center mt-2 btn-block position-relative'}
							onClick={login}>
							Войти c Keycloak
							
							<svg width={'2em'} height={'2em'} viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'>
								{/*<circle cx="512" cy="512" r="512" style={{fill: '#008aaa'}}/>*/}
								<path fill='#fff'
								      d='M786.2 395.5h-80.6c-1.5 0-3-.8-3.7-2.1l-64.7-112.2c-.8-1.3-2.2-2.1-3.8-2.1h-264c-1.5 0-3 .8-3.7 2.1l-67.3 116.4-64.8 112.2c-.7 1.3-.7 2.9 0 4.3l64.8 112.2 67.2 116.5c.7 1.3 2.2 2.2 3.7 2.1h264.1c1.5 0 3-.8 3.8-2.1L702 630.6c.7-1.3 2.2-2.2 3.7-2.1h80.6c2.7 0 4.8-2.2 4.8-4.8V400.4c-.1-2.7-2.3-4.9-4.9-4.9zM477.5 630.6l-20.3 35c-.3.5-.8 1-1.3 1.3-.6.3-1.2.5-1.9.5h-40.3c-1.4 0-2.7-.7-3.3-2l-60.1-104.3-5.9-10.3-21.6-36.9c-.3-.5-.5-1.1-.4-1.8 0-.6.2-1.3.5-1.8l21.7-37.6 65.9-114c.7-1.2 2-2 3.3-2H454c.7 0 1.4.2 2.1.5.5.3 1 .7 1.3 1.3l20.3 35.2c.6 1.2.5 2.7-.2 3.8l-65.1 112.8c-.3.5-.4 1.1-.4 1.6 0 .6.2 1.1.4 1.6l65.1 112.7c.9 1.5.8 3.1 0 4.4zm202.1-116.7L658 550.8l-5.9 10.3L592 665.4c-.7 1.2-1.9 2-3.3 2h-40.3c-.7 0-1.3-.2-1.9-.5-.5-.3-1-.7-1.3-1.3l-20.3-35c-.9-1.3-.9-2.9-.1-4.2l65.1-112.7c.3-.5.4-1.1.4-1.6 0-.6-.2-1.1-.4-1.6l-65.1-112.8c-.7-1.2-.8-2.6-.2-3.8l20.3-35.2c.3-.5.8-1 1.3-1.3.6-.4 1.3-.5 2.1-.5h40.4c1.4 0 2.7.7 3.3 2l65.9 114 21.7 37.6c.3.6.5 1.2.5 1.8 0 .4-.2 1-.5 1.6z'/>
							</svg>
							
							{/*<svg className={'abs-c-right-center mr-1'} width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none"*/}
							{/*     xmlns="http://www.w3.org/2000/svg">*/}
							{/*	<path d="M4 12H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*	<path d="M14 6L20 12L14 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>*/}
							{/*</svg>*/}
						
						</button>
					
					</div>
				</div>
			</div>
			
			<AppVersion className={'position-fixed position-top-0 position-right-0 font-italic opacity-c-50 font-small-1 px-1 py-50'}/>
		</>
	)
}

