import axios from "axios";
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

export const launchersAPI = {
  getAll: (page = 1, search, ordering, page_size, version_name__in, channel__name__in, release_date__gte, release_date__lte, admin__account__keycloak_id__in) => 
    axios('/launchers/', 
      setPreparedAxiosParams({page, search, ordering, page_size, version_name__in, channel__name__in, release_date__gte, release_date__lte, admin__account__keycloak_id__in})),

  postVersion: (data) => axios.post('/launchers/', data),

  getVersion: (id) => axios(`/launchers/${id}/`),

  patchVersion: (id, data) => axios.patch(`/launchers/${id}/`, data),

  deleteVersion: (id) => axios.delete(`/launchers/${id}/`),

  getChannels: (page = 1, page_size) => 
    axios('/launchers/channels/', 
      setPreparedAxiosParams({page, page_size})),

  getAllChannels() {
    return axios('/launchers/channels/', setPreparedAxiosParams({...arguments[0]}),)
  },
}