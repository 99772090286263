import { Link } from 'react-router-dom';
import {APPS} from '../../../constants/constants';
import {getFullNameFromObj} from '../../../js-helpers/helpers';

const arrCrashFields = [
  'user',
  'app',
  'event_date',
  'device',
  'serial_number',
  'system_version',
  'application',
];

export const createApp = (data) => {
  const {
    app,
    system_version,
    build_version,
    display_version,
    model,
    manufacturer,
    serial_number,
  } = data;
  const abc = {};

  arrCrashFields.map((item) => {
    switch (item) {
      case 'device':
        abc['Устройство'] = `${manufacturer} ${model}`;
        break;
      case 'serial_number':
        abc['Серийный №'] = serial_number ? <Link to={`/admins/devices/${serial_number}/info/`}>{serial_number}</Link> : '-';
        break;
      case 'system_version':
        abc['Версия операционной системы'] = system_version;
        break;
      case 'application':
        abc['Версия лаунчера'] = `${display_version} (${build_version})`;
        break;
      // case 'event_date':
      // 	abc['Время ошибки'] = getLocaleFullDate(data[item])
      // 	break
      case 'user':
        abc['ФИО пользователя'] = data[item]
          ? <Link to={`/admins/users/${data[item].keycloak_id}/info/`}>{getFullNameFromObj(data[item])}</Link>
          : '-';
        break;
      case 'app':
        abc['Приложение'] = APPS[app];
        break;
      default:
        break;
    }
    return null;
  });
  return Object.entries(abc);
};