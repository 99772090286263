import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

export const contactsAPI = {
	getUserContacts: ({keycloakID, ...params}) =>
		axios(`/users/${keycloakID}/contacts/`,
			setPreparedAxiosParams(params)
		),
	
	// getUserContactsAllFolders: (keycloakID, page = 1) =>
	// 	axios(`/users/${keycloakID}/contact-folders/`, {...prepareAxiosParams({page})}),
	
	getContactInfo: (keycloakID, contactID) =>
		axios(`/users/${keycloakID}/contacts/${contactID}/`),
	
	getAllShared: (page, search, ordering, page_size) =>
		axios('/internal-contacts/',
			setPreparedAxiosParams({page, search, ordering, page_size})),
	
	getShared: (contactID) => axios(`/internal-contacts/${contactID}/`),
	
	// searchShared: (page, search, signal) =>
	// 	axios('/internal-contacts/', {signal, ...prepareAxiosParams({page, search})}),
	
	createShared: (data) => axios.post('/internal-contacts/', data),
	
	patchVisibility: (uuid, data) =>
		axios.patch(`/internal-contacts/${uuid}/visibility/`, data),
	
	putVisibility: (uuid, data) =>
		axios.put(`/internal-contacts/${uuid}/visibility/`, data),
	
	getAllRules: ({page_size = 25, ...props} = {}) =>
		axios('/internal-contacts/rules/', setPreparedAxiosParams({page_size, ...props})),
	
	postRule: (data) => axios.post('/internal-contacts/rules/', data),
	
	getRule: (id) => axios(`/internal-contacts/rules/${id}/`),
	
	deleteRule: (id) => axios.delete(`/internal-contacts/rules/${id}/`),
	
	patchRule: (id, data) => axios.patch(`/internal-contacts/rules/${id}/`, data),
}