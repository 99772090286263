import React, {useEffect, useRef, useState} from 'react';
import {toggle} from '../../js-helpers/helpers';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {useUpdateEffect} from '@react-hookz/web';

export const oneTransparentPx = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export function Img({
	                    src,
	                    alt,
	                    width,
	                    height,
	                    onClick,
	                    style = {},
	                    noSrcImage = oneTransparentPx,
	                    className,
	                    holderClassName
                    }) {
	const imgProps = {alt, height, width}
	const [loaded, setLoaded] = useState(false)
	const [isErr, setIsErr] = useState(false)
	
	useUpdateEffect(() => {
		setLoaded(false)
		setIsErr(false)
	}, [src])
	
	useEffect(() => {
		!src && setLoaded(true)
	}, [src])
	
	const transitionRef = useRef(null)
	
	return (
		<>
			{!loaded &&
			<div className={`img-c-placeh bg-rgba-light ${className} ${holderClassName}`}
			     style={{height, width, ...style}}
			>
				
				{isErr
					?
					<span className='badge badge-circle badge-circle-sm badge-danger badge-up font-weight-bold'>
						!
					</span>
					:
					<div className='spinner-grow text-light-secondary'
					     style={{height, width, ...style, borderRadius: 'inherit'}}
					     role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				}
				
				<img className={`sr-only ${className}`}
				     src={isErr ? noSrcImage : oneTransparentPx}
				     {...imgProps}
				/>
			</div>
			}
			
			<CSSTransition
				in={loaded}
				timeout={15000}
				nodeRef={transitionRef}
				classNames='transition-fade'
			>
				<img className={`${toggle(!loaded, 'visually-hidden')} ${className}`}
				     ref={transitionRef}
				     src={src || noSrcImage}
				     onClick={onClick}
				     onLoad={() => setLoaded(true)}
				     onError={() => setIsErr(true)}
				     {...imgProps}
				     style={style}
				/>
			</CSSTransition>
		</>
	)
}

Img.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.oneOfType([
		PropTypes.string.isRequired, PropTypes.object.isRequired
	]),
	className: PropTypes.string,
	onClick: PropTypes.func,
}

Img.defaultProps = {
	className: '',
	holderClassName: '',
}