import { Link } from "react-router-dom";
import { getLocaleFullDate, getShortFullNameFromObj } from "../../../../js-helpers/helpers";
import { ReactComponent as EditSVG } from '../../../../assets/icons/services/edit.svg';
import { loggedAdmin } from "../../../../stores/loggedAdmin";
import { ButtonWithIcon } from "../../../button/ButtonWithIcon";


export const getGroupsTableOptions = (deleteGroup) => [
   {
    head: 'Название группы',
    cell: (title) => title,
    nestedCellField: 'title',
    sortField: 'title',
  },
   {
    head: 'Количество пользователей',
    cell: (users_count) => users_count,
    nestedCellField: 'users_count',
    sortField: 'users_count',
  },
   {
    head: 'Автор группы',
    cell: getShortFullNameFromObj,
    nestedCellField: 'created_by',
    sortField: 'created_by__account__last_name',
  },
   {
    head: 'Дата создания',
    cell: getLocaleFullDate,
    nestedCellField: 'created_at',
    sortField: 'created_at'
  },
 {
    head: 'Действия',
    cell: (data) => <ActionsRow deleteGroup={deleteGroup} data={data}/>,
  }
]


const ActionsRow = ({deleteGroup, data}) => {
  const isSuperAdmin = loggedAdmin.isSuperAdmin

  return (
    <div className='d-flex align-items-center'>
      <Link className={'c-hover-opacity min-c-touch-zone'}
			      to={`/admins/groups/${data?.id}/`}>
              <EditSVG style={{marginTop: '3px'}}/>
      </Link>
      {isSuperAdmin
       && <ButtonWithIcon type={'delete'}
                          onClick={() => deleteGroup(data)}
                          className={'ml-2'}/>}
    </div>
  )
}