import {AxiosInterceptorsHelper} from './services/axios/interceptor/AxiosInterceptorsHelper';
import {ToastContainer} from 'react-toastify';
import {limitsStore} from './stores/limitsOptionsStore';
import {useEventListener, useLocalStorageValue, useMountEffect} from '@react-hookz/web';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {AppRouter} from './components/AppRouter';
import {ErrorBoundary} from 'react-error-boundary';
import {NotFoundPage} from './pages/notFoundPage/NotFoundPage';
import {InsideErrorPage} from './pages/insideErrorPage/InsideErrorPage';

import './snow/snow.scss'
import {useLayoutEffect} from 'react';
import {initSnow} from './snow/snow_p';

dayjs.extend(relativeTime);
dayjs.locale('ru');


export function App() {
	useEventListener(window, 'beforeunload', () => {
		limitsStore.saveLimits();
	});
	
	// const [isShowSnow, setIsShowSnow] = useLocalStorageValue('isShowSnow')
	// const [snowCount, setSnowCount] = useLocalStorageValue('snowCount')
	// const [snowSpeed, setSnowSpeed] = useLocalStorageValue('snowSpeed')
	
	// useMountEffect(() => {
	// 	snowCount ?? setSnowCount(5000)
	// 	isShowSnow ?? setIsShowSnow(true)
	// 	snowSpeed ?? setSnowSpeed(0)
	// })
	
	// useLayoutEffect(() => {
	// 	isShowSnow && initSnow(snowCount ?? 5000, snowSpeed ?? 1,)
	// }, [isShowSnow])
	
	return (
		<>
			{/* {isShowSnow && <div className='snow'/>} */}
			
			<AxiosInterceptorsHelper/>
			
			<ToastContainer
				limit={5}
				position={'bottom-right'}
				className={'w-auto'}
				toastClassName={'text-body rounded shadow'}
				bodyClassName={'px-1'}
				hideProgressBar
				closeButton={false}
				autoClose={4000}
			/>
			<ErrorBoundary FallbackComponent={InsideErrorPage} >
				<div className={'app'}>
					<AppRouter/>
				</div>
			</ErrorBoundary>
		</>
	);
}
