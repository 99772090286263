import {SearchInput} from '../../components/form/SearchInput';
import {useFetchStates} from '../../hooks/useFetchStates';
import {searchGlobalAPI} from '../../API/searchGlobal';
import {isEmpty, isNotEmpty, loader} from '../../js-helpers/helpers';
import {useEffect} from 'react';
import {useUpdateEffect} from '@react-hookz/web';
import {GlobalSearchResultsList} from './list/GlobalSearchResultsList';
import {Head} from '../../components/UI/Head';
import {ButtonGoBack} from '../../components/button/ButtonGoBack';
import {FullPagination} from '../../components/paginations/FullPagination';
import {urlParams} from '../../constants/urlParams';
import {useComboSearch} from '../../hooks/url/useComboSearch';
import {EmptyFoundDataMessage} from '../../components/EmptyFoundDataMessage/EmptyFoundDataMessage';

const {SEARCH, PAGE, LIMIT,} = urlParams

const searchParams = [
	{param: SEARCH, init: '', debounce: 666},
	{param: PAGE, init: 1},
	{param: LIMIT, init: 10},
]

export function PageGlobalSearch() {
	const [
		[urlQuery, setUrlQuery],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(searchParams)
	
	const {data, getData, isReady} = useFetchStates(
		() => searchGlobalAPI.search(urlPage, urlQuery, urlLimit)
		, {initData: {results: []}, noInitFetch: true})
	
	useEffect(() => {
		if (isNotEmpty(urlQuery))
			getData()
	}, [])
	
	useUpdateEffect(() => {
		getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (+urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData()
	}, [urlQuery, urlLimit])
	
	// const onSearchKeyDown = (e) => {
	// 	if (e.key === 'Enter') {
	// 		setUrlQuery(e.target.value)
	// 	}
	// }
	
	// const onSearchClick = () => {
	// 	const searchQuery = document.forms[0].search.value
	// 	setUrlQuery(searchQuery)
	// }
	
	// const synchronousWithDataSearchQuery = useMemo(() => urlQuery, [data])
	
	const showEmptyFoundDataMessage = isEmpty(data?.results) && isNotEmpty(urlQuery);
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head title={'Глобальный поиск'}/>
			
			<form onSubmit={e => e.preventDefault()}>
				
				{/*<button className={'ml-1 btn btn-primary float-right'}*/}
				{/*        type={'button'}*/}
				{/*        onClick={onSearchClick}>*/}
				{/*	Найти*/}
				{/*</button>*/}
				
				<SearchInput
					placeholder={'Введите запрос для поиска...'}
					onChange={e => setUrlQuery(e.target.value)}
					defaultValue={urlQuery}
					withBorder
					name={'search'}
					className={'mb-1'}/>
				
				Количество совпадений: <strong>{data?.count}</strong>
				
				{showEmptyFoundDataMessage
					?
					<EmptyFoundDataMessage isDataReady={isReady}/>
					:
					<GlobalSearchResultsList
						className={`list-group mt-50 mb-1 ${loader(isReady)}`}
						results={data.results}
					/>
				}
				
				
				<FullPagination allCount={data?.count}/>
			</form>
		</>
	)
}