import { useMountEffect, useToggle, useUpdateEffect } from "@react-hookz/web";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { userAPI } from "../../../API/userAPI";
import { ButtonFilter } from "../../../components/button/ButtonFilter";
import { FilterPills } from "../../../components/FilterPills/FilterPills";
import { MySearchSelect } from "../../../components/form/MySelect/MySearchSelect";
import { RangeDatePicker } from "../../../components/form/RangeDatePicker";
import { SearchInput } from "../../../components/form/SearchInput";
import { ALL_TYPE, DEVICE_TYPE, USER_TYPE } from "../../../constants/constants";
import { urlParams } from "../../../constants/urlParams";
import { useComboSearch } from "../../../hooks/url/useComboSearch";
import { useFetchStates } from "../../../hooks/useFetchStates";
import { getFullNameFromObj, getIsoUtcDate, getLocaleFullDate, toggle } from "../../../js-helpers/helpers";

const {SEARCH, PAGE, LIMIT, USER, DEVICE, DATE_FROM, DATE_TO} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 'last'},
	{param: LIMIT},
  {param: USER},
  {param: DEVICE},
  {param: DATE_FROM},
  {param: DATE_TO}
]

const FiltersPageCommonMedia = ({type}) => {
  const [
    [urlSearchQuery, setUrlSearchQuery],
    [urlPage],
    [urlLimit], 
    [urlUser, setUrlUser],
    [urlDevice, setUrlDevice],
    [urlDateFrom, setUrlDateFrom],
    [urlDateTo, setUrlDateTo],
  ] = useComboSearch(pageSearchParams);

  const [search, setSearch] = useSearchParams();
  const [showFilters, toggleShowFilters] = useToggle(
    urlUser || urlDevice || urlDateFrom || urlDateTo
  );

  useUpdateEffect(() => {
    if (!showFilters) {
      setUrlDateFrom('');
      setUrlDateTo('');
      setUrlUser('');
      setUrlDevice('');
      setInitFilters((draft) => {
        draft.user = {
          text: '',
          value: '',
        };
        draft.device = {
          text: '',
          value: '',
        };
      });
    }
  }, [showFilters]);

  const [pillsOptions, setPillsOptions] = useImmer(() => [
    {
      urlKey: SEARCH,
      label: null,
    },
    {
      urlKey: USER,
      label: null,
    },
    {
      urlKey: DEVICE,
      label: null,
    },
    {
      urlKey: DATE_FROM,
      label: (date) => `От  ${getLocaleFullDate(date)}`,
    },
    {
      urlKey: DATE_TO,
      label: (date) => `До  ${getLocaleFullDate(date)}`,
    },
  ]);

  const customSetter = ({ data: { account } }) => {
    const text = getFullNameFromObj(account);

    setPillsOptions((draft) => {
      draft.find((_pill) => _pill.urlKey === USER).label = text;
    });

    setInitFilters((draft) => {
      draft.user = {
        text,
        value: account.keycloak_id,
      };
    });

    initUserState.setReady(true);
  };

  const [initFilters, setInitFilters] = useImmer({
    user: null,
    device: null,
  });

  const initUserState = useFetchStates(() => userAPI.getUserInfo(urlUser), {
    noInitFetch: true,
    customSetter,
  });

  useMountEffect(() => {
    urlUser && initUserState.getData();
  });

  
  const onUserSelect = (selectedOption) => {
    if (!selectedOption) return;
    const { text, value, extraText } = selectedOption;

    setUrlUser(value);

    setPillsOptions((draft) => {
      draft.find(
        (_pill) => _pill.urlKey === USER
      ).label = text;
    });
    initUserState.setReady(true);
  };

  
  const onDeviceSelect = (selectedOption) => {
    if (!selectedOption) return;

    const { text, value } = selectedOption;

    setUrlDevice(value);
    setPillsOptions((draft) => {
      draft.find((_pill) => _pill.urlKey === DEVICE).label = text;
    });
  }

  const onClosePill = (urlKey) => {
    search.delete(urlKey);
    setSearch(search.toString(), { replace: true });
  };

  return (
    <>
    <div
      className={
        'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
      }
    >
      <div className={'col-12 row no-gutters align-items-center'}>
        <SearchInput
          resetWhen={!urlSearchQuery}
          className={'col mr-1'}
          onChange={(e) => setUrlSearchQuery(e.target.value)}
          placeholder={'Поиск...'}
          defaultValue={urlSearchQuery}
        />

        <ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
      </div>

      {showFilters && (
        <div
          className={
            'row col-12 no-gutters align-items-center gap-1r'
          }
        >
          {(type === ALL_TYPE || type === DEVICE_TYPE) &&<MySearchSelect
            className={'col'}
            type={'usersWithKeycloakID'}
            init={urlUser && initUserState.isReady}
            initialSelectedItem={initFilters.user}
            resetWhen={!urlUser}
            onSelect={onUserSelect}
            initText={'Все пользователи'}
            titleClassName={`${toggle(!urlUser, 'text-muted')}`}
          />}

          {(type === ALL_TYPE || type === USER_TYPE) && <MySearchSelect
            className={'col'}
            type={'allDevices'}
            init={urlDevice}
            initialSelectedItem={initFilters.device}
            resetWhen={!urlDevice}
            onSelect={onDeviceSelect}
            initText={'Все устройства'}
            titleClassName={`${toggle(!urlDevice, 'text-muted')}`}
          />}

          <RangeDatePicker
            fromPickerProps={{
              value: urlDateFrom,
              onChange: ([date]) => {
                setUrlDateFrom(date && getIsoUtcDate(date));
              },
              options: { maxDate: urlDateTo },
            }}
            toPickerProps={{
              value: urlDateTo,
              onChange: ([date]) => {
                setUrlDateTo(
                  date && getIsoUtcDate(dayjs(date).second(59).toDate())
                );
              },
              options: { minDate: urlDateFrom },
            }}
          />
        </div>
      )}
    </div>

    <FilterPills pillsOptions={pillsOptions} search={search} onClosePill={onClosePill}/>
  </>
  )
}

export default FiltersPageCommonMedia