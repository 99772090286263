import {toast} from 'react-toastify'

const checkIcon = (name) => {
  toast.error(<>Убедитесь, что имя файла {<b>{name}</b>} содержит не более 100 символов</>)
}

const checkIconFormat = (name, error) => {
  toast.error(<>Проверьте формат {<b>{name}</b>}. {error}</>)
}

export const checkIconLengthAngFormat = (iconParam, nameOnToast) => {
  if (iconParam.toLowerCase().includes('не более 100 символов')) {
    checkIcon(nameOnToast)
  } else if (iconParam.toLowerCase().includes('расширение')) {
    checkIconFormat(nameOnToast, iconParam)
  }
}