import {useSearchParams} from 'react-router-dom';
import {isEmpty} from '../../js-helpers/helpers';
import {useMemo} from 'react';

export const useSearchParamsUpdate = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const searchString = searchParams.toString()
	
	const updateUrl = () => {
		searchParams.sort()
		setSearchParams(searchParams.toString(), {replace: true})
	}
	
	const setNewSearch = (param, value) => {
		if (searchParams.get(param) === value?.toString())
			return
		
		if (isEmpty(value)) {
			deleteSearch(param)
			return
		}
		
		searchParams.set(param, value)
		updateUrl()
	}
	
	const deleteSearch = (param) => {
		searchParams.delete(param)
		updateUrl()
	}
	
	return useMemo(() => ({setNewSearch, deleteSearch, updateUrl, searchParams}), [searchString])
	// return {setNewSearch, deleteSearch, updateUrl, searchParams}
}