import {useImmer} from 'use-immer';
import {toggle} from '../../../../js-helpers/helpers';
import {useMountEffect, useToggle, useUpdateEffect} from '@react-hookz/web';
import {SearchInput} from '../../../../components/form/SearchInput';
import {urlParams} from '../../../../constants/urlParams';
import {useComboSearch} from '../../../../hooks/url/useComboSearch';
import React, { useMemo } from 'react';
import {useSearchParamsUpdate} from '../../../../hooks/url/useSearchParamsUpdate';
import {MySelect} from '../../../../components/form/MySelect/MySelect';
import {Columns} from '../../../../components/UI/Columns';
import {ButtonFilter} from '../../../../components/button/ButtonFilter';
import {blockedStatusOptions, haveDeviceOptions, initSelects, isOnlineOptions, inGroupOptions} from './filtersPageUsersFunctions';
import {MySearchMultiSelect} from '../../../../components/form/MySelect/MySearchMultiSelect';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { divisionsAPI } from '../../../../API/divisionsAPI';
import { useLocation } from 'react-router-dom';

const {SEARCH, DIVISION, SUB_DIVISION, LAUNCHER_VER, BLOCKED, HAVE_DEVICE, IS_ONLINE, USER, DEVICE, IN_GROUP} = urlParams


export const FiltersPageUsers = ({page, inGroup = false}) => {
  const location = useLocation()

  const isNewUsersPage = useMemo(() => 
    location?.pathname?.includes('users-new')
  , [location])

  const searchParams = useMemo(() => [
    {param: SEARCH, init: '', debounce: 333},
    {param: DIVISION},
    {param: SUB_DIVISION},
    {param: LAUNCHER_VER},
    {param: BLOCKED, init: isNewUsersPage ? '' : 'false'},
    {param: HAVE_DEVICE, init: inGroup ? '' : isNewUsersPage ? '' : 'true'},
    {param: IS_ONLINE},
    {param: USER},
    {param: DEVICE},
    {param: IN_GROUP, init: isNewUsersPage ? '' : 'true'}
  ], [isNewUsersPage, inGroup])

	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlDivision, setUrlDivision],
		[urlSubDivision, setUrlSubDivision],
		[urlLauncherVer, setUrlLauncherVer],
		[urlBlocked, setUrlBlocked],
		[urlHaveDevice, setHaveDevice],
    [urlIsOnline, setUrlIsOnline],
    [urlUser, setUrlUser],
    [urlDevice, setUrlDevice],
    [urlInGroup, setUrlInGroup]
	] = useComboSearch(searchParams)

	const [showFilters, toggleShowFilters] =
		useToggle(
			urlDivision || urlSubDivision ||	urlLauncherVer || (urlHaveDevice && urlHaveDevice !== 'null')
      || urlIsOnline || urlUser || urlDevice || (inGroup && urlInGroup && urlInGroup !== 'null') || (urlBlocked && urlBlocked !== 'null')
		)

	useUpdateEffect(() => {
		if (!showFilters) {
			setUrlBlocked(isNewUsersPage ? '' : 'null')
			setHaveDevice(isNewUsersPage ? '' : 'null')
			setUrlDivision('')
			setUrlLauncherVer('')
			setUrlSubDivision('')
      setUrlIsOnline('')
      setUrlUser('')
      setUrlDevice('')
      setUrlInGroup(isNewUsersPage ? '' : 'null')
			setInitSelectsLabels(initSelects)
		}
	}, [showFilters])
	
	const [initSelectsLabels, setInitSelectsLabels] = useImmer(initSelects)

	const setInitSelects = (urlKey, text, value = text) => {
    setInitSelectsLabels(draft => {
      draft[urlKey] = {text, value}
    })
	}

  const customDivisionSetter = ({data}) => {
    const text = data?.results?.map((item) => item?.name).join(',')
    const value = data?.results?.map((item) => item?.id).join(',')

		setInitSelects(DIVISION, text, value)
		initDivisionState.setReady(true);
	}

  const initDivisionState = useFetchStates(
		() => divisionsAPI.getAll({page_size: 50, id__in: urlDivision}, {allowMultipleRequests: true}), {
			noInitFetch: true,
			customSetter: customDivisionSetter,
		});

	useMountEffect(() => {
    urlDivision && initDivisionState.getData()
		urlLauncherVer && setInitSelects(LAUNCHER_VER, urlLauncherVer)
		urlSubDivision && setInitSelects(SUB_DIVISION, urlSubDivision)
		
		if (urlBlocked && urlBlocked !== 'null') {
			const {text, value} = blockedStatusOptions.find(({value}) => value === urlBlocked)
		  setInitSelects(BLOCKED, text, value)
		}
		
		if (urlHaveDevice && urlHaveDevice !== 'null') {
			const {text, value} = haveDeviceOptions.find(({value}) => value === urlHaveDevice)
			setInitSelects(HAVE_DEVICE, text, value)
		}

		if (urlIsOnline) {
			const {text, value} = isOnlineOptions.find(({value}) => value === urlIsOnline)
			setInitSelects(IS_ONLINE, text, value)
		}

    urlUser && setInitSelects(USER, urlUser)
    urlDevice && setInitSelects(DEVICE, urlDevice)

    if (urlInGroup && urlInGroup !== 'null') {
      const {text, value} = inGroupOptions.find(({value}) => value === urlInGroup)
      setInitSelects(IN_GROUP, text, value)
    }
	})
	
	const {setNewSearch} = useSearchParamsUpdate()
	
	const onFilterSelect = (urlKey, item) => {
		if (!item) return
    if (Array.isArray(item)) {
      const itemsToSearch = item.map((i) => i.value)

      setNewSearch(urlKey, itemsToSearch)
    } else {
      setNewSearch(urlKey, item.value)
    }
	}

  const onDivisionSelect = (selectedOptions) => {
    if (!selectedOptions) return;

    const resultOptions = selectedOptions.map((option) => option.value)

    setUrlDivision(resultOptions)
    initDivisionState.setReady(true);
  }
	
  const columnsParams =  inGroup ? 
  { cols: 7, sumItems: 7 } : { cols: 6, sumItems: 6 }

	return (
		<>
			<div className={'row no-gutters mb-50 px-1 py-75 rounded bg-transparent-60'}>
				<div className={'row no-gutters col-12'}>
					
					<SearchInput className={'col mr-1'}
					             resetWhen={!urlSearchQuery}
					             onChange={e => setUrlSearchQuery(e.target.value)}
					             placeholder={'Поиск по имени, ID, подразделению...'}
					             defaultValue={urlSearchQuery}
					/>
					
					<ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
				</div>
				
				{showFilters &&
				<Columns as={'div'} cols={columnsParams.cols} sumItems={columnsParams.sumItems} colGap={1} className={'mt-1 w-100'}>

          {page === 'groups' && inGroup ? <MySelect
						initText={'Пользователи в группе'}
						resetWhen={urlInGroup === 'null' || !urlInGroup}
						titleClassName={toggle(urlInGroup === 'null' || !urlInGroup, 'text-muted')}
						onSelect={(item) => onFilterSelect(IN_GROUP, item)}
						initialSelectedItem={initSelectsLabels[IN_GROUP]}
						optionsArr={inGroupOptions}
            activeItemClassName={'active_gray'}
            closeButtonHandled={() => setUrlInGroup('null')}
            isSelectedNow={urlInGroup}
            // type={'have_device'}
            closeButton={true}
					/> : <></>}
        {page === 'groups' && <MySearchMultiSelect
						type={'usersWithKeycloakID'}
						initialSelectedItems={initSelectsLabels[USER]}
						resetWhen={!urlUser}
						onSelect={(item) => onFilterSelect(USER, item)}
						initText={'ФИО'}
            clearUrl={() => setUrlUser('')}
						titleClassName={`${toggle(!urlUser, 'text-muted')}`}/>
            }

          {page === 'groups' && <MySearchMultiSelect
						type={'allDevices'}
						initialSelectedItems={initSelectsLabels[DEVICE]}
						resetWhen={!urlDevice}
						onSelect={(item) => onFilterSelect(DEVICE, item)}
						initText={'Серийный №'}
            clearUrl={() => setUrlDevice('')}
						titleClassName={`${toggle(!urlDevice, 'text-muted')}`}/>
            }
					
					<MySearchMultiSelect
						type={'divisionsWithUsers'}
						initialSelectedItems={initSelectsLabels[DIVISION]}
            init={urlDivision && initDivisionState.isReady}
						resetWhen={!urlDivision}
            // onSelect={(item) => onFilterSelect(DIVISION, item)}
						onSelect={onDivisionSelect}
						initText={'Дивизион'}
            clearUrl={() => setUrlDivision('')}
						titleClassName={`${toggle(!urlDivision, 'text-muted')}`}/>
					
					<MySearchMultiSelect
						type={'subdivisions'}
						initialSelectedItems={initSelectsLabels[SUB_DIVISION]}
						resetWhen={!urlSubDivision}
						onSelect={(item) => onFilterSelect(SUB_DIVISION, item)}
						initText={'Подразделение'}
            clearUrl={() => setUrlSubDivision('')}
            division={urlDivision}
						titleClassName={`${toggle(!urlSubDivision, 'text-muted')}
            `}/>
					
					{page !== 'groups' && <MySearchMultiSelect
						type={'launcherVersions'}
						initialSelectedItems={initSelectsLabels[LAUNCHER_VER]}
						resetWhen={!urlLauncherVer}
						onSelect={(item) => onFilterSelect(LAUNCHER_VER, item)}
						initText={'Версия прошивки'}
            clearUrl={() => setUrlLauncherVer('')}
						titleClassName={`${toggle(!urlLauncherVer, 'text-muted')}`}/>}
					
          {page !== 'groups' && <MySelect
						initText={'Статус авторизации'}
						resetWhen={!urlIsOnline}
						titleClassName={toggle(!urlIsOnline, 'text-muted')}
						onSelect={(item) => onFilterSelect(IS_ONLINE, item)}
						initialSelectedItem={initSelectsLabels[IS_ONLINE]}
						optionsArr={isOnlineOptions}
            activeItemClassName={'active_gray'}
            closeButtonHandled={() => setUrlIsOnline('')}
            isSelectedNow={urlIsOnline}
            // type={'is_online'}
            closeButton={true}
					/>}

          <MySelect
						initText={'Статус пользователя'}
						resetWhen={urlBlocked === 'null' || !urlBlocked}
						titleClassName={
							toggle(
								urlBlocked === 'null' || !urlBlocked,
								// !pillsOptions.find(option => option.urlKey === BLOCKED).label?.trim(),
								'text-muted')
						}
						onSelect={(item) => {onFilterSelect(BLOCKED, item)}}
						initialSelectedItem={initSelectsLabels[BLOCKED]}
						optionsArr={blockedStatusOptions}
            activeItemClassName={'active_gray'}
            closeButtonHandled={() => setUrlBlocked('null')}
            isSelectedNow={urlBlocked}
            // type={'blocked'}
            closeButton={true}
					/>
					
					<MySelect
						initText={'Наличие устройства'}
						resetWhen={urlHaveDevice === 'null' || !urlHaveDevice}
						titleClassName={
              toggle(urlHaveDevice === 'null' || !urlHaveDevice, 'text-muted')}
						onSelect={(item) => onFilterSelect(HAVE_DEVICE, item)}
						initialSelectedItem={initSelectsLabels[HAVE_DEVICE]}
						optionsArr={haveDeviceOptions}
            activeItemClassName={'active_gray'}
            closeButtonHandled={() => setHaveDevice('null')}
            isSelectedNow={urlHaveDevice}
            // type={'have_device'}
            closeButton={true}
					/>
				</Columns>
				} 
			</div>
		</>
	)
};