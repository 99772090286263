import { useEffect } from "react"
import { loader } from "../../../../../js-helpers/helpers"

const options = [
  'настроена видимость доступных разделов;',
  'добавлены группы пользователей, данные по которым доступны к просмотру/редактированию;',
  'включен/выключен свитчер на просмотр/редактирование данных, получаемых от лаунчера без авторизации'
]

export const AdminModalDialogBody = ({adminStates, setHandledOptions, handledOptions}) => {

  useEffect(() => {
    adminStates.getData()

    setHandledOptions({
      sections: adminStates?.data.available_sections?.length,
      groups: adminStates?.data.visibility_groups?.length || adminStates?.data?.is_unauthorized_launchers_visible
    })
  }, [])

  return (
    <div div className={`text-left px-2 pb-2 ${loader(adminStates.isReady)}`}>
      {adminStates?.data?.is_superadmin && 
        <div className='mb-2 font-weight-bold text-center'> 
          Подтвердите ознакомление с карточкой СуперАдминистратора
        </div>
      }

      {!adminStates.data?.is_superadmin &&
        <>
          {handledOptions.sections && handledOptions.groups ?
          (<>
          <div className='mb-2 font-weight-bold text-center'>
            Проверьте, что вы произвели следующие настройки:
          </div>
          <ul>
            {options.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
          <div className='pl-2 font-weight-bold'>После подтверждения обработки карточки нового администратора будет удалена из таблицы “Новые Администраторы”</div>
        </>
      ) : (
      <>
        <div className='mb-2 font-weight-bold text-center'>
          Не произведены настройки:
        </div>
        <ul>
          {!handledOptions.sections && <li>не настроена видимость доступных разделов;</li>}
          {!handledOptions.groups && <li>не добавлена хотя бы одна группа или не включен свитчер на просмотр/редактирование данных, получаемых от лаунчера без авторизации</li>}
        </ul>
      </>)}
        </>
      }

    </div>
  )
}