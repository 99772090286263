import { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ButtonGoBack } from "../../components/button/ButtonGoBack";
import { Head } from "../../components/UI/Head";
import {ReactComponent as InsideErrorIcon} from './inside_error.svg';

export const InsideErrorPage = ({resetErrorBoundary}) => {
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate(-1);
    resetErrorBoundary()
  }

  return (
    <div className={'text-center w-100 text-gray-300 p-2'}>
      <ButtonGoBack className={'d-block ml-auto'} onClick={goBack}/>
      <div
        style={{ height: '90vh' }}
        className={'d-flex justify-content-center flex-column'}
      >
        <InsideErrorIcon className='ml-auto mr-auto mb-2'/>
        <Head className={'text-body text-uppercase font-weight-bold'}>
          Упс! Что-то пошло не так…
        </Head>
        <Head>
          Ошибка на стороне веб-сервиса
        </Head>
      </div>
    </div>
  );
};