import {Card} from '../../../../../components/UI/Card';
import {Head} from '../../../../../components/UI/Head';
import {SearchInput} from '../../../../../components/form/SearchInput';
import {InView} from 'react-intersection-observer';
import React, {useEffect, useMemo, useState} from 'react';
import {useFetchStates} from '../../../../../hooks/useFetchStates';
import {userAPI} from '../../../../../API/userAPI';
import {useAbortController} from '../../../../../hooks/useAbortController';
import {isEmpty, loader} from '../../../../../js-helpers/helpers';
import {useCountState} from '../../../../../hooks/useCountState';
import {CheckBox} from '../../../../../components/form/CheckBox';
import {Radio} from '../../../../../components/form/Radio';
import {useDebouncedState} from '@react-hookz/web';

function SearchList(
	{
		list, onCheck, setDefaultChecked, isLastItemVisible, loading,
		skipInView, isFoundType, className = '', isBlock, ...props
	}) {
	return (
		<div className={`col ${className}`}>
			{props.children}
			
			<div className={`d-flex flex-column height-350 ${loader(!loading)}`}>
				<ul className={'list-group m-0 flex-grow-1 card shadow-sm overflow-auto scroll-c '}>
					
					{isEmpty(list) && !loading &&
					<li className={'list-group-item px-1 py-50 text-muted'}>Не найдено ...</li>
					}
					
					{list?.map((contact) => {
						const {id, first_name, last_name, middle_name, key} = contact
						return (
							<li key={key || id}
							    className={'list-group-item list-group-item-action p-0'}>
								<label
									className={'px-1 py-50 checkbox cursor-pointer text-c-transform-none d-flex align-items-center'}
								>
									{`${id} - ${last_name} ${first_name} ${middle_name}`}
									
									<CheckBox className={'ml-auto'}
									          disabled={isBlock}
									          onChange={e => onCheck(e, contact)}
									          defaultChecked={setDefaultChecked(contact)}
									/>
								</label>
							</li>
						)
					})}
					
					{isFoundType &&
					<InView as={'li'}
					        delay={333}
					        skip={skipInView}
					        onChange={isLastItemVisible}
					/>}
				</ul>
			</div>
		</div>
	)
}

export function SectionVisibility(
	{
		setWhiteListState, whiteListState, haveWhiteList, toggleHaveWhiteList, isBlock
	}) {
	const [whiteListSearchQuery, setWhiteListSearchQuery] = useState('')
	const foundPage = useCountState(1)
	const [lastSearchPage, setLastSearchPage] = useState(false)
	
	const withController = useAbortController()
	const [allUsersSearchQuery, setAllUsersSearchQuery] = useDebouncedState('', 333)
	
	const foundUsers = useFetchStates(
		(page = foundPage.count, searchQuery, signal) =>
			userAPI.searchUsers(page, searchQuery, signal),
		{noInitFetch: true, customSetter, initData: []})
	
	function customSetter({data}, page) {
		setLastSearchPage(!data.next)
		const preparedUsers = data.results.map(user => user.account)
		
		page === 1
			? foundUsers.setData(preparedUsers)
			: foundUsers.setData(state => [...state, ...preparedUsers])
		
		foundUsers.setReady(true)
		foundPage.setNextCount()
	}
	
	useEffect(() => {
		withController((signal) => {
			foundPage.setCount(1)
			foundUsers.getData(1, allUsersSearchQuery, signal)
		})
	}, [allUsersSearchQuery])
	
	const onCheck = ({target}, contact) => {
		setWhiteListState(state =>
			!target.checked
				? state.filter(({id}) => +id !== +contact.id)
				: [...state, contact])
	}
	
	const withSearch = list =>
		isEmpty(whiteListSearchQuery)
			? list
			: list.filter((contact) =>
				Object.values(contact)
					.some(val => String(val).toLowerCase()
						.includes(whiteListSearchQuery.toLowerCase())))
	
	const preparedFoundUsers = useMemo(() => {
		return isEmpty(foundUsers.data)
			? []
			: foundUsers.data.map(user => {
				
				const checked = whiteListState.some(cont => +cont.id === +user.id)
				return {...user, key: user.id + checked.toString(), checked}
			})
	}, [foundUsers.data, whiteListState])
	
	function isLastItemVisible(inView) {
		if (inView) foundUsers.getData(foundPage.count, allUsersSearchQuery)
	}
	
	return (
		<Card secondary className={'mb-1'} title={'Видимость'}>
			<div className={'row no-gutters'}>
				<div className={'col-2'}>
					<label className={'cursor-pointer d-block text-capitalize font-size-base font-weight-bolder'}>
						
						<Radio
							disabled={isBlock}
							name='whiteList'
							onChange={toggleHaveWhiteList}
							checked={!haveWhiteList}
						/>
						Все
					</label>
					
					<label className={'mt-1 cursor-pointer text-capitalize font-size-base font-weight-bolder'}>
						
						<Radio
							disabled={isBlock}
							name='whiteList'
							onChange={toggleHaveWhiteList}
							checked={haveWhiteList}
						/>
						Индивидуальный
					</label>
				</div>
				
				{haveWhiteList
					? <>
						{!isBlock &&
						<SearchList
							isBlock={isBlock}
							isFoundType={true}
							className={'mr-2'}
							onCheck={onCheck}
							list={preparedFoundUsers}
							loading={!foundUsers.isReady}
							isLastItemVisible={isLastItemVisible}
							setDefaultChecked={contact => contact.checked}
							skipInView={!foundUsers.isReady || lastSearchPage}
						>
							<Head secondary noMargins as={'h3'}>Все пользователи:</Head>
							<SearchInput placeholder={'Поиск по всем пользователям'}
							             onChange={e => setAllUsersSearchQuery(e.target.value)}/>
						</SearchList>
						}
						
						<SearchList
							isBlock={isBlock}
							list={withSearch(whiteListState)}
							onCheck={onCheck}
							setDefaultChecked={contact => whiteListState.some(cont => +cont.id === +contact.id)}
							isLastItemVisible={isLastItemVisible}
						>
							<Head secondary noMargins as={'h3'}>Контакт будет доступен:</Head>
							<SearchInput placeholder={'Поиск по всем пользователям'}
							             onChange={e => setWhiteListSearchQuery(e.target.value)}/>
						</SearchList>
					</>
					:
					<div className={'height-400'}/>
				}
			</div>
		</Card>
	)
}