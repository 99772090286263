import {toggle} from '../../js-helpers/helpers';
import {UserLogsIcon} from './icons/UserLogsIcon';
import React, {useEffect, useState} from 'react';
import {useImmer} from 'use-immer';

export function UserLogsNav({ 
  urlService, initShowNavs, userLogsOptions, baseLogsOptions, onClickNavButton, appsOptions
}) {
	const [showedGroupServices, setShowedGroupServices] = useImmer(initShowNavs);
	
	const [showedUserLogsOptions, setShowedUserLogsOptions] = useState(
		appsOptions.find((app) => app.service === urlService)
	);
	
	useEffect(() => {
		setShowedUserLogsOptions(
			appsOptions.find((app) => app.service === urlService)
		);
	}, [urlService]);
	
	function toggleShowNav(groupName) {
		setShowedGroupServices((draft) => {
			draft[groupName] = !draft[groupName];
		});
	}
	
	return (
		<ul className={'list-unstyled list-gap-025r'}>
			{userLogsOptions.map(({head, groupName, icon, options}) => {
				return (
					<li
						key={head}
						className={`dropdown rounded ${toggle(showedUserLogsOptions, 'show bg-transparent-60')}`}
					>
						<button
							className={
								'btn px-1 btn-block text-left font-14px dropdown-toggle text-gray-500'
							}
							onClick={() => {
								toggleShowNav(groupName);
								setShowedUserLogsOptions(!showedUserLogsOptions);
							}}
						>
							
							<UserLogsIcon type={icon} className={'mr-75'}/>
							
							{head}
						</button>
						
						{
							showedUserLogsOptions && (
								<ul className={'list-group list-unstyled'}>
									{options.map(({service, text}) => (
										<li
											key={service}
											className={'text-gray-500 c-hover-opacity'}
										>
											<button
												onClick={() => onClickNavButton(service)}
												className={`btn btn-block text-left  ${toggle(
													urlService === service,
													'bg-white text-body',
													'text-gray-500'
												)}`}
												style={{paddingLeft: '3.5rem'}}
											>
												{text}
											</button>
										</li>
									))}
								</ul>
							)
						}
					</li>
				);
			})}
			
			{baseLogsOptions.map(({text, service, disabled, icon}) => (
				<li key={service}>
					<button
						disabled={disabled}
						className={`btn px-1 btn-block text-left font-14px c-hover-opacity ${toggle(
							urlService === service,
							'bg-white text-body',
							'text-gray-500'
						)}`}
						onClick={() => {
							onClickNavButton(service);
							setShowedUserLogsOptions(false);
						}}
					>
						<UserLogsIcon type={icon}/>
						
						<span className={'ml-75 align-middle'}>{text}</span>
					</button>
				</li>
			))}
		</ul>
	);
}
