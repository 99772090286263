import React from 'react';
import ReactDOM from 'react-dom';

// import './assets/fonts/font-rubik.css'
import 'react-toastify/dist/ReactToastify.css';
// import './vendors/frest/app-assets/vendors/css/boxicons/css/boxicons.css'
import './styles/index.scss'
// import './styles/custom-styles-frest/style.scss';

// import 'bootstrap/dist/js/bootstrap.bundle.min';

import {BrowserRouter} from 'react-router-dom';
import {App} from './App';
import './services/axios/axiosConfig'
import reportWebVitals from './reportWebVitals';
import {KeycloakProvider} from './context/KeycloakProvider';

ReactDOM.render(
	<React.StrictMode>
		<KeycloakProvider>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</KeycloakProvider>
	</React.StrictMode>
	,document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
