import { adminSetUpAPI } from "../../../API/adminSetUpAPI";
import { adminSmassAPI } from "../../../API/adminSmassAPI";
import { contactsAPI } from "../../../API/contactsAPI";
import { deviceAPI } from "../../../API/deviceAPI";
import { divisionsAPI } from "../../../API/divisionsAPI";
import { launchersAPI } from "../../../API/launchersAPI";
import { userAPI } from "../../../API/userAPI";
import { channelsNamesForLaunchers } from "../../../constants/constants";
import { getFullLauncherVersion, getFullNameFromObj } from "../../../js-helpers/helpers";

// const prepareDevices = (items) =>
//   items.map(({ brand_and_model, android_id }) => ({
//     text: brand_and_model,
//     extraText: `(id:${android_id})`,
//     value: android_id,
//   }));

export const prepareDevicesSerialNumber = (items) =>
	items.map(({serial_number}) => ({
		text: serial_number,
		value: serial_number,
	}));


export const prepareUsers = (items) =>
	items.map(({account}) => ({
		text: `${getFullNameFromObj(account)}`,
		extraText: `(id:${account.id})`,
		value: account.id,
	}));

export const prepareUsersWithKeycloakID = (items) =>
	items.map(({account}) => ({
		text: `${getFullNameFromObj(account)}`,
		// extraText: `(ID: ${account.keycloak_id?.slice(0, 4) + '...'})`,
		value: account.keycloak_id,
	}));

export const prepareSetupAdmins = (items) =>
	items.map(({account}) => ({
		text: `${getFullNameFromObj(account)}`,
		// extraText: `(ID: ${account.keycloak_id?.slice(0, 4) + '...'})`,
		value: account.keycloak_id,
	}));

export const prepareRulesItems = (items) =>
	items.map(({name, id}) => ({text: name, value: id}));

export const prepareFullDataRules = (items) =>
	items.map((item) => ({text: item.name, value: item}));

export const prepareDivisions = (items) =>
	items.map(({name, id}) => name || name?.trim()?.length !== 0 ? {text: name, value: String(id)} : {text: 'Без дивизиона', value: 'Без дивизиона'});

export const prepareSubdivisions = (items) =>
	items.map((name) => ({text: name, value: name}));

export const prepareVersions = (items) =>
	items.map(({version_code, version_name}) => ({
		text: getFullLauncherVersion(version_name, version_code),
		value: version_name,
	}));

export const prepareBrands = (items) =>
	items.map((item) => ({text: item, value: item}));

export const prepareChannels = (items) => 
  items.map((item) => ({text: channelsNamesForLaunchers?.[item.name] || item.name, value: item.name}))

export const selectOptionsOnType = {
	// devises: {
	//   apiMethod: deviceAPI.getAllFreeDevices,
	//   prepareItems: prepareDevices,
	//   findHolder: 'Поиск по свободным устройствам...',
	// },
	allDevices: {
		apiMethod: deviceAPI.getDevices,
		prepareItems: prepareDevicesSerialNumber,
		findHolder: 'Поиск по устройствам...',
	},
	usersWithoutDevice: {
		apiMethod: userAPI.getAllWithoutDevice,
		prepareItems: prepareUsers,
		findHolder: 'Поиск по Пользователям без устройства...',
	},
	usersWithCalls: {
		apiMethod: userAPI.getAllWithCalls,
		prepareItems: prepareUsers,
		findHolder: 'Поиск по Пользователям со звонками...',
	},
	users: {
		apiMethod: userAPI.getAllUsers,
		prepareItems: prepareUsers,
		findHolder: 'Поиск по Пользователям...',
	},
	usersWithKeycloakID: {
		apiMethod: (items) => userAPI.getAllUsers({...items}, {allowMultipleRequests: true}),
		prepareItems: prepareUsersWithKeycloakID,
		findHolder: 'Поиск по Пользователям...',
	},
	allSetupAdmins: {
		apiMethod: (items) => adminSetUpAPI.searchAdmins({...items}, {allowMultipleRequests: true}),
		prepareItems: prepareSetupAdmins,
		findHolder: 'Поиск по Администраторам настройки...',
	},
  allSmassAdmins: {
    apiMethod: (items) => adminSmassAPI.searchAdmins({...items}, {allowMultipleRequests: true}),
    prepareItems: prepareSetupAdmins,
    findHolder: 'Поиск по Администраторам...',
  },
	rules: {
		apiMethod: contactsAPI.getAllRules,
		prepareItems: prepareRulesItems,
		findHolder: 'Поиск по Правилам...',
	},
	rulesWithFullData: {
		apiMethod: contactsAPI.getAllRules,
		prepareItems: prepareFullDataRules,
		findHolder: 'Поиск по Правилам...',
	},
	subdivisions: {
		apiMethod: (items) => userAPI.getSubdivisions({...items}),
		prepareItems: prepareSubdivisions,
		findHolder: 'Поиск по Подразделениям...',
	},
	divisions: {
		apiMethod: divisionsAPI.getAll,
		prepareItems: prepareDivisions,
		findHolder: 'Поиск по Дивизионам...',
	},
  divisionsWithUsers: {
		apiMethod: (items) => divisionsAPI.getAll({...items, have_accounts: true}, {allowMultipleRequests: true}),
		prepareItems: prepareDivisions,
		findHolder: 'Поиск по Дивизионам...',
	},
	launcherVersions: {
		apiMethod: userAPI.getLauncherVersions,
		prepareItems: prepareVersions,
		findHolder: 'Поиск по Версиям прошивки...',
	},
	allBrands: {
		apiMethod: deviceAPI.getDevicesBrands,
		prepareItems: prepareBrands,
		findHolder: 'Поиск по Брендам...',
	},
	allModels: {
		apiMethod: deviceAPI.getDevicesModels,
		prepareItems: prepareBrands,
		findHolder: 'Поиск по Моделям...',
	},
  channels: {
    apiMethod: launchersAPI.getAllChannels,
    prepareItems: prepareChannels,
    findHolder: 'Поиск по Каналам...',
  }
};