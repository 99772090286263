import { useFetchStates } from '../../../hooks/useFetchStates';
import { logsAPI } from '../../../API/logsAPI';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { getLocaleDate, getLocaleTime, loader } from '../../../js-helpers/helpers';
import { CrashLogFields } from '../logFields/CrashLogFields';
import React, { useEffect } from 'react';
import { Card } from '../../../components/UI/Card';

export function PageCrashlyticsInfo() {
  const { eventID } = useParams();
  const [searchParams] = useSearchParams();

  const setPageHead = useOutletContext();

  const { data, isReady } = useFetchStates(
    () => logsAPI.getCrashEvent(eventID, searchParams.get('app')),
    { initData: {} }
  );

  const { issue_title, issue_subtitle, event_date, event_id = '-' } = data;

  useEffect(() => {
    setPageHead({ head:`Crash ID: ${eventID}`})
  }, [])

  return (
      <Card
        className={`accordion-wrapper pb-50 ${loader(isReady)}`}
        size={0}
        secondary
      >
        {isReady && (
          <>
            <div className={'d-flex justify-content-between px-1 pt-50'}>
              <div className='text-break mr-2'>
                <h2 className={'font-weight-bolder'}>{issue_title}</h2>
                {issue_subtitle}
              </div>

              <div className={'d-flex align-items-center'}>
                {getLocaleDate(event_date)} <br /> {getLocaleTime(event_date)}
              </div>
            </div>

            <div className={'accordion-wrapper__content-wrapper'}>
              <section
                className={'accordion-wrapper__content-body p-0'}
                style={{ maxHeight: '670px' }}
              >
                <CrashLogFields log={data} />
              </section>
            </div>
          </>
        )}
      </Card>
  );
}
