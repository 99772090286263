import React, {useEffect, useMemo} from 'react';
import {userAPI} from '../../../API/userAPI';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {getBoolFromString, getFlatObject, isEmpty, loader, toggle} from '../../../js-helpers/helpers';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {Head} from '../../../components/UI/Head';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {useUpdateEffect} from '@react-hookz/web/esnext';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {useExportData} from '../../../services/export/useExportData';
import {pageUsersConfig} from './pageUsersTables';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {FiltersPageUsers} from './FilterPageUsers/FiltersPageUsers';
import {observer} from 'mobx-react-lite';
import {exportExcel} from './pageUserFunctions';

const {searchParams, getTable} = pageUsersConfig
export const prepareItem = (user) => getFlatObject(user, {toFlatFields: ['account', 'device']})


export const PageUsers = observer(() => {
	const [
		[urlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlIsExportMode],
		[urlDivision],
		[urlSubDivision],
		[urlLauncherVer],
		[urlBlocked],
		[urlHaveDevice],
    [urlOnline]
	] = useComboSearch(searchParams)
	
	const exportRequestQuery = {
		search: urlSearchQuery,
		account__division__in: urlDivision?.split(',')?.filter((div) => div !== 'Без дивизиона')?.join(','),
		account__subdivision__in: urlSubDivision,
		device2__launcher_version_name__in: urlLauncherVer,
		account__is_active: getBoolFromString(urlBlocked, {invert: true}),
		// have_device_v1_or_v2: getBoolFromString(urlHaveDevice),
		ever_have_device: getBoolFromString(urlHaveDevice), //|| true,
    login_status: urlOnline,
    account__division__isnull: urlDivision ? urlDivision?.includes('Без дивизиона') : undefined
	}
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		...exportRequestQuery
	}
	
	const {data: users, isReady, getData} = useFetchStates(
		(_page = urlPage) => userAPI.getAllUsersWithCustomParams({...requestQuery, page: _page,}),
		{noInitFetch: true, initData: {results: []}})
	
	const prepareUsers = useMemo(() => users.results.map(prepareItem),
		[users])
	
	const onSaveExport = (exportData) => {
		exportExcel(exportData)
		exportStates.clearIncludedIDS()
	}
	
	const {
		ButtonToggleExport,
		CounterToExport,
		ButtonSaveExport,
		checkBoxesColl,
		...exportStates
	} = useExportData({
		requestMethod: userAPI.getAllUsersWithCustomParams,
		requestParams: exportRequestQuery,
		prepareItemFunc: prepareItem,
		pageData: prepareUsers,
		onSaveExport
	})
	
	useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	// console.log({urlBlocked, urlHaveDevice})
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1)
	}, [
		urlSearchQuery, urlLimit, urlDivision,
		urlSubDivision, urlLauncherVer, urlHaveDevice,
		urlBlocked, urlOnline
	])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const tableOptions = useMemo(() =>
			getTable(isEmptySearchQuery, urlIsExportMode, checkBoxesColl),
		[isEmptySearchQuery, urlIsExportMode])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)
	const title = urlIsExportMode ? 'Экспорт Пользователей' : 'Пользователи'
	
	return (
		<>
			{!urlIsExportMode && <ButtonGoBack className={'float-right ml-1'}/>}
			<ButtonToggleExport className={'float-right'}/>
			
			<Head children={title}/>
			
			<FiltersPageUsers/>
			
			<CounterToExport/>
			
			<MyTable colgroup={tableColgroup}
			         renderCardContainer={urlIsExportMode}
			         withScroll
			         appendElement={<ButtonSaveExport/>}
			         showEmptyFoundDataMessage={users?.count === 0 && isReady}
			         isDataReady={isReady}
			         paginationAllCount={users?.count}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(isReady)}
				             data={prepareUsers}
				             getRowClassName={(row) => toggle(row?.is_active === false, 'opacity-c-50')}
				             tableOptions={tableOptions}
				             highestRowToFill={45}
				/>
			</MyTable>
		</>
	)
})