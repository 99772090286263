import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';


export const adminSetUpAPI = {
	getInfo: (keycloakID) => axios(`/admins/device-setup/${keycloakID}/`),
	
	// getAll: (page = 1) => axios('/admins/device-setup/', {...prepareAxiosParams({page})}),
	
	searchAdmins({page, search, ordering, page_size}, config = {}) {
		return axios('/admins/device-setup/', {...setPreparedAxiosParams(arguments[0]), ...config})
	},
	
	// getLoggedNow: () => axios('/admins/device-setup/current/'),
}

