import {getFullNameFromObj} from '../../../../../js-helpers/helpers';
import { logsOptions } from '../../../../../constants/logsOptions';

const getExtraAccountFields = (fields) => {
	const value = fields.is_admin ? 'Администратор, Пользователь' : 'Пользователь';
	return {field: 'Профили',	value}
}

export const modelsOptions = {
	'account': {
		modelName: 'Аккаунт',
		crumbs: ({fields: user}) => [{
			name: 'Пользователи', link: '/admins/users/'
		}, {
			name: getFullNameFromObj(user), link: `/admins/users/${user.keycloak_id}/info/`
		}],
		getExtraFields: getExtraAccountFields
	},
	'admin': {
		modelName: 'Аккаунт',
		crumbs: ({fields}) => [{
			name: 'Администраторы', link: '/admins/'
		}, {
			name: getFullNameFromObj(fields), link: `/admins/${fields.id}/`
		}],
		getExtraFields: getExtraAccountFields
	},
	'contactv2': {
		modelName: 'Контакт',
		crumbs: ({fields: user}) => ([{
				name: 'Контакты', link: '/admins/users/'
			}, {
				name: getFullNameFromObj(user), link: `/admins/users/${user.keycloak_id}/info/`
			}]
		)
	},
	'call': {
		modelName: 'Звонок',
		crumbs: (item) => [{
			name: 'Звонки', link: `/admins/users/${item.fields.user.keycloak_id}/calls/`
		}]
	},
	'log': {
		modelName: 'Лог',
		crumbs: (model, keycloakID) => {
			const logOptions = logsOptions.find(log => log.globalSearchModel === model)

			return ([{
					name: 'Логи', link: `/admins/users/${keycloakID}/logs/`
				}, {
					name: logOptions.text,
					link: {
						pathname: `/admins/users/${keycloakID}/logs/`,
						search: `?service=${logOptions.service}`
					}
				}]
			)
		}
	},
	'devicev2': {
		modelName: 'Устройство',
		crumbs: ({fields: {serial_number, brand, model}}) => ([{
				name: 'Устройства', link: '/admins/devices/'
			}, {
				name: `${brand} ${model}`, link: `/admins/devices/${serial_number}/info/`
			}]
		)
	},
	// 'sms': {
	// 	modelName: 'Сообщение SMS',
	// 	crumbs: ({fields: {user, phone_number}}) => ([{
	// 			name: 'Сообщения SMS', link: `/admins/users/${user.keycloak_id}/messages/`
	// 		}, {
	// 			name: phone_number,
	// 			link: {
	// 				pathname: `/admins/users/${user.keycloak_id}/messages/`,
	// 				search: `?service=sms&with=${encodeURIComponent(phone_number)}`
	// 			}
	// 		}]
	// 	)
	// },
	'contactvisibilityrule': {
		modelName: 'Правило видимости контакта',
		crumbs: ({fields}) => ([{
				name: 'Правила видимости', link: '/admins/internal-contacts/rules/'
			}, {
				name: fields.name, link: `/admins/internal-contacts/rules/${fields.id}/`
			}]
		)
	},
	
	'app': {
		modelName: 'Приложение',
		// crumbs: ({fields}) => ([{
		// 		name: 'Правила видимости', link: '/admins/internal-contacts/rules/'
		// 	}, {
		// 		name: fields.name, link: `/admins/internal-contacts/rules/${fields.id}/`
		// 	}]
		// )
	},
	
	'division': {
		modelName: 'Дивизион',
	},
}
