import { ReactComponent as Notifications } from './icons/notifications.svg';
import { ReactComponent as Rules } from './icons/rules.svg';
import PropTypes from 'prop-types';

const icons = {
  notifications: Notifications,
  rules: Rules,
};

export const NotificationsIcon = ({type, className=''}) => {
  const Icon = icons[type];
  return <Icon className={`svg-curr-color svg-service-size ${className}`} />;
}

NotificationsIcon.propTypes = {
  type: PropTypes.oneOf([
    'notifications',
    'rules',
  ]),
};
