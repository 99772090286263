import {Card} from '../../../../components/UI/Card';
import {loader, toggle} from '../../../../js-helpers/helpers';
import React from 'react';
import {MySelect} from '../../../../components/form/MySelect/MySelect';
import {MySwitch} from '../../../../components/form/MySwitch';
import {MyNewInput} from '../../../../components/form/MyNewInput';
import {updateChanelOptions} from '../../../../constants/constants';

const getInit = (value) => {
	if (!value) value = 1
	return updateChanelOptions.find(channel => channel.value === value)
}

export const UserEditSystemInfoCard =
	({
		 userInfoReady,
		 isDevelopMode,
		 updateChanel,
		 setUserState,
		 keycloakID,
		 disabled
	 }) => {
		
		const onDevelopModeChange = ({target}) => {
			setUserState(draft => {
				draft.is_develop_mode = target.checked
			})
		}
		
		const onUpdateChanelSelect = ({value}) => {
			setUserState(draft => {
				draft.launcher_channel = value
			})
		}
		
		return (
			<Card secondary title={'Системная информация'}
			      className={loader(userInfoReady)}>
				
				<ul className={'list-unstyled row'}>
					<li className={'col'}>
						
						<MyNewInput label={'ID пользователя в keycloak'} value={keycloakID || '-'} disabled/>
					</li>
					
					<li className={'col'}>
						<label>Отладка</label>
						<label className={'d-flex justify-content-between align-items-center' +
						' zindex-0 text-c-transform-none cursor-pointer border-primary' +
						' bg-white form-control ' + toggle(disabled, ' disabled border-lighten-5')}
						>
							Режим разработчика
							<MySwitch id={'developer-mode'}
							          disabled={disabled}
							          checked={isDevelopMode}
							          onChange={onDevelopModeChange}/>
						</label>
					</li>
					
					<li className={'col'}>
						<label>Канал обновления лаунчера</label>
						
						<MySelect
							disabled={disabled}
							init={userInfoReady}
							onSelect={onUpdateChanelSelect}
							optionsArr={updateChanelOptions}
							initialSelectedItem={getInit(updateChanel)}
						/>
					</li>
				</ul>
			</Card>
		)
	};