import {useCallback} from 'react';
import {useSafeState} from '@react-hookz/web';

export function useCountState(initCount) {
	const [count, setCount] = useSafeState(initCount || 0)
	
	const setNextCount = useCallback(() => setCount(page => page + 1), [])
	const setPrevCount = useCallback(() => setCount(page => page - 1), [])
	
	return {count, setCount, setNextCount, setPrevCount}
}