import React, { useEffect, useMemo, useState } from 'react';
import { ButtonGoBack } from '../../../components/button/ButtonGoBack';
import { SearchInput } from '../../../components/form/SearchInput';
import { Head } from '../../../components/UI/Head';
import { useComboSearch } from '../../../hooks/url/useComboSearch';
import { useFetchStates } from '../../../hooks/useFetchStates';
import { usePrevious, useUpdateEffect } from '@react-hookz/web';
import { getBoolFromString, isEmpty, loader } from '../../../js-helpers/helpers';
import { MyTable } from '../../../components/table/MyTable/MyTable';
import { MyTableHead } from '../../../components/table/MyTable/MyTableHead';
import { MyTableBody } from '../../../components/table/MyTable/MyTableBody';
import { useMyTable } from '../../../components/table/MyTable/useMyTable';
import { notificationsAPI } from '../../../API/notificationsAPI';
import { PageNotificationsNav } from './PageNotificationsNav';
import { historyNotificationsGroup, notificationsOptions} from './notificationsOptions';
import { exportExcel, pageNotificationsConfig, topButtons } from './PageNotificationsFunctions';
import { DeleteItemModal } from '../../../components/modals/DeleteItemModal';
import { toast } from 'react-toastify';
import { PageNotificationsFilters } from './pageNotificationsFilters/PageNotificationsFilters';
import { useExportData } from '../../../services/export/useExportData';
import {observer} from 'mobx-react-lite';
import { ADMIN_TYPE, USER_TYPE } from '../../../constants/constants';

const {pageSearchParams, getNotificationsTable} = pageNotificationsConfig

const PageNotifications = observer(() => {
  const [pageHead, setPageHead] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [deletingRule, setDeletingRule] = useState({});

  const [
    [urlSearchQuery, setUrlSearchQuery, clearUrlSearchQuery],
    [urlPage, setUrlPage],
    [urlSortField],
    [urlLimit],
    [urlType, setUrlType],
    [urlSender, setUrlSender],
    [urlReceiver, setUrlReceiver],
    [urlDateFrom],
    [urlDateTo],
    [urlIsExportMode, setUrlIsExportMode],
  ] = useComboSearch(pageSearchParams);

  const prevUrlType = usePrevious(urlType);

  const onClickNavButton = (groupName) => {
    setUrlType(groupName);
    clearUrlSearchQuery();
  };

  const initShowNavs = useMemo(
    () =>
      Object.fromEntries(
        historyNotificationsGroup.map(({ groupName, options }) => {
          const show = options.find((item) => item.type === urlType);
          return [groupName, show];
        })), []);

  const { data, getData, isReady } = useFetchStates(
    // eslint-disable-next-line max-len
    () => {
      if (urlType === ADMIN_TYPE) {
        return notificationsAPI.getAll(urlPage, urlSearchQuery, urlSortField, urlLimit);
      } else if (urlType === USER_TYPE) {
        return notificationsAPI.getP2PAll(urlPage, urlSearchQuery, urlSortField, urlLimit, urlSender, urlReceiver, urlDateFrom, urlDateTo);
      } else if (urlType === 'rules') {
        return notificationsAPI.getP2PAllRules(urlPage, urlSearchQuery, urlSortField, urlLimit);
      }
    },
    { noInitFetch: true }
  );

  useEffect(() => {
    getData();
  }, [urlPage, urlSortField]);

  useEffect(() => {
    const { text, pageTitle } = notificationsOptions?.find(
      (item) => item.type === urlType
    );
    const head = pageTitle || text
    setPageHead(urlIsExportMode ? `Экспорт - ${head}` : head);
  }, [urlType, urlIsExportMode]);

  useUpdateEffect(() => {
    if (urlPage !== 'last') {
      setUrlPage('last');
      return;
    }
    getData();
  }, [urlSearchQuery, urlLimit, urlType, urlSender, urlReceiver, urlDateFrom, urlDateTo]);


  const isEmptySearchQuery = isEmpty(urlSearchQuery);

  const deleteRule = (id, rule) => {
    setIsShowModal(true);
    setDeletingRule({ title: rule, id: id });
  };

  const onClickDeleteRule = async (id) => {
    try {
      await notificationsAPI.deleteP2PRule(id);
      await getData();
      toast.success(`Правило "${deletingRule.title}" удалено`);
    } catch (e) {
      toast.error('Ошибка удаления правила');
    } finally {
      closeModal();
    }
  };

  const closeModal = () => {
    setIsShowModal(false);
    setDeletingRule({});
  };

  const exportRequestQuery = useMemo(() => ({
    search: urlSearchQuery,
    user__account__keycloak_id__in: urlSender,
    notify_users__account__keycloak_id__in: urlReceiver,
    created_at__gte: urlDateFrom,
    created_at__lte: urlDateTo,
  }), [urlSearchQuery, urlSender, urlReceiver, urlDateFrom, urlDateTo])


  const onSaveExport = (exportData) => {
		exportExcel(exportData, urlType)
	}

  const onSaveExportAdmin = (exportData) => {
		exportExcel(exportData, ADMIN_TYPE)
	}

  const onSaveExportUser = (exportData) => {
		exportExcel(exportData, USER_TYPE)
	}

  const {
		ButtonToggleExport,
		CounterToExport,
		ButtonSaveExport,
		checkBoxesColl,
    clearIncludedIDS,
    ...exportStates
	} = useExportData({
		requestMethod: notificationsAPI.getAllToExport,
		requestParams: exportRequestQuery,
		pageData: data?.results,
		onSaveExport: onSaveExport
	})

  useEffect(() => {
    exportStates.resetStore()
    exportStates.setRequestParams(exportRequestQuery)
    if(urlType === ADMIN_TYPE) {
      exportStates.setRequestMethod(notificationsAPI.getAllToExport)
      exportStates.setOnSaveExport(onSaveExportAdmin)
    } else if (urlType === USER_TYPE) {
      exportStates.setRequestMethod(notificationsAPI.getP2PAllToExport)
      exportStates.setOnSaveExport(onSaveExportUser)
    }
  }, [urlType, exportRequestQuery, urlSearchQuery, urlSender, urlReceiver, urlDateFrom, urlDateTo])


  const tableOptions = useMemo(() => 
  getNotificationsTable(isEmptySearchQuery, urlType, getBoolFromString(urlIsExportMode), checkBoxesColl, deleteRule),
  [isEmptySearchQuery, urlType, urlIsExportMode])

  const {tableHeads, tableColgroup} = useMyTable(tableOptions)

  const showEmptyFoundDataMessage = useMemo(() => {
      return urlSearchQuery ||  urlDateFrom || urlDateTo || urlSender ||	urlReceiver 
    }, [urlSearchQuery, urlSender, urlReceiver, urlDateFrom, urlDateTo])


    useEffect(() => {
      if (prevUrlType && prevUrlType !== urlType) {
        setUrlSender('');
        setUrlReceiver('')
        setUrlIsExportMode('')
      }
    }, [urlType])

  return (
    <main className={`${loader(exportStates.isReady)} position-relative`}>
      <div className="d-flex mb-2">
        <Head children={pageHead} noMargins />
        <div className={'ml-auto d-flex'}>
          {topButtons(ButtonToggleExport)[urlType]}
          {!urlIsExportMode && <ButtonGoBack />}
        </div>
      </div>
      <div className={'d-flex gap-1r no-gutters'}>
        <PageNotificationsNav
          urlType={urlType}
          onClickNavButton={onClickNavButton}
          initShowNavs={initShowNavs}
        />
        <div className={'col overflow-hidden'}>

        {urlType === USER_TYPE ? 
          <PageNotificationsFilters prevUrlType={prevUrlType}/> :
          <SearchInput  withBorder
                        className={'mb-1'}
                        onChange={(e) => setUrlSearchQuery(e.target.value)}
                        placeholder={'Поиск...'}
                        defaultValue={urlSearchQuery}
                        resetWhen={!urlSearchQuery}
          />}

          <CounterToExport/>

          <MyTable  paginationAllCount={data?.count}
                    isDataReady={isReady}
                    showEmptyFoundDataMessage={!!showEmptyFoundDataMessage}
                    renderCardContainer={urlIsExportMode}
                    appendElement={<ButtonSaveExport/>}
                    withScroll
          >
            <MyTableHead heads={tableHeads} />

            <MyTableBody  className={loader(isReady)}
                          data={data?.results}
                          tableOptions={tableOptions}
                          highestRowToFill={39.0}
            />
          </MyTable>
        </div>
      </div>

      {isShowModal && (
        <DeleteItemModal  title={deletingRule.title}
                          onClickAbort={closeModal}
                          onClickDelete={() => onClickDeleteRule(deletingRule.id)}
                          titleText={'правило'}
        />
      )}
    </main>
  )
})

export default PageNotifications;
