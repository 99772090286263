import {useParams} from 'react-router-dom';
import React, {useMemo} from 'react';
import {ButtonGoBack} from '../../../components/button/ButtonGoBack';
import {contactTypes} from '../../members/pageUsers/PageUserContacts/PageUserContacts'; 
import {useFetchStates} from '../../../hooks/useFetchStates';
import {Card} from '../../../components/UI/Card';
import {MyAvatar} from '../../../components/UI/MyAvatar';
import {Head} from '../../../components/UI/Head';
import {contactsAPI} from '../../../API/contactsAPI';
import {loader} from '../../../js-helpers/helpers';
import {Columns} from '../../../components/UI/Columns';
import { prepareData } from './preparePageContactInfoFunction';


function PageInput({data, className = ''}) {
	const {label, value} = data
	
	return (
		<label className={className}>
			{label}
			<input
				disabled
				className={'form-control'}
				value={value || undefined}
				type='text'/>
		</label>
	)
}

export function PageContactInfo({avatar}) {
	const {keycloakID, contactID} = useParams()
	
	const {data: contactState, isReady} = useFetchStates(() =>
			contactsAPI.getContactInfo(keycloakID, contactID)
		, {initData: {}})
	
	const preparedData = useMemo(() =>
			isReady ? prepareData(contactState) : [],
		[isReady])
	
	const getContactFrom = () => {
		const notFolderText = contactTypes
			.find(cont => cont.type === contactState?.type)
			?.text.slice(0, -1)
		
		return notFolderText
			? `${notFolderText}й контакт`
			: ''
	}
	
	const {photo, last_name, first_name} = contactState

	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head title={'Информация о Контакте'}/>
			
			<Card className={loader(isReady)} title={getContactFrom()}>
				<Card secondary className={'mb-2'}>
					
					<MyAvatar
						size={120}
						className={'mr-2 float-left'}
						src={photo}
						alt={(last_name + first_name) || ''}
						{...avatar}
					/>
					
					<Columns as={'fieldset'} sumItems={preparedData.length}>
						
						{preparedData.map((data, ind) => (
							<PageInput
								className={'d-block'}
								key={data.label + ind}
								data={data}/>
						))}
					</Columns>
				</Card>
			</Card>
		</>
	)
}

