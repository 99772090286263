import { Link } from "react-router-dom"
import { getFullNameFromObj, getLocaleFullDate, getShortFullNameFromObj, toggle } from "../../../../js-helpers/helpers"
import { TextFeedbacks } from "../../../TextFeedbacks"
import { CellAttachmentsPhoto } from "../CellAttachmentsPhoto"

export const getFeedbacksTableOptions = (openFiles, isEmptySearchQuery) => [
	{
		head: 'ID',
		col: {width: 100},
		cell: 'id',
		sortField: 'id'
	}, {
		head: 'Дата и время',
		col: {width: 200},
		cell: (row) => getLocaleFullDate(row.date),
		sortField: 'date',
	}, {
		head: 'Автор запроса',
		col: {width: 225},
		cell: (user) =>
      user ? <Link className={'btn btn-link px-0 text-wrap text-left'} to={`/admins/users/${user?.keycloak_id}/info/`}>
				{isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user)}
			</Link> : '-',
		sortField: 'user__account__last_name',
		nestedCellField: 'user',
	}, {
		head: 'Текст запроса',
		col: {width: 350},
		cell: (text) => <TextFeedbacks head={text}/>,
		nestedCellField: 'text',
	}, {
		head: 'Версия прошивки',
		col: {width: 250},
		cell: ({launcher_version_name: _name, launcher_version_code: _code}) =>
			`${_name || '-'} ${_code ? `(${_code})` : ''}`,
	}, {
		// head: 'Устройство',
		// col: {width: 350},
		// cell: ({device_brand, device_model, device_system_version, device_api_level}) => {
		// 	const noInfo = !device_brand && !device_model &&
		// 		!device_system_version && !device_api_level
		// 	if (noInfo) return '-'
			
		// 	return <>
		// 		<div className={'font-weight-bold'}>
		// 			{`${device_brand || ''} ${device_model || ''}`}
		// 		</div>
		// 		{toggle(device_system_version, `Android ver. ${device_system_version},`)}
		// 		{toggle(device_api_level, ` API level: ${device_api_level}`)}
		// 	</>
		// },
    head: 'Устройство',
		col: {width: 350},
		cell: ({device}) => {
			return device ? <Link to={`/admins/devices/${device}/info/`}>{device}</Link> : '-'
		},
	}, {
		head: 'Прикрепленные файлы',
		col: {width: 275},
		cell: (attachments) =>
			<div className={'d-flex'}>
				<CellAttachmentsPhoto files={attachments} openSwiper={openFiles}/>
			</div>,
		nestedCellField: 'attachments',
	},
]