import React, {useEffect, useMemo} from 'react';
import {isEmpty, loader, toggle} from '../../../js-helpers/helpers';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {SearchInput} from '../../../components/form/SearchInput';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {useUpdateEffect} from '@react-hookz/web';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {urlParams} from '../../../constants/urlParams';
import {adminSmassAPI} from '../../../API/adminSmassAPI';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {Head} from '../../../components/UI/Head'
import {ButtonGoBack} from '../../../components/button/ButtonGoBack'
import { getAdminsTableOptions } from '../../../components/table/MyTable/options/columnsAdmins';


const {SEARCH, SORT, PAGE, LIMIT} = urlParams

const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: 'account__last_name'},
	{param: PAGE, init: 1, paramHandler: Number},
	{param: LIMIT}
]

export function PageAdmins() {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(pageSearchParams)
	
	const queryRequest = {
		search: urlSearchQuery,
		ordering: urlSortField,
		page_size: urlLimit
	}
	
	const {data, setData, isReady, getData, setReady} = useFetchStates(
		(_page = urlPage) => adminSmassAPI.searchAdmins({...queryRequest, page: _page}),
		{
			noInitFetch: true,
			customSetter: ({data}) => {
				const finalData = {
					count: data.count,
					results: data?.results,
				}
				setData(finalData)
				setReady(true)
			}
		})
	
	useEffect(() => {
		getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		getData(1)
	}, [urlSearchQuery, urlLimit])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const tableOptions = useMemo(() => getAdminsTableOptions(isEmptySearchQuery),
		[isEmptySearchQuery])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head>Администраторы</Head>
			
			<SearchInput
				withBorder
				className={'mb-1'}
				onChange={e => setUrlSearchQuery(e.target.value)}
				placeholder={'Поиск по имени, ID, подразделению...'}
				defaultValue={urlSearchQuery}
			/>
			
			<MyTable colgroup={tableColgroup}
			         showEmptyFoundDataMessage={!isEmptySearchQuery}
			         isDataReady={isReady}
			         paginationAllCount={data?.count}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(isReady)}
				             getRowClassName={(row) => toggle(row?.is_active === false, 'opacity-c-50')}
				             data={data?.results}
				             tableOptions={tableOptions}
				             highestRowToFill={45}/>
			
			</MyTable>
		</>
	)
}