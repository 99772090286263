import { Outlet, useParams } from 'react-router-dom';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { NavSubPages } from '../../../../components/UI/NavSubPages';
import { ButtonGoBack } from '../../../../components/button/ButtonGoBack';
import { Head } from '../../../../components/UI/Head';
import { adminPermissions } from '../../../../constants/adminPermissions';
import { loggedAdmin } from '../../../../stores/loggedAdmin';
import { observer } from 'mobx-react-lite';
import { getShortFullNameFromObj, isEmpty } from '../../../../js-helpers/helpers';
import { useFetchStates } from '../../../../hooks/useFetchStates';
import { userAPI } from '../../../../API/userAPI';
import { ModalDialog } from '../../../../components/modals/ModalDialog';
import { UserModalDialogBody } from './UserModalDialogBody';
import { toast } from 'react-toastify';

const {
  USERS_GENERAL,
  USERS_CALLS,
  // USERS_MESSAGES,
  USERS_LOGS,
  USERS_SCREENSHOTS,
  USERS_PHONEBOOK,
  USERS_SETTINGS,
  USERS_TRACKERS,
  USERS_NOTIFICATIONS
} = adminPermissions;

const links = [
  { to: 'info/', text: 'Общая информация', permission: USERS_GENERAL },
  { to: 'calls/', text: 'Звонки', permission: USERS_CALLS },
  // { to: 'messages/', text: 'Сообщения', permission: USERS_MESSAGES },
  { to: 'logs/', text: 'Логи', permission: USERS_LOGS },
  { to: 'media/', text: 'Скриншоты', permission: USERS_SCREENSHOTS },
  { to: 'phone-book/', text: 'Телефонная книга', permission: USERS_PHONEBOOK },
  { to: 'trackers/', text: 'Сбор данных', permission: USERS_TRACKERS },
  { to: 'p2p-notifications/', text: 'Уведомления', permission: USERS_NOTIFICATIONS },
  { to: 'settings/', text: 'Настройки', permission: USERS_SETTINGS },
];

export const UserNavLayout = observer(() => {
  const { keycloakID } = useParams();

  const [pageTitle, setPageTitle] = useState('Пользователь');
  const [withBackBtn] = useState(true);
  const [extraElem, setExtraElem] = useState(null);
  const [userIsHandled, setUserIsHandled] = useState(true)
  const [isShowModal, setIsShowModal]  = useState(false)

  const userStates = useFetchStates(() => userAPI.getUserInfo(keycloakID),
		{initData: {}})

  const isNewUser = useFetchStates(() => userAPI.getAllNew({account__keycloak_id: keycloakID}), 
  {initData: {}, noInitFetch: true})

  const setPageHead = useCallback(({ head, extraElem }) => {
    setPageTitle(head);
    setExtraElem(extraElem);
  }, []);

  const withPermLinks = links.filter(({ permission }) =>
    loggedAdmin.getIsResolved(permission)
  );
  const emptyLinks = isEmpty(withPermLinks);
  
  const contextValue = useMemo(() => ({setPageHead, userStates}), [userStates.data])
  
  useEffect(() => {
    userStates.getData()
    loggedAdmin.isSuperAdmin && isNewUser.getData()
  }, [keycloakID, loggedAdmin])

  useEffect(() => {
    setUserIsHandled(isNewUser?.data?.count === 0)
  }, [isNewUser.data])

  const handleUser = async () => {
    try {
      await userAPI.markAsViewed({user_id: keycloakID})
      toast.success('Карточка обработана!', {autoClose: 5000})
      setUserIsHandled(true)
    } catch (e) {
      toast.error(e?.response?.data?.errors?.[0]?.detail,  {autoClose: 5000})
    } finally {
      setIsShowModal(false)
    }
  }
  
  return (
    <>
      {withBackBtn && !emptyLinks &&
      <ButtonGoBack className={'float-right'} />}

      {loggedAdmin.isSuperAdmin && isNewUser.isReady && !userIsHandled && 
        <button onClick={() => setIsShowModal(true)}
                className={'btn btn-primary float-right mr-1'}>
          Обработан
        </button>}

      {extraElem && extraElem}

      {pageTitle &&
      <Head children={
        pageTitle + `${
          userStates?.isReady
             ? ` (${getShortFullNameFromObj(userStates.data.account)})`
             : ''
        }`}/>}

      <NavSubPages
        links={withPermLinks}
        replace
        className={'mb-1'}
        rootPage={`/admins/users/${keycloakID}/`}
      />

      <Outlet context={contextValue} />

      <ModalDialog isShow={isShowModal}
                   onCancelClick={() => setIsShowModal(false)}
                   onSuccessClick={handleUser}
                   body={<UserModalDialogBody/>}
                   className={'width-550 text-left'}/>
    </>
  );
});
