import {CheckBox} from '../../../../../../components/form/CheckBox';
import {Head} from '../../../../../../components/UI/Head';
import {getLocaleFullDate} from '../../../../../../js-helpers/helpers';

export const SubStepReview = ({subInstruction, completedSubSetting,}) => {
	return (
		<ul className={'list-unstyled m-2 list-gap-1-5r'}>
			
			{subInstruction.map((item) => {
				const {description, subitem, sequence_number, id} = item
				
				const date = completedSubSetting
					.find((item) => item.setting.id === id)?.created_at
				
				return (
					<li className={'bg-c-gray-50'} key={description}>
						<CheckBox className={'mr-1 float-left'}
						          white
						          readOnly
						          checked={!!date}/>
						
						<Head as={'h6'} noMargins className={'mb-50 font-weight-bold'}>
							{`Шаг ${sequence_number}.${subitem}`}
						</Head>
						
						{description}
						
						<p className={'text-muted font-small-2 mt-50'}>
							{getLocaleFullDate(date)}
						</p>
					</li>
				)
			})
			}
		</ul>
	)
}