import { MySwitch } from '../../../../../components/form/MySwitch';

export const SwitchersList = ({ list, togglePermission, permissions, className = '' }) => {
  const toggleSubPermission = (checked, permission, children) => {
    children.forEach((child) =>
      togglePermission(checked, child.subPermission)
     )
    togglePermission(checked, permission)
  }

  return (
    <ul className={`list-unstyled mb-0 ${className}`}>
      {list.map(({ label, permission, children }) => (
        <li key={permission}>
          <MySwitch
            id={permission}
            appendLabel={label}
            className={'py-50'}
            checked={permissions.includes(permission)}
            onChange={(e) => children ? toggleSubPermission(e.target.checked, permission, children) : togglePermission(e.target.checked, permission)}
          />
          {children && (
            <ul className={'list-unstyled ml-4'}>
              {children.map(({ label, subPermission }) => (
                <li key={subPermission}>
                  <MySwitch
                    id={subPermission}
                    appendLabel={label}
                    className={'py-50'}
                    checked={permissions.includes(subPermission)}
                    onChange={(e) => {
                      togglePermission(e.target.checked, subPermission);
                      if (!permissions.includes(permission)) {
                        togglePermission(true, permission)
                      }
                    }}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
