import {Head} from '../../../../components/UI/Head';
import {Link} from 'react-router-dom';
import {MyTableHead} from '../../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../../components/table/MyTable/MyTableBody';
import {MyTable} from '../../../../components/table/MyTable/MyTable';
import {isEmpty, loader} from '../../../../js-helpers/helpers';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import React, {useEffect, useMemo} from 'react';
import {contactsAPI} from '../../../../API/contactsAPI';
import {ButtonGoBack} from '../../../../components/button/ButtonGoBack';
import {useUpdateEffect} from '@react-hookz/web';
import {urlParams} from '../../../../constants/urlParams';
import {useComboSearch} from '../../../../hooks/url/useComboSearch';
import {SearchInput} from '../../../../components/form/SearchInput';
import {getRulesTableOptions} from '../../../../components/table/MyTable/options/columnsRules';

const {PAGE, LIMIT, SEARCH, SORT} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: 'creation_date'},
	{param: PAGE, init: 'last'},
	{param: LIMIT}
]

export const PageRules = () => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(pageSearchParams)
	
	const {data: dataState, getData, isReady} = useFetchStates(
		() => contactsAPI.getAllRules({
			page: urlPage,
			page_size: urlLimit,
			search: urlSearchQuery,
			ordering: urlSortField,
		}),
		{noInitFetch: true})
	
	useEffect(() => {
		getData()
	}, [urlPage])
	
	useUpdateEffect(() => {
		if (urlPage !== 'last') {
			setUrlPage('last')
			return
		}
		getData()
	}, [urlLimit, urlSearchQuery, urlSortField])
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	const tableOptions = useMemo(() => getRulesTableOptions(isEmptySearchQuery),
		[isEmptySearchQuery])
	
	return (
		<>
			<Head className={'d-inline-block'}>Управление правилами</Head>
			<ButtonGoBack className={'float-right ml-1'}/>
			
			<Link to={'/admins/internal-contacts/rules/new'}
			      className={'btn btn-primary px-3 float-right'}>
				Создать правило
			</Link>
			
			<SearchInput withBorder
			             className={'mb-1'}
			             onChange={e => setUrlSearchQuery(e.target.value)}
			             placeholder={'Поиск по названию, автору, дате создания...'}
			             defaultValue={urlSearchQuery}
			/>
			
			<MyTable  paginationAllCount={dataState?.count}
                showEmptyFoundDataMessage={!isEmptySearchQuery}>
				<colgroup>
					<col width={500}/>
					<col span={2}/>
					<col width={150}/>
				</colgroup>
				
				<MyTableHead heads={tableOptions.map(({head, sortField}) => ({head, sortField}))}/>
				
				<MyTableBody
					className={loader(isReady)}
					data={dataState?.results}
					tableOptions={tableOptions}
					highestRowToFill={40.5}/>
			</MyTable>
		</>
	)
}