import {NavLink} from 'react-router-dom';
import {isEmpty, toggle} from '../../js-helpers/helpers';


export const NavSubPages =
	({
		 links = [],
		 rootPage = '',
		 replace,
		 className = '',
		 onLinkClick
	 }) => {
		
		if (isEmpty(links)) return null;
		
		//выключает переход по ссылке при навигации с клавиатуры и активной ссылке
		function onKeyDown(e) {
			if (e.key === 'Enter' && e.target.classList.contains('active')) {
				e.preventDefault()
				e.stopPropagation()
			}
		}
		
		return (
			<nav className={`nav py-50 rounded bg-transparent-60 ${className}`}>
				{links.map(({to, text}) => (
					<NavLink className={({isActive}) => `nav-link ${toggle(isActive, 'active no-pointer-event')}`}
					         to={rootPage.concat(to)}
					         replace={replace}
					         onKeyDown={onKeyDown}
					         onClick={onLinkClick && (() => onLinkClick({to, text}))}
					         key={to}>
						
						{text}
						
					</NavLink>
				))}
			</nav>
		)
	}