import React, {useState} from 'react';
import {toggle} from '../../js-helpers/helpers';
import {useUpdateEffect} from '@react-hookz/web/esnext';
import {icons} from '../../assets/icons/icons';
import { Img } from './Img';


export function AvatarWithChange(
	{
		url,
		className = '',
		disabled,
		deletePhotoState,
		photoState,
		onAddPhoto,
		onDeletePhoto
	}) {
	if (deletePhotoState) url = null
	
	const src = photoState ? URL.createObjectURL(photoState) : (url || icons.userDefaultIcon)
	
	return (
		<div className={`width-150 text-center ${className}`}>
			<Img className={'rounded-circle mb-1 obj-c-cover'}
			     width={120} height={120}
			     src={src}
			     alt='Аватар'
			/>
			
			<label className={`btn btn-sm btn-block text-c-transform-none ${
				toggle(photoState, 'btn-primary', 'btn-light-primary')}
				${toggle(disabled, 'disabled')}`}>
				Добавить Фото
				<input
					type='file'
					disabled={disabled}
					accept='image/jpeg, image/png'
					onChange={onAddPhoto}
					className={'visually-hidden'}
				/>
			</label>
			
			<button
				onClick={onDeletePhoto}
				type={'button'}
				disabled={disabled}
				className={`btn btn-sm font-weight-bold mb-1 border-0 py-50 ${
					toggle(photoState, 'btn-light-primary bg-transparent', 'btn-outline-light')}`}
			>
				Удалить Фото
			</button>
			
			<div className={'font-small-1 text-muted text-center'}>
				JPG, PNG. Макс. размер файла 20mb
			</div>
		</div>
	)
}

/**
 * Хук для изменения аватара в AvatarWithChange
 * @param resetDeps {Array} Массив для ресета значений
 * @returns {{onDeletePhoto: onDeletePhoto, deletePhotoState: boolean, onAddPhoto: onAddPhoto, photoState: unknown}}
 */

export const useAvatarWithChange = (resetDeps = []) => {
	const [photoState, setPhotoState] = useState(null)
	const [deletePhotoState, setDeletePhotoState] = useState(false)
	
	useUpdateEffect(() => {
		setPhotoState(null)
		setDeletePhotoState(false)
	}, resetDeps)
	
	function onAddPhoto(ev) {
		setPhotoState(ev.target.files[0])
		setDeletePhotoState(false)
	}
	
	function onDeletePhoto() {
		setPhotoState(null)
		setDeletePhotoState(true)
	}
	
	return {photoState, deletePhotoState, onAddPhoto, onDeletePhoto}
}
