import { useImmer } from 'use-immer';
import { getFullNameFromObj, getIsoUtcDate, toggle } from '../../../js-helpers/helpers';
import { useFetchStates } from '../../../hooks/useFetchStates';
import { userAPI } from '../../../API/userAPI';
import { useMountEffect, useToggle, useUpdateEffect } from '@react-hookz/web';
import { SearchInput } from '../../../components/form/SearchInput';
import { urlParams } from '../../../constants/urlParams';
import { useComboSearch } from '../../../hooks/url/useComboSearch';
import React from 'react';
import dayjs from 'dayjs';
import { RangeDatePicker } from '../../../components/form/RangeDatePicker';
import { ButtonFilter } from '../../../components/button/ButtonFilter';
import { callTypes } from '../../../constants/constants';
import { MySearchMultiSelect } from '../../../components/form/MySelect/MySearchMultiSelect';
import { MyMultiSelect } from '../../../components/form/MySelect/MyMultiSelect';

const callTypeFilter = Object.entries(callTypes).map(([key, value]) => ({
  text: value[0],
  value: key,
}));

const { SEARCH, TYPE, USER, DEVICE, DATE_FROM, DATE_TO } = urlParams;
const pageSearchParams = [
  { param: SEARCH, debounce: 333 },
  { param: USER },
  { param: TYPE },
  { param: DEVICE },
  { param: DATE_FROM },
  { param: DATE_TO },
];

export const FiltersPageCallsHistory = () => {
  const [
    [urlSearchQuery, setUrlSearchQuery],
    [urlUser, setUrlUser],
    [urlType, setUrlType],
    [urlDevice, setUrlDevice],
    [urlDateFrom, setUrlDateFrom],
    [urlDateTo, setUrlDateTo],
  ] = useComboSearch(pageSearchParams);

  const [showFilters, toggleShowFilters] = useToggle(
    urlUser || urlType || urlDevice || urlDateFrom || urlDateTo
  );

  useUpdateEffect(() => {
    if (!showFilters) {
      setUrlUser('');
      setUrlType('');
      setUrlDateFrom('');
      setUrlDateTo('');
      setUrlDevice('');
      setInitFilters((draft) => {
        draft.type = null;
        draft.user = null;
        draft.device = null;
      });
    }
  }, [showFilters]);

  const [initFilters, setInitFilters] = useImmer({
    user: null,
    device: null,
    type: null,
  });

  const customSetter = ({data}) => {
    const text = data?.results?.map((item) => getFullNameFromObj(item?.account)?.trim()).join(',')

    const value = data?.results?.map((item) => item?.account?.keycloak_id).join(',')

		setInitFilters((draft) => {
			draft.user = {text, value};
		});

    initUserState.setReady(true);
  };

  const initUserState = useFetchStates(() => userAPI.getAllUsersWithCustomParams({account__keycloak_id__in: urlUser}), {
    noInitFetch: true,
    customSetter,
  });

  useMountEffect(() => {
    urlUser && initUserState.getData();
    urlDevice && setInitFilters((draft) => {
      draft.device = {
        text: urlDevice,
        value: urlDevice,
      };
    });
    if (urlType) {
      const text = urlType.split(',').map(item => callTypes?.[item]?.[0])

      setInitFilters((draft) => {
        draft.type = {
          text: text.join(','),
          value: urlType,
        };
      });
    }
  });

  const onTypeSelect = (items) => {
    if (!items) return;

    const resultOptions = items.map((option) => option.value)
    setUrlType(resultOptions);
  };

  const onUserSelect = (selectedOptions) => {
    if (!selectedOptions) return;

    const resultOptions = selectedOptions.map((option) => option.value)

    setUrlUser(resultOptions);

    initUserState.setReady(true);
  };

    
  const onDeviceSelect = (selectedOptions) => {
    if (!selectedOptions) return;

    const resultOptions = selectedOptions.map((option) => option.value)

    setUrlDevice(resultOptions)
  }

  return (
      <div
        className={
          'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
        }
      >
        <div className={'col-12 row no-gutters align-items-center'}>
          <SearchInput
            resetWhen={!urlSearchQuery}
            className={'col mr-1'}
            onChange={(e) => setUrlSearchQuery(e.target.value)}
            placeholder={'Поиск по пользователю, номеру, длительности ...'}
            defaultValue={urlSearchQuery}
          />

          <ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
        </div>

        {showFilters && (
          <div
            className={
              'row col-12 no-gutters justify-content-end align-items-center gap-1r'
            }
          >
            <MyMultiSelect
              initText={'Тип вызова'}
              resetWhen={!urlType}
              titleClassName={toggle(!urlType, 'text-muted')}
              onSelect={onTypeSelect}
              initialSelectedItems={initFilters.type}
              optionsArr={callTypeFilter}
              className={'col-2'}
              type={'call_type'}
              clearUrl={() => setUrlType('')}
            />

            <MySearchMultiSelect
              className={'col'}
              type={'usersWithKeycloakID'}
              init={urlUser && initUserState.isReady}
              initialSelectedItems={initFilters.user}
              resetWhen={!urlUser}
              onSelect={onUserSelect}
              initText={'Все пользователи'}
              clearUrl={() => setUrlUser('')}
              titleClassName={`${toggle(!urlUser, 'text-muted')}`}
            />

            <MySearchMultiSelect
              className={'col'}
              type={'allDevices'}
              init={urlDevice}
              initialSelectedItems={initFilters.device}
              resetWhen={!urlDevice}
              onSelect={onDeviceSelect}
              initText={'Все устройства'}
              clearUrl={() => setUrlDevice('')}
              titleClassName={`${toggle(!urlDevice, 'text-muted')}`}
            />
            
            <RangeDatePicker
              fromPickerProps={{
                value: urlDateFrom,
                onChange: ([date]) => {
                  setUrlDateFrom(date && getIsoUtcDate(date));
                },
                options: { maxDate: urlDateTo },
              }}
              toPickerProps={{
                value: urlDateTo,
                onChange: ([date]) => {
                  setUrlDateTo(
                    date && getIsoUtcDate(dayjs(date).second(59).toDate())
                  );
                },
                options: { minDate: urlDateFrom },
              }}
            />
          </div>
        )}
      </div>
  );
};
