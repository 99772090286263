export const contacts = {
	
	phone_number: {
		1: {type: 1, name: 'Мобильный'},
		2: {type: 2, name: 'Рабочий'},
		3: {type: 3, name: 'Домашний'}
	},
	
	email: {
		1: {type: 1, name: 'Личный'},
		2: {type: 2, name: 'Рабочий'}
	}
}