import React from 'react';
import {toggle} from '../../../../js-helpers/helpers';

export const MySelectContainer = ({containerProps, children}) => {
	const {isOpen, className = '', getRootProps = {}} = containerProps
	
	return (
		<div className={`dropdown ${toggle(isOpen, 'show')} ${className}`}
		     {...getRootProps}
		>
			{children}
		</div>
	)
}

