import {useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {toggle} from '../../../../../../js-helpers/helpers';
import {MyAvatar} from '../../../../../../components/UI/MyAvatar';


export const RowIconAppForm = ({item, url, control, name, iconRef = null}) => {
	const [iconName, setIconName] = useState(null)
	const [icon, setIcon] = useState(null)

	const {title, iconType} = item
	const {field} = useController({control, name})
	
	useEffect(() => {
		iconRef && setIconName(iconRef)
	}, [iconRef])
	
	function onIconChange(ev) {
		const {files} = ev.target
		
		if (files.length > 0) {
			setIconName(files[0].name)
			setIcon(files[0])
			field.onChange(files)
		}
	}
	
	const showIcon = icon || url
	
	return (
		<li className='row py-1 align-items-center border-top'>
			<h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase'}>
				{title}
			</h3>
			
			<label className={'cursor-pointer text-c-transform-none width-600 d-flex align-items-center'}>
				
				<label
					className={`btn text-c-transform-none btn-light-primary mr-2 ${toggle(iconName, 'bg-white')} mb-0`}
				>
					{iconName ? 'Изменить' : 'Добавить'}
					
					<input type='file'
					       required={!iconName}
					       disabled={false}
					       accept={`${iconType === 'icon_md' ? 'image/png' : 'image/jpeg, image/png'}`}
					       className={'visually-hidden'}
					       onChange={onIconChange}
					/>
				</label>
				
				{showIcon &&
				<MyAvatar src={icon ? URL.createObjectURL(icon) : url} className={'rounded-0'}/>
				}
				
				<span className={'align-self-center ml-1 width-400'} style={{wordWrap: 'break-word'}}>{iconName}</span>
			</label>
		</li>
	)
}