import {getFullNameFromObj, getShortFullNameFromObj, isEmpty, isNotEmpty, toggle} from '../../js-helpers/helpers';
import {AppWimg} from '../UI/AppWimg';
import {InView} from 'react-intersection-observer';
import {oneOf} from 'prop-types';
import {ReactComponent as ShowInfo} from '../../assets/icons/services/info_eye.svg'
import { ButtonIcon } from '../button/ButtonIcon';

const onInitElem = <span className={'text-primary font-small-2 ml-50'}>(Применен)</span>


export function ExceptionList(
	{
		type,
		items = [],
		itemClassName = '',
		delBtn,
		onClick,
		isActiveItem = () => false,
		autoPaginate = {},
		className = '',
		isDataReady,
    showInfo
	}) {
	
	const {skip, onChange} = autoPaginate
	
	return (
		<ul className={`list-group list-unstyled rounded-0 border-0 scroll-c overflow-auto bg-transparent ${className}`}>
			
			{isDataReady && isEmpty(items) &&
			<li className={'px-1 py-50 text-gray-300 text-center'}>Не найдено...</li>
			}
			
			{items.map((item) => { 
        const itemId = item.keycloak_id || item.id ? item.keycloak_id || item.id : item.serial_number
        return (
				<li className={
					`list-group-item d-flex align-items-center justify-content-between  ${
						toggle(!delBtn, 'cursor-pointer', 'py-50')} ${
						toggle(isActiveItem(itemId), 'bg-rgba-primary', 'bg-transparent')} ${itemClassName}`}
				    key={itemId}
				    onClick={() => !delBtn && onClick(item)}
				>
					{type === 'apps'
						?
						<AppWimg
							size={32}
							textClassName={'ml-3'}
							icon_url={item.icon}
							name={item?.isInit ? <>{item.name} {onInitElem}</> : item.name}
						/>
						: type === 'sites'
							?
							<div className={'width-90-per overflow-hidden'}>
								{item?.isInit ? <>{item.url} {onInitElem}</> : item.url}
							</div>
							: type === 'users'
								?
								<div className={'width-90-per overflow-hidden'}>
									{item?.isInit
										? <>{getFullNameFromObj(item)} {onInitElem}</>
										: getFullNameFromObj(item)
									}
								</div>
                : type === 'devices'
                  ?
                  <div className={'width-90-per overflow-hidden'}>
                    {item?.isInit
										? <>{item.serial_number} ({item.user ? getShortFullNameFromObj(item.user) : 'Нет пользователя'}) {onInitElem}</>
										: <>{item.serial_number} ({item.user ? getShortFullNameFromObj(item.user) : 'Нет пользователя'})</>
									  }
                  </div>
								  : type === 'groups'
                    ?
                    <div className={'d-flex justify-content-between w-100 text-break'}>
                      {item?.title} ({item?.users_count})
                      {!isActiveItem(itemId) 
                      ? <button type={'button'}
                                className={'btn p-0 ml-2 c-hover-opacity min-c-touch-zone'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showInfo(itemId, item?.title)
                                }}
                        >
                        <ShowInfo/>
                      </button> : <></>}
                    </div>
                    : null
					}
					
					{delBtn &&
            <ButtonIcon onClick={() => onClick(item)}
                        className={'ml-1'}/>
					}
				</li>
			)})
			}
			
			{isNotEmpty(autoPaginate) &&
			<InView as={'li'}
			        delay={333}
			        className={'py-25'}
			        skip={skip}
			        onChange={onChange}
			/>
			}
		</ul>
	)
}

ExceptionList.propTypes = {
	type: oneOf(['apps', 'sites', 'users', 'devices', 'groups']).isRequired,
}

