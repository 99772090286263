import {useOutletContext, useParams} from 'react-router-dom';
import React, {useEffect, useMemo} from 'react';
import {useUpdateEffect} from '@react-hookz/web';
import {useImmer} from 'use-immer';
import {toast} from 'react-toastify';
import {ReactComponent as CallsIcon} from './icons/calls.svg';
import {ReactComponent as MessengersIcon} from './icons/messengers.svg';
import {ReactComponent as IconScreenshots} from './icons/screenshot.svg';
import {UserTrackersCallsCard} from './parts/UserTrackersCallsCard';
import {UserTrackersMessengersCards} from './parts/UserTrackersMessengersCards/UserTrackersMessengersCards';
import {urlParams} from '../../../../constants/urlParams';
import {useComboSearch} from '../../../../hooks/url/useComboSearch';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {userAPI} from '../../../../API/userAPI';
import {ListServiceButtons} from '../../../../components/UI/ListServiceButtons';
import Screenshots from './parts/UserTrackersScreenshots';

const pageOptions = [
	{
		text: 'Звонки',
		service: 'calls',
		Icon: CallsIcon,
		MainComponent: UserTrackersCallsCard,
	}, {
		text: 'Мессенджеры',
		service: 'messengers',
		Icon: MessengersIcon,
		MainComponent: UserTrackersMessengersCards,
	}, {
		text: 'Скриншоты',
		service: 'screenshots',
		Icon: IconScreenshots,
		MainComponent: Screenshots,
	},
]

const pageSearchParams = [
	{param: urlParams.SERVICE, init: pageOptions[0].service},
]

export function PageUserTrackers() {
	const {keycloakID} = useParams()
	const [[urlService]] = useComboSearch(pageSearchParams);
	const {setPageHead, userStates} = useOutletContext();
	
	useEffect(() => {
		const head = pageOptions.find(({service}) => service === urlService).text
		setPageHead({head: `Сбор данных - ${head}`});
	}, [urlService])
	
	const allTrackersStates = useFetchStates(userAPI.getTrackers, {
		initData: {},
		noInitFetch: true,
	})
	
	const initUserStates = useFetchStates(() => userAPI.getUserInfo(keycloakID), {
		initData: {},
		noInitFetch: true,
	})
	// console.log({allTrackersStates})
	
	useEffect(() => {
		userStates.data && initUserStates.getData()
		userStates.data && allTrackersStates.getData()
	}, [userStates.data])
	
	const isReadyRequests = initUserStates.isReady && allTrackersStates.isReady;
	
	const [initUserTrackers, setInitUserTrackers] = useImmer({});
	
	useUpdateEffect(() => {
		isReadyRequests &&
		setInitUserTrackers((draft) => {
			allTrackersStates.data.forEach(({name}) => {
				draft[name] = initUserStates.data.trackers.includes(name)
			})
		})
	}, [isReadyRequests, initUserStates.data])
	
	const onSave = async (trackers) => {
		initUserStates.setReady(false)
		const mergedTrackers = {...initUserTrackers, ...trackers};
		
		const finalTrackers = Object.entries(mergedTrackers)
			.filter(([, checked]) => checked)
			.map(([track]) => track)
		
		try {
			const {data} = await userAPI.patchUser(keycloakID, {
				trackers: finalTrackers,
			});
			initUserStates.setData(data)
			toast.success('Изменения сохранены')
		} catch (e) {
			toast.error(e?.response?.data?.errors?.[0]?.detail)
		} finally {
			initUserStates.setReady(true)
		}
	}
	
	const {MainComponent} = useMemo(() =>
			pageOptions.find(({service}) => service === urlService),
		[urlService])
	
	return (
		<main className={'row gap-1r no-gutters'}>
			<ul className={'width-15-per list-gap-025r list-unstyled'}>
				<ListServiceButtons arrServices={pageOptions}/>
			</ul>
			
			<MainComponent onSave={onSave}
			               isReadyRequests={isReadyRequests}
			               initTrackers={initUserTrackers}
			/>
		</main>
	);
}
