import { Link } from "react-router-dom";
import { ALL_TYPE, DEVICE_TYPE, INSTALL_ERROR_NAMES, LOG_IN_OUT_REASONS, LOG_IN_OUT_STATUSES, USER_TYPE } from "../../../../constants/constants";
import { getAppName, getFullLauncherVersion, getFullNameFromObj, getLocaleFullDate, getShortFullNameFromObj, getShortString, getTimeDiff } from "../../../../js-helpers/helpers";
import { getElemText, WithTooltip } from "../../../UI/WithTooltip";

const appCol = {
  head: 'Приложение',
  cell: ({ app_name, app_package_name }) =>
    `${app_name}`.toLowerCase() !== 'unknown' && !!app_name ? app_name : app_package_name,
  col: { width: 220 }
};

const appVerCol = {
  head: 'Версия',
  cell: 'version',
  col: { width: 200 }
};

const dateCol = {
  head: 'Дата и время',
  cellClassName: 'py-2',
  cell: getLocaleFullDate,
  col: { width: 150 }
};

const appStatusCol = {
  head: 'Статус',
  cell: (is_success) => (is_success ? 'Успешно' : 'Ошибка'),
  nestedCellField: 'is_success',
  cellClassName: (data) => (data?.is_success ? 'text-success' : 'text-danger'),
};

const userCol = (isEmptySearchQuery) => ({
  head: 'Пользователь',
  cell: (user) =>
  user ? (
    <Link to={`/admins/users/${user?.keycloak_id}/info/`}>
      {isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user)}
    </Link>
  ) : (
    '-'
  ),
  col: { width: 200 }
}) 

const deviceCol = {
  head: 'Устройство',
  cell: (device) => device ? (
    <Link to={`/admins/devices/${device.serial_number}/info/`}>
       {device.serial_number}
    </Link>
  ) : (
    '-'
  ), 
  col: { width: 150 }
}

const serialNumberCol = {
  head: 'Устройство',
  cell: (serial_number) =>  serial_number ? (
    <Link to={`/admins/devices/${serial_number}/info/`}>
       {serial_number}
    </Link>
  ) : (
    '-'
  ), 
  nestedCellField: 'serial_number', 
}

const errorNameCol = (type) => ({
  head: 'Наименование ошибки',
  cell: (data) => data?.is_success ? '-' : data?.error_type ? INSTALL_ERROR_NAMES[String(data?.error_type)] : <Link to={`/admins/logs/error/${data.id}/?type=${type}`}>Необработанная ошибка</Link>,
  col: { width: 250 }
})

export const getLogsTableOptions = (isEmptySearchQuery, filter) => {
  const services = {
    'app-installs': [appCol, appVerCol, {...dateCol,   nestedCellField: 'date'}, appStatusCol, errorNameCol('app-installs')],
    'app-updates': [
      appCol,
      {
        head: 'Версии обновления',
        cell: ({ from_version, to_version }) => (
          <>
            <span className={'text-muted'}>
              {from_version}&#8194;&#8658;&#8194;
            </span>
            {to_version}
          </>
        ),
        col: { width: 400 }
      },
      {
        ...dateCol,   
        nestedCellField: 'date'
      },
      appStatusCol,
      errorNameCol('app-updates')
    ],
    'app-launch': [
      appCol,
      {
        head: 'Последнее использование',
        col: { width: 250 },
        cellClassName: 'py-2',
        cell: ({ launch_date }) => getLocaleFullDate(launch_date),
      },
    ],
    'login-logout': [ 
      {
        ...dateCol,   
        nestedCellField: 'date',
      },
      {
        head: 'Статус',
        cell: ({ type }) => {
          return LOG_IN_OUT_STATUSES[type];
        },
      },
      {
        head: 'Причина',
        cell: ({ reason }) => {  
          return LOG_IN_OUT_REASONS[reason];
          },
        },
    ],
    'app-notifications': [
      {
        ...dateCol,
        nestedCellField: 'date',
        col: { width: 200 },
      },
      {
        ...appCol,
        col: { width: 200 },
      },
      {
        head: 'От кого',
        cell: 'sender',
        col: { width: 200 },
      },
      {
        head: 'Текст уведомления',
        cell: ({ text }) => (
          <WithTooltip
            elem={getElemText(text, 65)}
            tooltips={text?.length > 65 ? [text] : []}
          />
        ),
        col: { width: 500 },
      },
    ],
    keyboards: [
      {
        ...dateCol,
        nestedCellField: 'date',
        col: { width: 200 },
      },
      {
        ...appCol,
        col: { width: 200 },
      },
      {
        head: 'Текст ввода',
        cell: ({ text }) => (
          <WithTooltip
            elem={getElemText(text, 75)}
            tooltips={text?.length > 75 ? [text] : []}
          />
        ),
      },
    ],
    clipboards: [
      {
        ...dateCol,
        nestedCellField: 'date',
        col: { width: 200 },
      },
      {
        head: 'Скопированный текст',
        cell: ({ text }) => (
          <WithTooltip
            elem={getElemText(text, 100)}
            tooltips={text?.length > 100 ? [text] : []}
          />
        ),
      },
    ],
    'device-shutdowns': [
      {
        head: 'Дата и Время отключения',
        col: { width: 200 },
        cell: ({ switch_off_date }) => getLocaleFullDate(switch_off_date),
        cellClassName: 'py-2',
      },
      {
        head: 'Период отключения',
        col: { width: 200 },
        cell: ({ switch_off_date, switch_on_date }) =>
          getTimeDiff(switch_off_date, switch_on_date),
      },
      {
        head: 'Дата и Время включения',
        col: { width: 200 },
        cell: ({ switch_on_date }) => getLocaleFullDate(switch_on_date),
      },
      {
        head: 'Заряд аккумулятора',
        col: { width: 150 },
        cell: ({ battery_charge }) => `${battery_charge}%`,
      },
    ],
    'launcher-updates': [
      {
        ...dateCol,
        nestedCellField: 'date',
        col: { width: 200 },
      },
      {
        head: 'Версии обновления',
        cell: ({ from_version, to_version }) => (
          <>
            <span className={'text-muted'}>
              {from_version}&#8194;&#8658;&#8194;
            </span>
            {to_version}
          </>
        ),
      },
      appStatusCol,
    ],
    crashes: [
      {
        head: 'Crash ID',
        cell: (event_id) =>
          // isEmptySearchQuery ? (
            <WithTooltip
              elem={getShortString(event_id, 7)}
              tooltips={[event_id]}
            />,
          // ) : (
          //   event_id
          // ),
        col: { width: 75 },
        nestedCellField: 'event_id',
      },
      {
        ...dateCol,
        col: { width: 165 },
        nestedCellField: 'event_date',
      },
      {
        head: 'Приложение',
        cell: ({ app }) => getAppName(app),
        col: { width: 200 },
      },
      {
        head: 'Наименование ошибки',
        cell: ({ issue_title, event_id, app }) => (
          <WithTooltip
              elem={<Link to={`/admins/logs/${event_id}/?app=${app}`}>{getShortString(getFullLauncherVersion(issue_title), 20)}</Link>}
              tooltips={[<Link to={`/admins/logs/${event_id}/?app=${app}`}>{issue_title}</Link>,]}
            />
        ),
        col: { width: 100 },
      },
      {
        head: 'Версия ОС',
        cell: 'system_version',
        col: { width: 75 },
      },
      {
        head: 'Версия Лаунчера',
        cell: ({ display_version, build_version }) => (
            <WithTooltip
              elem={getShortString(getFullLauncherVersion(display_version, build_version), 10)}
              tooltips={[getFullLauncherVersion(display_version, build_version),]}
            />),
        cellClassName: 'text-c-nowrap py-2',
        col: { width: 100 },
      },
    ]
  }

  if (filter === ALL_TYPE) {
    services['app-installs'].splice(3, 0, {...deviceCol, nestedCellField: 'device'}, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})

    services['app-updates'].splice(3, 0, {...deviceCol, nestedCellField: 'device'}, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})

    services['app-launch'].splice(3, 0, {...deviceCol, nestedCellField: 'device'}, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})

    services['login-logout'].splice(1, 0, {...deviceCol, nestedCellField: 'device'}, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})

    services['launcher-updates'].splice(1, 0, {...deviceCol, nestedCellField: 'device'}, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})

    services['device-shutdowns'].splice(0, 0, {...deviceCol, nestedCellField: 'device'}, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})

    services['crashes'].splice(1, 0, serialNumberCol, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
  }

  if (filter === USER_TYPE) {
    services['app-installs'].splice(3, 0, {...deviceCol, nestedCellField: 'device'})
    services['app-updates'].splice(3, 0, {...deviceCol, nestedCellField: 'device'})
    services['app-launch'].splice(3, 0, {...deviceCol, nestedCellField: 'device'})
    services['login-logout'].splice(1, 0, {...deviceCol, nestedCellField: 'device'})
    services['launcher-updates'].splice(1, 0, {...deviceCol, nestedCellField: 'device'})
    services['device-shutdowns'].splice(0, 0, {...deviceCol, nestedCellField: 'device'})
    services['crashes'].splice(1, 0, serialNumberCol)
  }

  if (filter === DEVICE_TYPE) {
    services['app-installs'].splice(3, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
    services['app-updates'].splice(3, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
    services['app-launch'].splice(3, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
    services['login-logout'].splice(1, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
    services['launcher-updates'].splice(1, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
    services['device-shutdowns'].splice(0, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
    services['crashes'].splice(1, 0, {...userCol(isEmptySearchQuery), nestedCellField: 'user'})
  }

  return services
};
