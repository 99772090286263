/* eslint-disable max-lines */
import React, {useEffect} from 'react';
import {useMountEffect, useToggle, useUpdateEffect,} from '@react-hookz/web/esnext';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {urlParams} from '../../../constants/urlParams';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {getFullNameFromObj, getIsoUtcDate, getLocaleFullDate, toggle,} from '../../../js-helpers/helpers';
import {useSearchParams} from 'react-router-dom';
import {SearchInput} from '../../../components/form/SearchInput';
import {useImmer} from 'use-immer';
import {userAPI} from '../../../API/userAPI';
import dayjs from 'dayjs';
import {MySelect} from '../../../components/form/MySelect/MySelect';
import {MySearchSelect} from '../../../components/form/MySelect/MySearchSelect';
import {RangeDatePicker} from '../../../components/form/RangeDatePicker';
import {mapToOptions} from './filtersFunctions';
import {ALL_TYPE, APPS, DEVICE_TYPE, USER_TYPE} from '../../../constants/constants';
import {FilterPills} from '../../../components/FilterPills/FilterPills';
import {ButtonFilter} from '../../../components/button/ButtonFilter';

const appFilter = mapToOptions(APPS)

const {	SEARCH,	DATE_FROM, DATE_TO,	SORT,	PAGE,	LIMIT, STATUS, USER, DEVICE,
	APP, DATE_OFF_FROM,	DATE_OFF_TO, DATE_ON_FROM, DATE_ON_TO,	TYPE} = urlParams;

export const pageSearchParams = [
	{param: STATUS},
	{param: TYPE},
	{param: USER},
	{param: DEVICE},
	{param: APP},
	{param: PAGE, init: 'last'},
	{param: SORT, init: 'event_date'},
	{param: LIMIT},
	{param: SEARCH, init: '', debounce: 333},
	{param: DATE_FROM},
	{param: DATE_TO},
	{param: DATE_OFF_FROM},
	{param: DATE_OFF_TO},
	{param: DATE_ON_FROM},
	{param: DATE_ON_TO},
];

const FiltersPageCrashlyticsList = ({	urlService,	filter,	prevSearchService}) => {
	const [
		[urlStatus, setUrlStatus],
		[urlType, setUrlType],
		[urlUser, setUrlUser],
		[urlDevice, setUrlDevice],
		[urlApp, setUrlApp],
		[urlPage],
		[urlSortField],
		[urlLimit],
		[urlSearchQuery, setUrlSearchQuery],
		[urlDateFrom, setUrlDateFrom],
		[urlDateTo, setUrlDateTo],
		[urlDateOffFrom, setUrlDateOffFrom],
		[urlDateOffTo, setUrlDateOffTo],
		[urlDateOnFrom, setUrlDateOnFrom],
		[urlDateOnTo, setUrlDateOnTo],
	] = useComboSearch(pageSearchParams);
	
	const [search, setSearch] = useSearchParams();
	
	const [showFilters, toggleShowFilters] = useToggle(
		urlUser || urlApp || urlDateFrom || urlDateTo || urlDevice
	);
	
	useUpdateEffect(() => {
		if (!showFilters) {
			setUrlUser('');
			setUrlDevice('');
			setUrlApp('');
			setUrlDateFrom('');
			setUrlDateTo('');
			setInitFilters((draft) => {
				draft.user = {text: '',	value: ''};
				draft.device = {text: '',	value: ''};
				draft.app = {text: '', value: ''};
			});
		}
	}, [showFilters]);
	
	useEffect(() => {
		if (prevSearchService && prevSearchService !== urlService) {
			toggleShowFilters(false)
			setUrlSearchQuery('')
		}
	}, [urlService])
	
	const [pillsOptions, setPillsOptions] = useImmer(() => [
		{
			urlKey: SEARCH,
			label: null,
		},
		{
			urlKey: USER,
			label: null,
		},
		{
			urlKey: DEVICE,
			label: null,
		},
		{
			urlKey: APP,
			label: APPS?.[urlApp],
		},
		{
			urlKey: DATE_FROM,
			label: (date) => `От  ${getLocaleFullDate(date)}`,
		},
		{
			urlKey: DATE_TO,
			label: (date) => `До  ${getLocaleFullDate(date)}`,
		},
	]);
	
	const [initFilters, setInitFilters] = useImmer({
		user: null,
		device: null,
		app: urlApp ? {text: APPS[urlApp], value: urlApp} : '',
	});
	
	const customSetter = ({data: {account}}) => {
		const text = getFullNameFromObj(account);

		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === USER).label = text;
		});
		
		setInitFilters((draft) => {
			draft.user = {
				text,
				value: account.keycloak_id,
			};
		});
		
		initUserState.setReady(true);
	};
	
	const initUserState = useFetchStates(() => userAPI.getUserInfo(urlUser), {
		noInitFetch: true,
		customSetter,
	});
	
	useMountEffect(() => {
		if (!prevSearchService || prevSearchService === urlService) {
			urlUser && initUserState.getData();
      urlDevice && setInitFilters((draft) => {
        draft.device = {
          text: urlDevice,
          value: urlDevice,
        };
      });
		}
	});
	
	
	const onAppSelect = (item) => {
		if (!item) return;
		
		const {text, value} = item;
		setUrlApp(value);
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === APP).label = text;
		});
	};
	
	const onDeviceSelect = (selectedOption) => {
		if (!selectedOption) return;
		
		const {text, value} = selectedOption;
		
		setUrlDevice(value);
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === DEVICE).label = text;
		});
	}
	
	const onUserSelect = (selectedOption) => {
		if (!selectedOption) return;
		const {text, value, extraText} = selectedOption;
		
		setUrlUser(value);
		
		setPillsOptions((draft) => {
			draft.find(
				(_pill) => _pill.urlKey === USER
			).label = text;
		});
		initUserState.setReady(true);
	};
	
	const onClosePill = (urlKey) => {
		search.delete(urlKey);
		setSearch(search.toString(), {replace: true});
	};
	
	return (
		<>
			<div
				className={
					'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
				}
			>
				<div className={'col-12 row no-gutters align-items-center'}>
					<SearchInput
						resetWhen={!urlSearchQuery}
						className={'col mr-1'}
						onChange={(e) => setUrlSearchQuery(e.target.value)}
						placeholder={'Поиск...'}
						defaultValue={urlSearchQuery}
					/>
					
					<ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
				</div>
				
				{showFilters && (
					<div
						className={
							'row col-12 no-gutters justify-content-end align-items-center gap-1r flex-nowrap'
						}
					>
						<MySelect
							initText={'Все приложения'}
							resetWhen={!urlApp}
							titleClassName={toggle(!urlApp, 'text-muted')}
							onSelect={onAppSelect}
							initialSelectedItem={initFilters.app}
							optionsArr={appFilter}
							className={'col-2'}
						/>
						
						{(filter === ALL_TYPE || filter === DEVICE_TYPE) && <MySearchSelect
							className={'col width-200'}
							type={'usersWithKeycloakID'}
							init={urlUser && initUserState.isReady}
							initialSelectedItem={initFilters.user}
							resetWhen={!urlUser}
							onSelect={onUserSelect}
							initText={'Все пользователи'}
							titleClassName={`${toggle(!urlUser, 'text-muted')}`}
						/>}
						
						{(filter === ALL_TYPE || filter === USER_TYPE) && <MySearchSelect
							className={'col width-200'}
							type={'allDevices'}
							init={urlDevice}
							initialSelectedItem={initFilters.device}
							resetWhen={!urlDevice}
							onSelect={onDeviceSelect}
							initText={'Все устройства'}
							titleClassName={`${toggle(!urlDevice, 'text-muted')}`}
						/>}
						
						<RangeDatePicker
							fromPickerProps={{
								value: urlDateFrom,
								onChange: ([date]) => {
									setUrlDateFrom(date && getIsoUtcDate(date));
								},
								options: {maxDate: urlDateTo},
								className: `${filter === ALL_TYPE ? 'width-169' : ''}`
							}}
							toPickerProps={{
								value: urlDateTo,
								onChange: ([date]) => {
									setUrlDateTo(
										date && getIsoUtcDate(dayjs(date).second(59).toDate())
									);
								},
								options: {minDate: urlDateFrom},
								className: `${filter === ALL_TYPE ? 'width-169' : ''}`
							}}
							className={`${filter === ALL_TYPE ? 'col-4' : 'col-6'} flex-nowrap`}
						/>
					</div>
				)}
			</div>
			
			<FilterPills pillsOptions={pillsOptions} search={search} onClosePill={onClosePill}/>
		</>
	);
};

export default FiltersPageCrashlyticsList;
