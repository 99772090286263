import React, {useState} from 'react'
import {Card} from '../../../../../components/UI/Card'
import {Controller} from 'react-hook-form'
import {Head} from '../../../../../components/UI/Head'
import {toggle} from '../../../../../js-helpers/helpers'
import {MySwitch} from '../../../../../components/form/MySwitch'
import {MySelect} from '../../../../../components/form/MySelect/MySelect'
import {RowIconAppForm} from './rowIconAppForm/RowIconAppForm'
import classNames from 'classnames';

const arrIconsForm = [
	{title: 'ИКОНКА НА САЙТЕ:', iconType: 'icon'},
	{title: 'ИКОНКА СВЕТЛАЯ ТЕМА:', iconType: 'icon_light'},
	{title: 'ИКОНКИ ТЕМНАЯ ТЕМА:', iconType: 'icon_dark'},
  {title: 'Иконки Material design:', iconType: 'icon_md'}
]

const selectOptions = [
	{value: false, text: 'Главный экран'},
	{value: true, text: 'Папка "Прочее"'}
]

const checkDefaultSelectOptions = (value, selectOptions) =>
	selectOptions.filter(item => item.value === value)[0]

export const FormAppEdit = ({control, data}) => {
	const [appType, setAppType] = useState(1)
	const isChoiceType = (!data && appType === 1) || data?.type === 1
	
	const getNameFile = (refFile) => refFile?.split('/').pop().split('?')[0]
	
	const initIsInOtherFolder = checkDefaultSelectOptions(data?.is_in_other_folder, selectOptions)
		|| selectOptions[0]
	
	return (
		<>
			<Head secondary uppercase>
				{data ? 'форма редактирования приложения' : 'форма добавления приложения'}
			</Head>
			
			<Card secondary as={'ul'} className={'list-unstyled'}>
				<li className='row align-items-center py-1'>
					<h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase'}>
						название приложения:
					</h3>
					
					<Controller name={'name'}
					            control={control}
					            defaultValue={''}
					            render={({field}) =>
						            <input className={'form-control col-6'}
						                   required={!data}
						                   {...field}
						            />
					            }
					/>
				</li>
				
				{!data &&
				<li className={'row align-items-center py-1 border-top'}>
					<h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase'}>
						тип приложения:
					</h3>
					
					<button className={`${toggle(appType === 2, 'text-muted')} btn px-0`}
					        onClick={() => setAppType(1)}
					        type={'button'}>
						Общее
					</button>
					
					<Controller
						name={'type'}
						control={control}
						defaultValue={1}
						render={({field}) =>
							<MySwitch id={'app-type'}
							          className={'mx-2'}
							          checked={appType === 2}
							          onChange={({target}) => {
								          field.onChange(target.checked ? 2 : 1)
								          setAppType(target.checked ? 2 : 1)
							          }}/>
						}
					/>
					
					<button className={`${toggle(appType === 1, 'text-muted')} btn px-0`}
					        onClick={() => setAppType(2)}
					        type={'button'}>
						Системное
					</button>
				</li>
				}
				
				{arrIconsForm.map((item, index) =>
					<RowIconAppForm key={index}
					                item={item}
					                url={data && data[item.iconType]}
					                control={control}
					                name={item.iconType}
					                iconRef={data && getNameFile(data[item.iconType])}
					/>
				)}
				
				{isChoiceType &&
				<li className={'row align-items-center py-1 border-top'}>
					<h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase'}>
						Видимость на мобильном устройстве:
					</h3>
					
					<Controller name={'is_hidden'}
					            control={control}
					            shouldUnregister
					            render={({field}) =>
						            <MySwitch
							            prependLabel={
								            <button className={classNames('btn px-0', {'text-muted': !field.value})}
								                    onClick={() => {field.onChange(true)}}
								                    type={'button'}>
									            Скрыто
								            </button>
							            }
							            appendLabel={
														<button
								            className={classNames('btn px-0', {'text-muted': field.value})}
								            onClick={() => {field.onChange(false)}}
								            type={'button'}>
								            Доступно
							            </button>
							            }
							            id={'is_hidden'}
							            className={'py-50'}
							            checked={!field.value}
							            onChange={(e) => field.onChange(!e.target.checked)}
						            />
					            }
					/>
				</li>
				}
				
				{isChoiceType &&
				<li className={'row align-items-center py-1 border-top'}>
					<h3 className={'col-2 m-0 font-weight-bold font-small-2 text-uppercase'}>
						Отображение на мобильном устройстве:
					</h3>
					
					<div className={'col-6 pl-0'}>
						<Controller name={'is_in_other_folder'}
						            control={control}
						            render={({field}) =>
							            <MySelect optionsArr={selectOptions}
							                      initialSelectedItem={initIsInOtherFolder}
							                      onSelect={(e) => field.onChange(e.value)}
							            />
						            }/>
					</div>
				</li>}
			</Card>
		</>
	)
}