import React from 'react';
import {Head} from '../../components/UI/Head';
import {ButtonGoBack} from '../../components/button/ButtonGoBack';

import {ReactComponent as LockIcon} from './lock.svg'
import {useLocation} from 'react-router-dom';

export const NotResolvedPage = () => {
	
	const {state} = useLocation()
	const text = state || 'У Вас нет доступа к этой странице'
	
	return <div className={'text-center w-100 text-gray-300'}>
		<ButtonGoBack className={'d-block ml-auto'}/>
		
		<LockIcon className={'svg-curr-color-fill mb-1'}
		          width={100}
		          height={100}
		          style={{marginTop: '33vh'}}/>
		
		<Head>{text}</Head>
	</div>;
}