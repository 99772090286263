import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Header} from '../UI/Header';

const usePageHead = ({head, goBack, extraElem}) => {
	const [pageTitle, setPageTitle] = useState(null)
	const [withBackBtn, setWithBackBtn] = useState(false)
	const [extraElement, setExtraElem] = useState(null)
	
	useEffect(() => {
		setPageTitle(head)
		setWithBackBtn(goBack)
		setExtraElem(extraElement)
	}, [head, goBack, extraElem])

	return {pageTitle, withBackBtn, extraElement}
}

export const AppLayout = () => {
	
	return (
		<>
			<Header/>
			
			<div className={'container pt-2 pb-1'}>
				<Outlet context={usePageHead}/>
			</div>
		</>
	)
}


