import axios from 'axios'
import {toast} from 'react-toastify'
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions'

export const appsAPI = {
	getAll: ({query, config}) => axios('/apps/', {...setPreparedAxiosParams(query), ...config}),
	
	getAppInfo: (app_id) => axios(`/apps/${app_id}/`),
	
	getVersionApp: (app_id) => axios('/apps/versions/', setPreparedAxiosParams({app: app_id})),
	
	createNewAppIcons: (data) => axios.post('/apps/', data, {
		headers: {'Content-Type': 'multipart/form-data'}
	}),
	
	createNewAppVersion: (data, toastId) => axios.request({
		method: 'post',
		url: '/apps/versions/',
		data,
		headers: {'Content-Type': 'multipart/form-data'},
		onUploadProgress: p => {
			const progress = p.loaded / p.total
      
			if (toastId.current === null) {
				toastId.current = toast.success('Идет загрузка файла .apk', {progress})
			} else {
				toast.update(toastId.current, {
					render: `Идет загрузка файла .apk ${Math.round(progress * 100)}%`,
					progress
				}) 
			}
      if (progress === 1) toastId.current = null
		}
	}),
	
	getNoSystemApps({page, search, page_size = 25}) {
		return axios('/apps/', {
			...setPreparedAxiosParams({...arguments[0], type: 1})
		});
	},
	
	pathIconsApp: (app_id, data) => axios.patch(`/apps/${app_id}/`, data),
	
	patchVersionApp: (app_version_id, data) =>
		axios.patch(`/apps/versions/${app_version_id}/`, data),
	
	// postNewVersion: (data) => axios.post('/apps/versions/', data, {
	// 	headers: {'Content-Type': 'multipart/form-data'}
	// }),
	
	deleteApp: (app_id) => axios.delete(`/apps/${app_id}/`),
	
	deleteAppVersion: (app_version_id) => axios.delete(`apps/versions/${app_version_id}/`)
}