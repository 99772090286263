import {useOutletContext} from 'react-router-dom';
import {ListServiceButtons} from '../../../../components/UI/ListServiceButtons';
import {urlParams} from '../../../../constants/urlParams';
import React, {useEffect, useMemo} from 'react';
import {useComboSearch} from '../../../../hooks/url/useComboSearch';
import {ReactComponent as LimitsIcon} from './icons/p2p_notify_limits.svg'
import {ReactComponent as HistoryIcon} from './icons/notifications.svg'
import UserP2PRule from './UserP2PRule';
import UserP2PHistory from './UserP2PHistory';
import { useMountEffect, usePrevious, useUpdateEffect } from '@react-hookz/web';

const p2pNotifyOptions = [
	{
		text: 'Ограничения',
		service: 'limits',
		Icon: LimitsIcon,
		MainSection: UserP2PRule
	}, {
		text: 'История уведомлений',
		service: 'history',
		Icon: HistoryIcon,
		MainSection: UserP2PHistory
	},
]

const {TYPE, PAGE, LIMIT, SORT, SEARCH, SENDER, RECEIVER, DATE_FROM, DATE_TO} = urlParams

const pageSearchParams = [
	{ param: TYPE, init: p2pNotifyOptions[0].service },
  { param: SEARCH },
  { param: PAGE },
  { param: LIMIT },
  { param: SORT }, 
  { param: SENDER },
  { param: RECEIVER },
  { param: DATE_FROM },
  { param: DATE_TO },
]

export function PageUserP2PNotifications() {
	const {setPageHead} = useOutletContext()
	
	const [
    [urlType], 
    [urlSearchQuery, setUrlSearchQuery],
    [urlPage, setUrlPage], 
    [urlLimit, setUrlLimit],
    [urlSort, setUrlSort],
    [urlSender, setUrlSender],
    [urlReceiver, setUrlReceiver],
    [urlDateFrom, setUrlDateFrom],
    [urlDateTo, setUrlDateTo],
  ] = useComboSearch(pageSearchParams)

  const prevUrlType = usePrevious(urlType);

	useEffect(() => {
		const head = p2pNotifyOptions
			.find(({service}) => service === urlType)?.text || ''
		
		setPageHead({head: 'Уведомления - ' + head.split(' ').at(0)})
	}, [urlType])
	
	const MainSection = useMemo(
		() => p2pNotifyOptions.find(({service}) => service === urlType).MainSection
		, [urlType])

  useMountEffect(() => {
    if (urlType === 'limits' && (urlPage === 'last' || !urlPage)) {
      setUrlPage(1);
    }
  })
	
  useUpdateEffect(() => {
    if (prevUrlType && prevUrlType !== urlType) {
      setUrlSearchQuery('')
      setUrlSender('')
      setUrlReceiver('')
      setUrlDateFrom('')
      setUrlDateTo('')
      if (urlType === 'limits'){
        setUrlPage(1);
        setUrlSort(null)
      } else if (urlType === 'history') {
        setUrlPage('last');
        setUrlSort('created_at')
      }
    }
  }, [urlType])
	
	return (
		<main className={'row no-gutters'}>
			<ul className={' list-gap-025r list-unstyled mr-1'}>
				<ListServiceButtons
					searchName={TYPE}
					init={p2pNotifyOptions[0].service}
					arrServices={p2pNotifyOptions}
				/>
			</ul>
			
			<MainSection/>
		</main>
	)
}