import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useRef, useState} from 'react';
import {MyAvatar} from './MyAvatar';
import {useKeycloak} from '@react-keycloak/web';
import {isEmpty, loader} from '../../js-helpers/helpers';
import {Card} from './Card';
import {CSSTransition} from 'react-transition-group';
import {SearchInput} from '../form/SearchInput';
import {useClickOutside, useLocalStorageValue, useToggle} from '@react-hookz/web';
import {observer} from 'mobx-react-lite';
import {loggedAdmin} from '../../stores/loggedAdmin';
// import {adminPermissions} from '../../constants/adminPermissions';
// import {useAdminPermissions} from '../../hooks/useAdminPermissions';
import Logo from '../Logo/Logo';
import {AppVersion} from '../AppVersion';
import {MenuDropdown} from '../../pages/menuAdmin/menuDropdown/MenuDropdown';
import classNames from 'classnames';

// const {USERS, GLOBAL_SEARCH} = adminPermissions

export const Header = observer(() => {
	const {keycloak} = useKeycloak()
	const {pathname, search} = useLocation()
	const navigate = useNavigate()
	const {isReady, data, isSuperAdmin} = loggedAdmin
	const {first_name, last_name, login, profile_photo_thumbnail} = data.account
	// const [, isGlobalSearchResolved] = useAdminPermissions(USERS, GLOBAL_SEARCH,)
	const [openAdminMenu, toggleOpenAdminMenu] = useToggle(false)
	const [showGlobalSearch, setShowGlobalSearch] = useState(false)
	
	const logOut = () => {
		keycloak.logout()
	}
	
	const isSearchPage = pathname.includes('search')
	// const isActiveNav = (who) => pathname.includes(who) ? 'active' : ''
	// const isNavUsers = pathname.includes('users')
	
	const onEnterToPageSearch = (e) => {
		const searchQuery = e.target.value
		if (isEmpty(searchQuery) || e.key !== 'Enter')
			return
		navigate(`/admins/users/search/?search=${searchQuery}`)
	}
	
	const adminMenuRef = useRef(null)
	useClickOutside(adminMenuRef, () => toggleOpenAdminMenu(false))
	const transitionRef = useRef(null)
	
	const linkTo = {
		users: {pathname: '/admins/users/', search: pathname === '/admins/users/' && search},
		menu: {pathname: '/admins/menu/'}
	}
	
	// const resolvedOnLogoLink = isUserResolved
	// 	? linkTo.users
	// 	: linkTo.menu
	//
	
	const onShowToggle = e => {
		if (isEmpty(e.target.value) && !isSearchPage) {
			setShowGlobalSearch(false)
		}
	};
	
	// const [isShowSnow, setIsShowSnow] = useLocalStorageValue('isShowSnow')
	// const [snowCount, setSnowCount] = useLocalStorageValue('snowCount')
	// const [snowSpeed, setSnowSpeed] = useLocalStorageValue('snowSpeed')
	
	
	// const onToggleSnow = () => {
	// 	setIsShowSnow(!isShowSnow)
	// }
	
	// const onToggleSpeed = () => {
	// 	isShowSnow && setIsShowSnow(false)
	// 	setSnowSpeed(snowSpeed <= 3 ? snowSpeed + 1 : 0)
	// 	setTimeout(() => {setIsShowSnow(true)}, 50)
	// }
	
	// const onToggleSnowCount = () => {
	// 	isShowSnow && setIsShowSnow(false)
	// 	setSnowCount(snowCount >= 12500 ? 2500 : snowCount + 2500)
	// 	setTimeout(() => {setIsShowSnow(true)}, 50)
	// }
	
	return (
		<header className='bg-transparent-60 zindex-1'>
			<div className='container height-75 d-flex align-items-center justify-content-between'>
				
				<Link to={linkTo.menu/*resolvedOnLogoLink*/} className={'position-relative mb-50 mr-1'}>
					<Logo height={'2rem'}/>
				</Link>
				
				{/* <nav className='navbar'>
					<ul className='nav'>
						
						{isUserResolved &&
						<li className='nav-item'>
							<NavLink to={linkTo.users} className={`nav-link ${isActiveNav('users')}`}>
								Пользователи
							</NavLink>
						</li>
						}
						
						{!isEmptyAdminMenu &&
						<li className='nav-item'>
							<NavLink to={linkTo.menu} className={`nav-link ${isActiveNav('admins')}`}>
								Администрирование
							</NavLink>
						</li>
						}
					
					</ul>
				</nav> */}
				<div className='ml-auto d-flex align-items-center justify-content-end col-10 w-100'>
					
					{isSuperAdmin && !isSearchPage
						? /*isGlobalSearchResolved &&*/  showGlobalSearch
							?
							<div className='row col'>
								<SearchInput disabled={isSearchPage}
								             onKeyDown={onEnterToPageSearch}
								             className={'col'}
								             placeholder={'Глобальный поиск...'}
								             autoFocus
								             onBlur={onShowToggle}
								/>
							</div>
							:
							<button className={'btn p-1 mr-50 cursor-pointer ml-auto'}
							        onClick={() => {setShowGlobalSearch(true)}}>
								<i className={'bx bx-search font-medium-5'}/>
							</button>
						: <></>
					}
					
					{loggedAdmin.isSuperAdmin 
            ? <MenuDropdown/> 
            : !loggedAdmin.isEmptyAdminMenu && !loggedAdmin.isEmptyVisibilityGroups 
            ? <MenuDropdown/> : <></>}
					
					<div ref={adminMenuRef}
					     className={`ml-50 border-0 text-center cursor-pointer bg-transparent p-0 zindex-1 ${
						     loader(isReady, {size: 'small'})}`}
					     onClick={() => toggleOpenAdminMenu()}>
						
						<div className='ml-1 d-flex align-items-center'>
							<div className={'d-inline-block font-small-3 text-right mr-1'}>
								{first_name}
								<div className={'text-muted'}>
									{data.is_superadmin ? 'Суперадминистратор' : 'Администратор'}
								</div>
							</div>
							
							<MyAvatar src={profile_photo_thumbnail} alt={login} size={32}/>
						</div>
						
						<CSSTransition nodeRef={transitionRef}
						               in={openAdminMenu}
						               timeout={200}
						               classNames='transition-slide-down'
						               unmountOnExit>
							
							<Card size={2}
							      ref={transitionRef}
							      onClick={e => e.stopPropagation()}
							      className={'white cursor-default position-absolute position-right-0 zindex-0 shadow bg-primary text-center mt-75 card-arrow'}
							>
								
								{/* <div className={'py-1 d-flex gap-1r justify-content-center'}>
									<button onClick={onToggleSnow}
									        className={classNames(
										        'btn font-large-1 p-0 bx',
										        {'bx-angry': !isShowSnow},
										        {'white bx-happy-heart-eyes': isShowSnow}
									        )}/>
									
									<button onClick={onToggleSpeed}
									        className={classNames(
										        'btn font-large-1 p-0 bx bx-wind',
										        {'white': snowSpeed === 1},
										        {'success': snowSpeed === 2},
										        {'warning': snowSpeed === 3},
										        {'danger': snowSpeed === 4},
									        )}/>
									
									<button onClick={onToggleSnowCount}
									        className={classNames(
										        'btn font-large-1 p-0 bx bx-cloud-snow',
										        {'white': snowCount === 5000},
										        {'success': snowCount === 7500},
										        {'warning': snowCount === 10000},
										        {'danger': snowCount === 12500},
									        )}/>
								</div> */}
								
								{/* <MyAvatar alt={login} src={profile_photo} size={64}/> */}
								<h2 className={'text-c-nowrap font-medium-1 mt-1 mb-25'}>
									{first_name} {last_name}
								</h2>
								
								{/*<div>*/}
								{data.is_superadmin ? 'Суперадминистратор' : 'Администратор'}
								{/*</div>*/}
								
								{/* <div className={'text-muted font-size-base'}>
								{login}
							</div> */}
								
								{/* <hr/> */}
								{/* <hr/> */}
								
								<button className={'btn btn-outline-primary-white my-1 w-100 font-size-base'}
								        onClick={logOut}>
									Выйти
								</button>
								
								{/*<div>*/}
								<AppVersion className={'font-italic'}/>
								{/*</div>*/}
							
							</Card>
						</CSSTransition>
					</div>
				</div>
			</div>
		</header>
	
	)
})
