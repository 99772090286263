import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { Card } from "../../../components/UI/Card"

const MenuItemInsideInfo = ({icon, head, className}) => {
  return (
    <>
      <i className={`btn svg-curr-color mb-50 p-1 ${className ? className : ''}`} style={{top: 0}}>{icon}</i>
      <h5 className={'text-muted mb-0 text-wrap text-center'} style={{fontSize: '10px'}}>{head}</h5>
    </>
  )
}

export const MenuDropdownItem = ({title, items}) => {
  const location = useLocation()

  const currentLocationPathName = useMemo(() => {
    const locationArray= location.pathname.split('/');
    return `/${locationArray[1]}/${locationArray[2] ? `${locationArray[2]}/` : '' }${locationArray[3] ? locationArray[3] : ''}`;
  }, [location])

  return (
    <div style={{width: '352px'}}>
      <h4 className={'font-small-3 font-weight-bold mb-1'}>{title}</h4>
      <Card as={'ul'} size={0} className={'list-unstyled d-flex list-gap-05r flex-wrap'}>
        {items.map(({link, icon, head, description}, index) => (
         <li key={index} className={'mt-0 mr-2'} style={{width: '61px'}}>
            {currentLocationPathName === `/admins/${link}` ?
              <div className={'d-flex flex-column align-items-center '}
              >
               <MenuItemInsideInfo icon={icon} head={head} className={'bg-c-gray-75'}/>
              </div>
             :
             <Link to={`/admins/${link}`}
							      className={'d-flex flex-column align-items-center '}
							>
                <MenuItemInsideInfo icon={icon} head={head} className={'bg-c-rgba-light hover-c-gray-75'}/>
            </Link>
            }
            
          </li>
        ))}
      </Card>
    </div>
  )
}