import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import { ButtonGoBack } from "../../../../components/button/ButtonGoBack";
import { Head } from "../../../../components/UI/Head";

export const LogsLayout = observer(() => {
  const [pageTitle, setPageTitle] = useState('Логи');
  const [withBackBtn] = useState(true);
  const [extraElem, setExtraElem] = useState(null);
  
  const setPageHead = useCallback(({ head, extraElem }) => {
    setPageTitle(head)
    setExtraElem(extraElem)
  }, [])
  
  return (
    <>
      {withBackBtn && <ButtonGoBack className={'float-right'} />}

      {extraElem && extraElem}

      {pageTitle && <Head children={pageTitle} />}

      <Outlet context={setPageHead} />
    </>
  )
})