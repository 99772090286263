import {Card} from '../../../../../components/UI/Card'
import {OneAppVersion} from './oneVersion/OneAppVersion'
import {useEffect, useState} from 'react'
import {Button} from '../../../../../components/button/Button'
import {appsAPI} from '../../../../../API/appsAPI'
import {Head} from '../../../../../components/UI/Head'
import {isNotEmpty} from '../../../../../js-helpers/helpers'
import {toast} from 'react-toastify';


export const FormAppVersion = ({resetField, control, data, type, appID, setDataVersion}) => {
	const [versions, setVersions] = useState([])
	const [showHistory, setShowHistory] = useState(false)
	
	useEffect(() => {
		setVersions(data ? showHistory ? [...data] : [data[0]] : [null])
	}, [data, showHistory])
	
	function onToggleHistory() {
		setShowHistory(!showHistory)
		setVersions(showHistory ? [data[0]] : [...data])
	}
	
	async function onDeleteVersion() {
		if (window.confirm('Удалить версию?')) {
			try {
				await appsAPI.deleteAppVersion(versions[0].id)
				toast.success('Версия удалена')
				const res = await appsAPI.getVersionApp(appID)
				setDataVersion(res.data.results)
				setShowHistory(true)
			} catch (e) {
				toast.error('Не удалось удалить версию')
			}
		}
	}
	
	return (
		<>
			<Head secondary uppercase className={'mt-2'}>
				ВЕРСИИ ПРИЛОЖЕНИЯ
			</Head>
			
			<Card secondary>
				
				{isNotEmpty(data)
				&&
				<div className='border-bottom mb-1 d-flex justify-content-between'>
					
					{data.length > 1 &&
					<button className={'btn btn-link px-0 start'}
					        type={'button'}
					        onClick={onToggleHistory}>
						{`${showHistory ? 'Скрыть' : 'Просмотреть'} историю версий`}
					</button>
					}
					
					{!!versions[0] &&
					<button className={'btn btn-link px-0'}
					        type={'button'}
					        onClick={() => setVersions([null, ...versions])}>
						Добавить версию
					</button>
					}
				</div>
				}
				
				<div className='d-flex justify-content-between position-relative flex-column'>
					
					{versions.map((item, index) => (
							<OneAppVersion
								isDisabled={item}
								key={item ? item.id : index}
								control={control}
								data={item}/>
						),
					)}
					
					{versions[0] && versions.length > 1
					&&
					<Button onClick={onDeleteVersion}
					        className='d-block align-self-start mt-2 position-absolute position-right-0'
					        remove
					/>
					}
					
					{!versions[0] && !type && versions.length > 1
					&&
					<button
						type='button'
						className={'btn align-self-start mt-2 btn-outline-secondary position-absolute position-right-0'}
						onClick={() => {
							setVersions([data[0]]);
							resetField('release_date')
						}}>
						Отмена
					</button>
					}
				</div>
			</Card>
		</>
	)
}