import { usePrevious } from '@react-hookz/web'
import { useCallback, useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import { useImmer } from 'use-immer'
import { useToggleState } from '../../../../hooks/useToggleState'
import { getFileNameFormatDate, getShortString, isNotEmpty } from '../../../../js-helpers/helpers'
import DownloadApkModal from '../../pageApps/formsApps/formAppVersion/oneVersion/DownloadApkModal'
import { getFileName, isResolvedFileType, showFileExtensionErr } from '../../pageApps/formsApps/formAppVersion/oneVersion/OneAppVersion'
import {ReactComponent as UploadSVG} from '../../pageApps/formsApps/formAppVersion/oneVersion/Upload.svg'
import {ReactComponent as DownloadSVG} from '../../pageApps/formsApps/formAppVersion/oneVersion/Download.svg'
import { titlesStyles } from '../../pageGroups/PageGroupsItem/PageGroupsItem'
import { ModalDialog } from '../../../../components/modals/ModalDialog'


export const VersionItem = ({setApk, file, setFile, valueFile, setValueFile, onlySave, versionData = {}}) => {
  const [drag, setDrag] = useState(false)
	const [fileSize, setFileSize] = useState(null)

  const [isShowModal, toggleShowModal] = useToggleState(false)
  const [modalDialogProps, setModalDialogProps] = useImmer({})

  const getShortNameFile = (refFile) => {
		const nameFile = getFileName(refFile);
		return getShortString(nameFile, 40, {startTrim: true})
	}

  const prevValueFile = usePrevious(valueFile)

  const resetFileData = useCallback((target) => {
		target &&
		(target.value = null)
		setFile(null)
		setValueFile('')
		setFileSize(null)
		setApk([])
	}, [])

  const dragStartHandler = (e) => {
		e.preventDefault()
		setDrag(true)
	}
	
	const dragLeaveHandler = (e) => {
		e.preventDefault()
		setDrag(false)
	}
	
	const onDropHandler = (e) => {
		e.preventDefault()
		let file = [...e.dataTransfer.files];
		const formatArr = file[0].name.split('.')
		
		if (formatArr[formatArr.length - 1] !== 'apk') {
			setDrag(false)
			resetFileData()
			showFileExtensionErr(formatArr[formatArr.length - 1])
			// return
		} else {
			setFile(file[0].name)
			setFileSize(file[0].size)
			setValueFile(file[0])
			setApk(file)
			setDrag(false)
		}
	}


  const dropNDownTitle = useMemo(() => {
		if (!drag && !file) {
			return 'Перетащите файл';
		} else if (drag) {
			return 'Отпустите файл'
		} else if (file) {
			return getShortNameFile(file)
		}
	}, [drag, file])

  const dropNDownSubtitle = useMemo(() => {
		if (fileSize) {
			const size = (fileSize / (1024 * 1024)).toFixed(2);
			return size === '0.00' ? '< 0.01 МБ' : `${size} МБ`
		} else {
			return 'Поддерживаемые форматы: .apk'
		}
	}, [fileSize])

  function onChangeAPK({target}) {
		const file = target.files[0]
		// console.dir(file)
		
		if (!isResolvedFileType(file?.type)) {
			const extension = file?.name?.split('.').at(-1)
			resetFileData(target)
			showFileExtensionErr(extension)
			return
		}
		
		if (target.files.length > 0) {
			setFile(file.name)
			setValueFile(target.value)
			setFileSize(file.size)
			setApk(target.files)
		}
	}

  function downloadAPK() {
		setModalDialogProps({
			head: `Вы действительно хотите скачать файл ${getFileName(versionData?.apk)} на компьютер?`,
			body: <DownloadApkModal
				apk={isNotEmpty(versionData) && versionData.apk}
				onCancelClick={() => toggleShowModal(false)}/>,
			showFooter: false,
			className: 'width-600',
		})
		
		toggleShowModal()
	}


  return (
    <>
    <ModalDialog isShow={isShowModal} {...modalDialogProps} />
    <div className={'d-flex pb-1 border-bottom-extra-light'}>
      <h3 className={titlesStyles}>APK</h3>
      {!onlySave ? 
      <label className={'d-flex flex-column p-75 align-items-center uploader cursor-pointer'}
           style={prevValueFile && !valueFile ? {borderColor: 'red'} :{}}
           htmlFor={'uploadFile'}
           onDragStart={dragStartHandler}
           onDragLeave={dragLeaveHandler}
           onDragOver={dragStartHandler}
           onDrop={onDropHandler}
      >
        <UploadSVG className={'mb-75'}/>
        <span className={'mb-25 text-bold-500 font-small-3 text-body text-center'}>
          {dropNDownTitle}
        </span>
        <span className={'font-small-2'}>{dropNDownSubtitle}</span>

      </label> 

      :
      	
      <div onClick={downloadAPK} 
           className='d-flex flex-column p-75 align-items-center uploader cursor-pointer'>
        <DownloadSVG className='mb-75'/>
        <span className='mb-25 text-bold-500 font-small-3'>
          {isNotEmpty(versionData) ? getShortNameFile(versionData.apk) : ''}
        </span>
        <span className='font-small-2 text-gray-300'>Скачать</span>
      </div>
      }
      <input type={'file'}
           className={'visually-hidden'}
          //  required={!valueFile}
           onChange={onChangeAPK}
           accept='application/vnd.android.package-archive'
           id={'uploadFile'}
    />
  </div>
  </>
  )
}