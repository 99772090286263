import exportFromJSON from 'export-from-json';
import { toast } from 'react-toastify';
import { getCell } from '../../../components/table/MyTable/MyTableBody';
import {
  columnsDevices,
  getDevicesTableOptions,
} from '../../../components/table/MyTable/options/columnsDevices';
import { urlParams } from '../../../constants/urlParams';
import { getDateNow, getFileNameFormatDate } from '../../../js-helpers/helpers';

const {SEARCH, SORT, PAGE, LIMIT, USER, SERIAL_NUMBER, LAUNCHER_VER, SETUP_ADMIN, MODEL, BRAND, EXPORT_MODE } = urlParams;

const { serialNumberClear, userClear, brand, model, launcher_version,adminClear, imei1, imei2 } = columnsDevices;

export const exportExcel = (exportList) => {
  const dataToExcel = exportList.map((item) => {
    const finalObject = {};

    pageDevicesConfig.exportColumns.forEach((col) => {
      finalObject[col.head] = getCell(col, item);
    });

    return finalObject;
  });

  const fileName = `Устройства_на_${getFileNameFormatDate(getDateNow())}`;

  exportFromJSON({
    data: dataToExcel,
    withBOM: true,
    fileName,
    exportType: exportFromJSON.types.xls,
  });

  toast.success(`Файл создан! 
  Устройств: ${dataToExcel.length}`);
};

export const pageDevicesConfig = {
  pageSearchParams: [
    { param: SEARCH, init: '', debounce: 333 },
    { param: SORT, init: 'user__account__last_name' },
    { param: PAGE, init: 1 },
    { param: LIMIT },
    { param: SERIAL_NUMBER },
    { param: USER },
    { param: LAUNCHER_VER },
    { param: SETUP_ADMIN },
    { param: MODEL },
    { param: BRAND },
    { param: EXPORT_MODE },
  ],
  getDevicesTable(isEmptySearchQuery, isExportMode, checkBoxesColl) {
    const baseOptions = [...getDevicesTableOptions(isEmptySearchQuery)];

    const exportModeOptions = [checkBoxesColl(), ...baseOptions, imei1, imei2];

    return isExportMode ? exportModeOptions : baseOptions;
  },

  exportColumns: [
    serialNumberClear,
    userClear,
    brand,
    model,
    launcher_version,
    adminClear,
    imei1, 
    imei2
  ],
};
