const settingsItems = [
  'установленные трекеры (звонки, мессенджеры, скриншоты);',
  'присвоено правило для работы с уведомлениями;',
  'выдан/не выдан режим разработчика;',
  'выбран канал обновления;',
  'установлен список доступных приложений',
  'пользователь добавлен в какую-либо группу видимости'
]

export const UserModalDialogBody = () => {
  return (
    <div div className={'text-left px-2 pb-2'}>
      <div className='mb-2 font-weight-bold text-center'>
        Проверьте что вы произвели следующие настройки:
      </div>
      <ul>
        {settingsItems.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <div className='pl-2 font-weight-bold'>После подтверждения обработки карточка нового пользователя будет удалена из таблицы “Новые Пользователи”</div>
    </div>
  )
}