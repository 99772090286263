import {Card} from '../../../../../components/UI/Card';
import {MyTable} from '../../../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../../../components/table/MyTable/MyTableBody';
import {loader} from '../../../../../js-helpers/helpers';
import {Button} from '../../../../../components/button/Button';
import React, {useEffect} from 'react';
import {useMyTable} from '../../../../../components/table/MyTable/useMyTable';
import {CheckBox} from '../../../../../components/form/CheckBox';
import {useImmer} from 'use-immer';
import {DescriptionBabble} from '../../../../../components/DescriptionInfoBabble/DescriptionInfoBabble';

const callsTrackers = [
	['Внутренние', 'calls_internal'],
	['Внешние', 'calls_external'],
	['Личные', 'calls_personal'],
	['Остальные', 'calls_other']
]

const getTrackersTableOptions = (trackers, setTrackers) => {
	
	const getMainCallsColls = () =>
		callsTrackers.map(([text, track]) => ({
			head: track === 'calls_other'
				? <DescriptionBabble text={text} tooltip={'Контакты, которые не внесены в телефонную книгу пользователя'}/>
				: text,
			staticCell: <CheckBox checked={Boolean(trackers[track])}
			                      onChange={e => toggleCallTrack(track, e.target.checked)}/>
		}))
	
	const toggleAllTracks = (e) => {
		const checked = e.target.checked
		setTrackers(draft => {
			callsTrackers.forEach(track => draft[track[1]] = checked)
		})
	}
	
	const toggleCallTrack = (track, checked) => {
		setTrackers(draft => {
			draft[track] = checked
		})
	}
	
	return [
		{
			head: 'Тип контакта',
			headClassName: 'text-left',
			cellClassName: 'py-2 text-left',
			staticCell: 'Звонки',
		}, {
			head: 'Все',
			staticCell: <CheckBox
				checked={callsTrackers.every(track => trackers[track[1]])}
				onChange={toggleAllTracks}
			/>,
		},
		...getMainCallsColls(),
	]
}

export function UserTrackersCallsCard(
	{
		onSave,
		isReadyRequests,
		initTrackers
	}) {
	
	const [trackers, setTrackers] = useImmer({})
	
	useEffect(() => {
		setTrackers(
			Object.fromEntries(
				Object.entries(initTrackers)
					.filter(([key]) => key.includes('calls')))
		)
	}, [initTrackers])
	
	const {tableColgroup, tableHeads, tableOptions} = useMyTable(
		getTrackersTableOptions(trackers, setTrackers) || [],
		{memoDependencies: [isReadyRequests, trackers]})
	
	function getIsDisabledSaveBtn() {
		return !isReadyRequests ||
			Object.entries(trackers).every(([track, checked]) => initTrackers[track] === checked)
	}
	
	return (
		<Card className={'col'}>
			
			<MyTable colgroup={tableColgroup}
			         tableClassName={'bg-c-rgba-light text-center'}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={Array(1).fill(null)}
				             tableOptions={tableOptions}
				             className={loader(isReadyRequests)}
				/>
			</MyTable>
			
			<Button save
			        disabled={getIsDisabledSaveBtn()}
			        position={'right'}
			        onClick={() => onSave(trackers)}/>
		</Card>
	)
}