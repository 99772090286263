import React, {useEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {NavSubPages} from '../../../../../components/UI/NavSubPages';
import {ButtonGoBack} from '../../../../../components/button/ButtonGoBack';
import {Head} from '../../../../../components/UI/Head';
import {useFetchStates} from '../../../../../hooks/useFetchStates';
import {adminSmassAPI} from '../../../../../API/adminSmassAPI';
import {loggedAdmin} from '../../../../../stores/loggedAdmin';
import { getShortFullNameFromObj } from '../../../../../js-helpers/helpers';
import { ModalDialog } from '../../../../../components/modals/ModalDialog';
import { AdminModalDialogBody } from './AdminModalDialogBody';
import { toast } from 'react-toastify';

const links = [
	{to: '', text: 'Общая информация'},
	{to: 'permissions/', text: 'Настройка видимости'},
  {to: 'groups/', text: 'Видимость групп'},
]

export const AdminEditLayout = () => {
  const {keycloakID} = useParams()
	const {pathname} = useLocation()

  const [adminIsHandled, setAdminIsHandled] = useState(true)
  const [handledOptions, setHandledOptions] = useState({sections: null, groups: null})
  const [isShowModal, setIsShowModal] = useState(false)
	const [pageTitle, setPageTitle] = useState('Общая информация')
	const {isSuperAdmin} = loggedAdmin
	
	const adminStates = useFetchStates(() => adminSmassAPI.getInfo(keycloakID), )
	const _isEditSuperAdmin = adminStates.data?.is_superadmin
	const showNav = isSuperAdmin && !_isEditSuperAdmin && adminStates.isReady

  const isAdminNew = useFetchStates(() => adminSmassAPI.getAllNew({account__keycloak_id: keycloakID}), {noInitFetch: true})
	
	useEffect(() => {
    const adminName = adminStates.data ? `(${getShortFullNameFromObj(adminStates.data.account)})` : ''
		setPageTitle(
			pathname.includes('permissions')
				? `Настройка видимости ${adminName}`
        : pathname.includes('groups') ?
        `Видимость групп ${adminName}`
				: `Общая информация ${adminName}` 
		)
	}, [pathname, adminStates.isReady])

  useEffect(() => {
    loggedAdmin.isSuperAdmin && isAdminNew.getData()
  
  }, [keycloakID, loggedAdmin.isSuperAdmin])

  
  useEffect(() => {
    setAdminIsHandled(isAdminNew?.data?.count === 0)
  }, [isAdminNew.data])

  const handleAdmin = async () => {
    try {
      await adminSmassAPI.markAsViewed({admin_id: keycloakID})
      toast.success('Карточка обработана!', {autoClose: 5000})
      setAdminIsHandled(true)
    } catch (e) {
      toast.error(e?.response?.data?.errors?.[0]?.detail,  {autoClose: 5000})
    } finally {
      setIsShowModal(false)
    }
  }
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
      {isSuperAdmin && _isEditSuperAdmin && isAdminNew.isReady && !adminIsHandled && 
        <button onClick={() => setIsShowModal(true)}
                className={'btn btn-primary float-right mr-1'}>
          Просмотрен
        </button>}
      {isSuperAdmin && !_isEditSuperAdmin && isAdminNew.isReady && !adminIsHandled &&
        <button onClick={() => setIsShowModal(true)}
                className={'btn btn-primary float-right mr-1'}>
          Обработан
        </button>}

			<Head>{pageTitle}</Head>
			
			{showNav &&
			<NavSubPages  links={links}
			              replace
				            className={'mb-1'}
				            rootPage={`/admins/${keycloakID}/`}/>
			}
			
			<Outlet context={adminStates}/>

      <ModalDialog isShow={isShowModal}
                   onCancelClick={() => setIsShowModal(false)}
                   onSuccessClick={handleAdmin}
                   successHided={_isEditSuperAdmin 
                    ? false 
                    : (!handledOptions.sections || !handledOptions.groups)}
                   body={<AdminModalDialogBody 
                      adminStates={adminStates} 
                      setHandledOptions={setHandledOptions}
                      handledOptions={handledOptions}
                    />}
                   className={'width-550 text-left'}/>
		</>
	)
}
