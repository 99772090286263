import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { launchersAPI } from "../../../../API/launchersAPI";
import { MySelect } from "../../../../components/form/MySelect/MySelect";
import { channelsNamesForLaunchers } from "../../../../constants/constants";
import { useFetchStates } from "../../../../hooks/useFetchStates";
import { isNotEmpty, loader, toggle } from "../../../../js-helpers/helpers";
import { titlesStyles } from "../../pageGroups/PageGroupsItem/PageGroupsItem";

export const ChannelItem = ({channel, setChannel, versionData = {}, versionDataIsReady, isDisabled = false}) => {
  const { data, getData, isReady } = useFetchStates(() => 
    launchersAPI.getChannels()
  );

  const channelOptions = useMemo(() => {
    if (isReady) {
      return data?.results?.map((channel) => ({text: channelsNamesForLaunchers?.[channel?.name] || channel?.name, value: channel?.id}));
    }
  }, [data?.results, isReady])

  const initialSelectedItem = useMemo(() => {
    const result = channelOptions?.find((option) => option.value === versionData?.channel)
    return result
  }, [channelOptions, versionData])

  return (
    <div className={`d-flex border-bottom-extra-light py-1 ${versionDataIsReady && !isDisabled ? loader(isReady) : ''}`}>
      <h3 className={titlesStyles}>Канал</h3>

        <MySelect initText={'Выберите канал'}
                  keyValue={'value'}
                  keyText={'text'}
                  onSelect={(e) => {
                     setChannel(e.value)
                  }}
                  initialSelectedItem={isNotEmpty(versionData) ? initialSelectedItem : undefined}
                  optionsArr={channelOptions}
                  className={'col-5 px-0'}
                  titleClassName={toggle(!channel, 'text-muted')}
                  disabled={isDisabled}
        />

    </div>
  );
};
