import { MySelect } from '../../../../../components/form/MySelect/MySelect';
import { Radio } from '../../../../../components/form/Radio';
import { ExceptionsLists } from '../../../../../components/listsAccess/ExceptionsLists';
import { Card } from '../../../../../components/UI/Card';
import { Head } from '../../../../../components/UI/Head';
import { restrictionsOptions } from '../PageNotificationsRulesCreate';

export const RuleSendAvailable = ({
  checkedRule, toggleRecipient, restrictionSelectKey, status,
  onRestrictionsSelect, exceptionsHead, exceptions, onExceptionItemClick,
  setExceptions,
}) => {
  return (
    <Card secondary className={'mb-2'}>
      <Head secondary uppercase>
        Доступна отправка уведомлений
      </Head>
      <div className={'d-flex'}>
        <label
          className={`cursor-pointer d-block text-c-transform-none font-size-base mr-3 mb-0 ${
            checkedRule === 'all' && 'text-body'
          }`}
        >
          <Radio
            name="all"
            checked={checkedRule === 'all'}
            onChange={() => toggleRecipient('all')}
          />
          Всем
        </label>
        <label
          className={`cursor-pointer d-block text-c-transform-none font-size-base mr-3 mb-0 ${
            checkedRule === 'nobody' && 'text-body'
          }`}
        >
          <Radio
            name="nobody"
            checked={checkedRule === 'nobody'}
            onChange={() => toggleRecipient('nobody')}
          />
          Никому
        </label>
        <label
          className={`cursor-pointer d-block text-c-transform-none font-size-base mb-0 mr-2 ${
            checkedRule === 'list' && 'text-body'
          }`}
        >
          <Radio
            name="list"
            checked={checkedRule === 'list'}
            onChange={() => toggleRecipient('list')}
          />
          Настраиваемый
        </label>

        {checkedRule === 'list' ? (
          <fieldset className="width-350">
            <label className={'text-body'}>ВЫБЕРИТЕ ОГРАНИЧЕНИЕ</label>
            <MySelect
              key={restrictionSelectKey}
              optionsArr={restrictionsOptions}
              initialSelectedItem={restrictionsOptions[status - 1]}
              onSelect={onRestrictionsSelect}
            />
          </fieldset>
        ) : (
          <></>
        )}
      </div>

      {checkedRule === 'list' ? (
        <ExceptionsLists
          type={'users'}
          // background={'white'}
          headExceptions={exceptionsHead}
          exceptions={exceptions}
          onItemClick={onExceptionItemClick}
          setExceptions={setExceptions}
          className={'mt-2'}
        />
      ) : (
        <></>
      )}
    </Card>
  );
};
