import { useEffect } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import { deviceAPI } from "../../../../API/deviceAPI"
import { MyTable } from "../../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../../components/table/MyTable/MyTableHead"
import { deviceSetupTableOptions } from "../../../../components/table/MyTable/options/columnsDeviceSetup"
import { useFetchStates } from "../../../../hooks/useFetchStates"
import { loader } from "../../../../js-helpers/helpers"



export const PageDeviceSetup = () => {
  const {deviceID} = useParams()
  const setPageHead = useOutletContext()

  const deviceStates = useFetchStates(() => deviceAPI.getOneDevice(deviceID), {initData: {}})

  useEffect(() => {
		setPageHead({head: `История Настройки устройства (${deviceID})`});
	}, [deviceID])

  return (
    <MyTable paginationAllCount={0} >
      <colgroup>
        <col span={3} width={500}/>
        <col width={150}/>
      </colgroup>
    
      <MyTableHead heads={deviceSetupTableOptions}/>
    
      <MyTableBody
        highestRowToFill={45}
        maxFillItems={1}
        className={loader(deviceStates?.isReady)}
        data={[deviceStates?.data?.setup]}
        tableOptions={deviceSetupTableOptions}
      />
  
  </MyTable>
  )
}