import dayjs from 'dayjs';
import {toast} from 'react-toastify';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {appAPI} from '../../../../API/appAPI';
import {useUpdateEffect} from '@react-hookz/web';
import {loggedAdmin} from '../../../../stores/loggedAdmin';
import {useRef, useState} from 'react';

const currAppVersion = process.env.REACT_APP_VERSION

const ReloadPageToast = () =>
	<>
		Доступна новая версия!
		<button className={'btn btn-primary btn-sm ml-1'}
		        onClick={() => { window.location.reload(true) }}>
			Обновить
		</button>
	</>

const showReloadAppToast = ({onClose}) => {
	toast.success(
		ReloadPageToast,
		{
			position: 'top-center',
			autoClose: false,
			toastId: 'updateAppVer',
			className: 'text-c-nowrap',
			onClose
		}
	)
}

const getDiffInMinutes = (date, requestTime) => -date.diff(dayjs(requestTime), 'minutes')

export function useAdminAndAppUpdate() {
	// хук для запроса данным при разнице с предыдущим запросом 5+ минут
	const [isNeedUpdateAppVer, setIsNeedUpdateAppVer] = useState(false)
	const lastUpdateAppVerRef = useRef(dayjs())
	
	const [isNeedUpdateAdmin, setIsNeedUpdateAdmin] = useState(false)
	const lastUpdateAdminRef = useRef(dayjs())
	
	const serverAppVer = useFetchStates(appAPI.getAppVersion)
	
	useUpdateEffect(() => {
		if (!isNeedUpdateAppVer) return
		
		const isDefinedVersions = serverAppVer.data && currAppVersion
		const isDiffVersions = currAppVersion !== serverAppVer.data
		
		if (isDefinedVersions && isDiffVersions) {
			showReloadAppToast({
				onClose: () => {
					lastUpdateAppVerRef.current = dayjs()
					setIsNeedUpdateAppVer(false)
				},
			})
		}
	}, [isNeedUpdateAppVer, serverAppVer.data])
	
	useUpdateEffect(() => {
		if (isNeedUpdateAdmin && loggedAdmin.isReady)
			loggedAdmin.fetchAdmin()
				.then(() => {
					setIsNeedUpdateAdmin(false)
					lastUpdateAdminRef.current = dayjs()
				})
	}, [isNeedUpdateAdmin])
	
	useUpdateEffect(() => {
		if (isNeedUpdateAppVer && serverAppVer.isReady)
			serverAppVer.getData()
				.then(() => {
					// serverAppVer.setData(currAppVersion + '1')
					lastUpdateAppVerRef.current = dayjs()
				})
	}, [isNeedUpdateAppVer])
	
	const checkNeedUpdateAdminAndAppVer = (requestTime) => {
		const minutesAdminDiff = getDiffInMinutes(lastUpdateAdminRef.current, requestTime)
		const minutesAppVerDiff = getDiffInMinutes(lastUpdateAppVerRef.current, requestTime)
		
		if (minutesAdminDiff > 5)
			setIsNeedUpdateAdmin(true)
		
		if (minutesAppVerDiff > 5)
			setIsNeedUpdateAppVer(true)
	}
	
	return checkNeedUpdateAdminAndAppVer
}
