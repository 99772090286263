import {useSearchParams} from 'react-router-dom';
import {useDebouncedCallback, useRafEffect} from '@react-hookz/web';
import {isEmpty} from '../../js-helpers/helpers';

// Хук для изменения только нужного параметра поля search url

// Принимает key - параметр для управления, initial -
// для устрановки значения key при первом рендере хука

// Возвращет итоговое значение key и функцию по ее изменению

export function useCustomSearch(key, initial, {debounce, toNumber} = {}) {
	const [searchParams, setSearchParams] = useSearchParams()
	
	const setNewSearch = (value) => {
		if (searchParams.get(key) === value?.toString())
			return
		
		if (isEmpty(value)) {
			deleteSearch()
			return
		}
		
		searchParams.set(key, value)
		searchParams.sort()
		
		setSearchParams(searchParams.toString(), {replace: true})
	}
	
	
	const deleteSearch = () => {
		searchParams.delete(key)
		setSearchParams(searchParams.toString(), {replace: true})
	}
	
	const param = searchParams.get(key) || initial?.toString()
	
	useRafEffect(() => {
		if (initial) setNewSearch(param)
	}, [])
	
	const debouncedSetNewSearch = useDebouncedCallback(val => {
		setNewSearch(val)
	}, [searchParams.toString()], debounce)
	
	const finalSetNewSearch = debounce ? debouncedSetNewSearch : setNewSearch
	const finalParam = toNumber ? Number(param) : param
	
	return [finalParam, finalSetNewSearch, deleteSearch]
}