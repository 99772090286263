import React, {useRef} from 'react';

const GetFullTable = ({length = 10, highestElem = 40, cols, maxItems = 10}) => {
	
	const styles = useRef({height: highestElem})
	if (length > maxItems) return null
	
	return (
		new Array(maxItems - length).fill(null).map((_, i) => (
			<tr key={i}>
				<td colSpan={cols}>
					<div style={styles.current}/>
				</td>
			</tr>
		)))
}


export default GetFullTable;