import {
	getFullNameFromObj,
	getLocaleFullDate,
	getShortFullNameFromObj,
	isEqualSome,
	translateThisBullshit,
} from '../../../../js-helpers/helpers';
import {AudioPlayer} from '../../../media/AudioPlayer/AudioPlayer';
import {Link} from 'react-router-dom';
import {ButtonWithIcon} from '../../../button/ButtonWithIcon';
import { callTypes } from '../../../../constants/constants';

const appsByType = {
	1: 'Телефон',
	2: 'WhatsApp',
	3: 'Skype',
	4: 'Viber',
	5: 'Telegram',
}

export const columnsCalls = {
	type: {
		head: 'Тип',
		cell: (type) => type ? callTypes[type][0] : '-',
		nestedCellField: 'type',
		nestedCellClassNameField: 'type',
		cellClassName: (type) => type ? callTypes[type][1] : '',
		sortField: 'type',
	},
	contact_phone: {
		head: 'Номер',
		cell: 'contact_phone',
		cellClassName: 'py-2',
	},
	contact_name: {
		head: 'Контакт',
		nestedCellField: 'contact_name',
		sortField: 'contact_name',
		cell: translateThisBullshit,
	},
  device: {
    head: 'Устройство',
    nestedCellField: 'device',
    // sortField: 'serial_number',
    cell: (device) => device?.serial_number ? <Link to={`/admins/devices/${device?.serial_number}/calls/`}>{device?.serial_number}</Link> : '-' 
  },
  device_serial: {
    head: 'Устройство',
    nestedCellField: 'device',
    // sortField: 'serial_number',
    cell: (device) => device?.serial_number || '-'
  },
	date: {
		head: 'Дата и время',
		cell: getLocaleFullDate,
		sortField: 'date',
		nestedCellField: 'date'
	},
	// duration: {
	// 	head: 'Длительность',
	// 	cell: 'duration',
	// 	sortField: 'duration'
	// },
	player: ({playerProps, playerStates, fullScreenPlaying, setFullScreenPlayerState, setAudioName }) => ({
		head: 'Длительность',
		cellClassName: 'position-relative',
		cell: ({audio, normalized_audio, contact_name, contact_phone, date, duration, type}) => {
			const audioURL = normalized_audio || audio
			const fileName = `${translateThisBullshit(contact_name)
        }_${callTypes[type][0]
        }_${contact_phone}_${
          new Date(date)
          .toLocaleString()
          .replaceAll('.', '-')
          .replaceAll(' ', '_')
          .replaceAll(',', '')
          .replaceAll(':', '-')
        }_${duration.replaceAll(':', '-')}`

			return (!audioURL || isEqualSome(audioURL, playerStates.badLinks))
				? <span className={'text-muted'}>Запись отсутствует</span>
				:
				<div className={'d-flex align-items-center'} style={{width: '141px'}}>
					{/*{normalized_audio &&*/}
					{/*<small className={'position-absolute text-muted'}*/}
					{/*       style={{right: '1rem', top: '.25rem'}}>*/}
					{/*	BOOSTED*/}
					{/*</small>*/}
					{/*}*/}
          {/* <WithTooltip  elem={fileName.length > 5? fileName.slice(0, 5)+ '...' : fileName } 
                        tooltips={[fileName]}
                        
          /> */}
          <div>{duration}</div>
          <div className={`${playerProps.playingCallURL === audioURL ? 'd-flex' : 'hiddenIfNotHover'} ml-50`}>
					  <AudioPlayer  key={audioURL}
					                url={audioURL}
                          showRange={false}
                          fullScreenPlaying={fullScreenPlaying}
                          setFullScreenPlayerState={setFullScreenPlayerState}
                          setAudioName={setAudioName}
                          fileName={fileName}
					                {...playerProps}
					  />
            <ButtonWithIcon href={audioURL}
				                    className={'rounded-circle p-0 ml-50'}
				                    type={'save_call'}
				                    style={{width: '2rem', height: '2rem'}}
				                    fileName={fileName}
				                    disabled={!isEqualSome(audioURL, playerStates.loadedLinks)}
			      />
          </div>
				</div>
		},
    sortField: 'duration'
	}),
	user: (isEmptySearchQuery) => ({
		head: 'Пользователь',
		sortField: 'user__account__last_name',
		nestedCellField: 'user',
		cell: (user) =>
        user?.keycloak_id ? <Link className={'px-0'} to={`/admins/users/${user?.keycloak_id}/calls/`}>
				{isEmptySearchQuery
					? getShortFullNameFromObj(user)
					: getFullNameFromObj(user)
				}
			</Link> : '-',
	}),
	saveBTN: (loadedLinks) => ({
		head: 'Скачать',
		cell: ({audio, normalized_audio, contact_name, contact_phone, date, duration, type}) => {
			const audioURL = normalized_audio || audio
			
			return <ButtonWithIcon
				href={audioURL}
				className={'rounded-circle p-0'}
				type={'save'}
				style={{width: '2rem', height: '2rem'}}
				fileName={`${translateThisBullshit(contact_name)
				}_${callTypes[type][0]
				}_${contact_phone}_${
					new Date(date)
						.toLocaleString()
						.replaceAll('.', '-')
						.replaceAll(' ', '_')
						.replaceAll(',', '')
						.replaceAll(':', '-')
				}_${duration.replaceAll(':', '-')}`}
				disabled={!isEqualSome(audioURL, loadedLinks)}
			/>
		},
		cellClassName: 'text-center',
	}),
	fromAppType: {
		head: 'Источник звонка',
		cell: app => appsByType?.[app] || '-',
		nestedCellField: 'app',
		sortField: 'app',
	},
	status: {
		head: 'Статус',
		cell: is_success => is_success === true ? 'Завершен' : 'Недозвон',
		nestedCellField: 'is_success',
		sortField: 'is_success',
	},
}