import { getFullNameFromObj } from "../../../../js-helpers/helpers";

const arrNotificationFields = [
  'title',
  'body',
  'receiver',
  'admin',
  'user',
];

export const createNotification = (data) => {
  const {
    title,
    body,
    admin,
    user,
    notify_users_profiles,
    viewed_by_users_profiles
  } = data;
  const result = {};

  arrNotificationFields.map((item) => {
    switch (item) {
      case 'title':
        result['Заголовок'] = title;
        break;
      case 'body':
        result['Текст'] = body;
        break;
      // case 'admin':
      //   result['Администратор'] = getFullNameFromObj(admin);
      //   break;
      // case 'user': 
      //   result['Отправитель'] = getFullNameFromObj(user);
      //   break;
      default:
        break;
    }
    return null;
  });

  if (admin) {
    result['Администратор'] = getFullNameFromObj(admin);
  }
  if (user) {
    result['Отправитель'] = getFullNameFromObj(user);
  }
  return Object.entries(result);
};
