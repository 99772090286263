import { Head } from "../../components/UI/Head";
import {ReactComponent as WelcomePageIcon} from './welcome_page.svg';

export const AdminWelcomePage = () => {
  return (
    <div className={'text-center w-100 text-gray-300'}>
      <div style={{ height: '70vh' }}
           className={'d-flex justify-content-center flex-column'}
          >
        <WelcomePageIcon className="ml-auto mr-auto mb-3 mt-3" />
        <Head className={'text-body text-uppercase font-weight-bold'}>
          Отсутствует настройка доступов
        </Head>
        <Head>
          Для получения доступа к административной панели обратитесь к старшему администратору
        </Head>
      </div>
    </div>
  );
};
