import {MyAvatar} from '../../../UI/MyAvatar';
import {
	getFullNameFromObj,
	getShortFullNameFromObj,
	getShortString,
	isEmpty,
	toggle
} from '../../../../js-helpers/helpers';
import {Link} from 'react-router-dom';
import {WithTooltip} from '../../../UI/WithTooltip';
import {LOGIN_STATUS} from '../../../../constants/constants';

export const columnsUser = {
	keycloak_id: {
		head: 'Keycloak ID',
		cell: (user) => user.keycloak_id
			? <WithTooltip elem={getShortString(user?.keycloak_id, 8)}
			               tooltips={[user?.keycloak_id]}/>
			: '-',
		// col: {width: 75},
		sortField: 'account__keycloak_id',
	},
	full_keycloak_id: {
		head: 'Keycloak ID',
		cell: (user) => user.keycloak_id ? user?.keycloak_id : '-',
		col: {width: 75},
		sortField: 'account__keycloak_id',
	},
	// user_id: {
	// 	head: 'ID',
	// 	cell: 'id',
	// 	col: {width: 75},
	// 	sortField: 'account__id',
	// },
	user_photo: {
		head: 'Фото',
		cell: (user) => {
			return user?.login_status === 1 || user?.login_status === 3
				? <MyAvatar src={user?.profile_photo_thumbnail} alt={getShortFullNameFromObj(user)}/>
				: <div className='position-relative' style={{width: '45px'}}>
					<MyAvatar src={user?.profile_photo_thumbnail}
					          alt={getShortFullNameFromObj(user)}
					          className={'avatar-not-active'}/>
					
					<div className={'position-absolute'} style={{top: '-5px', right: '-10px'}}>
						<WithTooltip
							// rock - boxicons
							// elem={<Alert/>}
							elem={<i className={'bx bxs-error-circle warning font-small-3'}/>}
							tooltips={[LOGIN_STATUS[user?.login_status]]}/>
					</div>
				</div>
		},
		col: {width: 100}
	},
  user_photo_without_babble: {
    head: 'Фото',
		cell: (user) => <MyAvatar src={user?.profile_photo_thumbnail} alt={getShortFullNameFromObj(user)}/>,
		col: {width: 100}
  },
	getUserName: (isEmptySearchQuery) => ({
		head: 'Пользователь',
		cell: user => user ?
			<Link to={`/admins/users/${user.keycloak_id}/info/`}>
				{(isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user))}
				 {/* toggle(!user.is_active, ' (заблокирован)')} */}
			</Link> : '-',
		col: {width: 200},
		sortField: 'account__last_name',
	}),
  getUserNameWithoutSort: (isEmptySearchQuery) => ({
		head: 'Пользователь',
		cell: user => user ?
			<Link to={`/admins/users/${user.keycloak_id}/info/`}>
				{(isEmptySearchQuery ? getShortFullNameFromObj(user) : getFullNameFromObj(user))}
				 {/* toggle(!user.is_active, ' (заблокирован)')} */}
			</Link> : '-',
		col: {width: 200},
	}),
	user_subdivision: {
		head: 'Подразделение',
		cell: (user) => user.subdivision ? user.subdivision : '-',
		col: {width: 350},
		sortField: 'account__subdivision'
	},
	user_division: {
		head: 'Дивизион',
		cell: (user) => user.division ? user.division : '-',
		col: {width: 250},
		sortField: 'account__division__name'
	},
	user_phone_numbers: {
		head: 'Номер телефона',
		cell: (phone_numbers) => isEmpty(phone_numbers) ? '-' : phone_numbers.join(', '),
			// isEmpty(user?.phone_numbers) ? '-' : user.phone_numbers.map((number) => number).join(', '),
		col: {width: 200},
		nestedCellField: 'phone_numbers',
	},
	user_mail: {
		head: 'E-mail',
		// cell: (user) => user.email ? user.email : '-',
		cell: 'email',
		col: {width: 200},
		// nestedCellField: 'email',
		sortField: 'account__email'
	}
}
