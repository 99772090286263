import {useAvatarWithChange} from "../../../../components/UI/AvatarWithChange";
import {useForm} from "react-hook-form";
import {ButtonGoBack} from "../../../../components/button/ButtonGoBack";
import React, {useState} from "react";
import {Button} from "../../../../components/button/Button";
import {Card} from "../../../../components/UI/Card";
import {Head} from "../../../../components/UI/Head";
import {contactsAPI} from "../../../../API/contactsAPI";
import {SectionPersonalInfo} from "./components/SectionPersonalInfo";
import {SectionVisibility} from "./components/SectionVisibility";
import {useToggleState} from "../../../../hooks/useToggleState";

export function PageNewSharedContact() {
	 const [haveWhiteList, toggleHaveWhiteList, setHaveWhiteList] = useToggleState(false)
	 const [whiteListState, setWhiteListState] = useState([])
	 
	 const {formState, ...form} = useForm({mode: 'onChange'})
	 const avatar = useAvatarWithChange()
	 
	 async function onSubmit(submitData) {
			const dirtyFieldsArr = Object.keys(formState.dirtyFields)
			const mainData = {contact: {}}
			
			dirtyFieldsArr.forEach(dirtField =>
				mainData.contact[dirtField] = submitData[dirtField])
			
			const changedWhiteListIDs = () => {
				 const finalIDs = haveWhiteList ? whiteListState.map(contact => contact.id) : []
				 return ({users_whitelist_ids: finalIDs})
			}
			
			const finalData = {
				 ...mainData,
				 ...changedWhiteListIDs(),
			}
			
			//append emails
			if (!finalData.contact.hasOwnProperty('emails')) {
				 finalData.contact.emails = []
			}
			
			const {data} = await contactsAPI.createShared(finalData)
			
			const newAvatar = avatar.photoState
			const newContactID = data?.contact?.id
			
			if (newAvatar && newContactID)
				 await contactsAPI.putSharedAvatar(newContactID, newAvatar)
	 }
	 
	 return (
		 <>
				<Head>Добавление контакта</Head>
				
				<Card>
					 <ButtonGoBack className={'float-right'}/>
					 
					 <Head secondary uppercase className={'mb-3'}>
							Создание нового контакта
					 </Head>
					 
					 <SectionPersonalInfo
						 onSubmit={onSubmit}
						 avatar={avatar}
						 form={form}
						 isValid={formState.isValid}
					 />
					 
					 <SectionVisibility
						 whiteListState={whiteListState}
						 setWhiteListState={setWhiteListState}
						 haveWhiteList={haveWhiteList}
						 toggleHaveWhiteList={toggleHaveWhiteList}
					 />
					 
					 <Button onClick={form.handleSubmit((onSubmit))} save className={'d-block ml-auto'}/>
				</Card>
		 </>
	 )
	 
}