import {useForm} from 'react-hook-form'
import React, {useEffect, useState} from 'react'
import {Card} from '../../../../components/UI/Card'
import {FormAppEdit} from '../formsApps/formAppIcon/FormAppEdit'
import {FormAppVersion} from '../formsApps/formAppVersion/FormAppVersion'
import {appsAPI} from '../../../../API/appsAPI'
import {useParams} from 'react-router-dom'
import {Loader} from '../../../../components/UI/Loader'
import {ButtonGoBack} from '../../../../components/button/ButtonGoBack'
import {Head} from '../../../../components/UI/Head'
import {Button} from '../../../../components/button/Button'
import {isEmpty, loader} from '../../../../js-helpers/helpers'
import {useFetchStates} from '../../../../hooks/useFetchStates'
import {toast} from 'react-toastify'
import useRerender from '../../../../hooks/useRerender'
import {checkIconLengthAngFormat} from '../pageAppsFunctions'
import {useUpdateEffect} from '@react-hookz/web';

export const PageAppsEdit = () => {
	const {appID} = useParams()
	const [dataVersion, setDataVersion] = useState(null)
	const [isLoadedVersion, setIsLoadedVersion] = useState(false)
	const appInfoStates = useFetchStates(() => appsAPI.getAppInfo(appID))
	
	const {control, handleSubmit, formState: {isDirty, dirtyFields}, reset, resetField} = useForm({
		mode: 'onChange',
		// defaultValues: {
		// 	icon: appInfoStates.data?.icon,
		// 	icon_dark: appInfoStates.data?.icon_dark,
		// 	icon_light: appInfoStates.data?.icon_light,
		// 	name: appInfoStates.data?.name,
		// 	is_in_other_folder: appInfoStates.data?.is_in_other_folder,
		// 	apk: null,
		// 	release_date: dataVersion?.[0]?.create_date
		// }
	})
	
	const [showLoader, setShowLoader] = useState(false)
	const [key, rerender] = useRerender()
	const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false)
	
	const toastId = React.useRef(null)
	
	useUpdateEffect(() => {
		reset({...appInfoStates.data, apk: null, release_date: dataVersion?.[0]?.create_date})
	}, [appInfoStates.data, dataVersion])
	
	useEffect(() => {
		appsAPI.getVersionApp(appID)
			.then(r => {
				setDataVersion(r.data.results)
				setIsLoadedVersion(true)
			})
	}, [appID])
	
	if (!appInfoStates.isReady || !isLoadedVersion) {
		return <div className={'d-flex justify-content-center'}>
			<Loader/>
		</div>
	}

//todo разделить на мелкие функций, не понимаю что происходит
	const onSubmit = async (submitData) => {
		// console.log({submitData})
		const bodyFormDataIcons = new FormData()
		const bodyFormDataVersion = new FormData()
		
		const arrIcon = ['name', 'icon', 'icon_light', 'icon_dark', 'icon_md', 'is_in_other_folder', 'is_hidden']
		const arrVersion = ['is_mandatory', 'is_force_install', 'apk', 'release_date']
		let isFormIcons = false
		let isFormVersion = false
		
		for (let key in dirtyFields) {
			
			if (arrIcon.includes(key)) {
				isFormIcons = true
				
				const appendedData =
					['name', 'is_in_other_folder', 'is_hidden'].some(field => field === key)
						? submitData[key]
						: submitData[key][0]
				
				bodyFormDataIcons.append(key, appendedData)
				
				// if (['name', 'is_in_other_folder', 'is_hidden'].some(field => field === key)) {
				// 	bodyFormDataIcons.append(key, submitData[key])
				//
				// } else {
				// 	bodyFormDataIcons.append(key, submitData[key][0])
				// }
			}
			
			if (arrVersion.includes(key)) {
				isFormVersion = true
				
				const appendedData =
					key === 'apk'
						? submitData.apk[0]
						: submitData[key]
				
				bodyFormDataVersion.append(key, appendedData)
				
				// if (key === 'apk') {
				// 	bodyFormDataVersion.append(key, submitData.apk[0])
				// } else {
				// 	bodyFormDataVersion.append(key, submitData[key])
				// }
			}
		}
		
		if (isFormIcons) {
			try {
				const res = await appsAPI.pathIconsApp(appInfoStates.data.id, bodyFormDataIcons)
				appInfoStates.setData(res.data)
				toast.success('Данные приложения обновлены!')
				
			} catch ({response: {data}}) {
				data?.errors?.forEach((error, index) => {
					if (data?.errors?.[index]?.attr === 'name') {
						toast.error(`Приложение "${submitData.name}" уже есть!`)
					}
					if (data?.errors?.[index]?.attr === 'icon') {
						checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки на сайте')
					}
					if (data?.errors?.[index]?.attr === 'icon_dark') {
						checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки темной темы')
					}
					if (data?.errors?.[index]?.attr === 'icon_light') {
						checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки светлой темы')
					}
					if (data?.errors?.[index]?.attr === 'icon_md') {
						checkIconLengthAngFormat(data?.errors?.[0]?.detail, 'иконки material design')
					}
				})
				
				if (!data.errors?.[0]?.detail.includes('Расширение файлов')) {
					toast.error(data.errors?.[0]?.detail)
				}
				
			} finally {
				setShowLoader(false)
			}
		}
		
		if (isFormVersion) {
			// console.log({submitData})
			if (isEmpty(submitData.release_date)) {
				toast.error('Выберите дату установки!')
				return
			}
			
			bodyFormDataVersion.append('app', appInfoStates.data.id)
			bodyFormDataVersion.set('release_date', submitData.release_date)
			
			if (bodyFormDataVersion.get('apk')) {
				bodyFormDataVersion.append('is_mandatory', false)
				bodyFormDataVersion.append('is_force_install', false)
				
				try {
					setIsDisabledSaveButton(true)
					await appsAPI.createNewAppVersion(bodyFormDataVersion, toastId)
					
					const {data} = await appsAPI.getVersionApp(appID)
					setDataVersion(data.results)
					setIsLoadedVersion(true)
					
					toast.success('Версия добавлена!')
					
					rerender()
				} catch ({response}) {
					const errors = response?.data?.errors
					
					if (errors?.[0]?.detail.includes('already exists')) {
						toast.error('Такая версия apk уже существует')
						return
					}
					
					if (errors?.[0]?.detail.includes('не является корректным файлом')) {
						toast.update(toastId.current, {
							type: 'error',
							render: response?.data?.errors?.[0]?.detail,
							autoClose: 5000,
							isLoading: false,
							closeOnClick: true,
							progress: null
						})
						return
					}
					
					errors.forEach(({detail}) => {
						toast.error(detail)
					})
					
				} finally {
					setShowLoader(false)
					setIsDisabledSaveButton(false)
				}
				
			} else {
				
				try {
					const res = await appsAPI.patchVersionApp(dataVersion[0].id, bodyFormDataVersion)
					setDataVersion([res.data, ...dataVersion.slice(1)])
					reset()
					toast.success('Изменения Версии сохранены!')
					
				} catch (e) {
					toast.error(e?.response?.data?.detail)
					
				} finally {
					setShowLoader(false)
				}
			}
		}
		setShowLoader(false)
	}
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head>Редактирование приложения</Head>
			
			<Card key={key} as={'form'} className={loader(!showLoader)} onSubmit={handleSubmit(onSubmit)}>
				
				<FormAppEdit control={control} data={appInfoStates.data}/>
				
				<FormAppVersion appID={appID}
				                control={control}
				                data={dataVersion}
				                setDataVersion={setDataVersion}
				                resetField={resetField}/>
				
				<Button disabled={!isDirty || isDisabledSaveButton}
				        className={'mt-1 d-block ml-auto'}
				        save/>
			</Card>
		</>
	)
}