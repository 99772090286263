import { useUpdateEffect } from "@react-hookz/web"
import { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { groupsAPI } from "../../../API/groupsAPI"
import { ButtonGoBack } from "../../../components/button/ButtonGoBack"
import { SearchInput } from "../../../components/form/SearchInput"
import { DeleteItemModal } from "../../../components/modals/DeleteItemModal"
import { MyTable } from "../../../components/table/MyTable/MyTable"
import { MyTableBody } from "../../../components/table/MyTable/MyTableBody"
import { MyTableHead } from "../../../components/table/MyTable/MyTableHead"
import { getGroupsTableOptions } from "../../../components/table/MyTable/options/columnsGroups"
import { useMyTable } from "../../../components/table/MyTable/useMyTable"
import { Head } from "../../../components/UI/Head"
import { urlParams } from "../../../constants/urlParams"
import { useComboSearch } from "../../../hooks/url/useComboSearch"
import { useFetchStates } from "../../../hooks/useFetchStates"
import { loader } from "../../../js-helpers/helpers"
import { loggedAdmin } from "../../../stores/loggedAdmin"

const {SEARCH, PAGE, SORT, LIMIT} = urlParams
const pageSearchParams = [
  {param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 'last'},
  {param: SORT, init: 'created_at'},
	{param: LIMIT}
]

export const PageGroups = () => {
  	const [
    [urlSearchQuery, setUrlSearchQuery],
		[urlPage, setUrlPage],
    [urlSortField],
		[urlLimit],
	] = useComboSearch(pageSearchParams)

  const [isShowModal, setIsShowModal] = useState(false)
  const [deletingGroup, setDeletingRule] = useState(null)
 
  const { data, getData, isReady } = useFetchStates(
    () => groupsAPI.getAll(urlPage, urlSearchQuery, urlSortField, urlLimit)
  );

  useEffect(() => {
    getData();
  }, [urlPage, urlSortField]);

  useUpdateEffect(() => {
    if (urlPage !== 'last') {
      setUrlPage('last');
      return;
    }
    getData();
  }, [urlSearchQuery, urlLimit])

  const deleteGroup = (data) => {
    setDeletingRule({
      id: data?.id,
      title: data?.title
    })
    setIsShowModal(true)
  }
  
  const closeModal = () => {
    setIsShowModal(false)
  }

  const onClickDelete = async(id) => {
    try {
      await groupsAPI.deleteItem(id);
      await getData();
      toast.success(`Группа "${deletingGroup.title}" удалена`);
    } catch (e) {
      toast.error('Ошибка удаления правила');
    } finally {
      closeModal();
    }
  }

  const {tableHeads, tableOptions} = useMyTable(getGroupsTableOptions(deleteGroup))

  const isSuperAdmin = loggedAdmin.isSuperAdmin

  const showEmptyFoundDataMessage = useMemo(() => urlSearchQuery, [urlSearchQuery])

  return (
    <> 
      <ButtonGoBack className={'float-right'}/>
      {isSuperAdmin && <Link to={'create/'} className={'btn btn-primary float-right mr-1'}>Создать группу</Link>}
   
      <Head children={'Настройка групп'}/>

      <SearchInput withBorder
			             className={'mb-1'}
			             onChange={e => setUrlSearchQuery(e.target.value)}
			             placeholder={'Поиск...'}
			             defaultValue={urlSearchQuery}
			/>

      <MyTable isDataReady={isReady}
			         showEmptyFoundDataMessage={!!showEmptyFoundDataMessage}
			         paginationAllCount={data?.count}
      >
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={data?.results}
				             tableOptions={tableOptions}
				             className={loader(isReady)}
				             highestRowToFill={25}
				/>
			</MyTable>

      {isShowModal && (
        <DeleteItemModal  title={deletingGroup.title}
                          onClickAbort={closeModal}
                          onClickDelete={() => onClickDelete(deletingGroup.id)}
                          titleText={'группу'}
        />
      )}
    </>
  )
}