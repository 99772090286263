import {isEmpty, isNotEmpty} from '../../../js-helpers/helpers';
import { contactTypes } from '../../members/pageUsers/PageUserContacts/PageUserContacts';

const pageFields = {
	last_name: 'Фамилия',
	first_name: 'имя',
	middle_name: 'отчество',
	birth_date: 'Дата рождения',
	phone_numbers: '№ телефона',
	division: 'Дивизион',
	subdivision: 'Подразделение',
	folders: 'папки',
	position: 'Должность',
	company: 'Компания',
	note: 'краткая заметка',
	emails: 'адрес электронной почты',
}

const commonFields =
	['last_name', 'first_name', 'middle_name', 'birth_date', 'phone_numbers',]

const fieldsOnContactType = {
	shared: ['company', 'division', 'subdivision', 'position', 'emails',],
	outer: ['company', 'position', 'note', 'emails',],
	personal: ['note', 'emails',],
}

export function prepareData(contact) {
	const arr = []
	
	const contactType = contactTypes.find(cont => cont.type === contact?.type).service
	const onContactTypeFields = [...commonFields, ...fieldsOnContactType[contactType]]
	
	onContactTypeFields.forEach(_name => {
		let value = contact?.[_name] || ''
		const label = pageFields[_name]
		
		if (_name === 'phone_numbers' && isNotEmpty(contact?.phone_numbers)) {
			contact?.phone_numbers?.forEach(({phone_number}) =>
				arr.push({label, value: phone_number}))
			return
		}
		
		if (_name === 'emails' && isNotEmpty(contact?.emails)) {
			contact?.emails?.forEach(({email}) =>
				arr.push({label, value: email}))
			return
		}
		
		if (_name === 'birth_date') {
			value = value.split('-').reverse().join('.')
		}

    if (_name === 'company') {
			value = contact?.company?.title
		}
		
		if (Array.isArray(value) && isEmpty(value))
			value = ''
		
		arr.push({label, value})
	})
	
	return arr
}